import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function EffectiveClause(props: ClauseComponentInterface) {
  const { getClauseDataByType, clauseData, fileId, hasData } = props;

  const data = React.useMemo(
    () => clauseData?.effectiveDate && clauseData.effectiveDate,
    [clauseData?.effectiveDate]
  );

  useEffect(() => {
    hasData && getClauseDataByType(props.fileId, "effective_date");
  }, [fileId, hasData]);

  const [counter, setCounter] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddNotify, setShowAddNotify] = useState(false);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(
      data?.raw_content?.effective_date,
      "Effective Dates"
    );
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Effective Dates</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={() => {
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", data?.raw_content?.effective_date);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${data?.raw_content?.effective_date?.length}`
                  : data?.raw_content?.effective_date?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={() => {
                    if (data?.raw_content?.effective_date?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", data?.raw_content?.effective_date);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            <div className="d-flex flex-column w-100">
              {!showAdd ? (
                <div className="d-flex flex-column w-100">
                  <div className="clause-present-heading">
                    <p className="m-0">Present</p>
                    <p className="m-0 font-weight-bold">Yes</p>
                    <div>
                      {!hasData && (
                        <Tooltip
                          title={"Auto extraction failed. Manually review"}
                          style={{ marginRight: "8px" }}
                        >
                          <img src="/static_images/clause-info.svg"></img>
                        </Tooltip>
                      )}
                      <DarkTooltip
                        title={"Add"}
                        placement="right-end"
                        onClick={() => {
                          props.onEdit(
                            "Effective Dates",
                            0,
                            false,
                            [],
                            -1,
                            "",
                            data?.raw_content?.effective_date
                          );
                          setShowAdd(true);
                        }}
                      >
                        <img src="/static_images/add-icn.svg" alt="add" />
                      </DarkTooltip>
                    </div>
                  </div>
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={props.toBeEdited}
                  savedInsight={
                    !isNullOrUndefined(data?.raw_content?.effective_date)
                      ? data?.raw_content?.effective_date
                      : []
                  }
                  savedParentClauseDataPoint={data?.raw_content?.effective_date}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={props.durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAdd(false);
                  }}
                  // onSubmit={(payload: any) => onUpdate(payload)}
                />
              )}
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Notify</p>
                {!showAddNotify ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Effective Dates Notify",
                        data?.raw_content?.effective_date,
                        setShowAddNotify
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited={props.toBeEdited}
                    savedInsight={
                      !isNullOrUndefined(data?.raw_content?.effective_date)
                        ? data?.raw_content?.effective_date
                        : []
                    }
                    savedParentClauseDataPoint={
                      data?.raw_content?.effective_date
                    }
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    durationList={props.durationList}
                    currencyList={props.currencyList}
                    contractData={props.contractData}
                    onClose={() => {
                      setShowAddNotify(false);
                    }}
                    //onSubmit={(payload: any) => onUpdate(payload)}
                  />
                )}
              </div>
              <div className="mt-2">
                {data?.raw_content?.effective_date?.map(
                  (item: any, index: number) => (
                    <div className="clause-div" key={index}>
                      <p className="clause-data">
                        {getPara(
                          props?.sentenceData,
                          item.para_id,
                          item.table && item.table[0]?.row,
                          item.table && item.table[0]?.column
                        )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
