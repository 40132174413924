import * as changesets from "json-diff-ts";

import { ClauseType, ClauseTypeMap } from "../../../../../Constants/ClauseType";
import {
  LinkSentenceRequest,
  SentencesData,
  editedSentences,
  sentenceInfo,
  tableInfo,
} from "../../../../../State/documentState";
import React, { Component } from "react";
import {
  deleteDataFromObligation,
  getClauseDataFormat,
  getClauseObjects,
  getEditedPayload,
} from "../../../../ClauseComponent/utils/ClauseTypeUtils";

import { dataForMap } from "../../../../Utils/docUtils";
import { duration } from "@mui/material";
import { regulatoryMap } from "../consentAddEdit";
import { table } from "console";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  dataPointName: string;
  savedInsightChild: any;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseItem: any;
  onClose: any;
  clauseData: any;
}

interface State {
  regulatory: string;
  origData: any;
  present: string;
}

export default class ConsentRegulatoryAddEdit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      regulatory: regulatoryMap[this.props.clauseItem?.type],
      origData: props.clauseDataByType,
      present: "Yes",
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      this.props.savedInsightChild !== nextProps.savedInsightChild &&
      nextProps.savedInsightChild.length > 0
    ) {
      this.setState({ regulatory: nextProps.savedInsightChild[0].name });
    }
  }

  render() {
    let { dataPointName } = this.props;
    let { regulatory } = this.state;
    return (
      <div className="row toc-content toc-content-default">
        <div className="col-md-12">
          <div className="row mt-1">
            <div className="col-md-4 my-2">Regulatory</div>
            <div
              className="col-md-3 my-2 ml-0"
              style={{ margin: "auto" }}
              onClick={() => this.setState({ regulatory: "Yes" })}
            >
              <img
                className="cursor-pointer"
                src={
                  regulatory === "Yes"
                    ? "/static_images/radio-active.svg"
                    : "/static_images/radio-inactive.svg"
                }
                alt="radio-btn"
              />
              &nbsp;&nbsp;
              <span>Yes</span>
            </div>
            <div
              className="col-md-3 my-2 ml-0"
              style={{ margin: "auto" }}
              onClick={() => this.setState({ regulatory: "No" })}
            >
              <img
                className="cursor-pointer"
                src={
                  regulatory === "No"
                    ? "/static_images/radio-active.svg"
                    : "/static_images/radio-inactive.svg"
                }
                alt="radio-btn"
              />
              &nbsp;&nbsp;
              <span>No</span>
            </div>
          </div>
          {this.saveOrCancelRegulatory()}
        </div>
      </div>
    );
  }

  saveOrCancelRegulatory() {
    return (
      <div className="row my-2">
        <div className="col-md-5" />
        <div className="col-md-7">
          <span
            className="add-datapoint"
            style={{ textDecoration: "none" }}
            onClick={() => this.onCancel()}
          >
            Cancel
          </span>
          {this.props.savedInsightChild.length > 0 &&
          this.state.regulatory !== this.props.savedInsightChild[0].name ? (
            <span
              className="upload-yellow-btn ml-4"
              id="save-btn"
              onClick={() => this.onSave()}
            >
              Save
            </span>
          ) : (
            <span className="upload-disable-btn ml-4" id="save-btn">
              Save
            </span>
          )}
        </div>
      </div>
    );
  }

  editPresent(addSentenceRequest: any) {
    let {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      updatedClauseDataByType,
      postClauseDataByType,
      parentClauseType,
      updatedClauseData,
      clauseData,
    } = this.props;

    let updatedData = updatedClauseDataByType;
    let newData = updatedData;
    let obligationData = updatedClauseData.obligationStatements;

    let clauseItem = this.props.clauseItem;
    if (this.state.present === "Yes") {
      if (addSentenceRequest?.editedSentences?.upsert) {
        let addedData = addSentenceRequest.editedSentences.upsert;
        for (let i = 0; i < addedData.length; i++) {
          if (this.state.regulatory === "Yes") {
            addedData[i].typestring = "Regulatory";
          } else {
            addedData[i].typestring = "Non-Regulatory";
          }
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData,
            clauseItem
          );
          obligationData = deleteDataFromObligation(
            "sentence",
            addedData[i],
            obligationData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    const obligationDiff = changesets.diff(
      clauseData?.obligationStatements?.raw_content,
      obligationData,
      { children: "$index" }
    );

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    if (obligationDiff.length > 0) {
      postClauseDataByType(
        fileId,
        "obligation_statements",
        obligationDiff,
        obligationData
      );
    }
  }

  onSave() {
    let addSentenceRequest = this.addOrRemoveRegulatory("add");
    this.props.editPresentSentences(addSentenceRequest);
    this.props.editOptionSelected(false);
    this.editPresent(addSentenceRequest);
    this.props.onClose();
  }

  onCancel() {
    this.props.onClose();
    this.props.editOptionSelected(false);
  }

  addOrRemoveRegulatory(action: string): LinkSentenceRequest {
    let { savedInsightChild, dataPointName } = this.props;
    let { regulatory } = this.state;
    let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };

    let mergeEditedSentences: editedSentences = {
      upsert: [
        {
          paraId: this.props.clauseItem.para_id,
          sentenceId: this.props.clauseItem.sentence_id,
          rowId: -1,
          columnId: -1,
          startWordId: -1,
          endWordId: -1,
          phrase: this.props.clauseItem.sentence,
          typestring: regulatoryMap[this.state.regulatory],
        },
      ],
      deleted: [],
      dataFor: dataForMap[dataPointName],
    };

    if (action === "add") {
      tempPresentList = {
        mode: "manual",
        editedSentences: mergeEditedSentences,
      };
    }
    return tempPresentList;
  }
}
