import { ClauseType } from "../../../Constants/ClauseType";
import { clauseTypeData } from "../State/clauseComponentState";

export const GETCLAUSEDATABYTYPE = "GETCLAUSEDATABYTYPE";
export type GETCLAUSEDATABYTYPE = typeof GETCLAUSEDATABYTYPE;
export const GETCLAUSEDATABYTYPE_SUCCESS = "GETCLAUSEDATABYTYPE_SUCCESS";
export type GETCLAUSEDATABYTYPE_SUCCESS = typeof GETCLAUSEDATABYTYPE_SUCCESS;
export const GETCLAUSEDATABYTYPE_FAILURE = "GETCLAUSDATATYPE_FAILURE";
export type GETCLAUSEDATABYTYPE_FAILURE = typeof GETCLAUSEDATABYTYPE_FAILURE;

export const UPDATECLAUSEDATA = "UPDATECLAUSEDATA";
export type UPDATECLAUSEDATA = "UPDATECLAUSEDATA";

export const POSTCLAUSEDATABYTYPE = "POSTCLAUSEDATABYTYPE";
export type POSTCLAUSEDATABYTYPE = typeof POSTCLAUSEDATABYTYPE;
export const POSTCLAUSEDATABYTYPE_SUCCESS = "POSTCLAUSEDATABYTYPE_SUCCESS";
export type POSTCLAUSEDATABYTYPE_SUCCESS = typeof POSTCLAUSEDATABYTYPE_SUCCESS;
export const POSTCLAUSEDATABYTYPE_FAILURE = "POSTCLAUSEDATABYTYPE_FAILURE";
export type POSTCLAUSEDATABYTYPE_FAILURE = typeof POSTCLAUSEDATABYTYPE_FAILURE;

export interface GetClauseDataByType {
  type: GETCLAUSEDATABYTYPE;
  payload: {
    fileID: string;
    type: ClauseType;
  };
}

export interface GetClauseDataByTypeSuccess {
  type: GETCLAUSEDATABYTYPE_SUCCESS;
  payload: {
    clauseData: clauseTypeData;
    type: ClauseType;
  };
}

export interface GetClauseDataByTypeFailure {
  type: GETCLAUSEDATABYTYPE_FAILURE;
}

export interface PostClauseDataByType {
  type: POSTCLAUSEDATABYTYPE;
  payload: {
    fileID: string;
    type: ClauseType;
    diff: any;
    updatedClauseData: any;
  };
}

export interface PostClauseDataByTypeSuccess {
  type: POSTCLAUSEDATABYTYPE_SUCCESS;
  payload: {
    type: ClauseType;
    updatedClauseData: any;
  };
}

export interface PostClauseDataByTypeFailure {
  type: POSTCLAUSEDATABYTYPE_FAILURE;
}

export interface UpdateClauseData {
  type: UPDATECLAUSEDATA;
  payload: {
    clauseData: any;
    type: ClauseType;
  };
}

export type ClauseComponentAction =
  | GetClauseDataByType
  | GetClauseDataByTypeSuccess
  | GetClauseDataByTypeFailure
  | PostClauseDataByType
  | PostClauseDataByTypeSuccess
  | PostClauseDataByTypeFailure
  | UpdateClauseData;
