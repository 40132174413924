import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { convertPDF, downloadFile } from "./Helper";
import {
  createDraft,
  draftDeviation,
  editDraftData,
  fetchKDPTagDeviations,
  fetchMergeTagDeviations,
  getDraftApprovals,
  getDraftById,
  getS3PresignedUrl,
  upload_file_in_s3_bucket,
} from "../../Services/Draft";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { version0Tabs, version1Tabs } from "./StaticData";

import ApprovalTab from "./Approval/Approval";
import ChecklistTab from "./ChecklistTab/ChecklistTab";
import ClauseLibrary from "../../Approvals/Component/ClauseLibrary/ClauseLibrary";
import ClauseLibraryTab from "./ClauseLibraryTab/ClauseLibraryTab";
import CommitDraftIcon from "../../RiverusUI/Components/Icons/CommitDraftIcon";
import CommitOutlinedIcon from "@mui/icons-material/CommitOutlined";
import CompleteDraft from "./CompleteDraft/CompleteDraft";
import DeviationTab from "./DeviationTab/DeviationTab";
import DraftHeader from "../../RiverusUI/Header/DraftHeader";
import DrawerComponent from "../../RiverusUI/Components/DrawerComponent";
import DropdownButton from "../../RiverusUI/Header/DropdownButton";
import GenerateDraftDialog from "./DeviationTab/GenerateDraftDialog";
import InsightsTab from "./InsightsTab/InsightsTab";
import { LoadingButton } from "@mui/lab";
import PdfView from "./PdfView";
import ReusableConfirmationModal from "../../RiverusUI/Components/ReusableConfirmationModal";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SignatureTab from "./SignatureTab/SignatureTab";
import UploadDraftDialog from "./CompleteDraft/UploadDraftDialog";
import { a11yProps } from "../../RiverusUI/Components/CustomTabPanel";
import { draftStatus } from "../State/DraftState";
import { fetchExtractedData } from "../../Services/Insights";
import { getBlobDocument } from "../../Templates/Component/DocGenerate";
import { isRiverusAdmin } from "../../DataDictionary/DDUtils";
import { riPrimary } from "../../RiverusUI/Theme/colors";
import { useSnackbar } from "notistack";

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: "20px",
  paddingX: "15px",
};

const EditDocumentSaveMenu: any[] = [
  {
    value: "save_draft",
    label: "Save Draft",
    startIcon: <SaveOutlinedIcon />,
  },
  {
    value: "save_and_commit",
    label: "Save and commit",
    startIcon: <CommitOutlinedIcon />,
  },
];

const SparkMD5 = require("spark-md5");

const ViewDraft: React.FC = () => {
  const { id, version } = useParams<{
    id: string;
    version: string;
  }>();
  const history = useHistory();

  const { search } = useLocation();
  const queryParams = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const queryParamsValue = queryParams.get("isDraftUpdated");

  const isDraftUpdated = React.useMemo(() => {
    if (queryParamsValue) {
      return queryParamsValue === "true" ? true : false;
    } else {
      return true;
    }
  }, [queryParamsValue]);

  const [hideSignatureButton, setHideSignatureButton] =
    useState<boolean>(false);
  const [addedSignatureFields, setAddedSignatureFields] =
    useState<boolean>(false);
  const [dropPoint, setDropPoint] = useState<any>();
  const [tabs, setTabs] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<string>("complete_draft");
  const [openUploadDraftDialog, setOpenUploadDraftDialog] =
    useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(true);
  const [openClauseLibrary, setOpenClauseLibrary] = useState<boolean>(false);

  const [uploadedFile, setUploadedFile] = useState<any>();
  const [instance, setInstance] = useState<any>();
  const [uploadedFileData, setUploadedFileData] = useState<any>();
  const [completeDraftFormData, setCompleteDraftFormData] = useState<any>([]);
  const [isFieldUpdated, setFieldUpdated] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [status, setStatus] = useState<string>();
  const [versionType, setVersionType] = useState<string>("major");
  const [isOpenMergeTagDialog, setIsOpenMergeTagDialog] =
    useState<boolean>(false);
  const [falseMergeTags, setFalseMergeTags] = useState<any[]>([]);
  const [allMergeTags, setAllMergeTags] = useState<any[]>([]);
  const [openCommitDraftModal, setOpenCommitDraftModal] =
    useState<boolean>(false);
  const [confirmationDescription, setConfirmationDescription] =
    useState<string>("");
  const [confirmationText, setConfirmationText] = useState<string>("");
  const [deviationType, setDeviationType] = useState<string>("");
  const [redirect, setRedirect] = useState<string>("");
  const [saveDraftLoading, setSaveDraftLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: draftData } = useQuery(
    ["get_draft_by_id", id],
    async () => {
      const response = await getDraftById(id);
      return response;
    },
    { enabled: !!id }
  );

  const { data: approvalData, isLoading: approvalLoading } = useQuery(
    ["draft_approvals", draftData?.id, version],
    async () => {
      let type =
        version === "0" ? "requisition_approvers" : "deviation_approvers";
      const response = await getDraftApprovals(draftData?.id, type);
      return response?.results;
    },
    { enabled: !!draftData?.id }
  );

  const { data: extractedData } = useQuery(
    ["clause_term", draftData?.id],
    async () => {
      const response = await fetchExtractedData(draftData?.id);
      return response;
    },
    {
      enabled: !!id,
    }
  );

  const disableGenerateButton = useMemo(() => {
    let disabled = false;
    if (approvalData?.length) {
      approvalData.map((approver: any) => {
        let approvedBy = approver.approvers.filter(
          (data: any) => data.status === "approved"
        );
        if (approver.approval_sequence === "any") {
          if (approvedBy.length) {
            disabled = false;
          } else {
            disabled = true;
          }
        } else {
          if (approvedBy.length === approver.approvers.length) {
            disabled = false;
          } else {
            disabled = true;
          }
        }
        return disabled;
      });
    }
    return disabled;
  }, [approvalData]);

  const collaboratorDisabled = useMemo(() => {
    if (
      draftData?.status.toLowerCase().includes("signature") ||
      draftData?.status.toLowerCase().includes("contract executed successfully")
    ) {
      return true;
    }
    return false;
  }, [draftData?.status]);

  useEffect(() => {
    if (parseInt(version) === 0) {
      setTabs(version0Tabs);
      setActiveTab("complete_draft");
    } else if (
      (draftData?.status?.toLowerCase().includes("internal draft created") ||
        draftData?.status
          .toLowerCase()
          .includes("counter party draft created")) &&
      isDraftUpdated
    ) {
      setTabs([
        {
          label: "Issues",
          value: "issues",
        },
        {
          label: "Clause Library",
          value: "clause_library",
        },
        ...version1Tabs,
      ]);
      setActiveTab("insights");
    } else if (
      (draftData?.status?.toLowerCase().includes("signature pending") ||
        draftData?.status
          .toLowerCase()
          .includes("contract executed successfully") ||
        draftData?.status.toLowerCase().includes("committed for signature")) &&
      isDraftUpdated
    ) {
      setTabs([
        {
          label: "Signatures",
          value: "signature",
        },
        ...version1Tabs,
      ]);
      setActiveTab("signature");
    } else {
      setTabs(version1Tabs);
      setActiveTab("insights");
    }
  }, [version, draftData]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const fileLink = useMemo(() => draftData?.access_url, [draftData]);

  const handleCloseUploadDialog = () => {
    setOpenUploadDraftDialog(false);
  };

  const handleCloseCommitDraftModal = () => {
    setOpenCommitDraftModal(false);
    setConfirmationDescription("");
    setConfirmationText("");
  };

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      let reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      let file = uploadedFile?.[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedFile]
  );

  const { mutate: updateDraftData, isLoading: updatingDraft } = useMutation({
    mutationFn: editDraftData,
    onSuccess: (response: any) => {
      queryClient.invalidateQueries({
        queryKey: ["get_draft_by_id"],
      });
      enqueueSnackbar("Successfully updated draft data!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      redirect === "/draftingreview"
        ? history.push(redirect)
        : history.push(`/draft/${response.data.version}/${response?.data?.id}`);

      instance?.UI.loadDocument(response.data.access_url);
      setLoaded(true);
    },
    onError: () => {
      enqueueSnackbar("Failed to update draft data!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: createDraftMutation, isLoading: creatingDraft } = useMutation(
    ["create_draft"],
    async (payload: any) => createDraft(payload),
    {
      onSuccess: (response: any) => {
        queryClient.invalidateQueries({
          queryKey: ["get_draft_by_id"],
        });
        enqueueSnackbar("Draft edited successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        history.push(`/draft/${response?.data?.version}/${response?.data?.id}`);
        instance?.UI.loadDocument(response?.data?.access_url);
        setLoaded(true);
        if (openCommitDraftModal) {
          handleCloseCommitDraftModal();
        }
      },
      onError: () => {
        enqueueSnackbar("Failed to edit Draft!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const { mutate: uploadDocInS3Bucket, isLoading: uploadingDoc } = useMutation({
    mutationKey: [
      "upload_document_in_S3_bucket",
      uploadedFileData,
      versionType,
    ],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: () => {
      const draftPayload = {
        version: draftData?.version + 1,
        link: uploadedFileData?.presigned_url?.file_path,
        version_type: versionType,
        draftID: draftData?.draftID,
        collaborators: draftData?.collaborators.map((data: any) => data.id),
        approvers: draftData?.approvers.map((data: any) => data.id),
        owners: draftData?.owners.map((data: any) => data.id),
        contractType: draftData?.contractType.id,
        contractName: draftData?.contractName,
        groups: draftData?.groups,
        createFrom: draftData?.createFrom,
        status: canEdit ? draftData?.status : status,
        earlier_draft_link: draftData?.earlier_draft_link,
        executed_contract_link: draftData?.executed_contract_link,
        support_document_link: draftData?.support_document_link,
      };
      createDraftMutation(draftPayload);
      setLoaded(false);
      handleCloseUploadDialog();
    },
    onError: () => {
      enqueueSnackbar("Failed to upload the document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: uploadDocument, isLoading: loadingUploadDoc } = useMutation({
    mutationKey: ["upload_draft_document", uploadedFile],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response) => {
      if (response?.data) {
        setUploadedFileData(response?.data);
        let file = uploadedFile;
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };

          uploadDocInS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar("Failed to upload document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const generateDraft = useCallback(() => {
    if (isFieldUpdated) {
      document.getElementById("save_draft_button")?.click();
    }

    let flatArr = completeDraftFormData.flatMap(
      (section: any) => section.field_data
    );
    let keyValuePair: any = flatArr.reduce((acc: any, curr: any) => {
      let key = curr.field_name.replaceAll(/{|}/g, "");
      if (curr.data_type?.toLowerCase() === "duration") {
        acc = { ...acc, [key]: curr.field_value + " " + curr.fieldLength };
      } else if (
        curr.data_type.toLowerCase() === "bulleted" ||
        curr.data_type.toLowerCase() === "numbered"
      ) {
        acc = {
          ...acc,
          [key]: JSON.parse(curr.field_value).filter(
            (data: any) => data["list"] !== ""
          ),
        };
      } else acc = { ...acc, [key]: curr.field_value };
      return acc;
    }, {});

    for (let key in keyValuePair) {
      if (keyValuePair[key] === "") {
        keyValuePair[key] = "{" + key + "}";
      }
    }

    getBlobDocument(keyValuePair, draftData.access_url, (response: any) => {
      const file = response;
      file["name"] = draftData.contractName + ".docx";
      file["id"] = draftData.id;
      let reader = new FileReader();
      if (file) {
        setUploadedFile(file);
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          let file_ = {
            file_hash: hexHash,
            file_size: file.size,
            type: file.type,
            template_type: file["template_type"],
            file_type: "Contract_Draft",
            creation_type: isRiverusAdmin() ? "system" : "custom",
            file_name: file.name,
          };
          setStatus(draftStatus.INTERNAL_DRAFT_CREATED);
          uploadDocument(file_);
        };
      }
    });
  }, [completeDraftFormData, draftData, uploadDocument, isFieldUpdated]);

  //  signatureTab flow

  const handleDownload = React.useCallback(
    async (value: string) => {
      const { Core } = instance;
      if (value === "pdf") {
        const { Core } = instance;
        if (fileLink) {
          let file = await convertPDF(Core, fileLink);
          downloadFile(file, draftData?.contractName, "pdf");
        }
      } else {
        const doc = Core.documentViewer.getDocument();
        const annotationManager = Core.documentViewer.getAnnotationManager();
        const xfdfString = await annotationManager.exportAnnotations();
        const data = await doc.getFileData({
          // saves the document with annotations in it
          xfdfString,
        });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: "application/docx" });
        downloadFile(blob, draftData?.contractName, "docx");
      }
    },
    [draftData?.contractName, fileLink, instance]
  );

  const convertDocToPdf = async () => {
    if (!instance) return;
    const { Core } = instance;
    if (fileLink) {
      let file = await convertPDF(Core, fileLink);
      let reader = new FileReader();
      if (file) {
        setUploadedFile(file);
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          let file_ = {
            file_hash: hexHash,
            file_name: draftData.contractName + ".pdf",
            file_size: file?.size,
            file_type: "committed_draft",
            creation_type: isRiverusAdmin() ? "system" : "custom",
            type: file?.type,
          };

          setStatus(draftStatus.COMMITTED_FOR_SIGNATURE);
          uploadDocument(file_);
        };
      }
    }
  };

  const handleEditingModeSaveDraft = async (value: any) => {
    const { documentViewer } = instance.Core;

    const doc = documentViewer.getDocument();
    const annotationManager = documentViewer.getAnnotationManager();

    const xfdfString = await annotationManager.exportAnnotations();
    const data = await doc.getFileData({
      // saves the document with annotations in it
      xfdfString,
    });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: "application/docx" });
    const file = blob;

    let reader = new FileReader();
    if (file) {
      setUploadedFile(file);

      reader.readAsDataURL(file);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        let file_ = {
          file_hash: hexHash,
          file_name: draftData.contractName + ".docx",
          file_size: file.size,
          file_type:
            value === "save_draft" ? "committed_draft" : "Contract_Draft",
          creation_type: isRiverusAdmin() ? "system" : "custom",
          type: file.type,
        };
        setVersionType(value === "save_draft" ? "minor" : "major");
        uploadDocument(file_);
      };
    }
  };

  const handleDialogClose = () => {
    setIsOpenMergeTagDialog(false);
  };

  const { mutate: generateMergeTagIssue, isLoading: generatingMergeTagIssue } =
    useMutation({
      mutationFn: draftDeviation,
      onSuccess: () => {
        if (isOpenMergeTagDialog) {
          handleDialogClose();
        }
        generateDraft();
      },
    });

  const handleGenerateDraft = () => {
    generateMergeTagIssue(allMergeTags);
  };

  const { mutate: mergeTagDeviations, isLoading: isLoadingTagDeviation } =
    useMutation({
      mutationKey: ["merge_tag_deviation", id],
      mutationFn: fetchMergeTagDeviations,
      onSuccess: (response: any) => {
        const allMergeTagsArray = response?.results;
        setAllMergeTags(allMergeTagsArray);
        const filterData = allMergeTagsArray?.filter(
          (item: any) => item?.error === false
        );
        setFalseMergeTags(filterData);
        if (filterData?.length > 0) {
          setIsOpenMergeTagDialog(true);
        } else if (allMergeTagsArray?.length > 0 && filterData?.length === 0) {
          generateMergeTagIssue(allMergeTagsArray);
        } else if (allMergeTagsArray?.length === 0) {
          generateDraft();
        }
      },
    });

  const draftId =
    draftData?.createFrom === "template" && draftData?.version === 1
      ? draftData?.draftID
      : draftData?.id;

  useEffect(() => {
    if (draftData?.createFrom === "template" && draftData?.version === 1) {
      setDeviationType("merge_tag");
    } else {
      setDeviationType("kdp");
    }
  }, [draftData]);

  const { data: deviationsList } = useQuery(
    ["fetch_Kdp_deviations", draftId, deviationType],
    async () => {
      const response = await fetchKDPTagDeviations(draftId, deviationType);
      return response?.results;
    },
    { enabled: !!draftId && !!deviationType }
  );

  const { data: customDeviationList } = useQuery(
    ["custom_deviations", draftId],
    async () => {
      const response = await fetchKDPTagDeviations(draftId, "custom");
      return response?.results;
    },
    { enabled: !!draftId }
  );

  const handleOpenCommitDraftModal = () => {
    const filteredDeviationsList = deviationsList?.filter((listItem: any) => {
      if (
        (listItem?.approval_status === "approval_pending" ||
          listItem?.approval_status === null) &&
        listItem?.status === "deviant"
      ) {
        if (
          listItem?.draft_datapoint ||
          listItem?.kdp_datapoint?.length > 0 ||
          listItem?.manual_datapoint?.length > 0
        ) {
          return listItem;
        }
      }
    });

    const filterCustomApprovalPending = customDeviationList?.filter(
      (listItem: any) => {
        if (
          listItem?.approval_status === "approval_pending" ||
          listItem?.approval_status === null
        ) {
          if (listItem?.custom_datapoint?.length > 0) {
            return listItem;
          }
        }
      }
    );

    if (
      filteredDeviationsList?.length > 0 ||
      filterCustomApprovalPending?.length > 0
    ) {
      setConfirmationDescription(
        "Some Issues are still not resolved or require approval. Please resolve all Issues before you can Commit draft your draft."
      );
      setConfirmationText("Resolve Issues");
    } else {
      setConfirmationDescription(
        "Are you sure? You will not be able to make any changes to the draft and it will be shared with the signatories for signing."
      );
      setConfirmationText("Commit draft");
    }
    setOpenCommitDraftModal(true);
  };

  const handleConfirmActionBtn = () => {
    if (confirmationText === "Commit draft") {
      convertDocToPdf();
      handleCloseCommitDraftModal();
    } else if (confirmationText === "Resolve Issues") {
      if (
        draftData?.status.toLowerCase().includes("internal draft created") ||
        draftData?.status.toLowerCase().includes("counter party draft created")
      ) {
        setActiveTab("issues");
        handleCloseCommitDraftModal();
      }
    }
  };

  const switchToDocxEdit = useCallback(() => {
    setCanEdit(true);
  }, []);

  return (
    <React.Fragment>
      <Box>
        <DraftHeader
          version={parseInt(version)}
          name={draftData?.contractName}
          handleDownload={handleDownload}
          renderAction={() =>
            version === "0" ? (
              <Stack direction="row">
                <LoadingButton
                  type="submit"
                  id="save_draft_button"
                  form="complete_draft_form"
                  loading={saveDraftLoading || updatingDraft}
                  disabled={!isDraftUpdated}
                >
                  Save Draft
                </LoadingButton>
                {isDraftUpdated && (
                  <LoadingButton
                    variant="contained"
                    disabled={disableGenerateButton}
                    onClick={() => mergeTagDeviations(id)}
                    loading={isLoadingTagDeviation || generatingMergeTagIssue}
                  >
                    Generate Draft
                  </LoadingButton>
                )}
              </Stack>
            ) : canEdit ? (
              <DropdownButton
                options={EditDocumentSaveMenu}
                startIcon={<SaveOutlinedIcon />}
                buttonName="Save draft"
                handleDropdownChange={handleEditingModeSaveDraft}
              />
            ) : (
              (draftData?.status
                .toLowerCase()
                .includes("internal draft created") ||
                draftData?.status
                  .toLowerCase()
                  .includes("counter party draft created")) && (
                <LoadingButton
                  variant="contained"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                  }}
                  loading={loadingUploadDoc || uploadingDoc || creatingDraft}
                  disabled={!isDraftUpdated}
                  onClick={handleOpenCommitDraftModal}
                >
                  <CommitDraftIcon sx={{ mt: "4px" }} /> Commit Draft
                </LoadingButton>
              )
            )
          }
          canDownload={draftData?.version !== 0}
          collaborating={draftData?.version !== 0}
          collaboratorDisabled={collaboratorDisabled}
          goBackUrl="/draftingreview"
          setCanEdit={setCanEdit}
        />
        <Grid container sx={{ mt: 2 }}>
          <Grid item sm={canEdit ? 12 : 6}>
            {loaded && (
              <PdfView
                key={fileLink}
                draftData={draftData}
                fileLink={fileLink}
                instance={instance}
                setInstance={setInstance}
                dropPoint={dropPoint}
                setDropPoint={setDropPoint}
                addedSignatureFields={addedSignatureFields}
                setAddedSignatureFields={setAddedSignatureFields}
                hideSignatureButton={hideSignatureButton}
                setHideSignatureButton={setHideSignatureButton}
                canEdit={canEdit}
                handleOpenClauseLibraryDrawer={() => setOpenClauseLibrary(true)}
              />
            )}
          </Grid>
          {!canEdit && (
            <Grid item sm={6} sx={{ paddingLeft: 3 }}>
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="Draft tabs"
                variant="scrollable"
                scrollButtons={false}
                sx={innerTabStyle}
              >
                {tabs.map((tab: any, index: number) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    value={tab.value}
                    {...a11yProps(tab.value)}
                  />
                ))}
              </Tabs>
              {activeTab === "issues" ? (
                <DeviationTab
                  draftData={draftData}
                  instance={instance}
                  setActiveTab={setActiveTab}
                  handleOpenClauseLibraryDrawer={() =>
                    setOpenClauseLibrary(true)
                  }
                />
              ) : (
                <Stack
                  spacing={1}
                  sx={{
                    height: "90%",
                    overflowY: "auto",
                    backgroundColor: "riTertiary.50",
                    borderRadius: "24px",
                    padding: "8px",
                    mt: "8px",
                  }}
                >
                  {activeTab === "complete_draft" && (
                    <CompleteDraft
                      draftData={draftData}
                      setCompleteDraftFormData={setCompleteDraftFormData}
                      instance={instance}
                      setFieldUpdated={setFieldUpdated}
                      updateDraftData={updateDraftData}
                      setSaveDraftLoading={setSaveDraftLoading}
                    />
                  )}
                  {activeTab === "checklist" && (
                    <ChecklistTab draftData={draftData} />
                  )}
                  {activeTab === "clause_library" && <ClauseLibraryTab />}
                  {activeTab === "approvals" && (
                    <ApprovalTab
                      approvalData={approvalData}
                      draftData={draftData}
                      version={version}
                      approvalLoading={approvalLoading}
                    />
                  )}
                  {activeTab === "insights" && (
                    <InsightsTab
                      draftData={draftData}
                      instance={instance}
                      extractedData={extractedData}
                    />
                  )}
                  {activeTab === "signature" && (
                    <SignatureTab
                      draftData={draftData}
                      instance={instance}
                      dropPoint={dropPoint}
                      addedSignatureFields={addedSignatureFields}
                      hideSignatureButton={hideSignatureButton}
                      setAddedSignatureFields={setAddedSignatureFields}
                      updateDraftData={updateDraftData}
                      setRedirect={setRedirect}
                      createDraft={createDraftMutation}
                    />
                  )}
                </Stack>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
      <UploadDraftDialog
        files={uploadedFile}
        open={openUploadDraftDialog}
        onClose={handleCloseUploadDialog}
        setFiles={setUploadedFile}
        loadingUploadDoc={loadingUploadDoc}
        uploadDocument={uploadDocument}
        draftID={id}
      />
      <GenerateDraftDialog
        open={isOpenMergeTagDialog}
        onClose={handleDialogClose}
        mergeTagData={falseMergeTags}
        handleGenerateDraft={handleGenerateDraft}
      />
      <ReusableConfirmationModal
        open={openCommitDraftModal}
        onClose={handleCloseCommitDraftModal}
        onConfirm={handleConfirmActionBtn}
        title="Commit Draft"
        confirmBtnText={confirmationText}
        cancelBtnText="Cancel"
        children={<Typography>{confirmationDescription}</Typography>}
      />
      <DrawerComponent
        open={openClauseLibrary}
        onClose={() => setOpenClauseLibrary(false)}
        drawerHeader="Contract checklist: Photo Licensing Agreement"
      >
        <ClauseLibrary
          isClauseLibraryTab={true}
          openDocxEditor={switchToDocxEdit}
          isDocxOpen={canEdit}
        />
      </DrawerComponent>
    </React.Fragment>
  );
};

export default ViewDraft;
