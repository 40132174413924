import {
  ClauseFolderState,
  ClauseInfo,
  ClauseStructure,
  FolderHeadingInfo,
  SectionClauseInfo,
  SectionDictionaryInfo,
} from "../State/clauseLibraryState";

// For Section Dictionary

export const GETSECTIONDICTIONARY = "GETSECTIONDICTIONARY";
export type GETSECTIONDICTIONARY = typeof GETSECTIONDICTIONARY;
export const GETSECTIONDICTIONARY_SUCCESS = "GETSECTIONDICTIONARY_SUCCESS";
export type GETSECTIONDICTIONARY_SUCCESS = typeof GETSECTIONDICTIONARY_SUCCESS;
export const GETSECTIONDICTIONARY_FAILURE = "GETSECTIONDICTIONARY_FAILURE";
export type GETSECTIONDICTIONARY_FAILURE = typeof GETSECTIONDICTIONARY_FAILURE;

// For Section Clause

export const GETSECTIONCLAUSE = "GETSECTIONCLAUSE";
export type GETSECTIONCLAUSE = typeof GETSECTIONCLAUSE;
export const GETSECTIONCLAUSE_SUCCESS = "GETSECTIONCLAUSE_SUCCESS";
export type GETSECTIONCLAUSE_SUCCESS = typeof GETSECTIONCLAUSE_SUCCESS;
export const UPDATESECTIONCLAUSE = "UPDATESECTIONCLAUSE";
export type UPDATESECTIONCLAUSE = typeof UPDATESECTIONCLAUSE;
export const UPDATESECTIONCLAUSE_SUCCESS = "UPDATESECTIONCLAUSE_SUCCESS";
export type UPDATESECTIONCLAUSE_SUCCESS = typeof UPDATESECTIONCLAUSE_SUCCESS;
export const GETSECTIONCLAUSE_FAILURE = "GETSECTIONCLAUSE_FAILURE";
export type GETSECTIONCLAUSE_FAILURE = typeof GETSECTIONCLAUSE_FAILURE;

export const GETFOLDERHEADING = "GETFOLDERHEADING";
export type GETFOLDERHEADING = typeof GETFOLDERHEADING;
export const GETFOLDERHEADING_SUCCESS = "GETFOLDERHEADING_SUCCESS";
export type GETFOLDERHEADING_SUCCESS = typeof GETFOLDERHEADING_SUCCESS;
export const GETFOLDERHEADING_FAILURE = "GETFOLDERHEADING_FAILURE";
export type GETFOLDERHEADING_FAILURE = typeof GETFOLDERHEADING_FAILURE;

export const GETFOLDERSUBHEADING = "GETFOLDERSUBHEADING";
export type GETFOLDERSUBHEADING = typeof GETFOLDERSUBHEADING;
export const GETFOLDERSUBHEADING_SUCCESS = "GETFOLDERSUBHEADING_SUCCESS";
export type GETFOLDERSUBHEADING_SUCCESS = typeof GETFOLDERSUBHEADING_SUCCESS;
export const GETFOLDERSUBHEADING_FAILURE = "GETFOLDERSUBHEADING_FAILURE";
export type GETFOLDERSUBHEADING_FAILURE = typeof GETFOLDERSUBHEADING_FAILURE;

export const CREATEFOLDERHEADING = "CREATEFOLDERHEADING";
export type CREATEFOLDERHEADING = typeof CREATEFOLDERHEADING;
export const CREATEFOLDERHEADING_SUCCESS = "CREATEFOLDERHEADING_SUCCESS";
export type CREATEFOLDERHEADING_SUCCESS = typeof CREATEFOLDERHEADING_SUCCESS;
export const CREATEFOLDERHEADING_FAILURE = "CREATEFOLDERHEADING_FAILURE";
export type CREATEFOLDERHEADING_FAILURE = typeof CREATEFOLDERHEADING_FAILURE;

export const CREATEFOLDERSUBHEADING = "CREATEFOLDERSUBHEADING";
export type CREATEFOLDERSUBHEADING = typeof CREATEFOLDERSUBHEADING;
export const CREATEFOLDERSUBHEADING_SUCCESS = "CREATEFOLDERSUBHEADING_SUCCESS";
export type CREATEFOLDERSUBHEADING_SUCCESS =
  typeof CREATEFOLDERSUBHEADING_SUCCESS;
export const CREATEFOLDERSUBHEADING_FAILURE = "CREATEFOLDERSUBHEADING_FAILURE";
export type CREATEFOLDERSUBHEADING_FAILURE =
  typeof CREATEFOLDERSUBHEADING_FAILURE;

// Create Section Clause

export const CREATESECTIONCLAUSE = "CREATESECTIONCLAUSE";
export type CREATESECTIONCLAUSE = typeof CREATESECTIONCLAUSE;
export const CREATESECTIONCLAUSE_SUCCESS = "CREATESECTIONCLAUSE_SUCCESS";
export type CREATESECTIONCLAUSE_SUCCESS = typeof CREATESECTIONCLAUSE_SUCCESS;
export const CREATESECTIONCLAUSE_FAILURE = "CREATESECTIONCLAUSE_FAILURE";
export type CREATESECTIONCLAUSE_FAILURE = typeof CREATESECTIONCLAUSE_FAILURE;

export const CREATECLAUSE = "CREATECLAUSE";
export type CREATECLAUSE = typeof CREATECLAUSE;
export const CREATECLAUSE_SUCCESS = "CREATECLAUSE_SUCCESS";
export type CREATECLAUSE_SUCCESS = typeof CREATECLAUSE_SUCCESS;
export const CREATECLAUSE_FAILURE = "CREATECLAUSE_FAILURE";
export type CREATECLAUSE_FAILURE = typeof CREATECLAUSE_FAILURE;

export const SAVECOLLAPSEDFOLERIDS = "SAVECOLLAPSEDFOLERIDS";
export type SAVECOLLAPSEDFOLERIDS = typeof SAVECOLLAPSEDFOLERIDS;

export const EDITCLAUSE = "EDITCLAUSE";
export type EDITCLAUSE = typeof EDITCLAUSE;
export const EDITCLAUSE_SUCCESS = "EDITCLAUSE_SUCCESS";
export type EDITCLAUSE_SUCCESS = typeof EDITCLAUSE_SUCCESS;
export const EDITCLAUSE_FAILURE = "EDITCLAUSE_FAILURE";
export type EDITCLAUSE_FAILURE = typeof EDITCLAUSE_FAILURE;

export const SAVESELECTEDCLAUSEDATA = "SAVESELECTEDCLAUSEDATA";
export type SAVESELECTEDCLAUSEDATA = typeof SAVESELECTEDCLAUSEDATA;

export const SAVEFOLDERSUBHEADING = "SAVEFOLDERSUBHEADING";
export type SAVEFOLDERSUBHEADING = typeof SAVEFOLDERSUBHEADING;

export const DELETECLAUSE = "DELETECLAUSE";
export type DELETECLAUSE = typeof DELETECLAUSE;
export const DELETECLAUSE_SUCCESS = "DELETECLAUSE_SUCCESS";
export type DELETECLAUSE_SUCCESS = typeof DELETECLAUSE_SUCCESS;
export const DELETECLAUSE_FAILURE = "DELETECLAUSE_FAILURE";
export type DELETECLAUSE_FAILURE = typeof DELETECLAUSE_FAILURE;

export const DELETEFOLDER = "DELETEFOLDER";
export type DELETEFOLDER = typeof DELETEFOLDER;
export const DELETEFOLDER_SUCCESS = "DELETEFOLDER_SUCCESS";
export type DELETEFOLDER_SUCCESS = typeof DELETEFOLDER_SUCCESS;
export const DELETEFOLDER_FAILURE = "DELETEFOLDER_FAILURE";
export type DELETEFOLDER_FAILURE = typeof DELETEFOLDER_FAILURE;

export const GETCLAUSELIBRARYDATA = "GETCLAUSELIBRARYDATA";
export type GETCLAUSELIBRARYDATA = typeof GETCLAUSELIBRARYDATA;
export const GETCLAUSELIBRARYDATA_SUCCESS = "GETCLAUSELIBRARYDATA_SUCCESS";
export type GETCLAUSELIBRARYDATA_SUCCESS = typeof GETCLAUSELIBRARYDATA_SUCCESS;
export const GETCLAUSELIBRARYDATA_FAILURE = "GETCLAUSELIBRARYDATA_FAILURE";
export type GETCLAUSELIBRARYDATA_FAILURE = typeof GETCLAUSELIBRARYDATA_FAILURE;

export const GETCLAUSESDATA = "GETCLAUSESDATA";
export type GETCLAUSESDATA = typeof GETCLAUSESDATA;
export const GETCLAUSESDATA_SUCCESS = "GETCLAUSESDATA_SUCCESS";
export type GETCLAUSESDATA_SUCCESS = typeof GETCLAUSESDATA_SUCCESS;
export const GETCLAUSESDATA_FAILURE = "GETCLAUSESDATA_FAILURE";
export type GETCLAUSESDATA_FAILURE = typeof GETCLAUSESDATA_FAILURE;

// For Section Dictionary
export interface GetSectionDictionary {
  type: GETSECTIONDICTIONARY;
}

export interface GetSectionDictionarySuccess {
  type: GETSECTIONDICTIONARY_SUCCESS;
  payload: {
    sectionDictionaryList: SectionDictionaryInfo[];
  };
}

export interface GetSectionDictionaryFailure {
  type: GETSECTIONDICTIONARY_FAILURE;
}

// For Section Clause

export interface GetSectionClause {
  type: GETSECTIONCLAUSE;
  payload: number;
  creation_type: string;
}

export interface UpdateSectionClause {
  type: UPDATESECTIONCLAUSE;
}

export interface UpdateSectionClauseSuccess {
  type: UPDATESECTIONCLAUSE_SUCCESS;
  payload: {
    updateSectionClauseList: SectionClauseInfo[];
  };
}
export interface GetSectionClauseSuccess {
  type: GETSECTIONCLAUSE_SUCCESS;
  payload: {
    sectionClauseList: SectionClauseInfo[];
    totalCount: number;
  };
}

export interface GetSectionClauseFailure {
  type: GETSECTIONCLAUSE_FAILURE;
}

export interface GetFolderHeading {
  type: GETFOLDERHEADING;
}

export interface GetFolderHeadingSuccess {
  type: GETFOLDERHEADING_SUCCESS;
  payload: {
    folderHeadingList: FolderHeadingInfo[];
  };
}

export interface GetFolderHeadingFailure {
  type: GETFOLDERHEADING_FAILURE;
}

export interface GetFolderSubHeading {
  type: GETFOLDERSUBHEADING;
  payload: {
    id: string;
  };
}

export interface GetFolderSubHeadingSuccess {
  type: GETFOLDERSUBHEADING_SUCCESS;
  payload: {
    folderSubHeadingList: FolderHeadingInfo[];
  };
}

export interface GetFolderSubHeadingFailure {
  type: GETFOLDERSUBHEADING_FAILURE;
}

export interface CreateFolderHeading {
  type: CREATEFOLDERHEADING;
  payload: {
    name: string;
  };
}

export interface CreateFolderHeadingSuccess {
  type: CREATEFOLDERHEADING_SUCCESS;
}

export interface CreateFolderHeadingFailure {
  type: CREATEFOLDERHEADING_FAILURE;
}

export interface CreateFolderSubHeading {
  type: CREATEFOLDERSUBHEADING;
  payload: {
    name: string;
    id: string;
  };
}

export interface CreateFolderSubHeadingSuccess {
  type: CREATEFOLDERSUBHEADING_SUCCESS;
}

export interface CreateFolderSubHeadingFailure {
  type: CREATEFOLDERSUBHEADING_FAILURE;
}

//Create Section Clause

export interface CreateSectionClause {
  type: CREATESECTIONCLAUSE;
  payload: {
    section_name: string;
    clause_name: string;
    clause_type: string;
    clause_source: string;
    clause_text: string;
    description: string;
    creation_type: string;
    tags: any;
  };
}

export interface CreateSectionClauseSuccess {
  type: CREATESECTIONCLAUSE_SUCCESS;
}

export interface CreateSectionClauseFailure {
  type: CREATESECTIONCLAUSE_FAILURE;
}

export interface CreateClause {
  type: CREATECLAUSE;
  payload: {
    clauseName: string;
    extractedText: string;
    userText: string;
    clauseType: string;
    sourceFileId: number;
    folderId: string;
    id: string;
  };
}

export interface CreateClauseSuccess {
  type: CREATECLAUSE_SUCCESS;
}

export interface CreateClauseFailure {
  type: CREATECLAUSE_FAILURE;
}

export interface SaveCollapsedFolderIds {
  type: SAVECOLLAPSEDFOLERIDS;
  payload: {
    collapsedFolderIds: string[];
  };
}

export interface EditClause {
  type: EDITCLAUSE;
  payload: {
    clauseId: string;
    clauseName: string;
    userText: string;
    clauseType: string;
    folderId: string;
  };
}

export interface EditClauseSuccess {
  type: EDITCLAUSE_SUCCESS;
}

export interface EditClauseFailure {
  type: EDITCLAUSE_FAILURE;
}

export interface SaveSelectedClauseData {
  type: SAVESELECTEDCLAUSEDATA;
  payload: {
    selectedClauseData: ClauseInfo;
  };
}

export interface SaveFolderSubHeading {
  type: SAVEFOLDERSUBHEADING;
  payload: {
    folderSubHeadingList: FolderHeadingInfo[];
  };
}

export interface DeleteClause {
  type: DELETECLAUSE;
  payload: {
    clauseId: string;
    folderId: string;
  };
}

export interface DeleteClauseSuccess {
  type: DELETECLAUSE_SUCCESS;
}

export interface DeleteClauseFailure {
  type: DELETECLAUSE_FAILURE;
}

export interface DeleteFolder {
  type: DELETEFOLDER;
  payload: {
    folderId: string;
  };
}

export interface DeleteFolderSuccess {
  type: DELETEFOLDER_SUCCESS;
}

export interface DeleteFolderFailure {
  type: DELETEFOLDER_FAILURE;
}

export interface GetClauseLibraryData {
  type: GETCLAUSELIBRARYDATA;
}

export interface GetClauseLibraryDataSuccess {
  type: GETCLAUSELIBRARYDATA_SUCCESS;
  payload: {
    clauseLibraryData: ClauseFolderState[];
  };
}

export interface GetClauseLibraryDataFailure {
  type: GETCLAUSELIBRARYDATA_FAILURE;
}

export interface GetClausesData {
  type: GETCLAUSESDATA;
  payload: {
    clauseIds: string[];
  };
}

export interface GetClausesDataSuccess {
  type: GETCLAUSESDATA_SUCCESS;
  payload: {
    clausesData: ClauseStructure[];
  };
}

export interface GetClausesDataFailure {
  type: GETCLAUSESDATA_FAILURE;
}

export type ClauseLibraryActions =
  | GetSectionClause
  | UpdateSectionClauseSuccess
  | UpdateSectionClause
  | GetSectionClauseSuccess
  | GetSectionClauseFailure
  | GetSectionDictionary
  | GetSectionDictionarySuccess
  | GetSectionDictionaryFailure
  | GetFolderHeading
  | GetFolderHeadingSuccess
  | GetFolderHeadingFailure
  | GetFolderSubHeading
  | GetFolderSubHeadingSuccess
  | GetFolderHeadingFailure
  | CreateFolderHeading
  | CreateFolderHeadingSuccess
  | CreateFolderHeadingFailure
  | CreateFolderSubHeading
  | CreateFolderSubHeadingSuccess
  | CreateFolderSubHeadingFailure
  | CreateSectionClause
  | CreateSectionClauseSuccess
  | CreateSectionClauseFailure
  | CreateClause
  | CreateClauseSuccess
  | CreateClauseFailure
  | SaveCollapsedFolderIds
  | EditClause
  | EditClauseSuccess
  | EditClauseFailure
  | SaveSelectedClauseData
  | SaveFolderSubHeading
  | DeleteClause
  | DeleteClauseSuccess
  | DeleteClauseFailure
  | DeleteFolder
  | DeleteFolderSuccess
  | DeleteFolderFailure
  | GetClauseLibraryData
  | GetClauseLibraryDataSuccess
  | GetClauseLibraryDataFailure
  | GetClausesData
  | GetClausesDataSuccess
  | GetClausesDataFailure;
