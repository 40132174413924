import * as React from "react";

import { isNullOrUndefined } from "is-what";

interface Props {
  maxHeight: number | string;
  minHeight?: string;
  mode?: string;
  padding?: boolean;
  children: any;
  id?: string;
}

const Scrollable = (props: Props) => {
  if (typeof props.maxHeight === "number") {
    return (
      <div className="row">
        <div
          className={
            !isNullOrUndefined(props.mode)
              ? "col-md-12 custom-scrollbar-checkbox-dark"
              : !isNullOrUndefined(props.padding) && props.padding === false
              ? "col-md-12 custom-scrollbar-checkbox p-0"
              : "col-md-12 custom-scrollbar-checkbox"
          }
          style={{
            maxHeight: props.maxHeight + "px",
            minHeight: !isNullOrUndefined(props.minHeight)
              ? props.minHeight
              : "",
          }}
          id={props.id}
        >
          {props.children}
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <div
          className={
            !isNullOrUndefined(props.mode)
              ? "col-md-12 custom-scrollbar-checkbox-dark"
              : "col-md-12 custom-scrollbar-checkbox"
          }
          style={{
            maxHeight: props.maxHeight + "vh",
            minHeight: !isNullOrUndefined(props.minHeight)
              ? props.minHeight
              : "",
          }}
        >
          {props.children}
        </div>
      </div>
    );
  }
};

export default Scrollable;
