import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function DataBreachClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddNotify, setShowAddNotify] = useState(false);
  const [showAddRegulation, setShowAddRegulation] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "data_breach");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.dataBreach && clauseData.dataBreach,
    [clauseData?.dataBreach]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.dataBreach || {},
    [updatedClauseData]
  );

  const dataBreach = React.useMemo(() => {
    if (updates?.data_breach) {
      let sortData = updates.data_breach.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(dataBreach, "Data Breach");
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const notify = React.useMemo(() => {
    if (updates?.notify) {
      let sortData = updates.notify.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const regulation = React.useMemo(() => {
    if (updates?.regulation) {
      return updates.regulation;
    }
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let data_breach = dataBreach?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let notifyData = notify?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let regulation_data = regulation;
      if (regulation?.para_id === item.para_id) {
        regulation_data = null;
      }

      let updatedData = {
        ...updates,
        data_breach,
        notify: notifyData,
        regulation: regulation_data,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "data_breach", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, notify, data, fileId]
  );

  const deleteNotify = React.useCallback(
    (item: any) => {
      let notifyData = notify?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        notify: notifyData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "data_breach", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, notify, data, fileId]
  );

  const deleteRegulation = React.useCallback(() => {
    let updatedData = {
      ...updates,
      regulation: null,
    };
    const diff = changesets.diff(data?.raw_content, updatedData, {
      children: "$index",
    });
    if (diff.length > 0) {
      postClauseDataByType?.(fileId, "data_breach", diff, updatedData);
    }
  }, [updates, postClauseDataByType, regulation, data, fileId]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Data Breach</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", dataBreach);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${dataBreach?.length}`
                  : dataBreach?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (dataBreach?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", dataBreach);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {dataBreach.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        props.onEdit(
                          "Data Breach",
                          0,
                          false,
                          [],
                          -1,
                          "",
                          dataBreach
                        );
                        setShowAdd(true);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="data_breach"
                savedInsight={!isNullOrUndefined(dataBreach) ? dataBreach : []}
                savedParentClauseDataPoint={dataBreach}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {dataBreach?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "data_breach")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.para
                        ? item.para
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-non-compete"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Notify</p>
                {!showAddNotify ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Data Breach Notify",
                        notify,
                        setShowAddNotify
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Data Breach Notify"
                    savedInsight={!isNullOrUndefined(notify) ? notify : []}
                    savedParentClauseDataPoint={notify}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddNotify(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="data_breach"
                  />
                )}
              </div>
              <div className="mt-2">
                {notify?.map((item: any, index: number) => (
                  <div className="clause-div" key={index}>
                    <p className="clause-data">{item.notify[0]?.ext_}</p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteNotify(item)}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">regulation</p>
                {!showAddRegulation ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Data Breach Regulation",
                        regulation,
                        setShowAddRegulation
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Data Breach Regulation"
                    savedInsight={
                      !isNullOrUndefined([regulation]) ? regulation : []
                    }
                    savedParentClauseDataPoint={[regulation]}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddRegulation(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="data_breach"
                  />
                )}
              </div>
              {regulation?.extraction?.length && (
                <div className="mt-2">
                  <div className="clause-div">
                    <p className="clause-data">
                      {regulation?.extraction[0]?.ext}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteRegulation()}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
