import AUTH0 from "../../auth_config";
import { PresignedDataField } from "../../Draft/State/DraftState";
import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";

const APIPaths = {
  draftBaseUrl: process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft/",
  getRequisition:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/requisition_form/",
  getSignatories:
    process.env.REACT_APP_RIVERUS_CLM_API + "users/?role=Signatories",
  getCompareVersion:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/compare_version/",
  getTemplates: process.env.REACT_APP_RIVERUS_CLM_API + "stylus/templates/",
  getChecklist:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft_checklist/",
  createChecklist: process.env.REACT_APP_RIVERUS_CLM_API + "admin/checklist/",
  uploadDocUrl:
    process.env.REACT_APP_RIVERUS_CLM_API +
    "stylus/draft_checklist_supportdoc/",
  getContractTemplates: process.env.REACT_APP_RIVERUS_CLM_API + "contracts/",
  getTemplatePresignedURL: `${AUTH0.apiBasePath}stylus/templates/aws/presigned-url/`,
  getS3PresignedURL: `${AUTH0.apiBasePath}stylus/aws/presigned-url/`,
  getOptionalFields:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft/optional_field/",
  saveCompleteDraftFields:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft/bulk_optional_field/",
  getFields:
    process.env.REACT_APP_RIVERUS_CLM_API + "data-dictionary/get_field",
  getCollaborators:
    process.env.REACT_APP_RIVERUS_CLM_API + "users/?role=Collaborators",
  getApprovals:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft_approval/",
  approvalComments:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft_approval_comment/",
  requestApproval:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/request_approval/",
  getDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft_deviation/",
  getMergeTagDeviations:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft/merge-tag-deviation/",
  draft_deviation:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft_deviation/",
  draft_approval:
    process.env.REACT_APP_RIVERUS_CLM_API + "stylus/draft_approval/",
  getContractPreSignUrl:
    process.env.REACT_APP_RIVERUS_CLM_API + "contracts/aws/presigned-url/",
};

const APIConfig = () => ({
  headers: {
    Authorization: `Custom ${getLocalStorage("external_auth_token")}`,
  },
});

let axiosConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
};

export const createDraft = (payload: any) => {
  return axios.post(APIPaths.draftBaseUrl, payload, APIConfig());
};

export const editDraftDataExternal = (payload: any) => {
  const response = axios.patch(
    `${APIPaths.draftBaseUrl}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
  return response;
};

export const fetchExternalCollaborators = async () => {
  const res = await fetch(`${APIPaths.getCollaborators}`, APIConfig());
  return res.json();
};

export const fetchExternalTemplate = async () => {
  const response = await fetch(APIPaths?.getTemplates, APIConfig());
  return response.json();
};

export const updateExternalDraft = (id: string, body: any) => {
  return axios.put(`APIPaths.createApproval${id}/`, body, APIConfig());
};

export const fetchExternalRequisitionVersionHistory = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getRequisition}?search=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalChecklistData = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getChecklist}?draft=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalRequisitionVersionById = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getRequisition}${id}/`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalDraftVersionId = async (id: string) => {
  const response = await fetch(`${APIPaths.draftBaseUrl}${id}/`, APIConfig());
  return response.json();
};

export const updateExternalVersion = (id: string) => {
  const response = axios.patch(
    `${APIPaths.getRequisition}/${id}/`,
    {
      draft_created: true,
    },
    APIConfig()
  );
  return response;
};

export const updateExternalChecklist = (payload: any) => {
  const response = axios.put(
    `${APIPaths?.getChecklist}${payload?.id}/`,
    payload?.body,
    APIConfig()
  );
  return response;
};

export const createExternalChecklist = (payload: any) => {
  const response = axios.post(APIPaths?.createChecklist, payload, APIConfig());
  return response;
};

export const getExternalRequisitionById = async (id: string) => {
  const res = await fetch(`${APIPaths.getRequisition}${id}/`, APIConfig());
  return res.json();
};

export const fetchSignatoriesListExternal = async () => {
  const res = await fetch(`${APIPaths.getSignatories}`, APIConfig());
  return res.json();
};

export const fetchContractPreSignUrlExternal = async (fileInfo: any) => {
  const response = await axios.post(
    APIPaths?.getContractPreSignUrl,
    { ...fileInfo },
    APIConfig()
  );
  return response;
};

export const fetchPresignedUrlExternal = async (fileInfo: any) => {
  const response = await axios.post(
    APIPaths?.getS3PresignedURL,
    { ...fileInfo },
    APIConfig()
  );
  return response;
};

export const updateSignatoriesListExternal = (payload: any) => {
  const response = axios.patch(
    `${APIPaths?.draftBaseUrl}${payload.id}/`,
    payload.body,
    APIConfig()
  );
  return response;
};

export const getDraftById = async (id: string) => {
  const res = await fetch(`${APIPaths.draftBaseUrl}${id}/`, APIConfig());
  return res.json();
};

export const saveExternalDraft = async (payload?: any) => {
  return axios.post(
    `${APIPaths.draftBaseUrl}bulk_optional_field/`,
    payload,
    APIConfig()
  );
};

export const uploadNewDocumentExternal = async (payload: any) => {
  return axios.post(`${APIPaths?.uploadDocUrl}`, payload, APIConfig());
};

export const deleteSupportDocFromChecklistExternal = (id: string) => {
  const response = axios.delete(`${APIPaths?.uploadDocUrl}${id}/`, APIConfig());
  return response;
};

export const upload_file_in_s3_bucket_external = ({
  presignedPostData,
  file,
  onHandleFileProgress,
}: {
  presignedPostData: any;
  file?: any;
  onHandleFileProgress?: any;
}) => {
  const { fields, url } = presignedPostData;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof PresignedDataField]);
  });

  file && formData.append("file", file);

  let config = onHandleFileProgress
    ? { axiosConfig, ...onHandleFileProgress }
    : axiosConfig;

  return axios.post(url, formData, config);
};

export const fetchExternalTemplateById = async (id: string) => {
  const response = await fetch(`${APIPaths?.getTemplates}${id}/`, APIConfig());
  return response.json();
};

export const fetchExternalExecutedContracts = async (id: string) => {
  const response = await fetch(
    `${APIPaths?.getContractTemplates}?contract_type=${id}`,
    APIConfig()
  );
  return response.json();
};

export const fetchExternalEarlierDrafts = async (name: string) => {
  const response = await fetch(
    `${APIPaths?.draftBaseUrl}get_latest/?filter_parameter=Group&filter_type=earlier_draft&contractType=${name}`,
    APIConfig()
  );
  return response.json();
};

export const getS3PresignedUrlExternal = async (fileInfo: any) => {
  const response = await axios.post(
    fileInfo.file_type === "template"
      ? APIPaths.getTemplatePresignedURL
      : APIPaths.getS3PresignedURL,
    { ...fileInfo },
    APIConfig()
  );
  return response;
};

export const fetchOptionalFieldsExternal = async (draft_uuid: string) => {
  const res = await fetch(
    `${APIPaths.getOptionalFields}${draft_uuid}/`,
    APIConfig()
  );
  return res.json();
};

export const fetchGetFieldsExternal = async (id: string) => {
  const res = await fetch(
    `${APIPaths.getFields}?contract_type=${id}`,
    APIConfig()
  );
  return res.json();
};

export const addExternalDraftApprovalComments = async (payload: any) => {
  return axios.post(APIPaths.approvalComments, payload, APIConfig());
};
