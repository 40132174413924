import * as React from "react";

import { Box, IconButton, Popover } from "@mui/material";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";

interface IProps {
  renderIcon: () => React.ReactNode;
  children: React.ReactNode;
  id: string;
}

export default function PopoverPopupState({
  renderIcon,
  children,
  id,
}: IProps) {
  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState) => (
        <div>
          <IconButton {...bindTrigger(popupState)}>{renderIcon()}</IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box sx={{ p: 2 }}>{children}</Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
