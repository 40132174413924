import {
  CREATECLAUSE,
  CREATEFOLDERHEADING,
  CREATEFOLDERSUBHEADING,
  CREATESECTIONCLAUSE,
  CreateClause,
  CreateFolderHeading,
  CreateFolderSubHeading,
  CreateSectionClause,
  DELETECLAUSE,
  DELETEFOLDER,
  DeleteClause,
  DeleteFolder,
  EDITCLAUSE,
  EditClause,
  GETCLAUSELIBRARYDATA,
  GETCLAUSESDATA,
  GETFOLDERHEADING,
  GETFOLDERSUBHEADING,
  GETSECTIONCLAUSE,
  GETSECTIONDICTIONARY,
  GetClauseLibraryData,
  GetClausesData,
  GetFolderHeading,
  GetFolderSubHeading,
  GetSectionClause,
  GetSectionDictionary,
  UPDATESECTIONCLAUSE,
  UPDATESECTIONCLAUSE_SUCCESS,
  UpdateSectionClauseSuccess,
} from "../Actions/def";
import {
  ClauseFolderState,
  ClauseInfo,
  ClauseStructure,
  FolderHeadingInfo,
  SectionClauseInfo,
  SectionDictionaryInfo,
} from "../State/clauseLibraryState";
import { all, call, put, takeLatest } from "redux-saga/effects";

import ClauseLibraryAPI from "../Actions/API";
import ClauseLibraryActionGenerator from "../Actions/gen";
import { ResponseGenerator } from "../../Types";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";
import { getClauseIdsFromFolderHierarchy } from "../Component/Utils/clauseLibraryUtils";
import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

function* getSectionDictionary(action: GetSectionDictionary) {
  // let url = SITEAPI + "clauselibrary/folders";
  let url =
    process.env.REACT_APP_RIVERUS_CLM_API + "data-dictionary/section_names/"; //"http://localhost:6064/clause-name-get";  //"http://localhost:6064/clause-name-get";
  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.getsectionDictionary,
      url
    );

    switch (response.status) {
      case 200: {
        //let parsed = response.data.queryResult[0].data as FolderHeadingInfo[];
        let parsed = response.data.data as SectionDictionaryInfo[];

        yield put(
          ClauseLibraryActionGenerator.getSectionDictionarySuccess(parsed)
        );
      }
      default: {
        yield put(ClauseLibraryActionGenerator.getSectionDictionaryFailure());
      }
    }
  } catch (e) {
    console.log(e, "ERROR");

    yield put(ClauseLibraryActionGenerator.getSectionDictionaryFailure());
  }
}

function* getFolderHeading(action: GetFolderHeading) {
  // let url = SITEAPI + "clauselibrary/folders";
  let url = SITEAPI + "folders/"; //"http://localhost:6064/clause-name-get";  //"http://localhost:6064/clause-name-get";
  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.getFolderHeading,
      url
    );
    switch (response.status) {
      case 200: {
        //let parsed = response.data.queryResult[0].data as FolderHeadingInfo[];
        let parsed = response.data.results as FolderHeadingInfo[];
        yield put(ClauseLibraryActionGenerator.getFolderHeadingSuccess(parsed));
      }
      default: {
        yield put(ClauseLibraryActionGenerator.getFolderHeadingFailure());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.getFolderHeadingFailure());
  }
}

function* getFolderSubHeading(action: GetFolderSubHeading) {
  let id = action.payload.id;
  // let url = SITEAPI + "clauselibrary/subfolders/" + id;
  let url = SITEAPI + "folders/" + id + "/"; //"http://localhost:6064/get-sub-folder";
  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.getFolderSubHeading,
      url
    );
    switch (response.status) {
      case 200: {
        // let parsed = response.data.queryResult[0].data as FolderHeadingInfo[];
        let parsed = response.data.subfolders as FolderHeadingInfo[];
        yield put(
          ClauseLibraryActionGenerator.getFolderSubHeadingSuccess(parsed)
        );
      }
      default: {
        yield put(ClauseLibraryActionGenerator.getFolderSubHeadingFailure());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.getFolderHeadingFailure());
  }
}

function* updateSectionClauseSuccessData(action: UpdateSectionClauseSuccess) {
  yield put(
    ClauseLibraryActionGenerator.updateSectionClauseSuccess(
      action.payload.updateSectionClauseList
    )
  );
}

function* getSectionClauseData(action: GetSectionClause) {
  let url =
    process.env.REACT_APP_RIVERUS_CLM_API +
    "clauselibrary/?page=" +
    action.payload +
    "&creation_type=" +
    action.creation_type;

  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.getSectionClause,
      url
    );

    switch (response.status) {
      case 200: {
        // let parsed = response.data.queryResult[0].data as FolderHeadingInfo[];
        let parsed = response.data.results;
        yield put(
          ClauseLibraryActionGenerator.getSectionClauseSuccess(
            parsed,
            response.data.count
          )
        );
        break;
      }
      default: {
        yield put(ClauseLibraryActionGenerator.getSectionClauseFailure());
        break;
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.getSectionClauseFailure());
  }
}

function* createFolderHeading(action: CreateFolderHeading) {
  // let url = SITEAPI + "clauselibrary/create/folder";
  let url = SITEAPI + "folders/";
  let name = action.payload.name;
  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.createFolderHeading,
      url,
      name
    );
    switch (response.status) {
      case 200: {
        yield put(ClauseLibraryActionGenerator.createFolderHeadingSuccess());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
      default: {
        yield put(ClauseLibraryActionGenerator.createFolderHeadingFailure());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.createFolderHeadingFailure());
  }
}

function* createFolderSubHeading(action: CreateFolderSubHeading) {
  // let url = SITEAPI + "clauselibrary/create/subfolder";
  let url = SITEAPI + "folders/";
  let name = action.payload.name;
  let parent = action.payload.id;

  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.createFolderSubHeading,
      url,
      name,
      parent
    );
    switch (response.status) {
      case 200: {
        yield put(ClauseLibraryActionGenerator.createFolderSubHeadingSuccess());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
      default: {
        yield put(ClauseLibraryActionGenerator.createFolderSubHeadingFailure());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.createFolderSubHeadingFailure());
  }
}

function* createSectionClause(action: CreateSectionClause) {
  // let url = SITEAPI + "clauselibrary/create/clause";

  let section_name = action.payload.section_name;
  let clause_name = action.payload.clause_name;
  let clause_type = action.payload.clause_type;
  let clause_source = action.payload.clause_source;
  let clause_text = action.payload.clause_text;
  let description = action.payload.description;
  let creation_type = action.payload.creation_type;
  let tags = action.payload.tags;

  let url = process.env.REACT_APP_RIVERUS_CLM_API + "clauselibrary/";
  var resultset = {
    section_name: section_name,
    clause_name: clause_name,
    clause_type: clause_type,
    clause_source: clause_source,
    clause_text: clause_text,
    description: description,
    creation_type: creation_type,
    tags: tags,
  };

  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.createSectionClause,
      url,
      section_name,
      clause_name,
      clause_type,
      clause_source,
      clause_text,
      description,
      creation_type,
      tags
    );
    switch (response.status) {
      case 201: {
        yield put(ClauseLibraryActionGenerator.createSectionClauseSuccess());
        break;
      }
      case 200: {
        yield put(ClauseLibraryActionGenerator.createSectionClauseSuccess());
        break;
      }
      default: {
        yield put(ClauseLibraryActionGenerator.createSectionClauseFailure());
        break;
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.createSectionClauseFailure());
  }
}

function* createClause(action: CreateClause) {
  // let url = SITEAPI + "clauselibrary/create/clause";

  let clauseName = action.payload.clauseName;
  let extractedText = action.payload.extractedText;
  let userText = action.payload.userText;
  let clauseType = action.payload.clauseType;
  let sourceFileId = action.payload.sourceFileId;
  let folderId = action.payload.folderId;
  let id = action.payload.id;
  let url = SITEAPI + "folders/" + id + "/custom-clauses/";
  var resultset = {
    clauseName: clauseName,
    extractedText: extractedText,
    userText: userText,
    clauseType: clauseType,
    sourceFileId: sourceFileId,
    folderId: folderId,
    id: action.payload.id,
  };

  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.createClause,
      url,
      clauseName,
      extractedText,
      userText,
      clauseType,
      sourceFileId,
      folderId,
      id
    );

    switch (response.status) {
      case 200: {
        yield put(ClauseLibraryActionGenerator.createClauseSuccess());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
      default: {
        yield put(ClauseLibraryActionGenerator.createClauseFailure());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.createClauseFailure());
  }
}

function* editClause(action: EditClause) {
  let url = SITEAPI + "clauselibrary/edit/clause";

  let clauseId = action.payload.clauseId;
  let clauseName = action.payload.clauseName;
  let userText = action.payload.userText;
  let clauseType = action.payload.clauseType;
  let folderId = action.payload.folderId;
  url = SITEAPI + "folders/" + folderId + "/custom-clauses/" + clauseId + "/";

  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.editClause,
      url,
      clauseId,
      clauseName,
      userText,
      clauseType,
      folderId
    );
    switch (response.status) {
      case 200: {
        yield put(ClauseLibraryActionGenerator.editClauseSuccess());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
      default: {
        yield put(ClauseLibraryActionGenerator.editClauseFailure());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.editClauseFailure());
  }
}

function* deleteClause(action: DeleteClause) {
  let url = SITEAPI + "clauselibrary/delete/clause";

  let clauseId = action.payload.clauseId;
  url =
    SITEAPI +
    "folders/" +
    action.payload.folderId +
    "/custom-clauses/" +
    clauseId +
    "/";
  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.deleteClause,
      url,
      clauseId
    );
    switch (response.status) {
      case 200: {
        yield put(ClauseLibraryActionGenerator.deleteClauseSuccess());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
      default: {
        yield put(ClauseLibraryActionGenerator.deleteClauseFailure());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.deleteClauseFailure());
  }
}

function* deleteFolder(action: DeleteFolder) {
  // let url = SITEAPI + "clauselibrary/delete/folder";
  let folderId = action.payload.folderId;
  let url = SITEAPI + "folders/" + folderId + "/";

  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.deleteFolder,
      url,
      folderId
    );

    switch (response.status) {
      case 200: {
        yield put(ClauseLibraryActionGenerator.deleteFolderSuccess());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
      default: {
        yield put(ClauseLibraryActionGenerator.deleteFolderFailure());
        yield put(ClauseLibraryActionGenerator.getClauseLibraryData());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.deleteFolderFailure());
  }
}

function* getClauseLibraryData(action: GetClauseLibraryData) {
  let url = SITEAPI + "clauselibrary/data";
  url = SITEAPI + "clause-library/"; //"http://localhost:6064/clause-library"; //" // //"http://localhost:6064/clauselibrary/data";
  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.getClauseLibraryData,
      url
    );
    switch (response.status) {
      case 200: {
        // let parsed = response.data.queryResult[0].data as ClauseFolderState[];
        let parsed = response.data.results as ClauseFolderState[];
        var clauseData = [];
        if (response.data.results.length != 0) {
          for (var i = 0; i < response.data.results.length; i++) {
            if (response.data.results[i].subfolders.length != 0) {
              for (
                var ii = 0;
                ii < response.data.results[ii].subfolders.length;
                ii++
              ) {
                if (
                  response.data.results[i].subfolders[ii].clauses.length != 0
                ) {
                  for (
                    var j = 0;
                    j < response.data.results[i].subfolders[ii].clauses.length;
                    j++
                  ) {
                    var clauseSet = {
                      clauseID:
                        response.data.results[i].subfolders[ii].clauses[j].id,
                      sourceFileID: -1,
                      sourceFileName: "",
                      folderID: response.data.results[i].id,
                      folderName: response.data.results[i].name,
                      subfolderID: response.data.results[i].subfolders[ii].id,
                      subfolderName:
                        response.data.results[i].subfolders[ii].name,
                      extractedClause:
                        response.data.results[i].subfolders[ii].clauses[j]
                          .original_text,
                      userEditedClause:
                        response.data.results[i].subfolders[ii].clauses[j].text,
                    };
                    clauseData.push(clauseSet);
                  }
                }
              }
            }

            for (var p = 0; p < response.data.results[i].clauses.length; p++) {
              var clause_Set = {
                clauseID: response.data.results[i].clauses[p].id,
                sourceFileID: -1,
                sourceFileName: "",
                folderID: response.data.results[i].id,
                folderName: response.data.results[i].name,
                subfolderID: "",
                subfolderName: "",
                extractedClause:
                  response.data.results[i].clauses[p].original_text,
                userEditedClause: response.data.results[i].clauses[p].text,
              };
              clauseData.push(clause_Set);
            }
          }
        }

        yield put(
          ClauseLibraryActionGenerator.getClauseLibraryDataSuccess(parsed)
        );
        let parsed_clause = clauseData as ClauseStructure[];

        //calculate clauseIds
        /*let clauseIds: string[] = getClauseIdsFromFolderHierarchy(parsed);
        yield put(ClauseLibraryActionGenerator.getClausesData(clauseIds));

        console.log(JSON.stringify(parsed_clause));*/
        yield put(
          ClauseLibraryActionGenerator.getClausesDataSuccess(parsed_clause)
        );
      }
      default: {
        yield put(ClauseLibraryActionGenerator.getClauseLibraryDataFailure());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.getClauseLibraryDataFailure());
  }
}

function* getClausesData(action: GetClausesData) {
  let url = SITEAPI + "clauselibrary/clauses/data";
  url = SITEAPI + "clause-library/"; //"http://localhost:6064/contracts/clauselibrary/clauses/data";
  let clauseIds = action.payload.clauseIds;
  try {
    let response: ResponseGenerator = yield call(
      ClauseLibraryAPI.getClausesData,
      url,
      clauseIds
    );
    switch (response.status) {
      case 200: {
        var clauseData = [];
        if (response.data.results.length != 0) {
          for (var i = 0; i < response.data.results.length; i++) {
            if (response.data.results[i].subfolders.length != 0) {
              for (
                var ii = 0;
                ii < response.data.results[ii].subfolders.length;
                ii++
              ) {
                if (
                  response.data.results[i].subfolders[ii].clauses.length != 0
                ) {
                  for (
                    var j = 0;
                    j < response.data.results[i].subfolders[ii].clauses.length;
                    j++
                  ) {
                    var clauseSet = {
                      clauseID:
                        response.data.results[i].subfolders[ii].clauses[j].id,
                      sourceFileID: -1,
                      sourceFileName: "",
                      folderID: response.data.results[i].id,
                      folderName: response.data.results[i].name,
                      subfolderID: response.data.results[i].subfolders[ii].id,
                      subfolderName:
                        response.data.results[i].subfolders[ii].name,
                      extractedClause:
                        response.data.results[i].subfolders[ii].clauses[j]
                          .original_text,
                      userEditedClause:
                        response.data.results[i].subfolders[ii].clauses[j].text,
                    };
                    clauseData.push(clauseSet);
                  }
                }
              }
            }

            for (var p = 0; p < response.data.results[i].clauses.length; p++) {
              var clause_Set = {
                clauseID: response.data.results[i].clauses[p].id,
                sourceFileID: -1,
                sourceFileName: "",
                folderID: response.data.results[i].id,
                folderName: response.data.results[i].name,
                subfolderID: "",
                subfolderName: "",
                extractedClause:
                  response.data.results[i].clauses[p].original_text,
                userEditedClause: response.data.results[i].clauses[p].text,
              };
              clauseData.push(clause_Set);
            }
          }
        }

        let parsed = clauseData as ClauseStructure[];
        yield put(ClauseLibraryActionGenerator.getClausesDataSuccess(parsed));
        // let parsed = response.data.queryResult[0].data as ClauseStructure[];
        // yield put(ClauseLibraryActionGenerator.getClausesDataSuccess(parsed));
      }
      default: {
        yield put(ClauseLibraryActionGenerator.getClausesDataFailure());
      }
    }
  } catch (e) {
    yield put(ClauseLibraryActionGenerator.getClausesDataFailure());
  }
}

export default function* analysisWatcher() {
  yield all([
    takeLatest(UPDATESECTIONCLAUSE, updateSectionClauseSuccessData),
    takeLatest(GETSECTIONDICTIONARY, getSectionDictionary),
    takeLatest(GETSECTIONCLAUSE, getSectionClauseData),
    takeLatest(GETFOLDERHEADING, getFolderHeading),
    takeLatest(GETFOLDERSUBHEADING, getFolderSubHeading),
    takeLatest(CREATEFOLDERHEADING, createFolderHeading),
    takeLatest(CREATEFOLDERSUBHEADING, createFolderSubHeading),
    takeLatest(CREATECLAUSE, createClause),
    takeLatest(CREATESECTIONCLAUSE, createSectionClause),
    takeLatest(DELETECLAUSE, deleteClause),
    takeLatest(DELETEFOLDER, deleteFolder),
    takeLatest(GETCLAUSELIBRARYDATA, getClauseLibraryData),
    takeLatest(EDITCLAUSE, editClause),
    takeLatest(GETCLAUSESDATA, getClausesData),
  ]);
}
