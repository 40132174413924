import { FileHierarchy } from "../../DocumentLibrary/State/documentLibraryState";

export default interface DocumentState {
  sentenceData: SentencesData;
  sentenceLoader: boolean;
  insightsData: InsightsInterface[];
  tagType: TagData[];
  tagNature: TagData[];
  otherTags: TagData[];
  editLoader: boolean;
  editSuccessLoader: boolean;
  editFailureLoader: boolean;
  newTagData: TagData;
  storedOtherTags: any;
  insightsLoader: boolean;
  dataPointName: string;
  highlightedId: number[] | null;
  editOptionSelection: boolean;
  storedBiPointDataModes: BiPointDataMode[];
  savedHighlightedSentences: sentenceInfo[] | null;
  savedHighlightedPhrases: phraseInfo[] | null;
  phraseEditOptionSelected: boolean;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  savedHighlightedTableCells: tableInfo[] | null;
  savedHighlightedDates: dateInfo[] | null;
  dateInAddEditMode: dateInfo | null;
  dateEditingStatus: boolean;
  savedEvents: EventData[];
  newEventData: EventData;
  savedHighlightedEvents: eventInfo[] | null;
  eventInAddEdit: eventInfo | null;
  eventEditingStatus: boolean;
  documentTree: FileHierarchy;
  clauseModeStatus: boolean;
  insightToDelete: any;
  childLabelToDelete: string;
  otherTagsLoader: boolean;
  durationList: DurationType[];
  currencyList: CurrencyType[];
  deviationpolicyData: DeviationPolicyInterface[];
  approvalData: Approval[];
  contractData: ContractDetails;
  clauseInsightsLoader: boolean;
}

export interface clauseTypeInterface {
  nonCompete?: clauseTypeData;
  contractingParties?: clauseTypeData;
  changeOfControl?: clauseTypeData;
  confidentiality?: clauseTypeData;
  consent?: clauseTypeData;
  term?: clauseTypeData;
  renewal?: clauseTypeData;
  payment?: clauseTypeData;
  indemnity?: clauseTypeData;
  termination?: clauseTypeData;
  insurance?: clauseTypeData;
  notice?: clauseTypeData;
  eventsOfDefault?: clauseTypeData;
  limitedLiability?: clauseTypeData;
  forceMajeure?: clauseTypeData;
  governingLaw?: clauseTypeData;
  disputeResolution?: clauseTypeData;
  nonSolicitation?: clauseTypeData;
  subletting?: clauseTypeData;
  generalDefinitions?: clauseTypeData;
  dataBreach?: clauseTypeData;
  effectiveDate?: clauseTypeData;
  exclusivity?: clauseTypeData;
  obligationStatements?: clauseTypeData;
  terminationAtConvenience?: clauseTypeData;
}

export interface clauseTypeData {
  edited_content: any;
  raw_content: any;
}
export interface SentencesData {
  fileId: string;
  hierarchy: Hierarchy[];
}

export interface Hierarchy {
  index: number;
  para: string;
  bullet: string;
  heading: string;
  isLease: string;
  isHeading: string;
  sentences: Sentences[];
  analytics?: Analytics[];
  table: Table[];
}

export interface TableContainer {
  Table: Table[];
  index: number;
}

export interface Table {
  Row: Row[];
}

export interface Row {
  Column: number;
  Content: string;
  Row: number;
}
export interface Sentences {
  index: number;
  words: Words[];
  level: string;
  sentence: string;
}

export interface Words {
  word: string;
  index: number;
  analytics?: Analytics[];
}
export interface Analytics {
  key: string;
  paraid: number;
}

export interface QuickIntel {
  fileName: string;
  metaData: MetaData[];
}

export interface MetaData {
  label: string;
  value: any;
}

export interface InsightsInterface {
  label: string;
  children: Children[];
  dataPoints: DataPoints[];
  status?: string | null;
}

export interface DeviationPolicyInterface {
  label: string;
  children: Children[];
  dataPoints: DataPoints[];
  policy: [];
}

export interface Approval {
  approval_number: any;
  approval_name: string;
  approval_type: string;
  section_tag: string;
  merge_tag: string;
  conditions: string;
  value: string;
  created_on: string;
  optional_data: string;
  approvers: Approvers[];
  field_name: string;
}

export interface PolicyInterface {
  approval_flag: string;
  comment_flag: string;
}

export interface Approvers {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
}

export interface Children {
  childLabel: string;
  childAlias: string;
  childValue: Child[];
  parentDataPoints?: ParentClauseDataPoint;
}

export interface Child {
  name: string;
  dataPoints: DataPoints;
  id?: string;
  nature?: string;
}

export interface DataPoints {
  paraId: number;
  startSentenceId: number;
  endSentenceId: number;
  startWordId: number;
  endWordId: number;
  rowId: number;
  columnId: number;
  highlightedText: string;
  definedWord: string;
  groupedBiData: Children[];
  term_duration?: string | null;
}

export interface TagRequestStructure {
  tagType: string;
  tagName: string;
}

export interface TagData {
  name: string;
  id: string;
  type: string;
  group: number;
  contracts: string[];
  displayName: string;
}

export interface PartyData {
  partyName: string;
  partyType: string;
  party?: string;
  mode: string;
  id?: string | null;
}

export interface LinkParagraphRequest {
  data: string;
  paraId?: number[] | null;
  mode: string;
}

export interface LinkParaRequest {
  data: string;
  editedParas?: editedParas;
  mode: string;
}

export interface editedParas {
  upsert: paraInfo[];
  deleted: paraInfo[];
  bi: string;
}

export interface paraInfo {
  paraId: number;
  rowId: number | null;
  columnId: number | null;
  id?: string;
}

export interface BiPointDataMode {
  biDataPointName: string;
  isDataOriginal: boolean;
}

export interface LinkSentenceRequest {
  data?: string;
  editedSentences?: editedSentences;
  mode: string;
  bi?: string;
}

export interface editedSentences {
  upsert: sentenceInfo[];
  deleted: sentenceInfo[];
  bi?: string;
  dataFor?: string; //Consent Clause, Consent Regulatory, Consent Authority
}

export interface sentenceInfo {
  paraId: number | null;
  sentenceId: number | null;
  rowId: number | null;
  columnId: number | null;
  startWordId?: number;
  endWordId?: number;
  phrase?: string; //Consent Clause, General Definitions
  typestring?: string; //Consent Clause
  requirement?: boolean; //Subletting/Assignment Notice Info, Subletting/Assignment Consent Info
  bi?: string; //Subletting/Assignment Notice Info, Subletting/Assignment Consent Info,
  id?: string;
}

export interface editedPhrases {
  upsert: phraseInfo[];
  deleted: phraseInfo[];
  bi: string;
}

export interface phraseInfo {
  paraId: number;
  startWordId: number;
  endWordId: number;
  startSentenceId: number | null;
  endSentenceId: number | null;
  rowId: number | null;
  columnId: number | null;
  phrase: string;
  definedword?: string; //General Definitions
  durationValue?: number;
  durationTypeId?: number;
  total?: number;
  currency_typeid?: number;
  id?: string;
  date?: string;
  currency?: string;
}

export interface LinkPhraseRequest {
  editedPhrases: editedPhrases;
  mode: string;
}

export interface tableInfo {
  paraId: number;
  rowId: number;
  columnId: number;
  definedword?: string; //General Definitions
  startWordId?: number;
  endWordId?: number;
  phrase?: string; //Consent Clause, General Definitions
  typestring?: string; //Consent Clause
  requirement?: boolean; //Subletting/Assignment Notice Info, Subletting/Assignment Consent Info
  bi?: string; //Subletting/Assignment Notice Info, Subletting/Assignment Consent Info
}

export interface durationInfo {
  phrase: string;
  paraId: number;
  rowId: number;
  columnId: number;
}

export interface editedDuration {
  upsert: durationInfo[];
  deleted: durationInfo[];
}

export interface LinkDurationRequest {
  data: string;
  editedParas?: editedDuration;
  mode: string;
}

export interface dateInfo {
  dateId: number;
  phrase: string;
  paraId: number | null;
  rowId: number | null;
  columnId: number | null;
  duration_value?: number;
  duration_typeid?: number;
  id?: string;
}

export interface editedDates {
  upsert: dateInfo[];
  deleted: dateInfo[];
  bi: string;
}

export interface LinkDateRequest {
  data: string;
  editedDates: editedDates;
  mode: string;
}

export interface EventData {
  eventId: string;
  eventName: string;
}

export interface eventInfo {
  eventHighlightId: number;
  eventId: string;
  eventName: string;
  paraId: number;
  sentenceId: number;
  startWordId: number;
  endWordId: number;
  rowId: number;
  columnId: number;
  phrase: string;
  id?: string;
}

export interface editedEvent {
  upsert: eventInfo[];
  deleted: eventInfo[];
  bi: string;
}

export interface LinkEventRequest {
  editedEvents: editedEvent;
  mode: string;
}

export interface ParentClauseDataPoint {
  paraId: number;
  sentenceId: number;
  rowId: number;
  columnId: number;
  typeString?: string;
}

export interface currencyInfoData {
  data: currencyInfo;
}

export interface currencyInfo {
  currencyTypeId: number;
  currencyName: string;
  currencyType: string;
}

export interface DurationType {
  durationTypeId: number;
  durationName: string;
  durationType: string;
}

export interface CurrencyType {
  currencyTypeId: number;
  currencyName: string;
  currencyType: string;
}

export interface ContractParentDetails {
  id: string;
  file_name: string;
  title: string;
  uploaded_type: string;
}

export interface ContractDetails {
  id: string;
  file_name: string;
  created_by: string;
  created_on: string;
  file_size: number;
  file_hash: string;
  status: string;
  uploaded_type: String;
  children: ContractParentDetails[];
  parent: ContractParentDetails;
  amendment: ContractParentDetails[];
  support_document: ContractParentDetails[];
  contract_type: any[];
  projects: any[];
  groups: any[];
}

export function defaultDocumentState(): DocumentState {
  return {
    sentenceData: {
      fileId: "-1",
      hierarchy: [],
    },
    insightsData: [],
    sentenceLoader: false,
    tagNature: [],
    tagType: [],
    otherTags: [],
    editLoader: false,
    editSuccessLoader: false,
    editFailureLoader: false,
    newTagData: {
      name: "",
      id: "-1",
      group: 0,
      type: "",
      contracts: [],
      displayName: "",
    },
    storedOtherTags: {},
    insightsLoader: false,
    dataPointName: "",
    highlightedId: null,
    editOptionSelection: false,
    storedBiPointDataModes: [],
    savedHighlightedSentences: null,
    savedHighlightedPhrases: null,
    phraseEditOptionSelected: false,
    phraseInDeleteMode: null,
    phraseDeleteStatus: false,
    phraseInAddMode: null,
    phraseAddStatus: false,
    savedHighlightedTableCells: null,
    savedHighlightedDates: null,
    dateInAddEditMode: null,
    dateEditingStatus: false,
    savedEvents: [],
    newEventData: {
      eventName: "",
      eventId: "-1",
    },
    savedHighlightedEvents: [],
    eventInAddEdit: null,
    eventEditingStatus: false,
    documentTree: {
      fileID: -1,
      fileName: "",
      // levelID: -1,

      children: [],
      id: "-1",
      file_name: "",
    },
    clauseModeStatus: false,
    insightToDelete: null,
    childLabelToDelete: "",
    otherTagsLoader: false,
    durationList: [],
    currencyList: [],
    deviationpolicyData: [],
    approvalData: [],
    contractData: {
      id: "",
      file_name: "",
      created_by: "",
      file_size: -1,
      file_hash: "",
      created_on: "",
      status: "",
      uploaded_type: "",
      children: [],
      parent: {
        id: "",
        file_name: "",
        uploaded_type: "",
        title: "",
      },
      amendment: [],
      support_document: [],
      contract_type: [],
      projects: [],
      groups: [],
    },
    clauseInsightsLoader: false,
  };
}
