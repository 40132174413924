import {
  AuxArrayInterface,
  createChildDataPointsAggregate,
  detectMultipleInstances,
  disputeResolutionModeBi,
  getParentHighlights,
  highlight_lilac,
  highlight_other_clause,
  highlight_yellow,
  simulateScroll,
} from "./Utils/docUtils";
import {
  BiPointDataMode,
  Child,
  ContractDetails,
  CurrencyType,
  DurationType,
  InsightsInterface,
  QuickIntel,
} from "../State/documentState";
import React, { Component } from "react";

import BasicInformationClause from "./ClauseComponent/BasicInformationClause";
import ClauseComponent from "./ClauseComponent/Container/ClauseComponentCon";
import { ClauseErrorList } from "../../UniversalComponents/Modals/DocumentLibraryClauseErrorModal/State/clauseErrorManagementState";
import { ClauseType } from "../Constants/ClauseType";
import { CurrentTask } from "../../UniversalComponents/Modals/TaskManagementModal/State/taskManagementState";
import DownloadInsights from "./InsightComponents/saveInsights";
import { History } from "history";
import Scrollable from "../../UniversalComponents/Scrollable/scrollable";
import Tooltip from "@mui/material/Tooltip";
import { isNullOrUndefined } from "is-what";
import toast from "react-hot-toast";
import { withStyles } from "@mui/styles";

// import DeleteClause from "./ClauseComponent/Components/DeleteClause";

export const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#393939",
    // color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows,
    fontSize: 13,
    marginTop: 0,
  },
}))(Tooltip);

interface Props {
  sentenceData: any;
  insightsData: any[];
  saveDataPoints: (
    dataPoints: string[],
    state: boolean,
    tempParaIndex: number,
    type: string
  ) => void;
  scrolledChildIndex: number;
  superImposeChildIndex: (childIndex: number) => void;
  fileId: string;
  sentenceLoader: boolean;
  editLoader: boolean;
  editSuccessLoader: boolean;
  editFailureLoader: boolean;
  location: any;
  history: History;
  editOptionSelected: (editOptionSelected: boolean) => void;
  storedBiPointDataModes: BiPointDataMode[];
  saveBiPointDataMode: (storedBiPointDataModes: BiPointDataMode[]) => void;
  saveSelectedInsightPoint: (selectedInsightPoint: string) => void;
  setCurrentScrollIndex: (currentScrollIndex: number) => void;
  currentScrollIndex: number;
  currentTask: CurrentTask;
  setCurrentTask: (name: string, value: string, contractName: string) => void;
  fileEncoded: string;
  setTaskPage: (hideTaskPage: boolean) => void;
  getAllTasksData: (
    fileID: string,
    requestID: string,
    sort: string,
    order: string,
    selfAssigned: boolean,
    clauseType: string
  ) => void;
  clauseModeStatus: boolean;
  setTaskOrigin: (origin: string) => void;
  editDataPoint: (
    fileId: string,
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ) => void;
  saveInsightToDelete: (
    insightToDelete: any,
    childLabelToDelete: string
  ) => void;
  durationList: DurationType[];
  currencyList: CurrencyType[];
  contractData: ContractDetails;
  getcontractData: (fileId: string) => void;
  clauseErrorData: ClauseErrorList[];
  clauseInsightsLoader: boolean;
  getClauseDataByType: (fileId: string, type: ClauseType) => void;
  clauseData: any;
  updatedClauseData: any;
}

interface State {
  clickedMenu: string;
  clickedSubMenu: string;
  clickedInstanceIndex: number;
  highlightedYellow: string[];
  highlightedLilac: string[];
  savedParentDataPoints: string[];
  toBeEdited: string;
  editOptionSelected: boolean;
  quickIntel: QuickIntel;
  hideTaskPage: boolean;
  clickedTaskMenu: string;
  childInEditId: number;
  currentEditIsGroupedData: boolean;
  currentEditParentClause: Child[];
  currentEditParentClauseId: number;
  currentEditParentLabel: string;
  showClauseModal: boolean;
  currentInstance: number;
  localScrollIndex: number;
  extremeEnd: string;
  editfailureStatus: string;
  editClause: number;
  selectedDataPoints: any[];
  showDeleteModal: boolean;
  deleteData: any;
  clauseType: string;
}

export default class DocumentInsights extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      clickedMenu: "Basic Information",
      clickedSubMenu: "",
      highlightedYellow: [],
      highlightedLilac: [],
      clickedInstanceIndex: 0,
      savedParentDataPoints: [],
      toBeEdited: "",
      editOptionSelected: false,
      quickIntel: {
        fileName: "",
        metaData: [],
      },
      hideTaskPage: true,
      clickedTaskMenu: "",
      childInEditId: -1,
      currentEditIsGroupedData: false,
      currentEditParentClause: [],
      currentEditParentClauseId: -1,
      currentEditParentLabel: "",
      showClauseModal: false,
      currentInstance: 1,
      localScrollIndex: 0,
      extremeEnd: "up",
      editfailureStatus: "",
      editClause: 0,
      selectedDataPoints: [],
      showDeleteModal: false,
      deleteData: null,
      clauseType: "",
    };
  }

  setCurrentTask() {}

  componentDidMount() {
    if (!isNullOrUndefined(this.props.location.state)) {
      this.setState({
        editOptionSelected: true,
        toBeEdited: this.props.location.state.bi,
        clickedMenu: this.props.location.state.clause,
      });
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.editFailureLoader && this.props.editLoader) {
      if (this.state.editClause === 0) {
        this.setState({ editClause: 1 }, () => {
          notifyError();
        });
      }
    }
    if (nextProps.editSuccessLoader && this.props.editLoader) {
      notifySuccess();
      this.setState({ editClause: 0 });
    }
    if (this.props.editLoader !== nextProps.editLoader) {
      this.props.editLoader && this.setState({ editOptionSelected: false });
    }
    if (
      !isNullOrUndefined(this.props.location.state) &&
      this.props.location.state === nextProps.location.state
    ) {
      this.props.history.replace(this.props.location.pathname);
    }
    if (this.props.currentScrollIndex !== nextProps.currentScrollIndex) {
      if (this.state.clickedInstanceIndex !== nextProps.currentScrollIndex) {
        this.setState({
          clickedInstanceIndex: nextProps.currentScrollIndex,
        });
      }
    }
  }

  onClickCreateTask = (name: string, value: string, contractName: string) => {
    let {
      setCurrentTask,
      setTaskPage,
      getAllTasksData,
      fileId,
      setTaskOrigin,
    } = this.props;
    let { hideTaskPage, clickedTaskMenu } = this.state;
    getAllTasksData(fileId.toString(), "0", "", "", false, name);
    setCurrentTask(name, value, contractName);
    setTaskPage(hideTaskPage);
    setTaskOrigin("insight");
  };

  onClickDataPoint = (dataPoints: any, dataLabel: string) => {
    let selectedDataPoints: any = [];

    this.setState({ selectedDataPoints: dataPoints });
    selectedDataPoints = dataPoints;
    let { saveSelectedInsightPoint } = this.props;
    let { clickedMenu } = this.state;
    if (clickedMenu !== dataLabel) {
      this.setState({ localScrollIndex: 0 });
    }
    this.props.editOptionSelected(false);
    saveSelectedInsightPoint(dataLabel);
    this.scrollToDataPoint(
      "clause",
      dataLabel,
      clickedMenu === dataLabel ? [] : getParentHighlights(selectedDataPoints)
    );
  };

  scrollUpDown(current: string) {
    let paraHeader = document.getElementById(current);
    if (paraHeader) {
      paraHeader.style.background = highlight_lilac;
      paraHeader.scrollIntoView({ block: "center" });
    }
  }

  scrollTo(direction: string, data: any) {
    let { localScrollIndex } = this.state;
    let dataPoints: any = getParentHighlights(data);
    let currentScrollIndex = 0;
    if (direction === "up") {
      let paraHeader = document.getElementById(dataPoints[localScrollIndex]);
      if (paraHeader) {
        paraHeader.style.background = highlight_yellow;
      }
      currentScrollIndex = localScrollIndex - 1;
      if (currentScrollIndex >= 0) {
        if (currentScrollIndex === 0) {
          this.setState({
            localScrollIndex: currentScrollIndex,
            extremeEnd: "up",
          });
        } else {
          this.setState({
            localScrollIndex: currentScrollIndex,
            extremeEnd: "",
          });
        }
        this.scrollUpDown(dataPoints[currentScrollIndex]);
      }
    } else if (direction === "down") {
      let paraHeader = document.getElementById(
        dataPoints[localScrollIndex - 1]
      );
      if (paraHeader) {
        paraHeader.style.background = highlight_yellow;
      }
      currentScrollIndex = localScrollIndex + 1;
      if (currentScrollIndex <= dataPoints.length) {
        if (currentScrollIndex === dataPoints.length) {
          this.setState({
            extremeEnd: "down",
          });
        } else {
          this.setState({
            localScrollIndex: currentScrollIndex,
            extremeEnd: "",
          });
        }

        this.scrollUpDown(dataPoints[currentScrollIndex - 1]);
      }
    }
  }

  handleInstanceChange = (type: string, data: InsightsInterface) => {
    let { currentInstance } = this.state;
    if (type === "up" && currentInstance > 1) {
      this.scrollToDataPoint(
        "clause",
        data.label,
        getParentHighlights(data.dataPoints)
      );
      this.setState({ currentInstance: currentInstance - 1 });
    }
  };
  render() {
    let { clauseModeStatus, clauseInsightsLoader } = this.props;

    return (
      <>
        <div
          className="col-md-12 table-container"
          style={{ marginTop: "4rem" }}
        >
          {clauseInsightsLoader ? null : (
            <div
              className="d-flex flex-row-reverse"
              style={{ width: "95%", marginBottom: "4px" }}
            >
              <DownloadInsights
                insightsData={this.props.insightsData}
                quickIntel={{
                  fileName: this.props.contractData.file_name,
                  metaData: [],
                }}
              />
            </div>
          )}
          <div>
            <Scrollable
              maxHeight={
                window.screen.width < 1300
                  ? 650
                  : window.screen.width > 1300 && window.screen.width < 1500
                  ? 750
                  : 580
              }
            >
              <div style={{ marginBottom: "350px" }}>
                <BasicInformationClause
                  fileId={this.props.fileId}
                  toBeEdited={this.state.toBeEdited}
                  editOptionSelected={(selected: boolean) => {
                    this.props.editOptionSelected(selected);
                    this.setState({ editOptionSelected: selected });
                  }}
                  onEdit={(
                    clauseName: string,
                    key: number,
                    isGroupedData: boolean,
                    parentClause: any,
                    parentClauseId: number,
                    parentLabel: string,
                    dataPoints: any
                  ) =>
                    this.goToEditFeatures(
                      clauseName,
                      key,
                      isGroupedData,
                      parentClause,
                      parentClauseId,
                      parentLabel,
                      dataPoints
                    )
                  }
                  contractData={this.props.contractData}
                  getClauseDataByType={this.props.getClauseDataByType}
                  clauseData={this.props.clauseData}
                  updatedClauseData={this.props.updatedClauseData}
                />
                {/* <DeleteClause /> */}
                <ClauseComponent
                  fileId={this.props.fileId}
                  onScroll={(direction: string, data: any) =>
                    this.scrollTo(direction, data)
                  }
                  onClickDataPoint={(dataPoints: any, dataLabel: string) =>
                    this.onClickDataPoint(dataPoints, dataLabel)
                  }
                  onEdit={(
                    clauseName: string,
                    key: number,
                    isGroupedData: boolean,
                    parentClause: any,
                    parentClauseId: number,
                    parentLabel: string,
                    dataPoints: any
                  ) =>
                    this.goToEditFeatures(
                      clauseName,
                      key,
                      isGroupedData,
                      parentClause,
                      parentClauseId,
                      parentLabel,
                      dataPoints
                    )
                  }
                  toBeEdited={this.state.toBeEdited}
                  childInEditId={this.state.childInEditId}
                  editOptionSelected={(selected: boolean) => {
                    this.props.editOptionSelected(selected);
                    this.setState({ editOptionSelected: selected });
                  }}
                  scrollToChildDataPoint={this.scrollToChildDataPoint}
                  scrollToDatapoint={this.scrollToDataPoint}
                  otherClauses={(type: string) => {
                    this.OtherClauses(type);
                  }}
                />
              </div>
            </Scrollable>
          </div>
          {clauseModeStatus && (
            <div
              className="col-md-12"
              style={{
                zIndex: 10,
                background: "rgba(0, 0, 0, 0.15)",
                backdropFilter: "blur(2px)",
                position: "absolute",
                top: "-3vh",
                left: "0vw",
                height: "100vh",
                width: "50vw",
              }}
            />
          )}
        </div>
      </>
    );
  }

  goToEditFeature(
    toBeEdited: string,
    key: number,
    isGroupedData: boolean,
    parentClause: Child[],
    parentClauseId: number,
    parentLabel: string
  ) {
    this.props.setCurrentScrollIndex(0);
    this.scrollToChildDataPoint("bi", toBeEdited, [], 0, true);
    this.setState({
      toBeEdited: toBeEdited,
      editOptionSelected: true,
      childInEditId: key,
      currentEditIsGroupedData: isGroupedData,
      currentEditParentClause: parentClause,
      currentEditParentClauseId: parentClauseId,
      currentEditParentLabel: parentLabel,
    });
  }

  goToEditFeatures(
    toBeEdited: string,
    key: number,
    isGroupedData: boolean,
    parentClause: Child[],
    parentClauseId: number,
    parentLabel: string,
    dataPoints: any
  ) {
    this.setState({ selectedDataPoints: dataPoints });
    this.props.setCurrentScrollIndex(0);
    this.scrollToChildDataPoint("bi", toBeEdited, [], 0, true);
    this.setState({
      toBeEdited: toBeEdited,
      editOptionSelected: true,
      childInEditId: key,
      currentEditIsGroupedData: isGroupedData,
      currentEditParentClause: parentClause,
      currentEditParentClauseId: parentClauseId,
      currentEditParentLabel: parentLabel,
    });
  }

  getClauseData = (clauseType: string) => {
    const { updatedClauseData } = this.props;
    switch (clauseType) {
      case "contractingParties": {
        return updatedClauseData?.contractingParties?.contracting_parties || [];
      }
      case "nonCompete": {
        return updatedClauseData?.nonCompete?.non_compete || [];
      }
      case "changeOfControl": {
        return updatedClauseData?.changeOfControl?.change_of_control || [];
      }
      case "confidentiality": {
        return updatedClauseData?.confidentiality?.confidentiality || [];
      }
      case "consent": {
        return updatedClauseData?.consent?.consent || [];
      }
      case "dataBreach": {
        return updatedClauseData?.dataBreach?.data_breach || [];
      }
      case "disputeResolution": {
        return updatedClauseData?.disputeResolution?.dispute_resolution || [];
      }
      case "effectiveDate": {
        return updatedClauseData?.effectiveDate?.effective_date || [];
      }
      case "eventsOfDefault": {
        return updatedClauseData?.eventsOfDefault?.events_of_default || [];
      }
      case "exclusivity": {
        return updatedClauseData?.exclusivity?.exclusivity || [];
      }
      case "forceMajeure": {
        return updatedClauseData?.forceMajeure?.force_majeure || [];
      }
      case "generalDefinitions": {
        return updatedClauseData?.generalDefinitions?.general_definitions || [];
      }
      case "governingLaw": {
        return updatedClauseData?.governingLaw?.governing_law || [];
      }
      case "indemnity": {
        return updatedClauseData?.indemnity?.indemnity || [];
      }
      case "insurance": {
        return updatedClauseData?.insurance?.insurance || [];
      }
      case "limitedLiability": {
        return updatedClauseData?.limitedLiability?.limited_liability || [];
      }
      case "term": {
        return updatedClauseData?.term?.term || [];
      }
      case "renewal": {
        return updatedClauseData?.renewal?.renewal || [];
      }
      case "payment": {
        return updatedClauseData?.payment?.payment || [];
      }
      case "termination": {
        return updatedClauseData?.termination?.termination || [];
      }
      case "notice": {
        return updatedClauseData?.notice?.notice || [];
      }
      case "nonSolicitation": {
        return updatedClauseData?.nonSolicitation?.non_solicitation || [];
      }
      case "subletting": {
        return updatedClauseData?.subletting?.subletting || [];
      }
      case "terminationAtConvenience": {
        return (
          updatedClauseData?.termination_at_convenience
            ?.termination_at_convenience || []
        );
      }
    }
  };

  isClauseProperty = (clauseType: string) => {
    return this.props.updatedClauseData?.hasOwnProperty(clauseType);
  };

  OtherClauses(type: string) {
    let clauseData: any[] = [];

    if (this.isClauseProperty("contractingParties")) {
      let data = this.getClauseData("contractingParties");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("nonCompete")) {
      let data = this.getClauseData("nonCompete");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("changeOfControl")) {
      let data = this.getClauseData("changeOfControl");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("confidentiality")) {
      let data = this.getClauseData("confidentiality");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("consent")) {
      let data = this.getClauseData("consent");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("dataBreach")) {
      let data = this.getClauseData("dataBreach");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("disputeResolution")) {
      let data = this.getClauseData("disputeResolution");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("effectiveDate")) {
      let data = this.getClauseData("effectiveDate");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("eventsOfDefault")) {
      let data = this.getClauseData("eventsOfDefault");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("exclusivity")) {
      let data = this.getClauseData("exclusivity");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("forceMejeure")) {
      let data = this.getClauseData("forceMejeure");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("generalDefinitions")) {
      let data = this.getClauseData("generalDefinitions");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("governingLaw")) {
      let data = this.getClauseData("governingLaw");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("indemnity")) {
      let data = this.getClauseData("indemnity");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }

    if (this.isClauseProperty("insurance")) {
      let data = this.getClauseData("insurance");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("limitedLiability")) {
      let data = this.getClauseData("limitedLiability");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("term")) {
      let data = this.getClauseData("term");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("renewal")) {
      let data = this.getClauseData("renewal");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("payment")) {
      let data = this.getClauseData("payment");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("termination")) {
      let data = this.getClauseData("termination");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("notice")) {
      let data = this.getClauseData("notice");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("nonSolicitation")) {
      let data = this.getClauseData("nonSolicitation");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }
    if (this.isClauseProperty("subletting")) {
      let data = this.getClauseData("subletting");
      if (data) {
        clauseData = [...clauseData, ...data];
      }
    }

    let highlightedPale: any = getParentHighlights(clauseData);

    for (let i = 0; i < highlightedPale.length; i++) {
      let paraHeader = document.getElementById(highlightedPale[i]);
      if (!isNullOrUndefined(paraHeader)) {
        if (type === "add") {
          paraHeader.style.background = highlight_other_clause;
        } else {
          paraHeader.style.background = "none";
        }
      }
    }
  }

  setClickedItem(clickedItem: string, type: string, unselectChild: boolean) {
    let {
      highlightedYellow,
      highlightedLilac,
      clickedMenu,
      clickedSubMenu,
      clickedInstanceIndex,
    } = this.state;
    this.OtherClauses("remove");
    if (clickedItem === "clause") {
      this.setState({ clickedSubMenu: "", clickedInstanceIndex: 0 });
      clickedMenu === type
        ? this.setState({ clickedMenu: "" })
        : this.setState({ clickedMenu: type });
      for (let i = 0; i < highlightedYellow.length; i++) {
        //For clearing highlighted background color
        let clauseHeader = document.getElementById(highlightedYellow[i]);
        if (!isNullOrUndefined(clauseHeader)) {
          clauseHeader.style.background = "none";
        }
      }
      for (let i = 0; i < highlightedLilac.length; i++) {
        for (let j = 0; j < highlightedLilac[i].length; j++) {
          let biHeader = document.getElementById(highlightedLilac[i][j]);
          if (!isNullOrUndefined(biHeader)) {
            biHeader.style.background = "none";
          }
        }
      }
    } else if (clickedItem === "bi") {
      if (unselectChild) {
        clickedSubMenu === type
          ? this.setState({ clickedSubMenu: "" })
          : this.setState({ clickedSubMenu: type });
      } else {
        clickedInstanceIndex >= 0 && this.setState({ clickedSubMenu: type });
      }
      let index = highlightedYellow.findIndex((el) => {
        return el === highlightedLilac[0];
      });
      if (index !== -1) {
        //When parent and child both highlight same set of datapoints, highlight parent again when child is unclicked.
        for (let i = 0; i < highlightedYellow.length; i++) {
          let paraHeader = document.getElementById(highlightedYellow[i]);
          if (!isNullOrUndefined(paraHeader)) {
            paraHeader.style.background = highlight_yellow;
          }
        }
      } else {
        for (let i = 0; i < highlightedLilac.length; i++) {
          for (let j = 0; j < highlightedLilac[i].length; j++) {
            let biHeader = document.getElementById(highlightedLilac[i][j]);
            if (!isNullOrUndefined(biHeader)) {
              biHeader.style.background = "none";
            }
          }
        }
      }
    }
    return clickedItem;
  }

  scrollToDataPoint(insight: string, type: string, dataPoints: string[]) {
    this.props.superImposeChildIndex(0);
    let typeClicked = this.setClickedItem(insight, type, false); //Set clicked menu or submenu tempArray.push('p' + paraId[i]);
    let paraHeader = document.getElementById(dataPoints[0]);
    !isNullOrUndefined(paraHeader) &&
      paraHeader.scrollIntoView({ block: "center" });
    if (dataPoints?.length > 0) {
      this.setState({ highlightedYellow: dataPoints });
      simulateScroll(dataPoints, typeClicked);
      this.props.saveDataPoints(dataPoints, true, 0, "clause");
      this.props.setCurrentScrollIndex(0);
    } else {
      this.setState({ highlightedYellow: [] });
      this.props.saveDataPoints(dataPoints, false, 0, "clause");
      this.props.setCurrentScrollIndex(0);
    }
    this.setState({ savedParentDataPoints: dataPoints });
  }

  scrollToChildDataPoint = (
    insight: string,
    type: string,
    children: AuxArrayInterface[],
    index: number,
    unselectChild: boolean
  ) => {
    let { savedParentDataPoints } = this.state;
    let aggregateDataPoints = createChildDataPointsAggregate(children);
    let typeClicked = this.setClickedItem(insight, type, unselectChild);
    let tempParaIndex = -1;
    let tempParaId = "";
    let paraHeader = document.getElementById(savedParentDataPoints[0]);
    !isNullOrUndefined(paraHeader) &&
      paraHeader.scrollIntoView({ block: "center" });
    let aggregateDataArray: string[] = [];
    if (children?.length > 0) {
      let multipleInstances: AuxArrayInterface[] = [];
      multipleInstances =
        type === "Termination at Convenience" ||
        disputeResolutionModeBi.indexOf(type) > -1
          ? detectMultipleInstances(children)
          : children;
      let paraHeader = document.getElementById(
        multipleInstances[index].dataPoints[index]
      );
      !isNullOrUndefined(paraHeader) &&
        paraHeader.scrollIntoView({ block: "center" });
      this.setState({ highlightedLilac: aggregateDataPoints });
      simulateScroll(aggregateDataPoints, typeClicked);

      //For making scroll button act according to the child data point index in parent array. //Experimental
      tempParaId = children[index].dataPoints[0];
      for (let i = 0; i < aggregateDataPoints?.length; i++) {
        aggregateDataArray.push(aggregateDataPoints[i][0]);
      }
      tempParaIndex = aggregateDataArray.findIndex((el) => {
        return el === tempParaId;
      });
    }
    this.props.saveDataPoints(
      aggregateDataArray.length === 0
        ? savedParentDataPoints
        : aggregateDataArray,
      true,
      tempParaIndex !== -1 ? tempParaIndex : 0,
      aggregateDataArray.length === 0 ? "clause" : "bi"
    );
    if (!isNullOrUndefined(tempParaId) && !(tempParaId === "")) {
      let paraHeader = document.getElementById(tempParaId);
      !isNullOrUndefined(paraHeader) &&
        paraHeader.scrollIntoView({ block: "center" });
    }
  };
}

const notifySuccess = () =>
  toast.success("Updated Successfully", {
    position: "bottom-center",
    iconTheme: {
      primary: "#ffef5a",
      secondary: "#FFFAEE",
    },
    style: {
      zIndex: "0",
    },
  });

const notifyError = () =>
  toast.error("Oops! Something went wrong...", {
    position: "bottom-center",
    iconTheme: {
      primary: "#ffef5a",
      secondary: "#FFFAEE",
    },
    style: {
      zIndex: "0",
    },
  });
