import React, { Component } from "react";
import {
  getUploadedTime,
  truncateFileName,
  truncateString,
} from "../../../../Utils/DataModifierUtil/dataModUtil";

import { DarkTooltip } from "../../../../DocumentView/Component/documentInsights";
import { NotificationData } from "../../State/notificationState";

interface Props {
  notification: NotificationData;
  removeNotification: (notificationID: string) => void;
}

interface State {
  isActive: boolean;
}

export default class ReminderNotification extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  componentDidMount() {}

  removeNotification = () => {};

  render() {
    let { notification, removeNotification } = this.props;
    return (
      <>
        <div
          className="col-md-12"
          style={{
            borderLeft: "6px solid #FBCE2F'",
            // borderLeft: notification.isRead === false ? '6px solid #FBCE2F' : ''
          }}
          id="notification-type-container"
        >
          <div className="col-md-12 p-0" style={{ display: "inline-flex" }}>
            <div className="col-md-1">
              <img alt="reminder-icon" src="/static_images/reminder-icn.svg" />
            </div>
            <div
              className="col-md-10 mt-1 pb-2 task-title"
              // onClick={() => window.open('/document/tasks/' + btoa(notification.fileID.toString()) + '/' + btoa(notification.requestID.toString()), "_blank")}
            >
              {/* <span style={{ cursor: 'pointer' }}>{notification.details.title}</span> */}
              {(notification.details.title === null ||
                notification.details.title === "") && (
                <img src="/static_images/empty-dash.svg" alt="empty-dash"></img>
              )}
              {notification.details.title !== null &&
              notification.details.title.length > 25 ? (
                <DarkTooltip
                  title={notification.details.title}
                  placement="right-end"
                >
                  <span
                    style={{ display: "initial", cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "/document/tasks/" +
                          btoa(notification.fileID.toString()) +
                          "/" +
                          btoa(notification.requestID.toString()),
                        "_blank"
                      )
                    }
                  >
                    {truncateString(notification.details.title, 25)}
                  </span>
                </DarkTooltip>
              ) : (
                <span
                  style={{ display: "initial", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/document/tasks/" +
                        btoa(notification.fileID.toString()) +
                        "/" +
                        btoa(notification.requestID.toString()),
                      "_blank"
                    )
                  }
                >
                  {notification.details.title}
                </span>
              )}
            </div>
            <div className="col-md-1">
              <span
                style={{ float: "right", cursor: "pointer" }}
                id="close-btn"
                onClick={() => removeNotification(notification.id)}
              >
                <img src="/static_images/close-modal-icn.svg" alt="close" />
              </span>
            </div>
          </div>
          <div className="col-md-12 mt-1 file-name">
            {notification.details.contract_file_name !== null &&
            notification.details.contract_file_name.length > 15 ? (
              <DarkTooltip
                title={notification.details.contract_file_name}
                placement="right-end"
              >
                <span
                  style={{ display: "initial", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/document/analysis/" +
                        btoa(notification.fileID.toString()),
                      "_blank"
                    )
                  }
                >
                  {truncateFileName(notification.details.contract_file_name)}{" "}
                  <img src="/static_images/open-file-icn.svg" alt="close" />
                </span>
              </DarkTooltip>
            ) : (
              <span
                style={{ display: "initial", cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "/document/analysis/" +
                      btoa(notification.fileID.toString()),
                    "_blank"
                  )
                }
              >
                {notification.details.contract_file_name}{" "}
                <img src="/static_images/open-file-icn.svg" alt="close" />
              </span>
            )}
          </div>
          <div className="col-md-12 mt-2 clause-bi-name">
            <span>{notification.details.contract_clause_type} </span>
          </div>
          <div
            className="col-md-12 mt-3 mb-2"
            style={{ display: "inline-flex" }}
          >
            <div
              className={
                "col-md-4 mr-2 " +
                notification.details.progress.toLowerCase().replaceAll("_", "-")
              }
            >
              <span>{notification.details.progress.replaceAll("_", " ")}</span>
            </div>
            <div className="col-md-4 status">
              <span>{notification.details.status.replaceAll("_", " ")}</span>
            </div>
            <div className="col-md-4 mt-1 pr-0 alert-date">
              <span>
                {getUploadedTime(notification.details.reminder_until)}
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
