import { Box, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface Props {
  drawerHeader: string;
  open: boolean;
  onClose: VoidFunction;
  children: any;
}

const DrawerComponent: React.FC<Props> = ({
  open,
  drawerHeader,
  onClose,
  children,
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
    >
      <Box display="flex" alignItems="center">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography>{drawerHeader}</Typography>
      </Box>
      {children}
    </Drawer>
  );
};

export default DrawerComponent;
