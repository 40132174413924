import {
  ClauseFolderState,
  ClauseInfo,
  ClauseStructure,
  FolderHeadingInfo,
  SectionClauseInfo,
  SectionDictionaryInfo,
} from "../State/clauseLibraryState";
import {
  CREATECLAUSE,
  CreateClause,
  CreateClauseFailure,
  CreateClauseSuccess,
  CREATECLAUSE_FAILURE,
  CREATECLAUSE_SUCCESS,
  CREATEFOLDERHEADING,
  CreateFolderHeading,
  CreateFolderHeadingFailure,
  CreateFolderHeadingSuccess,
  CREATEFOLDERHEADING_FAILURE,
  CREATEFOLDERHEADING_SUCCESS,
  CreateFolderSubHeading,
  CREATEFOLDERSUBHEADING,
  CreateFolderSubHeadingFailure,
  CreateFolderSubHeadingSuccess,
  CREATEFOLDERSUBHEADING_FAILURE,
  CREATEFOLDERSUBHEADING_SUCCESS,
  DELETECLAUSE,
  DeleteClause,
  DeleteClauseFailure,
  DeleteClauseSuccess,
  DELETECLAUSE_FAILURE,
  DELETECLAUSE_SUCCESS,
  DELETEFOLDER,
  DeleteFolder,
  DeleteFolderFailure,
  DeleteFolderSuccess,
  DELETEFOLDER_FAILURE,
  DELETEFOLDER_SUCCESS,
  EDITCLAUSE,
  EditClause,
  EditClauseFailure,
  EditClauseSuccess,
  EDITCLAUSE_FAILURE,
  EDITCLAUSE_SUCCESS,
  GETCLAUSELIBRARYDATA,
  GetClauseLibraryData,
  GetClauseLibraryDataFailure,
  GetClauseLibraryDataSuccess,
  GETCLAUSELIBRARYDATA_FAILURE,
  GETCLAUSELIBRARYDATA_SUCCESS,
  GETCLAUSESDATA,
  GetClausesData,
  GetClausesDataFailure,
  GetClausesDataSuccess,
  GETCLAUSESDATA_FAILURE,
  GETCLAUSESDATA_SUCCESS,
  GETFOLDERHEADING,
  GetFolderHeading,
  GetFolderHeadingFailure,
  GetFolderHeadingSuccess,
  GETFOLDERHEADING_FAILURE,
  GETFOLDERHEADING_SUCCESS,
  GetFolderSubHeading,
  GETFOLDERSUBHEADING,
  GetFolderSubHeadingFailure,
  GetFolderSubHeadingSuccess,
  GETFOLDERSUBHEADING_FAILURE,
  GETFOLDERSUBHEADING_SUCCESS,
  GetSectionDictionary,
  GETSECTIONDICTIONARY,
  GetSectionDictionaryFailure,
  GETSECTIONDICTIONARY_FAILURE,
  GetSectionDictionarySuccess,
  GETSECTIONDICTIONARY_SUCCESS,
  SaveCollapsedFolderIds,
  SAVECOLLAPSEDFOLERIDS,
  SAVEFOLDERSUBHEADING,
  SaveFolderSubHeading,
  SAVESELECTEDCLAUSEDATA,
  SaveSelectedClauseData,
  CreateSectionClause,
  CREATESECTIONCLAUSE,
  CreateSectionClauseSuccess,
  CREATESECTIONCLAUSE_SUCCESS,
  CreateSectionClauseFailure,
  CREATESECTIONCLAUSE_FAILURE,
  GetSectionClause,
  GETSECTIONCLAUSE,
  GetSectionClauseFailure,
  GETSECTIONCLAUSE_FAILURE,
  GETSECTIONCLAUSE_SUCCESS,
  GetSectionClauseSuccess,
  UpdateSectionClauseSuccess,
  UPDATESECTIONCLAUSE_SUCCESS,
  UpdateSectionClause,
  UPDATESECTIONCLAUSE,
} from "./def";

export default class ClauseLibraryActionGenerator {
  // For Section Dictionary
  public static getSectionDictionary(): GetSectionDictionary {
    return {
      type: GETSECTIONDICTIONARY,
    };
  }

  public static getSectionDictionarySuccess(
    sectionDictionaryList: SectionDictionaryInfo[]
  ): GetSectionDictionarySuccess {
    return {
      type: GETSECTIONDICTIONARY_SUCCESS,
      payload: {
        sectionDictionaryList: sectionDictionaryList,
      },
    };
  }

  public static getSectionDictionaryFailure(): GetSectionDictionaryFailure {
    return {
      type: GETSECTIONDICTIONARY_FAILURE,
    };
  }

  // For Section Clause
  public static getSectionClause(
    payload: number,
    type: string
  ): GetSectionClause {
    return {
      payload: payload,
      creation_type: type,
      type: GETSECTIONCLAUSE,
    };
  }

  public static updateSectionClause(): UpdateSectionClause {
    return {
      type: UPDATESECTIONCLAUSE,
    };
  }
  public static updateSectionClauseSuccess(
    updateSectionClauseList: any
  ): UpdateSectionClauseSuccess {
    return {
      type: UPDATESECTIONCLAUSE_SUCCESS,
      payload: {
        updateSectionClauseList: updateSectionClauseList,
      },
    };
  }

  public static getSectionClauseSuccess(
    sectionClauseList: SectionClauseInfo[],
    count: number
  ): GetSectionClauseSuccess {
    return {
      type: GETSECTIONCLAUSE_SUCCESS,
      payload: {
        sectionClauseList: sectionClauseList,
        totalCount: count,
      },
    };
  }

  public static getSectionClauseFailure(): GetSectionClauseFailure {
    return {
      type: GETSECTIONCLAUSE_FAILURE,
    };
  }

  public static getFolderHeading(): GetFolderHeading {
    return {
      type: GETFOLDERHEADING,
    };
  }
  public static getFolderHeadingSuccess(
    folderHeadingList: FolderHeadingInfo[]
  ): GetFolderHeadingSuccess {
    return {
      type: GETFOLDERHEADING_SUCCESS,
      payload: {
        folderHeadingList: folderHeadingList,
      },
    };
  }
  public static getFolderHeadingFailure(): GetFolderHeadingFailure {
    return {
      type: GETFOLDERHEADING_FAILURE,
    };
  }
  public static getFolderSubHeading(id: string): GetFolderSubHeading {
    return {
      type: GETFOLDERSUBHEADING,
      payload: {
        id: id,
      },
    };
  }
  public static getFolderSubHeadingSuccess(
    folderSubHeadingList: FolderHeadingInfo[]
  ): GetFolderSubHeadingSuccess {
    return {
      type: GETFOLDERSUBHEADING_SUCCESS,
      payload: {
        folderSubHeadingList: folderSubHeadingList,
      },
    };
  }
  public static getFolderSubHeadingFailure(): GetFolderSubHeadingFailure {
    return {
      type: GETFOLDERSUBHEADING_FAILURE,
    };
  }
  public static createFolderHeading(name: string): CreateFolderHeading {
    return {
      type: CREATEFOLDERHEADING,
      payload: {
        name: name,
      },
    };
  }
  public static createFolderHeadingSuccess(): CreateFolderHeadingSuccess {
    return {
      type: CREATEFOLDERHEADING_SUCCESS,
    };
  }
  public static createFolderHeadingFailure(): CreateFolderHeadingFailure {
    return {
      type: CREATEFOLDERHEADING_FAILURE,
    };
  }
  public static createFolderSubHeading(
    name: string,
    id: string
  ): CreateFolderSubHeading {
    return {
      type: CREATEFOLDERSUBHEADING,
      payload: {
        name: name,
        id: id,
      },
    };
  }
  public static createFolderSubHeadingSuccess(): CreateFolderSubHeadingSuccess {
    return {
      type: CREATEFOLDERSUBHEADING_SUCCESS,
    };
  }
  public static createFolderSubHeadingFailure(): CreateFolderSubHeadingFailure {
    return {
      type: CREATEFOLDERSUBHEADING_FAILURE,
    };
  }

  // Create Section Clause

  public static createSectionClause(
    section_name: string,
    clause_name: string,
    clause_type: string,
    clause_source: string,
    clause_text: string,
    description: string,
    creation_type: string,
    tags: any
  ): CreateSectionClause {
    return {
      type: CREATESECTIONCLAUSE,
      payload: {
        section_name: section_name,
        clause_name: clause_name,
        clause_type: clause_type,
        clause_source: clause_source,
        clause_text: clause_text,
        description: description,
        creation_type: creation_type,
        tags: tags,
      },
    };
  }
  public static createSectionClauseSuccess(): CreateSectionClauseSuccess {
    return {
      type: CREATESECTIONCLAUSE_SUCCESS,
    };
  }
  public static createSectionClauseFailure(): CreateSectionClauseFailure {
    return {
      type: CREATESECTIONCLAUSE_FAILURE,
    };
  }

  public static createClause(
    clauseName: string,
    extractedText: string,
    userText: string,
    clauseType: string,
    sourceFileId: number,
    folderId: string,
    id: string
  ): CreateClause {
    return {
      type: CREATECLAUSE,
      payload: {
        clauseName: clauseName,
        extractedText: extractedText,
        userText: userText,
        clauseType: clauseType,
        sourceFileId: sourceFileId,
        folderId: folderId,
        id: id,
      },
    };
  }
  public static createClauseSuccess(): CreateClauseSuccess {
    return {
      type: CREATECLAUSE_SUCCESS,
    };
  }
  public static createClauseFailure(): CreateClauseFailure {
    return {
      type: CREATECLAUSE_FAILURE,
    };
  }

  public static saveCollapsedFolderIds(
    collapsedFolderIds: string[]
  ): SaveCollapsedFolderIds {
    return {
      type: SAVECOLLAPSEDFOLERIDS,
      payload: {
        collapsedFolderIds: collapsedFolderIds,
      },
    };
  }
  public static saveSelectedClauseData(
    selectedClauseData: ClauseInfo
  ): SaveSelectedClauseData {
    return {
      type: SAVESELECTEDCLAUSEDATA,
      payload: {
        selectedClauseData: selectedClauseData,
      },
    };
  }
  public static editClause(
    clauseId: string,
    clauseName: string,
    userText: string,
    clauseType: string,
    folderId: string
  ): EditClause {
    return {
      type: EDITCLAUSE,
      payload: {
        clauseId: clauseId,
        clauseName: clauseName,
        userText: userText,
        clauseType: clauseType,
        folderId: folderId,
      },
    };
  }
  public static editClauseSuccess(): EditClauseSuccess {
    return {
      type: EDITCLAUSE_SUCCESS,
    };
  }
  public static editClauseFailure(): EditClauseFailure {
    return {
      type: EDITCLAUSE_FAILURE,
    };
  }
  public static saveFolderSubHeading(
    folderSubHeadingList: FolderHeadingInfo[]
  ): SaveFolderSubHeading {
    return {
      type: SAVEFOLDERSUBHEADING,
      payload: {
        folderSubHeadingList: folderSubHeadingList,
      },
    };
  }
  public static deleteClause(clauseId: string, folderId: string): DeleteClause {
    return {
      type: DELETECLAUSE,
      payload: {
        clauseId: clauseId,
        folderId: folderId,
      },
    };
  }
  public static deleteClauseSuccess(): DeleteClauseSuccess {
    return {
      type: DELETECLAUSE_SUCCESS,
    };
  }
  public static deleteClauseFailure(): DeleteClauseFailure {
    return {
      type: DELETECLAUSE_FAILURE,
    };
  }
  public static deleteFolder(folderId: string): DeleteFolder {
    return {
      type: DELETEFOLDER,
      payload: {
        folderId: folderId,
      },
    };
  }
  public static deleteFolderSuccess(): DeleteFolderSuccess {
    return {
      type: DELETEFOLDER_SUCCESS,
    };
  }
  public static deleteFolderFailure(): DeleteFolderFailure {
    return {
      type: DELETEFOLDER_FAILURE,
    };
  }
  public static getClauseLibraryData(): GetClauseLibraryData {
    return {
      type: GETCLAUSELIBRARYDATA,
    };
  }
  public static getClauseLibraryDataSuccess(
    clauseLibraryData: ClauseFolderState[]
  ): GetClauseLibraryDataSuccess {
    return {
      type: GETCLAUSELIBRARYDATA_SUCCESS,
      payload: {
        clauseLibraryData: clauseLibraryData,
      },
    };
  }
  public static getClauseLibraryDataFailure(): GetClauseLibraryDataFailure {
    return {
      type: GETCLAUSELIBRARYDATA_FAILURE,
    };
  }
  public static getClausesData(clauseIds: string[]): GetClausesData {
    return {
      type: GETCLAUSESDATA,
      payload: {
        clauseIds: clauseIds,
      },
    };
  }
  public static getClausesDataSuccess(
    clausesData: ClauseStructure[]
  ): GetClausesDataSuccess {
    return {
      type: GETCLAUSESDATA_SUCCESS,
      payload: {
        clausesData: clausesData,
      },
    };
  }
  public static getClausesDataFailure(): GetClausesDataFailure {
    return {
      type: GETCLAUSESDATA_FAILURE,
    };
  }
}
