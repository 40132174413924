import {
  GetUserUploads,
  GETUSERUPLOADS,
  ChangeStatus,
  CHANGESTATUS,
  DeleteFile,
  DELETEFILE,
} from "../Actions/def";
import { SITE_API_BY_REALM_NAME, HOST } from "../../Configuration/global";
import FileUploadAPI from "../Actions/API";
import { call, put, takeLatest, all, takeEvery } from "redux-saga/effects";
import socketIOClient from "socket.io-client";
import { store } from "../..";
import { FileInfo, FileList } from "../../Upload/State/uploadState";
import HistoryActionGenerator from "../Actions/gen";
import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";
import { ResponseGenerator } from "../../Types";
const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
function* getUserUploads(action: GetUserUploads) {
  //let url = SITEAPI + "useruploads";
  let url = SITEAPI+"?page="+action.payload.page; //"http://localhost:8000/api/v1/contracts/";
  
  // let url = "http://localhost:6064/contracts"; //"http://localhost:6064/useruploads/";

  // console.log("User Uploads");
  try {
    let response:ResponseGenerator = yield call(FileUploadAPI.getUserUploads, url);
    console.log(
        "🚀 ~ file: historySaga.tsx ~ line 24 ~ Contract List",
        response
    );
    switch (response.status) {
      case 200: {
        // let parsed = response.data.queryResult as FileInfo[];

        let parsed = response.data.results as FileList[];
        let contractCount = response.data.count as number;
        yield put(HistoryActionGenerator.getUserUploadsSuccessNew(parsed, contractCount));
        break;
      }
      default: {
        yield put(HistoryActionGenerator.getUserUploadsFailure());
      }
    }
  } catch (e) {
    yield put(HistoryActionGenerator.getUserUploadsFailure());
  }
}

function* deleteFile(action: DeleteFile) {
  let fileId = action.payload.uniqueFileId;
  // let url = SITEAPI + "delete/" + fileId;
  let url = SITEAPI + "" + fileId + "/";
  console.log("🚀 ~ file: historySaga.ts ~ line 24 ~ deleteFile", url);
  try {
    let response:ResponseGenerator = yield call(FileUploadAPI.deleteFile, url);
    console.log(
        "🚀 ~ file: historySaga.ts ~ line 24 ~ deleteFile Response",
        response
    );
    // let parsed = response.data.queryResult;
    let parsed = response.data;
    if (parsed == "") {
      parsed = 1;
    }
    switch (response.status) {
      case 200: {
        yield put(HistoryActionGenerator.deleteFileSuccess(parsed));
        break;
      }
      case 204: {
        yield put(HistoryActionGenerator.deleteFileSuccess(parsed));
        break;
      }
      default: {
        yield put(HistoryActionGenerator.deleteFileFailure(parsed));
      }
    }
  } catch (e) {
    yield put(HistoryActionGenerator.deleteFileFailure(0));
  }
}

function* changeStatus(action: ChangeStatus) {
  /*const statusSocketClient = socketIOClient(HOST + "/status-change-namespace");
   statusSocketClient.on("statuschangeevent", (resultInterface: any) => {
   let statusPoints = {
   fileId: resultInterface.fileId,
   textract: resultInterface.textract,
   analytics: resultInterface.analytics,
   normalization: resultInterface.normalization,
   };
   store.dispatch(HistoryActionGenerator.changeStatusSuccess(statusPoints));
   });*/
}

export default function* historyWatcher() {
  yield all([
    takeLatest(GETUSERUPLOADS, getUserUploads),
    takeEvery(CHANGESTATUS, changeStatus),
    takeLatest(DELETEFILE, deleteFile),
  ]);
}
