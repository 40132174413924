export default interface DocumentLibraryState {
  initialFileIds: string[];
  libraryTags: LibraryTagData[];
  libraryTagsA: LibraryTagDataA[];
  documentHierarchy: DocumentHierarchyData[];
  savedMultipleSelectedFiles: BasicFileInfo[];
  savedCollapsedFileIds: string[];
  documentLibraryLoader: boolean;
  appliedLibraryTagFilters: LibraryTagFilterStructure[];
  filteredFileIds: string[];
  selectedFile: BasicFileInfo | null;
  selectedLibraryAction: string;
  isBulkAction: boolean;
  editFileIds: string[];
  dataType: string;
  tagEditData: any;
  editTagsLoader: boolean;
  savedFileTagData: any[];
  savedParentFileList: ParentFileInfo[];
  savedChildrenFileList: ChildrenFileInfo[];
  generalFilters: any;
  generalFilterLoader: boolean;
  generalFilterFileIds: string[];
  documentLibrarystatistics: LibraryStatistics[];
  documentLibraryData: documentLibraryInfo[];
  documentselectedFile: BasicdocumentLibraryFileInfo | null;
  documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[];
  documentfilteredFileIds: string[];
}

export interface LibraryTagData {
  id: number;
  name: string;
  categoryid: number;
}
export interface LibraryTagDataA {
  id: string;
  name: string;
  type: string;
}

export interface DocumentHierarchyData {
  fileId: any;
  fileName: string;
  levelId: number;
  tags: TagInfo[];
  title: string;
  startDate: string;
  parties: partyInfo[];
  children: DocumentHierarchyData[];
}

export interface partyInfo {
  partyId: number;
  partyName: string;
}

export interface TagInfo {
  tagId: any;
  tagName: string;
  tagCategory: string;
}

export interface LibraryTagFilterStructure {
  tagId: any;
  tagName: string;
}

export interface ParentFileInfo {
  fileId: string;
  fileName: string;
  levelId: number;
  isParent: number;
}

export interface ChildrenFileInfo {
  fileId: string;
  fileName: string;
  levelId: number;
  isChild: boolean;
  parentExists: boolean;
}

export interface BasicFileInfo {
  fileId: any;
  fileName: string;
  levelId: number;
}

export interface BasicdocumentLibraryFileInfo {
  fileId: string;
  file_name: string;
  levelId: number;
}

export interface FileHierarchy {
  fileID: any;
  // levelID: number;
  fileName: string;
  children: FileHierarchy[];

  id: string;
  file_name: string;
}
export interface LibraryStatistics {
  contracts_count: number;
  contracts_with_task_count: number;
  contracts_without_task_count: number;
  total_contracts_size: string;
}

export interface ContractingPartiesContent {
  // para: string;
  // party: string;
  // para_id: string;
  para: string;
  party: string;
  para_id: number;
}

export interface ContractingPartiesInfo {
  // id: string;
  // type: string;
  // content: ContractingPartiesContent;
  // edited_content: ContractingPartiesContent;


  // id: string;
  // content: ContractingPartiesContent;

  raw_content: {
    contracting_parties: ContractingPartiesContent[];
  }
  edited_content: null;
}

export interface StartInfo {
  id: string;
  type: string;
  content: string[];
}

export interface ContractTypeInfo {
  id: string;
  type: string;
  content: string[];
}

export interface DocumentTagInfo {
  id: string;
  name: string;
  type: string;
  contracts: string[];
  group: number;
}

export interface documentLibraryInfo {
  id: string;
  file_name: string;
  children: documentLibraryInfo[];
  tasks: string[];
  contracting_parties: ContractingPartiesInfo[];
  starts: StartInfo[];
  tags: DocumentTagInfo[];
  contract_types: ContractTypeInfo[];
  groups: string[];
  created_on: string;
}

export function defaultDocumentLibraryState(): DocumentLibraryState {
  return {
    initialFileIds: [],
    libraryTags: [],
    libraryTagsA: [],
    documentHierarchy: [],
    savedMultipleSelectedFiles: [],
    savedCollapsedFileIds: [],
    documentLibraryLoader: false,
    appliedLibraryTagFilters: [],
    filteredFileIds: [],
    selectedFile: null,
    selectedLibraryAction: "",
    isBulkAction: false,
    editFileIds: [],
    dataType: "",
    tagEditData: "",
    editTagsLoader: false,
    savedFileTagData: [],
    savedParentFileList: [],
    savedChildrenFileList: [],
    generalFilters: [],
    generalFilterLoader: false,
    generalFilterFileIds: [],
    documentLibrarystatistics: [],
    documentLibraryData: [],
    documentselectedFile: null,
    documentsavedMultipleSelectedFiles: [],
    documentfilteredFileIds: [],
  };
}
