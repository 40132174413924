import "react-quill/dist/quill.snow.css";

import { Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import React from "react";
import ReactQuill from "react-quill";

interface Props {
  name: string;
  control: any;
  disabled?: boolean;
}

const NotepadComponent: React.FC<Props> = ({
  name,
  control,
  disabled = false,
}) => {
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["link"],
    ],
  };

  return (
    <Controller
      disabled={disabled}
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <ReactQuill {...field} modules={modules} />
        </FormControl>
      )}
    />
  );
};

export default NotepadComponent;
