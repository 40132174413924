import * as changesets from "json-diff-ts";

import {
  GETCLAUSEDATABYTYPE,
  GetClauseDataByType,
  POSTCLAUSEDATABYTYPE,
  PostClauseDataByType,
} from "../Actions/def";
import { all, call, put, takeEvery } from "redux-saga/effects";

import ClauseComponentActionGenerator from "../Actions/Gen";
import ClauseComponentApi from "../Actions/API";
import { ResponseGenerator } from "../../../../Types";
import { clauseTypeData } from "../State/clauseComponentState";
import { getEditedPayload } from "../utils/ClauseTypeUtils";

function* getClauseDataByType(action: GetClauseDataByType) {
  let fileID = action.payload.fileID;
  let type = action.payload.type;
  try {
    let response: ResponseGenerator = yield call(
      ClauseComponentApi.getClausesByType,
      fileID,
      type
    );
    switch (response.status) {
      case 200: {
        let data = JSON.parse(JSON.stringify(response.data as clauseTypeData));

        yield put(
          ClauseComponentActionGenerator.getClausDataByTypeSuccess(data, type)
        );
        yield put(ClauseComponentActionGenerator.UpdateClauseData(data, type));
        break;
      }
      default: {
        yield put(ClauseComponentActionGenerator.getClausDataByTypeFailure());
        break;
      }
    }
  } catch (e) {
    yield put(ClauseComponentActionGenerator.getClausDataByTypeFailure());
  }
}

function* postClauseDataByType(action: PostClauseDataByType) {
  let fileID = action.payload.fileID;
  let type = action.payload.type;
  let diff = action.payload.diff;
  let updatedClauseData = action.payload.updatedClauseData;
  const flatChanges = changesets.flattenChangeset(diff);
  let editedPayload = getEditedPayload(flatChanges);

  try {
    let response: ResponseGenerator = yield call(
      ClauseComponentApi.postClauseDataByType,
      fileID,
      type,
      editedPayload
    );

    switch (response.status) {
      case 200: {
        yield put(
          ClauseComponentActionGenerator.postClauseDataByTypeSuccess(
            type,
            updatedClauseData
          )
        );
        break;
      }
      default: {
        yield put(ClauseComponentActionGenerator.postClauseDataByTypeFailure());
        break;
      }
    }
  } catch (e) {
    yield put(ClauseComponentActionGenerator.postClauseDataByTypeFailure());
  }
}

export default function* ClauseComponentWatcher() {
  yield all([
    takeEvery(GETCLAUSEDATABYTYPE, getClauseDataByType),
    takeEvery(POSTCLAUSEDATABYTYPE, postClauseDataByType),
  ]);
}
