import ClauseComponent from "../Components/ClauseComponent";
import ClauseComponentActionGenerator from "../Actions/Gen";
import { ClauseType } from "../../../Constants/ClauseType";
import DocumentActionGenerator from "../../../Actions/Gen";
import { StoreTree } from "../../../../Utils/MainReducer/mainReducer";
import { connect } from "react-redux";

export function mapStateToProps(appState: StoreTree, ownProps: any) {
  return {
    sentenceData: appState.document.sentenceData,
    insightsData: appState.document.insightsData,
    scrollToDatapoint: ownProps.scrollToDatapoint,
    scrollToChildDataPoint: ownProps.scrollToChildDataPoint,
    fileId: ownProps.fileId,
    onScroll: ownProps.onScroll,
    onClickDataPoint: ownProps.onClickDataPoint,
    onEdit: ownProps.onEdit,
    toBeEdited: ownProps.toBeEdited,
    childInEditId: ownProps.childInEditId,
    durationList: appState.document.durationList,
    currencyList: appState.document.currencyList,
    contractData: appState.document.contractData,
    editOptionSelected: ownProps.editOptionSelected,
    clauseData: appState.clauseComponent.clauseData,
    updatedClauseData: appState.clauseComponent.updatedClauseData,
  };
}

export function mapDispatchToProps(dispatch: any, ownProps: any) {
  return {
    getClauseDataByType: (fileID: string, type: ClauseType) =>
      dispatch(ClauseComponentActionGenerator.getClausDataByType(fileID, type)),
    postClauseDataByType: (
      fileID: string,
      type: ClauseType,
      payload: any,
      updatedObject: any
    ) =>
      dispatch(
        ClauseComponentActionGenerator.postClauseDataByType(
          fileID,
          type,
          payload,
          updatedObject
        )
      ),
    getInsights: (fileId: string) =>
      dispatch(DocumentActionGenerator.getInsights(fileId)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ClauseComponent);
