import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
} from "@mui/material";

import React from "react";

interface Props {
  list: string[];
  renderAction?: (id: string) => React.ReactNode;
}

const ApprovalChecklist: React.FC<Props> = ({ list, renderAction }) => {
  return (
    <FormGroup>
      {list?.map((item: any) => (
        <List
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ListItem secondaryAction={renderAction?.(item)}>{item}</ListItem>
        </List>
      ))}
    </FormGroup>
  );
};

export default ApprovalChecklist;
