import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  handleAddEdit,
  handleEditBasicInformation,
} from "./Components/ClauseComponent";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { ClauseType } from "../../Constants/ClauseType";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { clauseTypeInterface } from "../../State/documentState";
import { isNullOrUndefined } from "is-what";

interface Props {
  contractData: any;
  fileId: string;
  toBeEdited: string;
  onEdit: any;
  editOptionSelected: (editOptionSelected: boolean) => void;
  clauseData: clauseTypeInterface;
  getClauseDataByType: (fileId: string, type: ClauseType) => void;
  updatedClauseData: any;
}

export default function BasicInformationClause(props: Props) {
  const {
    contractData,
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
  } = props;

  const [isExpand, setExpand] = useState(false);
  const [showAddTitle, setShowAddTitle] = useState(false);
  const [showAddContractingParties, setShowAddContractingParties] =
    useState(false);
  const [showAddTags, setShowAddTags] = useState(false);

  const updates = React.useMemo(
    () => updatedClauseData?.contractingParties,
    [updatedClauseData]
  );

  const contracting_parties_clause = React.useMemo(() => {
    if (updates?.contracting_parties) {
      let sortData = updates.contracting_parties;
      return sortData;
    }
    return [];
  }, [updates]);

  const contractingData = React.useMemo(
    () => clauseData?.contractingParties && clauseData.contractingParties,
    [clauseData?.contractingParties]
  );

  const updateTitle = (editedTitle: string) => {
    contractData.title = editedTitle;
  };

  useEffect(() => {
    getClauseDataByType(fileId, "contracting_parties");
  }, [fileId]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
  };

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && "toc-row toc-row-active1 active-color1"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <p className="m-0">Basic Information</p>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            <div className="d-flex flex-column mt-2">
              {!showAddTitle ? (
                <div className="clause-div-basic">
                  <div className="w-50">
                    <div className="d-flex justify-content-between">
                      <p className="clause-data-basic">Title</p>
                      <p className="clause-data-basic">
                        {props.contractData.title}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="clause-data-basic">Nature</p>
                      <p className="clause-data-basic">Master Agreement</p>
                    </div>
                  </div>
                  {!showAddTitle ? (
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleEditBasicInformation(
                          props,
                          "Basic Information",
                          {},
                          setShowAddTitle
                        );
                      }}
                    >
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/new-edit-icon.svg"
                        alt="delete-insight"
                      />
                    </DarkTooltip>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={"Title"}
                  savedInsight={
                    !isNullOrUndefined(contractData) ? contractData : []
                  }
                  savedParentClauseDataPoint={contractData}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddTitle(false);
                  }}
                  updateTitle={updateTitle}
                />
              )}
            </div>
            <div className="d-flex flex-column mt-2">
              {!showAddTags ? (
                <div className="clause-div-basic">
                  <div>
                    <p className="clause-data-basic">Tags</p>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        maxWidth: "200px",
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {props?.contractData?.groups?.map(
                        (item: string, index: number) => (
                          <p
                            className="contracts-tags1 custom-chip"
                            key={index}
                          >
                            {item}
                          </p>
                        )
                      )}
                      {props?.contractData?.contract_type?.map(
                        (item: any, index: number) => (
                          <p
                            className="contracts-tags1 custom-chip"
                            key={index}
                          >
                            {item.displayName}
                          </p>
                        )
                      )}
                      {props?.contractData?.projects?.map(
                        (item: any, index: number) => (
                          <p
                            className="contracts-tags1 custom-chip"
                            key={index}
                          >
                            {item.name}
                          </p>
                        )
                      )}
                    </div>
                  </div>
                  {!showAddTags ? (
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleEditBasicInformation(
                          props,
                          "Basic Information",
                          {},
                          setShowAddTags
                        );
                      }}
                    >
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/new-edit-icon.svg"
                        alt="delete-insight"
                      />
                    </DarkTooltip>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={"Tags"}
                  savedInsight={
                    !isNullOrUndefined(contractData) ? contractData : []
                  }
                  savedParentClauseDataPoint={contractData}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddTags(false);
                  }}
                />
              )}
            </div>
            <div className="d-flex flex-column mt-2">
              {!showAddContractingParties ? (
                <div className="clause-div-basic">
                  <div>
                    <p className="clause-data-basic">Contracting Parties</p>
                    {contracting_parties_clause?.map(
                      (item: any, index: number) => (
                        <p className="clause-data-basic" key={index}>
                          {`${index + 1}.`}{" "}
                          {item.partyName
                            ? `${item.partyName}`
                            : `${item.party}`}
                          <br />
                          <span style={{ marginLeft: "1rem" }}>
                            {item.partyType}
                          </span>
                        </p>
                      )
                    )}
                  </div>
                  {!showAddContractingParties ? (
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "Contracting Parties",
                          contracting_parties_clause,
                          setShowAddContractingParties
                        );
                      }}
                    >
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/new-edit-icon.svg"
                        alt="delete-insight"
                      />
                    </DarkTooltip>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={"Contracting Parties"}
                  savedInsight={
                    !isNullOrUndefined(contracting_parties_clause)
                      ? contracting_parties_clause
                      : []
                  }
                  savedParentClauseDataPoint={contracting_parties_clause}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddContractingParties(false);
                  }}
                  clauseDataByType={contractingData}
                  updatedClauseData={updates}
                  updatedClauseDataByType={updates}
                  parentClauseType={"contracting_parties"}
                />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
