export default interface SearchBarState {
  visibility: boolean;
  term: string;
  filterType: string;
}

export function defaultSearchBarState(): SearchBarState {
  return {
    visibility: false,
    term: "",
    filterType: "contracts",
  };
}
