import { Stack } from "@mui/material";
import React from "react";
import AddCustomIssue from "./AddCustomIssue";
import CustomDeviationCard from "./CustomDeviationCard";
import { fetchKDPTagDeviations } from "../../../Services/Draft";
import { useQuery } from "@tanstack/react-query";

interface Props {
  instance: any;
  draftId: string;
}

const CustomDeviation: React.FC<Props> = ({ instance, draftId }) => {
  const { data: customDeviationList } = useQuery(
    ["fetch_custom_deviations", draftId],
    async () => {
      const response = await fetchKDPTagDeviations(draftId, "custom");
      return response?.results;
    },
    { enabled: !!draftId }
  );

  return (
    <Stack spacing={3}>
      <AddCustomIssue draftId={draftId} instance={instance} />
      {customDeviationList?.map((deviation: any) => (
        <CustomDeviationCard cardData={deviation} instance={instance} />
      ))}
    </Stack>
  );
};

export default CustomDeviation;
