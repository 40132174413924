import { Button, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReusableConfirmationModal from "../../../RiverusUI/Components/ReusableConfirmationModal";
import SelectElement from "../../../RiverusUI/Components/SelectElement";
import { riPrimary } from "../../../RiverusUI/Theme/colors";

const authUri = process.env.REACT_APP_RIVERUS_ADOBE_REDIRECT;

const methodList = [
  {
    label: "Stylus (Riverus)",
    value: "Stylus (Riverus)",
  },
  {
    label: "NDSL Aadhaar",
    value: "NDSL Aadhaar",
  },
  {
    label: "Docusign",
    value: "Docusign",
  },
  {
    label: "Adobe",
    value: "Adobe",
  },
];

interface Props {
  control: any;
  disabled: boolean;
  handleSignatureMethods: () => string;
  credentialsClientId: string | undefined;
}

const SignatureMethod: React.FC<Props> = ({
  control,
  disabled,
  handleSignatureMethods,
  credentialsClientId,
}) => {
  
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  const handleCloseConfirmation = () => {
    setOpenConfirmationModal(false);
  };

  const handleConfirmation = () => {
    const capturedMethod = handleSignatureMethods();
    if (capturedMethod === "Adobe") {
      getAuth();
    }
    handleCloseConfirmation();
  };

  const getAuth = () => {
    const accessLoginUrl = `https://secure.in1.adobesign.com/public/oauth/v2?redirect_uri=${authUri}&response_type=code&client_id=${credentialsClientId}&scope=user_login:account+agreement_write:account`;

    window.open(accessLoginUrl);
  };

  return (
    <>
      <Stack
        spacing={2}
        sx={{ backgroundColor: riPrimary[20] }}
        borderRadius="10px"
        padding={2}
        marginBottom={2}
      >
        <Typography fontSize="16px" fontWeight="bold">
          Signature method
        </Typography>
        <SelectElement
          label="Select signature method"
          name="captured_method"
          required
          control={control}
          options={methodList}
          labelKey="label"
          valueKey="value"
          disabled={disabled}
        />
        <Stack alignItems="end">
          <Button
            disabled={disabled}
            variant="text"
            style={{ padding: 0 }}
            onClick={() => setOpenConfirmationModal(true)}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <ReusableConfirmationModal
        open={openConfirmationModal}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmation}
        title="Save signature method"
        confirmBtnText="Yes, Save"
        cancelBtnText="Cancel, Go back"
        children={
          <Typography>
            Are you sure? You will not be able to change this again and this
            method will be used for all signatories.
          </Typography>
        }
      />
    </>
  );
};

export default SignatureMethod;
