import {
  GetFilterConfig,
  GETFILTERCONFIG,
  GetFilterConfigSuccess,
  GETFILTERCONFIG_SUCCESS,
  GetFilterConfigFailure,
  GETFILTERCONFIG_FAILURE,
  GetAggregates,
  GETAGGREGATES,
  GetAggregatesSuccess,
  GETAGGREGATES_SUCCESS,
  GetAggregatesFailure,
  GETAGGREGATES_FAILURE,
  AddFilter,
  ADDFILTER,
  AddFilterSuccess,
  ADDFILTER_SUCCESS,
  ExpandAggregate,
  EXPANDAGGREGATE,
  DeleteFilter,
  DELETEFILTER,
  FilterResult,
  FILTERRESULT,
  FilterResultSuccess,
  FILTERRESULT_SUCCESS,
  FilterResultFailure,
  FILTERRESULT_FAILURE,
  InitFilter,
  INITFILTERS,
  RemoveFilters,
  REMOVEFILTERS,
  SearchFilter,
  SEARCHFILTER,
  SearchFilterSuccess,
  SEARCHFILTER_SUCCESS,
  SAVEAPPLIEDFILTERS,
  SaveAppliedFilters,
  SearchClauses,
  SEARCH_CLAUSES,
} from "./AggregatesDef";
import {
  FilterConfig,
  AggregateState,
  AppliedFilters,
  AggregatesPayload,
} from "../State/AggregatesState";

export default class AggregatesActionGenerator {
  public static getFilterConfig(
    uniqueFileIds: string[],
    filter: AppliedFilters[],
    filterType: string
  ): GetFilterConfig {
    return {
      type: GETFILTERCONFIG,
      payload: {
        uniqueFileIds: uniqueFileIds,
        filter: filter,
        filterType: filterType,
      },
    };
  }
  public static getFilterConfigSuccess(
    config: FilterConfig[]
  ): GetFilterConfigSuccess {
    return {
      type: GETFILTERCONFIG_SUCCESS,
      payload: {
        config: config,
      },
    };
  }
  public static getFilterConfigFailure(): GetFilterConfigFailure {
    return {
      type: GETFILTERCONFIG_FAILURE,
    };
  }
  public static searchFilter(
    value: string,
    level: number,
    page: string,
    sort: string,
    type: string,
    filter: AppliedFilters[],
    fileId: string[],
    aggregateType: string
  ): SearchFilter {
    return {
      type: SEARCHFILTER,
      payload: {
        value: value,
        level: level,
        page: page,
        sort: sort,
        type: type,
        filter: filter,
        fileId: fileId,
        aggregateType: aggregateType,
      },
    };
  }
  public static searchFilterSuccess(
    filterResult: AggregateState[]
  ): SearchFilterSuccess {
    return {
      type: SEARCHFILTER_SUCCESS,
      payload: {
        filterResult: filterResult,
      },
    };
  }
  public static getAggregates(
    url:string,
    payload:any,
    searchType:string
  ): GetAggregates {
    return {
      type: GETAGGREGATES,
      payload: {
        url:url,
        payload:payload,
        searchType:searchType
      },
    };
  }
  public static getAggregatesSuccess(
    aggregates: any,
    searchType: string
  ): GetAggregatesSuccess {
    return {
      type: GETAGGREGATES_SUCCESS,
      payload: {
        aggregates: aggregates,
        searchType: searchType,
      },
    };
  }
  public static getAggregatesFailure(): GetAggregatesFailure {
    return {
      type: GETAGGREGATES_FAILURE,
    };
  }
  public static addFilter(clauseType: string,
    value: string,
    isAdd: boolean,
    currentAppliedFilter: any
  ): AddFilter {
    return {
      type: ADDFILTER,
      payload: {
        clauseType: clauseType,
        value: value,
        isAdd: isAdd,
        currentAppliedFilter: currentAppliedFilter
      },
    };
  }
  public static addFilterSuccess(filters: any): AddFilterSuccess {
    return {
      type: ADDFILTER_SUCCESS,
      payload: {
        filters: filters,
      },
    };
  }
  public static expandAggregate(name: string): ExpandAggregate {
    return {
      type: EXPANDAGGREGATE,
      payload: {
        name: name,
      },
    };
  }
  public static deleteFilter(
    filters: AppliedFilters[],
    path: string,
    sort: string,
    page: string,
    window: number,
    pageNumber: number,
    filterType: string
  ): DeleteFilter {
    return {
      type: DELETEFILTER,
      payload: {
        filters: filters,
        path: path,
        sort: sort,
        page: page,
        window: window,
        pageNumber: pageNumber,
        filterType: filterType,
      },
    };
  }
  public static filterResult(
    filters: AppliedFilters[],
    sort: string,
    window: number,
    isLoader: boolean,
    setToDefault: boolean,
    filterType: string
  ): FilterResult {
    return {
      type: FILTERRESULT,
      payload: {
        appliedFilters: filters,
        sort: sort,
        window: window,
        isLoader: isLoader,
        setToDefault: setToDefault,
        filterType: filterType,
      },
    };
  }
  public static filterResultSuccess(
    sort: string,
    isResultEmpty: boolean,
    setToDefault: boolean
  ): FilterResultSuccess {
    return {
      type: FILTERRESULT_SUCCESS,
      payload: {
        sort: sort,
        isResultEmpty: isResultEmpty,
        setToDefault: setToDefault,
      },
    };
  }
  public static filterResultFailure(): FilterResultFailure {
    return {
      type: FILTERRESULT_FAILURE,
    };
  }
  public static InitFilter(
    appliedFilters: AppliedFilters[],
    sort: string
  ): InitFilter {
    return {
      type: INITFILTERS,
      payload: {
        appliedFilters: appliedFilters,
        sort: sort,
      },
    };
  }
  public static removeFilters(): RemoveFilters {
    return {
      type: REMOVEFILTERS,
    };
  }
  public static saveAppliedFilters(
    savedFilterArray: string[]
  ): SaveAppliedFilters {
    return {
      type: SAVEAPPLIEDFILTERS,
      payload: {
        savedFilterArray: savedFilterArray,
      },
    };
  }

  public static searchClauses(results:any[],searchTerm:string):SearchClauses {
    return {
      type: SEARCH_CLAUSES,
      payload: {
        results:results,
        searchTerm: searchTerm
      }
    }
  }

}
