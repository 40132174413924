import { FileInfo, FileList } from "../../Upload/State/uploadState";

export default interface HistoryState {
  historyLoader: boolean;
  userUploads: FileList[];
  deleteStatus: number;
  uniqueFileId: string;
  documentName: string;
  contractCount: number;
}

export function defaultHistoryState(): HistoryState {
  return {
    historyLoader: false,
    userUploads: [],
    deleteStatus: -1,
    uniqueFileId: "",
    documentName: "",
    contractCount: 0
  };
}

export  interface Sort {
  sortKey: string;
  sortType: string;
  valueType: string;
}
export type FilterData = {
  [name: string]: string[]
}