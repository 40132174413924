import React, { Component } from "react";

import { DarkTooltip } from "../../../../DocumentView/Component/documentInsights";
import { NotificationData } from "../../State/notificationState";
import { isNullOrUndefined } from "is-what";
import { truncateString } from "../../../../Utils/DataModifierUtil/dataModUtil";

interface Props {
  notification: NotificationData;
  removeNotification: (notificationID: string) => void;
}

interface State {
  isActive: boolean;
  title: string;
}

export default class ContractUploadNotification extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isActive: false,
      title: "",
    };
  }

  componentDidMount() {
    if (!isNullOrUndefined(this.props.notification.details.file_name)) {
      let title: any = this.props.notification.details.file_name;
      this.setState({ title: title });
    }
  }

  removeNotification = () => {};

  render() {
    let { notification, removeNotification } = this.props;
    return (
      <>
        <div
          className="col-md-12"
          style={{
            borderLeft: "6px solid #FBCE2F",
            //   notification.isRead === false ? "6px solid #FBCE2F" : "",
          }}
          id="notification-type-container"
        >
          <div className="col-md-12 p-0" style={{ display: "inline-flex" }}>
            <div className="col-md-1">
              <img alt="progress-icon" src="/static_images/progress-icn.svg" />
            </div>
            <div className="col-md-10 mt-1 pb-2 task-title">
              {(notification.details.file_name === null ||
                notification.details.file_name === "") && (
                <img src="/static_images/empty-dash.svg" alt="empty-dash"></img>
              )}
              {this.state.title != null ? (
                <>
                  {this.state.title.length > 25 ? (
                    <DarkTooltip title={this.state.title} placement="right-end">
                      <span
                        style={{ display: "initial", cursor: "pointer" }}
                        onClick={() =>
                          window.open(
                            "/document/documentlibrary/" +
                              btoa(notification.details.id.toString()) +
                              "/",
                            "_blank"
                          )
                        }
                      >
                        {truncateString(this.state.title, 25)}
                      </span>
                    </DarkTooltip>
                  ) : (
                    <span
                      style={{ display: "initial", cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          "/document/documentlibrary/" +
                            btoa(notification.details.id.toString()) +
                            "/",
                          "_blank"
                        )
                      }
                    >
                      {this.state.title}
                    </span>
                  )}
                </>
              ) : null}
            </div>
            <div className="col-md-1">
              <span
                style={{ float: "right", cursor: "pointer" }}
                id="close-btn"
                onClick={() => removeNotification(notification.id)}
              >
                <img src="/static_images/close-modal-icn.svg" alt="close" />
              </span>
            </div>
          </div>
          {/* _____ */}
          <div className="col-md-12 mt-2 clause-bi-name">
            <span>
              A new file has been uploaded to your group
              {/* {notification.details.contract_clause_type} - Clause */}
              {/* - {notification.biType} */}
            </span>
          </div>
          <div className="col-md-4 mt-1 pr-0 alert-date">
            <span></span>
          </div>

          {/* _____ */}
        </div>
      </>
    );
  }
}
