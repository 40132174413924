import {
  GetSentences,
  GETSENTENCES,
  GETINSIGHTS,
  GetInsights,
  RequestTags,
  REQUESTTAGS,
  ListTagNature,
  ListTagType,
  LISTTAGTYPE,
  LISTTAGNATURE,
  ListOtherTags,
  LISTOTHERTAGS,
  EditDataPoint,
  EDITDATAPOINT,
  CreateOtherTags,
  CREATEOTHERTAGS,
  ListEvents,
  LISTEVENTS,
  CreateNewEvent,
  CREATENEWEVENT,
  GetDocumentTree,
  GETDOCUMENTTREE,
  GetDurationTypes,
  GETDURATIONTYPES,
  GETCURRENCYTYPES,
  GetCurrencyTypes,
  GetDeviationPolicy,
  GetApproval,
  GETAPPROVAL,
  GetContractData,
  GETCONTRACTDATA,
  GETINDIVIDUALINSIGHTS,
  GetIndividualInsights,
} from "../Actions/def";

import { call, put, all, takeLatest, takeEvery } from "redux-saga/effects";
import DocumentAPI from "../Actions/API";
import {
  SentencesData,
  InsightsInterface,
  TagData,
  EventData,
  DurationType,
  CurrencyType,
  Hierarchy,
  ContractDetails,
} from "../State/documentState";
import DocumentActionGenerator from "../Actions/Gen";
import {
  getEventDataFromEventResult,
  getClauseType,
} from "../Component/Utils/docUtils";
import { FileHierarchy } from "../../DocumentLibrary/State/documentLibraryState";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";
import {
  getKeyCloakRealmFromLS,
  getLocalStorage,
} from "../../Authentication/Actions/authentication";
import axios from "axios";
import { ResponseGenerator } from "../../Types";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

interface tempData {
  readonly fileid: number;
  readonly hierarchy: Hierarchy[];
}

// function* getSentences(action: GetSentences) {
//   let fileId = action.payload.fileId;

//   var contract_id = atob(action.payload.fileId.toString());

//   let url = SITEAPI + "" + contract_id + "/document/hierarchy/";

//   try {
//     let response = yield call(DocumentAPI.getSentences, url);
//     switch (response.status) {
//       case 200: {
//         let extracted: any[] = [];

//         if (response.data.results.length != 0) {
//           Object.keys(response.data.results).map(function (key) {
//             extracted.push(response.data.results[key]["content"]);
//           });

//           extracted.sort((a: any, b: any) => (a.index > b.index ? 1 : -1));
//         }
//         let data = {
//           fileId: contract_id,
//           hierarchy: extracted,
//         };

//         let parsed = data as SentencesData;
//         // let parsed = response.data.queryResult.extracted as SentencesData;
//         yield put(DocumentActionGenerator.getSentencesSuccess(parsed));
//         break;
//       }
//       default: {
//         yield put(DocumentActionGenerator.getSentencesFailure());
//       }
//     }
//   } catch (e) {
//     yield put(DocumentActionGenerator.getSentencesFailure());
//   }
// }
function* getSentences(action: GetSentences) {
  let fileId = action.payload.fileId;

  var contract_id = atob(fileId.toString());

  let url = SITEAPI + "insights/" + contract_id + "/extracted/";

  try {
    let response: ResponseGenerator = yield call(DocumentAPI.getSentences, url);
    switch (response.status) {
      case 200: {
        let extracted: any[] = [];
        if (response.data.raw_content.hierarchy.length !== 0) {
          Object.keys(response.data.raw_content.hierarchy).map(function (key) {
            extracted.push(response.data.raw_content.hierarchy[key]);
          });
          extracted.sort((a: any, b: any) => (a.index > b.index ? 1 : -1));
        }
        let data = {
          fileId: contract_id,
          hierarchy: extracted,
        };

        let parsed = data as SentencesData;
        // let parsed = response.data.queryResult.extracted as SentencesData;
        yield put(DocumentActionGenerator.getSentencesSuccess(parsed));
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getSentencesFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getSentencesFailure());
  }
}
function* getInsights(action: GetInsights) {
  let fileId = action.payload.fileId;
  let url = SITEAPI + "clause_types/" + fileId + "/";
  try {
    let response: ResponseGenerator = yield call(DocumentAPI.getInsights, url);
    switch (response.status) {
      case 200: {
        let clause_type_response: any = response.data;
        yield put(
          DocumentActionGenerator.getInsightsSuccess(clause_type_response)
        );
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getInsightsFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getInsightsFailure());
  }
}

// const getInsights = async (action: GetInsights) => {
//   let fileId = action.payload.fileId;
//   let insightURL: string =
//     SITEAPI + "insights/" + atob(action.payload.fileId.toString()) + "/";
//   let contract_id = atob(fileId);
//   let clauseTypeUrl = SITEAPI + "clause_types/" + contract_id + "/";

//   try {
//     const insightResponse = await DocumentAPI.getInsights(clauseTypeUrl);
//     switch (insightResponse.status) {
//       case 200: {
//         let insights_response: InsightsInterface[] = [];
//         let clause_type_response: any = insightResponse.data.queryResult;
//         let para_dataPoints: any[] = [];
//         let para_with_sentence_datapoints: any[] = [];
//         const url_list = clause_type_response?.map(
//           (item: string) => insightURL + "" + item + "/"
//         );

//         let parsed = insights_response as InsightsInterface[]; //response.data.queryResult as InsightsInterface[];
//         yield put(DocumentActionGenerator.getInsightsSuccess(parsed));
//         break;
//       }
//       default: {
//         // yield put(DocumentActionGenerator.getInsightsFailure());
//       }
//     }
//   } catch (error) {}
// };

function* getDeviationPolicy(action: GetDeviationPolicy) {
  let fileId = action.payload.fileId;
  yield put(DocumentActionGenerator.getDeviationSuccess([]));
  /*let url =
    SITEAPI +
    "deviation-policy/" +
    atob(action.payload.fileId.toString()) +
    "/";
  try {
    let response = yield call(DocumentAPI.getInsights, url);
    switch (response.status) {
      case 200: {
        let parsed = response.data.queryResult as DeviationPolicyInterface[];
        yield put(DocumentActionGenerator.getDeviationSuccess(parsed));
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getDeviationPolicyFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getDeviationPolicyFailure());
  }*/
}

function* getContractData(action: GetContractData) {
  let fileId = action.payload.fileId;

  let url = SITEAPI + atob(action.payload.fileId.toString()) + "/";
  try {
    let response: ResponseGenerator = yield call(DocumentAPI.getInsights, url);
    switch (response.status) {
      case 200: {
        let parsed = response.data as ContractDetails;

        yield put(DocumentActionGenerator.getContractDataSuccess(parsed));
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getContractDataFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getContractDataFailure());
  }
}

function* requestTags(action: RequestTags) {
  let tagList = action.payload.tagList;
  let comment = action.payload.comment;
  let url = SITEAPI + "document/requesttags";
  let payload = {
    tagList: tagList,
    comment: comment,
  };
  try {
    let response: ResponseGenerator = yield call(
      DocumentAPI.requestTags,
      url,
      payload
    );
    switch (response.status) {
      case 200: {
        yield put(DocumentActionGenerator.requestTagsSuccess());
        break;
      }
      default: {
        //do nothing
      }
    }
  } catch (e) {
    //do nothing
  }
}

function* listTagNature(action: ListTagNature) {
  //   let url = SITEAPI + "document/tags/nature";
  let url = SITEAPI + "tags/";
  try {
    let response: ResponseGenerator = yield call(
      DocumentAPI.listTagNature,
      url
    );

    switch (response.status) {
      case 200: {
        var responseData = [];
        if (response.data.results.length !== 0) {
          responseData = response.data.results.filter(
            (tag: any) => tag.type === "nature"
          );
          let parsed = responseData as TagData[];
          yield put(DocumentActionGenerator.listTagNatureSuccess(parsed));

          var tagtypeData = (responseData = response.data.results.filter(
            (tag: any) => tag.type === "type"
          ));
          let tagtype_parsed = tagtypeData as TagData[];
          yield put(DocumentActionGenerator.listTagTypeSuccess(tagtype_parsed));

          var tagotherData = (responseData = response.data.results.filter(
            (tag: any) => tag.type === "others"
          ));
          let tagotherparsed = tagotherData as TagData[];
          yield put(
            DocumentActionGenerator.listOtherTagsSuccess(tagotherparsed)
          );
        }

        // let parsed = response.data.queryResult as TagData[];

        break;
      }
      default: {
        //do nothing
      }
    }
  } catch (e) {
    //do nothing
  }
}

function* listTagType(action: ListTagType) {
  //let url = SITEAPI + "document/tags/type";
  let url = SITEAPI + "tags/";
  try {
    let response: ResponseGenerator = yield call(DocumentAPI.listTagType, url);
    switch (response.status) {
      case 200: {
        var responseData = [];
        if (response.data.results.length !== 0) {
          var tagotherData = (responseData = response.data.results.filter(
            (tag: any) => tag.type === "others"
          ));
          let tagotherparsed = tagotherData as TagData[];
          yield put(
            DocumentActionGenerator.listOtherTagsSuccess(tagotherparsed)
          );
        }
        //let parsed = response.data.queryResult as TagData[];
        // yield put(DocumentActionGenerator.listTagTypeSuccess(parsed));
        break;
      }
      default: {
        //do nothing
      }
    }
  } catch (e) {
    //do nothing
  }
}

export function* listOtherTags(action: ListOtherTags) {
  /*// let url = SITEAPI + 'document/grouptags';
  let url = SITEAPI + "document-library/";
  console.log("list other tags88888");
  let response = yield call(DocumentAPI.listOtherTags, url);
  if (response.status === 200) {
    console.log("🚀 ~ file: DocumentSaga.ts listOtherTags", response);
    let parsed = response.data.queryResult as TagData[];
    yield put(DocumentActionGenerator.listOtherTagsSuccess(parsed));
  } else {
    // console.log(response.data);
    //do nothing
  }*/
}

function* addOrEditData(action: EditDataPoint) {
  let url = SITEAPI + "document/editdata";
  let payload = {
    id: action.payload.fileId,
    dataType: action.payload.dataType,
    name: action.payload.dataPointName,
    highlightedId: action.payload.highlightedId,
  };

  try {
    let response: ResponseGenerator = yield call(
      DocumentAPI.addOrEditData,
      url,
      payload
    );
    switch (response.status) {
      case 200: {
        yield put(DocumentActionGenerator.editDataPointSuccess());

        // yield put(DocumentActionGenerator.getIndividualInsights(getClauseType(action.payload.dataType)))
        yield put(
          DocumentActionGenerator.getIndividualInsights(
            getClauseType(action.payload.dataType),
            action.payload.fileId
          )
        );
        break;
      }
      default: {
        yield put(DocumentActionGenerator.editDataPointFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.editDataPointFailure());
  }
}

function* createOtherTag(action: CreateOtherTags) {
  // let url = SITEAPI + "tags/";
  let url = process.env.REACT_APP_RIVERUS_CLM_API + `umbrella/projects/`;
  var params = {
    name: action.payload.name,
    displayName: action.payload.name,
    comments: "",
  };

  /*var params = {
    name: action.payload.name,
    type: "others",
    contracts: [],
  };*/
  try {
    let response: ResponseGenerator = yield call(
      DocumentAPI.createOtherTag,
      url,
      params
    );

    switch (response.status) {
      case 201: {
        let newTagData: TagData = {
          // id: response.data.queryResult.id as number,
          id: response.data.id as string,
          name: response.data.name,
          group: 0,
          type: "others",
          contracts: [],
          displayName: response.data.displayName,
        };

        yield put(DocumentActionGenerator.createOtherTagsSuccess(newTagData));
        break;
      }
      default: {
        //do nothing
      }
    }
  } catch (e) {
    //do nothing
  }
}

function* listEvents(action: ListEvents) {
  let eventTerm = action.payload.event;
  const url = SITEAPI + "event/?type=" + eventTerm;
  try {
    let response: ResponseGenerator = yield call(DocumentAPI.listEvents, url);
    switch (response.status) {
      case 200: {
        let parsed = getEventDataFromEventResult(response.data.results);
        yield put(DocumentActionGenerator.listEventsSuccess(parsed));
        break;
      }
      default: {
        //do nothing
      }
    }
  } catch (e) {
    //do nothing
  }
}

function* createNewEvent(action: CreateNewEvent) {
  let newEventName = btoa(action.payload.name);
  /*let url =
    SITEAPI +
    "document/events/create/" +
    action.payload.eventPoint +
    "/" +
    newEventName;*/
  let url = SITEAPI + "event/";
  let payload_data = {
    name: action.payload.name,
    type: action.payload.eventPoint,
  };
  try {
    let response: ResponseGenerator = yield call(
      DocumentAPI.createNewEvent,
      url,
      payload_data
    );
    switch (response.status) {
      case 201: {
        let newEventData: EventData = {
          eventId: response.data.results.id as string,
          eventName: response.data.results.name,
        };
        yield put(DocumentActionGenerator.createNewEventSuccess(newEventData));
        break;
      }
      default: {
        //do nothing
      }
    }
  } catch (e) {
    //do nothing
  }
}

function* getDocumentTree(action: GetDocumentTree) {
  let fileID = action.payload.fileID;
  // let url = SITEAPI + "library/dochierarchy";
  let url = SITEAPI + "document-library/?id=" + fileID;

  try {
    let response: ResponseGenerator = yield call(
      DocumentAPI.getDocumentTree,
      url,
      fileID
    );

    switch (response.status) {
      case 200: {
        // let hiearchy = {} as FileHierarchy;
        // let test = {} as {};
        let hiearchy: FileHierarchy[] = [];

        let parsed = {} as FileHierarchy;
        if (response.data.results.length !== 0) {
          parsed = response.data.results[0];
        }
        // parsed = response.data.results as FileHierarchy;
        yield put(DocumentActionGenerator.getDocumentTreeSuccess(parsed));
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getDocumentTreeFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getDocumentTreeFailure());
  }
}

function* getDurationTypes(action: GetDurationTypes) {
  let url = SITEAPI + "document/duration";

  try {
    let response = {
      data: [
        {
          durationTypeId: 1,
          durationName: "Days",
          durationType: "day",
        },
        {
          durationTypeId: 2,
          durationName: "Months",
          durationType: "month",
        },
        {
          durationTypeId: 3,
          durationName: "Years",
          durationType: "year",
        },
      ],
    };
    let parsed = response.data as DurationType[];
    yield put(DocumentActionGenerator.getDurationTypesSuccess(parsed));
    // let response = yield call(DocumentAPI.getDurationTypes, url);
    /*switch (response.status) {
      case 200: {
        let parsed = response.data.queryResult.data as DurationType[];
        yield put(DocumentActionGenerator.getDurationTypesSuccess(parsed));
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getDurationTypesFailure());
      }
    }*/
  } catch (e) {
    yield put(DocumentActionGenerator.getDurationTypesFailure());
  }
}

function* getCurrencyTypes(action: GetCurrencyTypes) {
  let url: any = process.env.REACT_APP_RIVERUS_CLM_API + "admin/currency/";
  try {
    let response = [
      { currencyTypeId: 1, currencyName: "€ Euro", currencyType: "€" },
      { currencyTypeId: 2, currencyName: "₹ Indian Rupee", currencyType: "₹" },
      { currencyTypeId: 3, currencyName: "$ Dollar", currencyType: "$" },
    ];
    let parsed = response as CurrencyType[];
    //yield put(DocumentActionGenerator.getCurrencyTypesSuccess(parsed));
    let currency_response: ResponseGenerator = yield call(
      DocumentAPI.getCurrencyTypes,
      url
    );
    switch (currency_response.status) {
      case 200: {
        let parsed = currency_response.data.results as CurrencyType[];
        yield put(DocumentActionGenerator.getCurrencyTypesSuccess(parsed));
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getCurrencyTypesFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getCurrencyTypesFailure());
  }
}

function* getApproval(action: GetApproval) {
  yield put(DocumentActionGenerator.getApprovalSuccess([]));
  /*let url = process.env.REACT_APP_RIVERUS_CLM_API + "stylus/approval/";
  try {
    let response = yield call(DocumentAPI.getApproval, url);
    switch (response.status) {
      case 200: {
        let parsed = response.data.results as Approval[];
        yield put(DocumentActionGenerator.getApprovalSuccess(parsed));
        break;
      }
      default: {
        yield put(DocumentActionGenerator.getApprovalFailure());
      }
    }
  } catch (e) {
    yield put(DocumentActionGenerator.getApprovalFailure());
  }*/
}

function* getIndividualInsights(action: GetIndividualInsights) {
  let url =
    SITEAPI +
    "insights/" +
    action.payload.id +
    "/" +
    action.payload.clause +
    "/";
  try {
    let clause_response: ResponseGenerator = yield call(
      DocumentAPI.getInsights,
      url
    );
    switch (clause_response.status) {
      case 200: {
        let parsed = clause_response.data.queryResult as InsightsInterface;
        yield put(DocumentActionGenerator.getIndividualInsightsSuccess(parsed));
        if (action.payload.clause !== "obligation_statements") {
          if (action.payload.clause === "change_of_control") {
            let child_clause: any = [
              "termination",
              "payment",
              "consent",
              "notice",
            ];
            for (var j = 0; j < child_clause.length; j++) {
              try {
                let child_clause_url =
                  SITEAPI +
                  "insights/" +
                  action.payload.id +
                  "/" +
                  child_clause[j] +
                  "/";
                let child_clause_response: ResponseGenerator = yield call(
                  DocumentAPI.getInsights,
                  child_clause_url
                );
                switch (child_clause_response.status) {
                  case 200: {
                    let child_clause_parsed: any = child_clause_response.data
                      .queryResult as InsightsInterface;
                    yield put(
                      DocumentActionGenerator.getIndividualInsightsSuccess(
                        child_clause_parsed
                      )
                    );
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }
          } else if (
            action.payload.clause === "termination" ||
            action.payload.clause === "payment" ||
            action.payload.clause === "consent" ||
            action.payload.clause === "notice"
          ) {
            try {
              let change_of_control_clause_url =
                SITEAPI +
                "insights/" +
                action.payload.id +
                "/change_of_control/";
              let change_of_control_clause_response: ResponseGenerator =
                yield call(
                  DocumentAPI.getInsights,
                  change_of_control_clause_url
                );
              switch (change_of_control_clause_response.status) {
                case 200: {
                  let change_of_control_clause_parsed: any =
                    change_of_control_clause_response.data
                      .queryResult as InsightsInterface;
                  yield put(
                    DocumentActionGenerator.getIndividualInsightsSuccess(
                      change_of_control_clause_parsed
                    )
                  );
                }
              }
            } catch (e) {
              console.log(e);
            }
          }
          try {
            let other_obligation_url =
              SITEAPI +
              "insights/" +
              action.payload.id +
              "/obligation_statements/";
            let obligation_response: ResponseGenerator = yield call(
              DocumentAPI.getInsights,
              other_obligation_url
            );
            switch (obligation_response.status) {
              case 200: {
                let obligation_parsed: any = obligation_response.data
                  .queryResult as InsightsInterface;
                yield put(
                  DocumentActionGenerator.getIndividualInsightsSuccess(
                    obligation_parsed
                  )
                );
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* DocumentWatcher() {
  yield all([
    takeLatest(GETSENTENCES, getSentences),
    takeLatest(GETINSIGHTS, getInsights),
    takeLatest(REQUESTTAGS, requestTags),
    takeLatest(LISTTAGTYPE, listTagType),
    takeLatest(LISTTAGNATURE, listTagNature),
    takeLatest(LISTOTHERTAGS, listOtherTags),
    takeLatest(EDITDATAPOINT, addOrEditData),
    takeLatest(CREATEOTHERTAGS, createOtherTag),
    takeLatest(LISTEVENTS, listEvents),
    takeLatest(CREATENEWEVENT, createNewEvent),
    takeLatest(GETDOCUMENTTREE, getDocumentTree),
    takeLatest(GETDURATIONTYPES, getDurationTypes),
    takeLatest(GETCURRENCYTYPES, getCurrencyTypes),
    takeLatest(GETINSIGHTS, getDeviationPolicy),
    takeLatest(GETAPPROVAL, getApproval),
    takeLatest(GETCONTRACTDATA, getContractData),
    takeEvery(GETINDIVIDUALINSIGHTS, getIndividualInsights),
  ]);
}
