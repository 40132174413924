import {
  BISentence,
  BIType,
  ClauseType,
  Comments,
  TaskState,
  UserData,
} from "../State/taskManagementState";
import {
  CREATETASK,
  CreateTask,
  GETBISENTENCE,
  GETBITYPE,
  GETCLAUSETYPE,
  GETCOMMENTS,
  GETTASKPROGRESS,
  GETTASKSTATE,
  GETUSERDATA,
  GetBISentence,
  GetBIType,
  GetClauseType,
  GetComments,
  GetTaskProgress,
  GetTaskState,
  GetUserData,
  POSTCOMMENT,
  PostComment,
  UPDATETASK,
  UpdateTask,
} from "../Action/def";
import { all, call, put, takeLatest } from "redux-saga/effects";

import NotificationGenerator from "../../../Notification/Actions/gen";
import { ResponseGenerator } from "../../../../Types";
import { SITE_API_BY_REALM_NAME } from "../../../../Configuration/global";
import TaskManagementAPI from "../Action/API";
import TaskManagementActionGenerator from "../Action/gen";
import { getKeyCloakRealmFromLS } from "../../../../Authentication/Actions/authentication";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
function* createTask(action: CreateTask) {
  // const url = SITEAPI + "task/create";
  let url = process.env.REACT_APP_RIVERUS_CLM_API + "tasks/";

  let payload = action.payload.taskData;

  var checklist = [];
  for (var i = 0; i < payload.checkList.length; i++) {
    var checklistData = {
      title: payload.checkList[i].title,
      flag: payload.checkList[i].flag,
      delete: payload.checkList[i].delete,
    };
    checklist.push(checklistData);
  }

  var progressList = [
    { id: "1", name: "NOT_STARTED" },
    { id: "2", name: "IN_PROGRESS" },
    { id: "3", name: "COMPLETED" },
  ];
  var index = progressList.findIndex((obj) => obj.id === payload.progressID);
  let task = {
    contract: payload.link.fileID,
    contract_clause_type: payload.link.clauseType,
    contract_business_intelligence_type: payload.link.linkedText,
    link_to_contract_text: payload.link.linkedText,
    title: payload.title,
    due_date: payload.due_date,
    progress: progressList[index]["name"],
    notes: payload.description,
    status: "DONE",
    reminder_before_or_after: payload.reminder.reminderType.toUpperCase(),
    reminder_repeats: payload.reminder.frequencyType.toUpperCase(),
    reminder_until: payload.reminder.reminderUntil,
    reminder_period: payload.reminder.reminderStartType.toUpperCase(),
    reminder_number: payload.reminder.reminderStart,
    assignees: payload.activityGroup,
    checklist: checklist,
  };

  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.createTask,
      url,
      task
    );
    switch (response.status) {
      case 201: {
        yield put(TaskManagementActionGenerator.createTaskSuccess(1));
        break;
      }
      default:
        yield put(TaskManagementActionGenerator.createTaskFailure(-1));
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.createTaskFailure(-1));
  }
}

function* getBISentence(action: GetBISentence) {
  // const url = SITEAPI + "task/bitext";
  // const url = "http://localhost:6064/task/bitext";
  const url =
    process.env.REACT_APP_RIVERUS_CLM_API +
    "tasks/" +
    action.payload.fileID +
    "/clausebitext/" +
    action.payload.biColumnName +
    "/";
  let payload = {
    fileID: action.payload.fileID,
    biColumnName: action.payload.biColumnName,
  };

  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.getBISentence,
      url,
      payload
    );

    switch (response.status) {
      case 200: {
        let biSen: BISentence = {
          sentences: response.data.sentence, //response.data.queryResult.sentence,
        };
        yield put(TaskManagementActionGenerator.getBISentenceSuccess(biSen));
        break;
      }
      default:
        yield put(TaskManagementActionGenerator.getBISentenceFailure());
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.getBISentenceFailure());
  }
}

function* getClauseType(action: GetClauseType) {
  // const url = SITEAPI + "task/clausetype";
  // const url = "http://localhost:6064/task/clausetype/";
  const url = SITEAPI + "clause-type/";
  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.getClauseType,
      url
    );
    switch (response.status) {
      case 200: {
        // let clauseType: ClauseType[] = response.data
        //   .queryResult as ClauseType[];
        let clauseType: ClauseType[] = response.data.results as ClauseType[];
        yield put(
          TaskManagementActionGenerator.getClauseTypeSuccess(clauseType)
        );
        break;
      }
      default: {
        yield put(TaskManagementActionGenerator.getClauseTypeFailure());
      }
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.getClauseTypeFailure());
  }
}

function* getBIType(action: GetBIType) {
  const url = SITEAPI + "task/bitype";
  let payload = {
    clauseType: action.payload.clauseName,
  };

  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.getBIType,
      url,
      payload
    );
    switch (response.status) {
      case 200: {
        let biType: BIType[] = response.data.queryResult as BIType[];

        yield put(TaskManagementActionGenerator.getBITypeSuccess(biType));
        break;
      }
      default: {
        yield put(TaskManagementActionGenerator.getBITypeFailure());
      }
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.getBITypeFailure());
  }
}

function* getTaskProgress(action: GetTaskProgress) {
  // const url = SITEAPI + "task/progress";
  let response = {
    queryResult: [
      { id: "1", name: "Not Started" },
      { id: "2", name: "In Progress" },
      { id: "3", name: "Completed" },
    ],
  };
  const parsed = response.queryResult as TaskState[];
  yield put(TaskManagementActionGenerator.getTaskProgressSuccess(parsed));
  /* try {
    const response:ResponseGenerator = yield call(TaskManagementAPI.getTaskProgress, url);
    switch (response.status) {
      case 200: {
        const parsed = response.data.queryResult as TaskState[];
        yield put(TaskManagementActionGenerator.getTaskProgressSuccess(parsed));
        break;
      }
      default: {
        yield put(TaskManagementActionGenerator.getTaskProgressFailure());
      }
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.getTaskProgressFailure());
  }*/
}

function* getTaskState(action: GetTaskState) {
  const url = SITEAPI + "task/state";
  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.getTaskState,
      url
    );
    switch (response.status) {
      case 200: {
        const parsed = response.data.queryResult as TaskState[];
        yield put(TaskManagementActionGenerator.getTaskStateSuccess(parsed));
        break;
      }
      default: {
        yield put(TaskManagementActionGenerator.getTaskStateFailure());
      }
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.getTaskStateFailure());
  }
}

function* getUserData(action: GetUserData) {
  // const url = SITEAPI + "task/users";

  let url = process.env.REACT_APP_RIVERUS_CLM_API + "users/";

  try {
    // API side effect
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.getUserData,
      url
    );
    switch (response.status) {
      case 200: {
        const parsed = response.data.results as UserData[];

        yield put(TaskManagementActionGenerator.getUserDataSuccess(parsed));
        break;
      }
      default: {
        yield put(TaskManagementActionGenerator.getUserDataFailure());
      }
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.getUserDataFailure());
  }
}

function* updateTask(action: UpdateTask) {
  // const url = SITEAPI + "task/edit";

  let url =
    process.env.REACT_APP_RIVERUS_CLM_API +
    "tasks/" +
    action.payload.taskData.id +
    "/";
  let payload = action.payload.taskData;

  var checklist = [];
  for (var i = 0; i < payload.checkList.length; i++) {
    if (
      payload.checkList[i].delete === true &&
      payload.checkList[i].id === "-1"
    ) {
    } else if (
      payload.checkList[i].delete === false &&
      payload.checkList[i].id === "-1"
    ) {
      var checklistData = {
        title: payload.checkList[i].title,
        flag: payload.checkList[i].flag,
        delete: payload.checkList[i].delete,
      };
      checklist.push(checklistData);
    } else if (
      payload.checkList[i].delete === false &&
      payload.checkList[i].id !== "-1"
    ) {
      var checklist_Data = {
        id: payload.checkList[i].id,
        title: payload.checkList[i].title,
        flag: payload.checkList[i].flag,
        delete: payload.checkList[i].delete,
      };
      checklist.push(checklist_Data);
    } else {
    }
  }
  var progressList = [
    { id: "1", name: "NOT_STARTED" },
    { id: "2", name: "IN_PROGRESS" },
    { id: "3", name: "COMPLETED" },
  ];
  var index = progressList.findIndex((obj) => obj.id === payload.progressID);
  let task = {
    contract: payload.link.fileID,
    contract_clause_type: payload.link.clauseType,
    contract_business_intelligence_type: payload.link.linkedText,
    link_to_contract_text: payload.link.linkedText,
    title: payload.title,
    due_date: payload.due_date,
    progress: progressList[index]["name"],
    notes: payload.description,
    status: "DONE",
    reminder_before_or_after: payload.reminder.reminderType.toUpperCase(),
    reminder_repeats: payload.reminder.frequencyType.toUpperCase(),
    reminder_until: payload.reminder.reminderUntil,
    reminder_period: payload.reminder.reminderStartType.toUpperCase(),
    reminder_number: payload.reminder.reminderStart,
    assignees: payload.activityGroup,
    checklist: checklist,
  };

  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.updateTask,
      url,
      task
    );
    switch (response.status) {
      case 200: {
        yield put(TaskManagementActionGenerator.updateTaskDataSuccess(1));
        break;
      }
      default:
        yield put(TaskManagementActionGenerator.updateTaskDataFailure(-1));
    }
  } catch (e) {
    yield put(TaskManagementActionGenerator.updateTaskDataFailure(-1));
  }
}

function* postComment(action: PostComment) {
  // const url = SITEAPI + "task/comment";
  let requestid = action.payload.requestid;
  let comment = action.payload.comment;
  const url =
    process.env.REACT_APP_RIVERUS_CLM_API + "tasks/" + requestid + "/comments/";

  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.postComment,
      url,
      {
        message: comment,
      }
    );
    switch (response.status) {
      case 201: {
        yield put(TaskManagementActionGenerator.postCommentSuccess());
        yield put(TaskManagementActionGenerator.getComments(requestid));
        yield put(NotificationGenerator.getNotificationsData());
        break;
      }
      default: {
        yield put(TaskManagementActionGenerator.postCommentFailure());
      }
    }
  } catch (error) {
    yield put(TaskManagementActionGenerator.postCommentFailure());
  }
}

function* getComments(action: GetComments) {
  let requestID = action.payload.requestId;
  // const url = SITEAPI + "task/comment/" + requestID;
  const url =
    process.env.REACT_APP_RIVERUS_CLM_API + "tasks/" + requestID + "/comments/";
  // const url = "http://localhost:6064/contract/task/comment";
  //const parsed = [] as Comments[]; //response.data.queryResult as Comments[];
  //yield put(TaskManagementActionGenerator.getCommentsSuccess(parsed));
  try {
    const response: ResponseGenerator = yield call(
      TaskManagementAPI.getComments,
      url
    );
    switch (response.status) {
      case 200: {
        const parsed = response.data.results as Comments[]; //[] as Comments[]; //
        /*  const parsed = [
          {
            id: "02f5dbba-4a3b-41d6-b7d1-76eb976e16e9",
            created_by: "Admin TN",
            message: "Test",
            created_at: "2022-08-01",
            task: "ca9f80c7-98b1-46b4-af9f-05aa4ddb23e2",
          },
          {
            id: "02f5dbba-4a3b-41d6-b7d1-76eb976e16e9",
            created_by: "Admin TN",
            message: "Test",
            created_at: "2022-08-01",
            task: "ca9f80c7-98b1-46b4-af9f-05aa4ddb23e2",
          },
          // {
          //   comment: "Text",
          //   commentTime: new Date("2022-08-01T09:19:19.419Z"),
          //   aliasName: "RT",
          //   name: "jii",
          // },
          // {
          //   comment: "Text",
          //   commentTime: new Date("2022-08-01T09:19:19.419Z"),
          //   aliasName: "RT",
          //   name: "jii",
          // },
        ] as Comments[];*/
        // const parsed = response.data.queryResult as Comments[];
        yield put(TaskManagementActionGenerator.getCommentsSuccess(parsed));
        break;
      }
      default: {
        //yield put(TaskManagementActionGenerator.getCommentsFailure());
      }
    }
  } catch (error) {
    // yield put(TaskManagementActionGenerator.getCommentsFailure());
  }
}

export default function* TaskManagementWatcher() {
  yield all([
    takeLatest(GETUSERDATA, getUserData),
    takeLatest(GETTASKSTATE, getTaskState),
    takeLatest(GETBITYPE, getBIType),
    takeLatest(GETBISENTENCE, getBISentence),
    takeLatest(CREATETASK, createTask),
    takeLatest(GETTASKPROGRESS, getTaskProgress),
    takeLatest(GETCLAUSETYPE, getClauseType),
    takeLatest(UPDATETASK, updateTask),
    takeLatest(POSTCOMMENT, postComment),
    takeLatest(GETCOMMENTS, getComments),
  ]);
}
