import ChangeClause from "../ChangeClause";
import { ClauseComponentInterface } from "../interfaces/ClauseComponentIterface";
import { ClauseType } from "../../../Constants/ClauseType";
import ConfidentialityClause from "../ConfidentialityClause";
import ConsentClause from "../ConsentClause";
import DataBreachClause from "../DataBreachClause";
import DisputeClause from "../DisputeClause";
import EffectiveClause from "../EffectiveClause";
import EventClause from "../EventClause";
import ExclusivityClause from "../ExclusivityClause";
import ForceClause from "../ForceClause";
import GeneralClause from "../GeneralClause";
import GoverningClause from "../GoverningClause";
import IndemnityClause from "../Indemnity";
import InsuranceClause from "../InsuranceClause";
import LimitationClause from "../LimitationClause";
import NonCompeteClause from "../NonCompeteClause";
import NonSolicationClause from "../NonSolicationClause";
import NoticeClause from "../NoticeClause";
import OtherObligationClause from "../OtherObligationClause";
import PaymentObligationsClause from "../PaymentObligations";
import React from "react";
import RenewalClause from "../RenewalClause";
import SublettingClause from "../SublettingClause";
import TermClause from "../TermClause";
import TerminationClause from "../TernimationClause";
import { getChildHighlights } from "../../Utils/docUtils";

interface Props
  extends Omit<ClauseComponentInterface, "hasData" | "onClickHighlight"> {
  insightsData: any[];
  scrollToChildDataPoint: any;
  scrollToDatapoint: any;
  getInsights: (fileId: string) => void;
  otherClauses: (type: string) => void;
}

const clauseComponents: any = {
  term: TermClause,
  renewal: RenewalClause,
  payment: PaymentObligationsClause,
  indemnity: IndemnityClause,
  confidentiality: ConfidentialityClause,
  change_of_control: ChangeClause,
  termination: TerminationClause,
  insurance: InsuranceClause,
  notice: NoticeClause,
  events_of_default: EventClause,
  limited_liability: LimitationClause,
  force_majeure: ForceClause,
  governing_law: GoverningClause,
  dispute_resolution: DisputeClause,
  non_compete: NonCompeteClause,
  non_solicitation: NonSolicationClause,
  subletting: SublettingClause,
  consent: ConsentClause,
  general_definitions: GeneralClause,
  data_breach: DataBreachClause,
  effective_date: EffectiveClause,
  exclusivity: ExclusivityClause,
  obligation_statements: OtherObligationClause,
};

const CLAUSE_RENDERING_ORDER: ClauseType[] = [
  "term",
  "renewal",
  "payment",
  "indemnity",
  "confidentiality",
  "change_of_control",
  "termination",
  "insurance",
  "notice",
  "events_of_default",
  "limited_liability",
  "force_majeure",
  "governing_law",
  "dispute_resolution",
  "non_compete",
  "non_solicitation",
  "subletting",
  "consent",
  "general_definitions",
  "data_breach",
  // "effective_date",
  "exclusivity",
  "obligation_statements",
];

export default function ClauseComponent(props: Props) {
  const {
    insightsData,
    fileId,
    sentenceData,
    clauseData,
    updatedClauseData,
    scrollToDatapoint,
    getInsights,
  } = props;

  React.useEffect(() => {
    getInsights(fileId);
  }, [fileId]);

  const clauseElements: any = [];

  const scrollToChildDataPoint = (
    insight: string,
    clause: any,
    key: number,
    label: any
  ) => {
    let clauseArray: any = [];
    clauseArray.push(clause);
    props.scrollToChildDataPoint(
      insight,
      label,
      getChildHighlights(clauseArray, label),
      0,
      false
    );
  };

  const onClickHighlight = React.useCallback(
    (key: number, clause: any, childAlias: string) => {
      if (childAlias === "Clauses") {
        scrollToDatapoint("bi", clause.dataPoints, key);
      } else {
        // if (selectedHighlightKey === key + 1) {
        //   scrollToChildDataPoint("bi", clause, key)
        // } else {
        scrollToChildDataPoint("bi", clause, key, childAlias);
        // }
      }
    },
    [scrollToDatapoint]
  );

  const updatedList = React.useMemo(() => {
    return CLAUSE_RENDERING_ORDER.map((item: string) => {
      if (insightsData.includes(item)) {
        return {
          name: item,
          hasData: true,
        };
      } else {
        return {
          name: item,
          hasData: false,
        };
      }
    });
  }, [insightsData]);

  updatedList.forEach((clauseName) => {
    const ClauseComponent = clauseComponents[clauseName.name];
    if (ClauseComponent) {
      clauseElements.push(
        <ClauseComponent
          fileId={fileId}
          sentenceData={sentenceData}
          key={clauseName.name}
          onScroll={props.onScroll}
          hasData={clauseName.hasData}
          onClickDataPoint={props.onClickDataPoint}
          onEdit={props.onEdit}
          toBeEdited={props.toBeEdited}
          childInEditId={props.childInEditId}
          durationList={props.durationList}
          currencyList={props.currencyList}
          contractData={props.contractData}
          editOptionSelected={props.editOptionSelected}
          onClickHighlight={onClickHighlight}
          getClauseDataByType={props.getClauseDataByType}
          clauseData={clauseData}
          updatedClauseData={updatedClauseData}
          postClauseDataByType={props.postClauseDataByType}
          otherClauses={props.otherClauses}
        />
      );
    }
  });
  return <div>{clauseElements}</div>;
}

export const handleAddEdit = (
  props: any,
  clauseType: string,
  data: any,
  setShowAdd: any
) => {
  props.onEdit(clauseType, 0, false, [], -1, "", data);
  setShowAdd(true);
};

export const handleEditBasicInformation = (
  props: any,
  clauseType: string,
  data: any,
  setShowAdd: any
) => {
  setShowAdd(true);
};
