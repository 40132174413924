import {
  ArticleOutlined,
  GroupOutlined,
  PersonOffOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DropdownButton from "./DropdownButton";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { isNullOrUndefined } from "is-what";

interface MenuOption {
  value: string;
  label: string;
  startIcon?: any;
}
const collaborateMenu: MenuOption[] = [
  {
    value: "collaborating",
    label: "Collaborating",
    startIcon: <GroupOutlined />,
  },
  {
    value: "editing",
    label: "Editing",
    startIcon: <PersonOffOutlined />,
  },
];

const downloadMenu: MenuOption[] = [
  {
    value: "pdf",
    label: "PDF",
    startIcon: <PictureAsPdfOutlined />,
  },
  {
    value: "wordDocument",
    label: "Word Document",
    startIcon: <ArticleOutlined />,
  },
];

interface Props {
  canDownload?: boolean;
  collaborating?: boolean;
  goBackUrl?: string;
  renderAction: () => React.ReactNode;
  version?: number;
  name?: string;
  setCanEdit?: Dispatch<SetStateAction<boolean>>;
  handleDownload?: (value: string) => void;
  collaboratorDisabled?: boolean;
}

const DraftHeader: React.FC<Props> = ({
  collaborating = false,
  canDownload = false,
  renderAction,
  version,
  goBackUrl,
  name,
  setCanEdit,
  handleDownload,
  collaboratorDisabled = false,
}) => {
  const [collaboratingValue, setCollaboratingValue] = useState<string>();

  React.useEffect(() => {
    if (collaboratingValue === "Editing") {
      setCanEdit?.(true);
    } else {
      setCanEdit?.(false);
    }
  }, [collaboratingValue, setCanEdit]);

  return (
    <Box>
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
            {goBackUrl && (
              <Link
                href={goBackUrl}
                color="riPrimary.500"
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon fontSize="small" sx={{ marginRight: "2px" }} />
                Close
              </Link>
            )}
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            flex={1}
            justifyContent="center"
          >
            <Typography
              marginRight={4}
              fontSize="16px"
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {name} {!isNullOrUndefined(version) && `(V${version})`}
            </Typography>
            {collaborating && (
              <>
                <Divider orientation="vertical" flexItem />
                <Box marginLeft={2}>
                  <DropdownButton
                    changeButtonText
                    options={collaborateMenu}
                    setValue={setCollaboratingValue}
                    disabled={collaboratorDisabled}
                  />
                </Box>
              </>
            )}
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            {canDownload && (
              <DropdownButton
                options={downloadMenu}
                startIcon={<SaveAltIcon />}
                menuHeader="Download as:"
                buttonName="Download"
                handleDropdownChange={handleDownload}
              />
            )}
            {renderAction()}
          </Stack>
        </Stack>
      </Toolbar>
      <Divider />
    </Box>
  );
};

export default DraftHeader;
