import axios from "axios";
import { getLocalStorage } from "../../Authentication/Actions/authentication";
import { ContractType } from "../State/ContractTypeState";
const APIPaths = {
  getContractType:
    process.env.REACT_APP_RIVERUS_CLM_API + "admin/contracttype/",
  cloneContractType:
    process.env.REACT_APP_RIVERUS_CLM_API + "data-dictionary/data_dict_clone/",
};

const APIConfig = () => ({
  headers: {
    Authorization: `Bearer ${getLocalStorage(`accessToken`)}`,
  },
});

export const getContractTypes = () => {
  return axios
    .get(APIPaths.getContractType, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("getContractTypes error", error);
    });
};

export const deleteContractType = (fileId: string) => {
  return axios
    .delete(APIPaths.getContractType + fileId + "/", APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("deleteContractType error", error);
    });
};

export const postContractType = (contractTypeData: ContractType) => {
  return axios
    .post(APIPaths.getContractType, contractTypeData, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("postContractType error", error);
    });
};

export const editContractType = (
  contractTypeData: ContractType,
  id: String
) => {
  return axios
    .put(APIPaths.getContractType + id + "/", contractTypeData, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("editContractType error", error);
    });
};

export const cloneContractType = (contractTypeData: any) => {
  return axios
    .post(APIPaths.cloneContractType, contractTypeData, APIConfig())
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("editContractType error", error);
    });
};
