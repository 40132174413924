import * as React from "react";
import * as changesets from "json-diff-ts";

import { ClauseType, ClauseTypeMap } from "../../../../Constants/ClauseType";
import {
  LinkSentenceRequest,
  SentencesData,
  editedSentences,
  sentenceInfo,
  tableInfo,
} from "../../../../State/documentState";
import {
  deleteDataFromObligation,
  getClauseDataFormat,
  getClauseObjects,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";
import {
  getSentencesFromChild,
  getTableCellsFromChild,
} from "../../../Utils/docUtils";

import SaveOrCancelSentence from "../saveOrCancelSentence";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  savedPresent: string;
  dataPointName: string;
  savedPresentData: any;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  onClose: any;
  fileId: string;
  clauseType: string;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseData: any;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  otherClauses: (type: string) => void;
}

function getAddedAndDeletedSentences(
  changedLinkedSentences: sentenceInfo[] | null,
  previousLinkedSentences: sentenceInfo[]
) {
  let addedSentences: sentenceInfo[] = [];
  let deletedSentences: sentenceInfo[] = [];

  if (previousLinkedSentences.length !== 0) {
    if (changedLinkedSentences !== null) {
      if (changedLinkedSentences.length !== 0) {
        //get newly added elements
        for (let i = 0; i < changedLinkedSentences.length; i++) {
          let addedExists = false;
          for (let j = 0; j < previousLinkedSentences.length; j++) {
            if (
              changedLinkedSentences[i].paraId ===
              previousLinkedSentences[j].paraId
            ) {
              if (
                changedLinkedSentences[i].sentenceId ===
                previousLinkedSentences[j].sentenceId
              ) {
                addedExists = true;
                break;
              }
            }
          }
          if (!addedExists) {
            addedSentences.push({
              paraId: changedLinkedSentences[i].paraId,
              sentenceId: changedLinkedSentences[i].sentenceId,
              rowId: -1,
              columnId: -1,
            });
          }
        }

        //get deleted elements
        for (let i = 0; i < previousLinkedSentences.length; i++) {
          let deletedExists = false;
          for (let j = 0; j < changedLinkedSentences.length; j++) {
            if (
              previousLinkedSentences[i].paraId ===
              changedLinkedSentences[j].paraId
            ) {
              if (
                previousLinkedSentences[i].sentenceId ===
                changedLinkedSentences[j].sentenceId
              ) {
                deletedExists = true;
                break;
              }
            }
          }
          if (!deletedExists) {
            deletedSentences.push({
              paraId: previousLinkedSentences[i].paraId,
              sentenceId: previousLinkedSentences[i].sentenceId,
              rowId: previousLinkedSentences[i].rowId,
              columnId: previousLinkedSentences[i].columnId,
            });
          }
        }
      } else if (changedLinkedSentences.length === 0) {
        for (let i = 0; i < previousLinkedSentences.length; i++) {
          deletedSentences.push({
            paraId: previousLinkedSentences[i].paraId,
            sentenceId: previousLinkedSentences[i].sentenceId,
            rowId: previousLinkedSentences[i].rowId,
            columnId: previousLinkedSentences[i].columnId,
          });
        }
      }
    }
  } else {
    if (changedLinkedSentences) {
      if (changedLinkedSentences.length) {
        //adding for first time, newly added elements
        for (let i = 0; i < changedLinkedSentences.length; i++) {
          addedSentences.push(changedLinkedSentences[i]);
        }
      }
    }
  }

  let addedDeletedSentences: editedSentences = {
    upsert: addedSentences,
    deleted: deletedSentences,
  };

  return addedDeletedSentences;
}

function getAddedAndDeletedTableCells(
  changedLinkedTableCells: tableInfo[] | null,
  previousLinkedTableCells: tableInfo[]
) {
  let addedTableCells: tableInfo[] = [];
  let deletedTableCells: tableInfo[] = [];
  if (previousLinkedTableCells.length && previousLinkedTableCells) {
    if (changedLinkedTableCells && changedLinkedTableCells.length) {
      //newly added
      for (let i = 0; i < changedLinkedTableCells.length; i++) {
        let addedCellExists = false;
        for (let j = 0; j < previousLinkedTableCells.length; j++) {
          if (
            changedLinkedTableCells[i].paraId ===
              previousLinkedTableCells[j].paraId &&
            changedLinkedTableCells[i].rowId ===
              previousLinkedTableCells[j].rowId &&
            changedLinkedTableCells[i].columnId ===
              previousLinkedTableCells[j].columnId
          ) {
            addedCellExists = true;
            break;
          }
        }
        if (addedCellExists === false) {
          addedTableCells.push({
            paraId: changedLinkedTableCells[i].paraId,
            rowId: changedLinkedTableCells[i].rowId,
            columnId: changedLinkedTableCells[i].columnId,
          });
        }
      }

      //deleted elements
      for (let i = 0; i < previousLinkedTableCells.length; i++) {
        let deletedCellExists = false;
        for (let j = 0; j < changedLinkedTableCells.length; j++) {
          if (
            previousLinkedTableCells[i].paraId ===
              changedLinkedTableCells[j].paraId &&
            previousLinkedTableCells[i].rowId ===
              changedLinkedTableCells[j].rowId &&
            previousLinkedTableCells[i].columnId ===
              changedLinkedTableCells[j].columnId
          ) {
            deletedCellExists = true;
            break;
          }
        }
        if (!deletedCellExists) {
          deletedTableCells.push({
            paraId: previousLinkedTableCells[i].paraId,
            rowId: previousLinkedTableCells[i].rowId,
            columnId: previousLinkedTableCells[i].columnId,
          });
        }
      }
    } else {
      //all deleted
      for (let i = 0; i < previousLinkedTableCells.length; i++) {
        deletedTableCells.push({
          paraId: previousLinkedTableCells[i].paraId,
          rowId: previousLinkedTableCells[i].rowId,
          columnId: previousLinkedTableCells[i].columnId,
        });
      }
    }
  } else if (changedLinkedTableCells && changedLinkedTableCells.length) {
    //newly added
    for (let i = 0; i < changedLinkedTableCells.length; i++) {
      addedTableCells.push(changedLinkedTableCells[i]);
    }
  }

  //Convert table cells json to sentence level json
  let upsertSentences: sentenceInfo[] = [];
  let deletedSentences: sentenceInfo[] = [];

  for (let i = 0; i < addedTableCells.length; i++) {
    upsertSentences.push({
      paraId: addedTableCells[i].paraId,
      sentenceId: -1,
      rowId: addedTableCells[i].rowId,
      columnId: addedTableCells[i].columnId,
    });
  }

  for (let i = 0; i < deletedTableCells.length; i++) {
    deletedSentences.push({
      paraId: deletedTableCells[i].paraId,
      sentenceId: -1,
      rowId: deletedTableCells[i].rowId,
      columnId: deletedTableCells[i].columnId,
    });
  }

  let editedTableCellsAsSentence: editedSentences = {
    upsert: upsertSentences,
    deleted: deletedSentences,
  };
  return editedTableCellsAsSentence;
}

const mergeEditedSentenceCallback = (
  firstEditedSentences: editedSentences,
  secondEditedSentences: editedSentences
) => {
  let mergedAddedSentences: sentenceInfo[] = firstEditedSentences.upsert.concat(
    secondEditedSentences.upsert
  );
  let mergedDeletedSentences: sentenceInfo[] =
    firstEditedSentences.deleted.concat(secondEditedSentences.deleted);

  let mergedEditedSentences: editedSentences = {
    upsert: mergedAddedSentences,
    deleted: mergedDeletedSentences,
  };
  return mergedEditedSentences;
};

const EditPresentSentence = (props: Props) => {
  const {
    editOptionSelected,
    saveHighlightedDataPoint,
    dataPointName,
    savedPresentData,
    savedHighlightedSentences,
    saveHighlightedSentences,
    savedHighlightedTableCells,
    saveHighlightedTableCells,
    onClose,
    fileId,
    clauseType,
    postClauseDataByType,
    sentenceData,
    updatedClauseData,
    clauseDataByType,
    updatedClauseDataByType,
    parentClauseType,
    otherClauses,
    clauseData,
  } = props;

  const [present, setPresent] = React.useState<string>("Yes");

  const saveHighlightedDataPointCallback = React.useCallback(() => {
    saveHighlightedDataPoint(dataPointName);
  }, [dataPointName, saveHighlightedDataPoint]);

  const addOrRemovePresent = React.useCallback(
    (action: string): LinkSentenceRequest => {
      let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };
      //edited sentences
      let previousLinkedSentences = getSentencesFromChild(savedPresentData);
      let editedSentencesObject = getAddedAndDeletedSentences(
        savedHighlightedSentences,
        previousLinkedSentences
      );

      //edited table cells
      let previousLinkedTableCells = getTableCellsFromChild(savedPresentData);
      let editedTableCellsObject = getAddedAndDeletedTableCells(
        savedHighlightedTableCells,
        previousLinkedTableCells
      );

      //merge both
      let mergeEditedSentences = mergeEditedSentenceCallback(
        editedTableCellsObject,
        editedSentencesObject
      );

      if (action === "add") {
        if (savedHighlightedSentences || savedHighlightedTableCells) {
          tempPresentList = {
            data: present,
            mode: "manual",
            editedSentences: mergeEditedSentences,
          };
        } else {
          tempPresentList = {
            data: present,
            mode: "retain",
          };
        }
      }
      return tempPresentList;
    },
    [
      savedHighlightedSentences,
      savedHighlightedTableCells,
      savedPresentData,
      present,
    ]
  );

  const editPresent = React.useCallback(() => {
    let addSentenceRequest = addOrRemovePresent("add");
    let newData = updatedClauseDataByType;
    let obligationData = updatedClauseData.obligationStatements;

    if (present === "Yes") {
      if (addSentenceRequest?.editedSentences?.upsert) {
        let addedData = addSentenceRequest.editedSentences.upsert;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData
          );

          if (clauseType !== "obligation_statements") {
            obligationData = deleteDataFromObligation(
              "sentence",
              addedData[i],
              obligationData
            );
          }
        }
      }
      if (addSentenceRequest?.editedSentences?.deleted) {
        let deletedData = addSentenceRequest.editedSentences.deleted;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType, newData);
    }

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    const obligationDiff = changesets.diff(
      clauseData?.obligationStatements?.raw_content,
      obligationData,
      { children: "$index" }
    );

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    if (clauseType !== "obligation_statements") {
      if (obligationDiff.length > 0) {
        postClauseDataByType(
          fileId,
          "obligation_statements",
          obligationDiff,
          obligationData
        );
      }
    }
    onClose();
  }, [
    updatedClauseData,
    fileId,
    clauseDataByType,
    addOrRemovePresent,
    clauseType,
    postClauseDataByType,
    sentenceData,
    present,
  ]);

  return (
    <div className="row toc-content toc-content-default">
      <div className="col-md-12">
        <div className="row mt-1">
          <div
            className="col-md-12 m-0 bi-label-clickable"
            style={{ fontWeight: 600 }}
          >
            Add {dataPointName}
          </div>
          <div className="col-md-12 my-1 edit-title-header">
            Add/Edit the {dataPointName} clause of your contract here
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-5 bi-label-clickable edit-date-title"
            style={{ fontWeight: 600 }}
          >
            Present
          </div>
          <div
            className="col-md-3"
            style={{ margin: "auto" }}
            onClick={() => setPresent("Yes")}
          >
            <img
              className="cursor-pointer"
              src={
                present === "Yes"
                  ? "/static_images/radio-active.svg"
                  : "/static_images/radio-inactive.svg"
              }
              alt="radio-btn"
            />
            &nbsp;&nbsp;
            <span>Yes</span>
          </div>
          <div
            className="col-md-3"
            style={{ margin: "auto" }}
            onClick={() => setPresent("No")}
          >
            <img
              className="cursor-pointer"
              src={
                present === "No"
                  ? "/static_images/radio-active.svg"
                  : "/static_images/radio-inactive.svg"
              }
              alt="radio-btn"
            />
            &nbsp;&nbsp;
            <span>No</span>
          </div>
        </div>
        <SaveOrCancelSentence
          enableHighlightOption={present === "Yes"}
          dataPointName={dataPointName}
          editOptionSelected={editOptionSelected}
          editDataPoint={() => {
            editPresent();
            saveHighlightedSentences(null);
            saveHighlightedTableCells(null);
            editOptionSelected(false);
          }}
          savedHighlightedSentences={savedHighlightedSentences}
          saveHighlightedDataPoint={saveHighlightedDataPointCallback}
          enableSaveBtn={true}
          saveHighlightedSentences={(
            savedHighlightedSentences: sentenceInfo[] | null
          ) => saveHighlightedSentences(savedHighlightedSentences)}
          savedHighlightedTableCells={savedHighlightedTableCells}
          saveHighlightedTableCells={(
            savedHighlightedTableCells: tableInfo[] | null
          ) => saveHighlightedTableCells(savedHighlightedTableCells)}
          previouslyLinkedSentences={
            getSentencesFromChild(savedPresentData).length > 0
              ? getSentencesFromChild(savedPresentData)
              : undefined
          }
          previouslyLinkedTableCells={
            getTableCellsFromChild(savedPresentData).length > 0
              ? getTableCellsFromChild(savedPresentData)
              : undefined
          }
          onClose={onClose}
          otherClauses={(type: string) => otherClauses(type)}
        />
      </div>
    </div>
  );
};

export default EditPresentSentence;
