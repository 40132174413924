import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  draftSignatures,
  getDraft,
  getSignatureDetails,
  onSignedStatus,
  sendAgreement,
  updateDraftSignatures,
  updateSignatureDetails,
  uploadDocument,
} from "../../../Services/signatureTab";
import { useMutation, useQuery } from "@tanstack/react-query";

import CustomModal from "../../../RiverusUI/Components/CustomModal";
import { KeyboardReturn } from "@mui/icons-material";
import NameAvatar from "../../../RiverusUI/DataGrid/NameAvatar";
import colors from "../../../RiverusUI/Theme/colors";
import { draftStatus } from "../../State/DraftState";
import { enqueueSnackbar } from "notistack";

interface Props {
  signatoriesList: any[];
  isLoggedInUser: any;
  hideSignatureButton: boolean;
  dragStart: any;
  dragEnd: any;
  addField: any;
  openPlaceDialog: any;
  setOpenPlaceDialog: any;
  dropPoint: any;
  draggableUserId: any;
  addedSignatureFields: boolean;
  updateSignatory: any;
  draftData: any;
  handleConfirmation: any;
  setStatus: any;
  setRedirect?: Dispatch<SetStateAction<string>>;
  isExternal?: boolean;
  sendDraftConfirmation: boolean;
}

const SignatoriesList: React.FC<Props> = ({
  signatoriesList,
  isLoggedInUser,
  hideSignatureButton,
  dragStart,
  dragEnd,
  addField,
  openPlaceDialog,
  setOpenPlaceDialog,
  dropPoint,
  draggableUserId,
  addedSignatureFields,
  updateSignatory,
  draftData,
  handleConfirmation,
  setStatus,
  setRedirect,
  isExternal,
  sendDraftConfirmation,
}) => {
  const [decline, setDecline] = useState<boolean>(false);

  const [isSending, setIsSending] = useState<boolean>(false);
  const [email, setEmail] = useState<string[]>([]);
  const [signatureName, setSignatureName] = useState<string[]>([]);
  const [signAccessToken, setSignAccessToken] = useState<string>();

  const draftUrl = React.useMemo(() => draftData?.access_url, [draftData]);

  const draftFileName = React.useMemo(
    () => draftData?.contractName,
    [draftData]
  );

  const openDeclineDialog = () => {
    setDecline(true);
  };

  const closeDeclineDialog = () => {
    setDecline(false);
  };

  const handleDecline = () => {
    const payload = {
      id: draftData?.id,
      body: {
        signatories: [],
      },
    };
    setStatus(draftStatus?.SIGNATURE_ABORTED);
    updateSignatory(payload);
    setDecline(false);
    enqueueSnackbar("Your Preference has been noted", {
      variant: "info",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  };

  const closePlaceDialog = () => {
    setOpenPlaceDialog(false);
  };

  const handlePlaceSign = (e: any, type: any) => {
    setOpenPlaceDialog(false);
    addField(type, dropPoint, draggableUserId);
  };

  const handleGoBackButton = async () => {
    handleConfirmation();
    setRedirect?.("/draftingreview");
  };

  const showSignButton = useCallback(
    (signatory: any) => {
      if (
        isLoggedInUser(signatory?.id, signatory?.email, signatory?.user_type) &&
        !hideSignatureButton &&
        !signatory?.signed_date &&
        draftData?.signature_method === "Stylus (Riverus)"
      ) {
        return true;
      }
      return false;
    },
    [draftData, hideSignatureButton, isLoggedInUser]
  );

  useEffect(() => {
    if (localStorage.getItem("signAccessToken")) {
      setSignAccessToken(localStorage.getItem("signAccessToken") || undefined);
    } else {
      window.addEventListener("storage", () => {
        const initialToken = localStorage.getItem("signAccessToken");
        if (initialToken) {
          setSignAccessToken(initialToken);
        }
      });
    }
  }, []);

  const { mutate: onSignedMutation } = useMutation(
    ["on_signed", draftData?.id],
    async (status: string) => await onSignedStatus(draftData?.id, status)
  );

  const { mutate: uploadMutation, data: uploadDocumentData } = useMutation(
    ["upload_document"],
    async (formData: any) => await uploadDocument(formData)
  );

  const { mutate: signDetailsMutation } = useMutation(
    ["sign_details"],
    async (payload: any) => await updateSignatureDetails(payload)
  );

  const { mutate: agreementIdMutation, data: agreementIdData } = useMutation(
    ["agreement_id"],
    async (payload: any) => await sendAgreement(payload)
  );

  const { data: signatureAgreementData } = useQuery(
    ["signature_agreement", draftData?.id],
    async () => await draftSignatures(draftData?.id),
    {
      onSuccess: (response: any) => {
        if (response?.data?.status === "SIGNED") {
          onSignedMutation("Contract Executed Successfully");
        }
      },
      enabled: !isExternal,
    }
  );

  const { data: signatureDetailsData } = useQuery(
    ["signature_details", draftData?.id, signatureAgreementData],
    async () =>
      await getSignatureDetails(
        draftData?.id,
        signatureAgreementData?.data?.agreement
      ),
    {
      enabled: !!signatureAgreementData?.data?.agreement && !isExternal,
    }
  );

  useQuery(["send_document"], async () => await getDraft(draftUrl), {
    onSuccess: (response: any) => {
      let myFile: File;
      myFile = new File([response], draftFileName + ".pdf");
      const formData = new FormData();
      formData.append("File", myFile);
      uploadMutation(formData);
    },
    enabled: isSending && !isExternal,
  });

  useQuery(
    ["send_agreement"],
    async () => {
      if (email.length === signatoriesList.length) {
        let participantSetsInfo: any[] = email.map((value, index) => {
          return {
            memberInfos: [{ email: email[index] }],
            order: index + 1,
            role: "SIGNER",
          };
        });
        const agreementInfo = {
          fileInfos: [
            {
              transientDocumentId:
                uploadDocumentData?.data?.transientDocumentId,
            },
          ],
          name: draftFileName + " - Agreement",
          participantSetsInfo: participantSetsInfo,
          signatureType: "ESIGN",
          state: "IN_PROCESS",
        };
        agreementIdMutation(agreementInfo);
      }
    },
    {
      enabled:
        !!uploadDocumentData && !!email && !!signatoriesList && !isExternal,
    }
  );

  const { data: addSignatureDetailsData } = useQuery(
    ["add_signature_details"],
    async () =>
      await updateDraftSignatures(agreementIdData?.data?.id, draftData?.id),
    {
      onSuccess: () => {
        let signatureDetails: any[] = email.map((element, index) => {
          return { email: email[index], name: signatureName[index] };
        });
        const payload = {
          draft: draftData?.id,
          agreement: agreementIdData?.data?.id,
          signatures: signatureDetails,
        };
        signDetailsMutation(payload);
      },
      enabled: !!agreementIdData && !isExternal,
    }
  );

  const sendDocument = () => {
    setIsSending(true);
    const savedSignAccessToken = localStorage.getItem("signAccessToken");
    setSignAccessToken(savedSignAccessToken || undefined);
  };

  const agreementStatus = React.useMemo(() => {
    if (addSignatureDetailsData?.data?.status) {
      return addSignatureDetailsData?.data?.status;
    } else {
      if (signatureAgreementData?.data?.message) {
        return signatureAgreementData?.data?.message;
      } else if (signatureAgreementData?.data?.status) {
        return signatureAgreementData?.data?.status;
      }
    }
  }, [addSignatureDetailsData, signatureAgreementData]);

  const displaySignatories = React.useMemo(() => {
    if (
      (agreementStatus &&
        agreementStatus !== "SIGNED" &&
        !draftData?.status
          .toLowerCase()
          .includes("contract executed successfully")) ||
      (draftData?.signature_method && draftData?.signature_method !== "Adobe")
    ) {
      return true;
    } else {
      return false;
    }
  }, [agreementStatus, draftData?.status, draftData?.signature_method]);

  useEffect(() => {
    if (
      sendDraftConfirmation &&
      draftData?.signature_method === "Adobe" &&
      agreementStatus !== "SIGNED" &&
      signAccessToken
    ) {
      sendDocument();
    }
  }, [
    sendDraftConfirmation,
    draftData?.signature_method,
    agreementStatus,
    signAccessToken,
  ]);

  useEffect(() => {
    let emailList: any[] = [];
    let signatureNameList: any[] = [];
    signatoriesList.map((signatory: any, index: number) => {
      const signatoryEmail = signatory.email;
      emailList[index] = signatoryEmail;
      if (signatory.user_type === "internal") {
        const signatureName =
          signatory?.first_name + " " + signatory?.last_name;
        signatureNameList[index] = signatureName;
      } else {
        const signatureName = signatory?.name;
        signatureNameList[index] = signatureName;
      }
    });
    setEmail(emailList);
    setSignatureName(signatureNameList);
  }, [signatoriesList]);

  return (
    <>
      {displaySignatories &&
        signatoriesList?.map((signatory: any) => (
          <Box
            sx={{ backgroundColor: colors?.riPrimary[20] }}
            borderRadius="10px"
            padding={2}
            marginY={2}
            key={signatory?.id}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box display="flex">
                <NameAvatar
                  firstName={
                    signatory?.first_name || signatory?.name?.split(" ")[0]
                  }
                  lastName={
                    signatory?.last_name || signatory?.name?.split(" ")[1]
                  }
                />

                {!signatory.signed_date && !signatory.declined_date && (
                  <Typography fontSize="16px" fontWeight="400" marginLeft={2}>
                    {signatory?.name ||
                      signatory?.first_name + " " + signatory?.last_name}{" "}
                    signature pending
                  </Typography>
                )}
                {signatory.signed_date && !signatory.declined_date && (
                  <Typography fontSize="16px" fontWeight="400" marginLeft={2}>
                    {signatory?.name ||
                      signatory?.first_name + " " + signatory?.last_name}{" "}
                    has already signed
                  </Typography>
                )}
              </Box>
              {signatory.signed_date && (
                <Typography fontSize="11px" fontWeight="500">
                  {signatory.signed_date}
                </Typography>
              )}
            </Box>

            {showSignButton(signatory) && (
              <Box display="flex" marginTop={1}>
                <IconButton
                  style={{
                    transform: "scaleX(-1)",
                    paddingRight: "15px",
                  }}
                >
                  <KeyboardReturn />
                </IconButton>

                <Button
                  draggable
                  onDragStart={(e) => dragStart(e, signatory?.id)}
                  onDragEnd={(e) => dragEnd(e, "SIGNATURE")}
                  variant="contained"
                >
                  Place Signature
                </Button>
                <Button onClick={openDeclineDialog}>Decline</Button>

                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  width="251px"
                  marginTop={1}
                >
                  Drag this button on to the desired place on the document to
                  place your signature
                </Typography>
              </Box>
            )}
            <CustomModal
              title="Decline signature"
              handleClose={closeDeclineDialog}
              open={decline}
            >
              <Typography margin={4} fontSize="16px" fontWeight="400">
                Are you sure you want to decline giving your signature?
              </Typography>
              <Box>
                <Button variant="contained" onClick={closeDeclineDialog}>
                  No, go back
                </Button>
                <Button variant="outlined" onClick={handleDecline}>
                  Yes, decline
                </Button>
              </Box>
            </CustomModal>

            <CustomModal
              title="Signature placement confirmation?"
              handleClose={closePlaceDialog}
              open={openPlaceDialog}
            >
              <Typography margin={4} fontSize="16px" fontWeight="400">
                Are you sure you want to place your signature here?
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  onClick={(e) => handlePlaceSign(e, "SIGNATURE")}
                >
                  Yes,place signature
                </Button>
                <Button variant="outlined" onClick={closePlaceDialog}>
                  Cancel
                </Button>
              </Box>
            </CustomModal>

            <CustomModal
              title="Signature placed successfully"
              handleClose={handleConfirmation}
              open={addedSignatureFields}
            >
              <Typography margin={4} fontSize="16px" fontWeight="400">
                Thank you for signing the draft. Your signature has been placed
                successfully.
              </Typography>
              <Box>
                {!isExternal && (
                  <Button variant="contained" onClick={handleGoBackButton}>
                    Go back to dashboard
                  </Button>
                )}
                <Button variant="outlined" onClick={handleConfirmation}>
                  Close
                </Button>
              </Box>
            </CustomModal>
          </Box>
        ))}
      {agreementStatus && (
        <Typography margin={4} fontSize="18px" fontWeight="600">
          Status: {agreementStatus}
        </Typography>
      )}
      {signatureDetailsData?.data.length > 0 &&
        agreementStatus === "SIGNED" && (
          <>
            <Typography margin={4} fontSize="18px" fontWeight="600">
              <Stack margin={4} sx={{ paddingBottom: 2 }} spacing={2}>
                Signed By:
              </Stack>
              <ul>
                {signatureDetailsData?.data?.map(
                  (element: any, index: number) => (
                    <li key={index}>{element.name}</li>
                  )
                )}
              </ul>
            </Typography>
            <Typography margin={4} fontSize="18px" fontWeight={600}>
              <Stack margin={4} sx={{ paddingBottom: 2 }} spacing={2}>
                Signed At:
              </Stack>
              <Stack margin={4} spacing={2}>
                {signatureDetailsData?.data[0]?.signed_at}
              </Stack>
            </Typography>
          </>
        )}
    </>
  );
};
export default SignatoriesList;
