import {
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import NameAvatar from "../../../RiverusUI/DataGrid/NameAvatar";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";

interface Props {
  setOpenUploadDialog: Dispatch<SetStateAction<any>>;
  handleOpenDeleteDialog: (id: string) => void;
  handleOpenAssigneeDialog: (data: any) => void;
  handleOpenUploadDialog: (id: string) => void;
  handleActiveStatus: (checkedValue: boolean, data: any) => void;
  checklistData: string[];
  isExternal?: boolean;
  userIsOwner: boolean;
}

const Checklist: React.FC<Props> = ({
  handleOpenDeleteDialog,
  handleOpenAssigneeDialog,
  handleOpenUploadDialog,
  handleActiveStatus,
  checklistData,
  isExternal,
  userIsOwner,
}) => {
  return (
    <List sx={{ width: "100%", bgcolor: "transparent" }}>
      {checklistData?.map((value: any) => {
        const fullName: string[] = value?.user_name?.split(" ");
        return (
          !value.deleted_status && (
            <ListItem
              key={value}
              secondaryAction={
                <Stack spacing={1} direction="row" alignItems="center">
                  {value?.support_doc?.length > 0 && (
                    <Stack direction="row" alignItems="center">
                      <Typography fontSize="12px">
                        {value?.support_doc?.length} attachment
                      </Typography>
                      <IconButton
                        edge="end"
                        disabled={userIsOwner}
                        onClick={() => handleOpenUploadDialog(value)}
                      >
                        <AttachFileIcon
                          style={{
                            rotate: "90deg",
                          }}
                        />
                      </IconButton>
                    </Stack>
                  )}
                  {value?.support_doc?.length === 0 && (
                    <IconButton
                      edge="end"
                      disabled={userIsOwner}
                      onClick={() => handleOpenUploadDialog(value)}
                    >
                      <FileUploadOutlinedIcon />
                    </IconButton>
                  )}
                  {!isExternal && (
                    <>
                      <IconButton
                        edge="end"
                        disabled={userIsOwner}
                        onClick={() => {
                          handleOpenDeleteDialog(value?.id);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                      {!value?.user_name && (
                        <IconButton
                          edge="end"
                          disabled={userIsOwner}
                          onClick={() => handleOpenAssigneeDialog(value)}
                        >
                          <PersonAddAltOutlinedIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                  {value?.user_name && (
                    <IconButton
                      edge="end"
                      disabled={userIsOwner}
                      onClick={() =>
                        isExternal ? {} : handleOpenAssigneeDialog(value)
                      }
                    >
                      <NameAvatar
                        firstName={fullName?.[0]}
                        lastName={fullName?.[1]}
                      />
                    </IconButton>
                  )}
                </Stack>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <Checkbox
                    disabled={userIsOwner}
                    edge="start"
                    defaultChecked={value?.active_status}
                    onChange={(event: any) => {
                      handleActiveStatus(event.target.checked, value);
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={value?.checklist_name} />
              </ListItemButton>
            </ListItem>
          )
        );
      })}
    </List>
  );
};

export default Checklist;
