import { Button, Link, Stack, TextField, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  addDraftApprovalComments,
  updateDeviationApprovals,
} from "../../../Services/Draft";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ActionButton from "../DeviationTab/ActionButton";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import NameAvatar from "../../../RiverusUI/DataGrid/NameAvatar";
import { getLocalStorage } from "../../../Authentication/Actions/authentication";
import { getPara } from "../../../DocumentView/Component/ClauseComponent/utils/ClauseTypeUtils";
import { useForm } from "react-hook-form";

interface IProps {
  approvals?: any;
  instance?: any;
  extractedData: any;
}

const NameWithAvatar = ({
  firstName,
  lastName,
  content,
}: {
  firstName: string;
  lastName: string;
  content?: string;
}) => {
  return (
    <Stack direction="row" spacing={1}>
      <NameAvatar
        firstName={firstName}
        lastName={lastName}
        avatarStyle={{ width: "40px", height: "40px" }}
      />
      <Typography variant="subtitle1">
        {`${firstName} ${lastName}`} {content}
      </Typography>
    </Stack>
  );
};

const ApprovalCard: React.FC<IProps> = React.forwardRef((props, ref) => {
  const { approvals, extractedData } = props;

  const [showInput, setShowInput] = useState<boolean>(false);
  const { handleSubmit, control, reset } = useForm();
  const [showFullText, setShowFullText] = useState<any>({
    id: "",
    show: false,
  });

  const queryClient = useQueryClient();

  const user_data = React.useMemo(() => getLocalStorage("user_profile"), []);

  const { mutate: addCommentMutation } = useMutation({
    mutationFn: addDraftApprovalComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["draft_approvals"],
      });
    },
  });

  const { mutate: updateApprovals } = useMutation({
    mutationKey: ["update_approval"],
    mutationFn: (payload: any) => {
      const currentApprover = approvals?.approvers.filter(
        (data: any) => data.approvers.id === user_data.id
      );
      const id = currentApprover[0].id;
      return updateDeviationApprovals(id, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["draft_approvals"],
      });
      queryClient.invalidateQueries({
        queryKey: ["fetch_Kdp_deviations"],
      });
    },
  });

  const onSubmit = (data: any) => {
    data.approval = approvals?.id;
    reset();
    setShowInput(false);
    addCommentMutation(data);
  };

  const formatDate = useCallback((date?: Date) => {
    if (date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm: any = today.getMonth() + 1;
      let dd: any = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;
      return formattedToday;
    }
    return null;
  }, []);

  const approveButton: { disabled: boolean; text: string } =
    React.useMemo(() => {
      const approval = approvals?.approvers?.filter(
        (data: any) => data.approvers.id === user_data.id
      );
      let priority = approval[0]?.priority;
      let status = approval[0]?.status;
      let disabled = false;
      let text = "Approve";
      if (status === "approved") return { disabled: true, text: "Approved" };
      if (approvals?.approval_sequence === "all_in_sequence") {
        if (priority === 1) return { disabled: false, text };
        approvals?.approvers?.map((data: any) => {
          if (data.priority < priority) {
            if (data.status === "approval_pending") {
              disabled = true;
              text = `Approval pending from ${data?.approvers?.first_name} ${data?.approvers?.last_name}`;
            }
          }
          return {
            disabled,
            text,
          };
        });
      }
      return { disabled, text };
    }, [approvals, user_data]);

  const handleToggle = (id: string) => {
    setShowFullText({
      id: id,
      show: !showFullText?.show,
    });
  };

  const approveDraft = React.useCallback(() => {
    updateApprovals({ status: "approved" });
  }, [updateApprovals]);

  const handleSeeMoreAndLess = (text: string) => {
    const words = text.split(/\s+/);
    if (words?.length > 20) {
      const sentence = words.slice(0, 20).join(" ");
      return `${sentence}...`;
    } else {
      return words.join(" ");
    }
  };

  return (
    <Stack
      key={approvals?.id || 1}
      spacing={2}
      sx={{ background: "#88305F1F", p: "16px", borderRadius: "18px" }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <NameWithAvatar
          firstName={approvals?.created_by.first_name}
          lastName={approvals?.created_by.last_name}
          content="seeks approval on the draft from"
        />
        <Typography variant="caption">
          {formatDate(approvals?.modified_on)}
        </Typography>
      </Stack>
      <Stack spacing={1}>
        {approvals?.approvers?.map((approver: any) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            key={approver.id}
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <KeyboardReturnIcon
                sx={{
                  transform: "scaleX(-1)",
                }}
              />
              <NameWithAvatar
                firstName={approver.approvers.first_name}
                lastName={approver.approvers.last_name}
              />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption">
                {formatDate(approver?.modified_on)}
              </Typography>
              {approver?.status === "approval_pending" ? (
                <AccessTimeOutlinedIcon />
              ) : (
                <CheckCircleOutlinedIcon />
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
      {approvals?.deviation && (
        <Stack spacing={1}>
          <Typography variant="subtitle1">
            Approval required on the following text from the draft:
          </Typography>
          {approvals?.deviation?.custom_datapoint?.map(
            (item: any, index: number) => (
              <Stack
                spacing={1}
                key={index}
                direction="row"
                justifyContent="space-between"
                alignItems="start"
                sx={{
                  padding: "10px",
                  background: "#FFF7FA",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <Typography fontSize="13px">
                  {showFullText?.id === approvals.deviation?.id &&
                  showFullText?.show
                    ? item?.selectedValue
                    : handleSeeMoreAndLess(item?.selectedValue)}
                  {item?.selectedValue?.split(/\s+/)?.length > 20 && (
                    <span
                      onClick={() => handleToggle(approvals?.deviation?.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {showFullText?.id === approvals.deviation?.id &&
                      showFullText?.show
                        ? " See less"
                        : "See more"}
                    </span>
                  )}
                </Typography>
                {/* <ActionButton
                fieldValueId={item?.para_id}
                sentence={item?.sentence}
                instance={instance}
                setOpenDeleteDialog={setOpenDeleteDialog}
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                setActiveTab={setActiveTab}
              /> */}
              </Stack>
            )
          )}
          {approvals?.deviation?.kdp_datapoint?.map(
            (item: any, index: number) => (
              <Stack
                key={index}
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="start"
                sx={{
                  padding: "10px",
                  background: "#FFF7FA",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                <Typography fontSize="13px">
                  {showFullText?.id === approvals.deviation?.id &&
                  showFullText?.show
                    ? getPara(extractedData?.raw_content, item.para_id)
                    : handleSeeMoreAndLess(
                        item?.selectedValue ||
                          getPara(extractedData?.raw_content, item.para_id)
                      )}
                  {getPara(extractedData?.raw_content, item.para_id)?.split(
                    /\s+/
                  )?.length > 20 && (
                    <span
                      onClick={() => handleToggle(approvals?.deviation?.id)}
                      style={{ cursor: "pointer" }}
                    >
                      {showFullText?.id === approvals.deviation?.id &&
                      showFullText?.show
                        ? " See less"
                        : "See more"}
                    </span>
                  )}
                </Typography>
                {/* <ActionButton
                fieldValueId={item?.para_id}
                sentence={item?.sentence}
                instance={instance}
                setOpenDeleteDialog={setOpenDeleteDialog}
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                setActiveTab={setActiveTab}
              /> */}
              </Stack>
            )
          )}
        </Stack>
      )}
      <Stack spacing={1}>
        {approvals?.comments?.map((comment: any) => (
          <Stack spacing={1} direction="row" alignItems="center">
            <NameAvatar
              firstName={comment?.created_by?.first_name}
              lastName={comment?.created_by?.last_name}
              avatarStyle={{ width: "40px", height: "40px" }}
            />
            <TextField value={comment?.comment} disabled fullWidth />
          </Stack>
        ))}
      </Stack>
      {showInput && (
        <Stack
          component="form"
          spacing={1}
          direction="row"
          alignItems="center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ControlledTextField name="comment" control={control} fullWidth />
          <NameAvatar
            firstName={user_data.first_name}
            lastName={user_data.last_name}
            avatarStyle={{ width: "40px", height: "40px" }}
          />
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Link
          component="button"
          variant="body2"
          onClick={() => setShowInput(true)}
          sx={{
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <ChatBubbleOutlineIcon sx={{ mr: "6px", fontSize: "14px" }} />
          Add Reply/Comment
        </Link>
        <Button
          variant="outlined"
          size="small"
          sx={{ padding: "8px 22px", whiteSpace: "nowrap" }}
          disabled={approveButton.disabled}
          onClick={approveDraft}
        >
          <DoneIcon sx={{ width: "18px", height: "18px" }} /> &nbsp;{" "}
          {approveButton.text}
        </Button>
      </Stack>
    </Stack>
  );
});

export default ApprovalCard;
