import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { isNullOrUndefined } from "is-what";

export default function ChangeClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    fileId,
    clauseData,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [counter, setCounter] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddDefinition, setShowAddDefinition] = useState(false);
  const [showAddTermination, setShowAddTermination] = useState(false);
  const [showAddNotice, setShowAddNotice] = useState(false);
  const [showAddConsent, setShowAddConsent] = useState(false);
  const [showAddPayment, setShowAddPayment] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "change_of_control");
  }, [hasData, fileId]);

  const data = React.useMemo(
    () => clauseData?.changeOfControl && clauseData.changeOfControl,
    [clauseData?.changeOfControl]
  );

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(changeOfControl, "Change of Control");
    setCounter(0);
  };

  const updates = React.useMemo(
    () => updatedClauseData?.changeOfControl || {},
    [updatedClauseData]
  );

  const changeOfControl = React.useMemo(() => {
    if (updates?.change_of_control) {
      let sortData = updates.change_of_control.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const definitions = React.useMemo(() => {
    if (updates?.definitions) {
      let sortData = updates.definitions.sort(function (a: any, b: any) {
        return a.para_id - b.para_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const termination = React.useMemo(() => {
    if (updates?.termination) {
      let sortData = updates.termination.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const notice = React.useMemo(() => {
    if (updates?.notice) {
      let sortData = updates.notice.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const consent = React.useMemo(() => {
    if (updates?.consent) {
      let sortData = updates.consent.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const payment = React.useMemo(() => {
    if (updates?.payment) {
      let sortData = updates.payment.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let change_of_control = changeOfControl?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let definition_data = definitions?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let notice_data = notice?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let termination_data = termination?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let consent_data = consent?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let payment_data = payment?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        change_of_control,
        definitions: definition_data,
        termination: termination_data,
        payment: payment_data,
        consent: consent_data,
        notice: notice_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, changeOfControl, data, fileId]
  );

  const deleteDefinition = React.useCallback(
    (item: any) => {
      let definition_data = definitions?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        definitions: definition_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, definitions, data, fileId]
  );

  const deleteTermination = React.useCallback(
    (item: any) => {
      let termination_data = termination?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        termination: termination_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, termination, data, fileId]
  );

  const deletePayment = React.useCallback(
    (item: any) => {
      let payment_data = payment?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        payment: payment_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, payment, data, fileId]
  );

  const deleteConsent = React.useCallback(
    (item: any) => {
      let consent_data = consent?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        consent: consent_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, consent, data, fileId]
  );

  const deleteNotice = React.useCallback(
    (item: any) => {
      let notice_data = notice?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        notice: notice_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "change_of_control", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, notice, data, fileId]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && "toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Change of Control</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", changeOfControl);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${changeOfControl?.length}`
                  : changeOfControl?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (changeOfControl?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", changeOfControl);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {changeOfControl.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        props.onEdit(
                          "Change of Control",
                          0,
                          false,
                          [],
                          -1,
                          "",
                          changeOfControl
                        );
                        setShowAdd(true);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="change_of_control"
                savedInsight={
                  !isNullOrUndefined(changeOfControl) ? changeOfControl : []
                }
                savedParentClauseDataPoint={changeOfControl}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {changeOfControl?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "change_of_control")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-change_of_control"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Definition</p>
                <p className="m-0 font-weight-bold">
                  {definitions?.length ? "Yes" : "No"}
                </p>
                {!showAddDefinition ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      props.onEdit(
                        "definition",
                        0,
                        false,
                        [],
                        -1,
                        "",
                        definitions
                      );
                      setShowAddDefinition(true);
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Change of Control Definition"
                    savedInsight={
                      !isNullOrUndefined(definitions) ? definitions : []
                    }
                    savedParentClauseDataPoint={definitions}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddDefinition(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="change_of_control"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {definitions?.map((item: any, index: number) => (
                    <div
                      className="clause-div cursor-pointer"
                      key={index}
                      onClick={() =>
                        props.onClickHighlight(
                          index,
                          item,
                          "Change of Control Definition"
                        )
                      }
                    >
                      <p className="clause-data">
                        <span>{index + 1}.&nbsp;</span>

                        {item.para
                          ? item.para
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.sentence_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteDefinition(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Termination</p>
                <p className="m-0 font-weight-bold">
                  {termination?.length ? "Yes" : "No"}
                </p>
                {!showAddTermination ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      props.onEdit(
                        "termination",
                        0,
                        false,
                        [],
                        -1,
                        "",
                        termination
                      );
                      setShowAddTermination(true);
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Change of Control Termination"
                    savedInsight={
                      !isNullOrUndefined(termination) ? termination : []
                    }
                    savedParentClauseDataPoint={termination}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddTermination(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="change_of_control"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {termination?.map((item: any, index: number) => (
                    <div
                      className="clause-div cursor-pointer"
                      key={index}
                      onClick={() =>
                        props.onClickHighlight(
                          index,
                          item,
                          "Change of Control Termination"
                        )
                      }
                    >
                      <p className="clause-data">
                        <span>{index + 1}.&nbsp;</span>
                        {item.sentence
                          ? item.sentence
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.sentence_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteTermination(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>

            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Payment</p>
                <p className="m-0 font-weight-bold">
                  {payment?.length ? "Yes" : "No"}
                </p>
                {!showAddPayment ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      props.onEdit("payment", 0, false, [], -1, "", payment);
                      setShowAddPayment(true);
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Change of Control Payment"
                    savedInsight={!isNullOrUndefined(payment) ? payment : []}
                    savedParentClauseDataPoint={payment}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddPayment(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="change_of_control"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {payment?.map((item: any, index: number) => (
                    <div
                      className="clause-div cursor-pointer"
                      key={index}
                      onClick={() =>
                        props.onClickHighlight(
                          index,
                          item,
                          "Change of Control Payment"
                        )
                      }
                    >
                      <p className="clause-data">
                        <span>{index + 1}.&nbsp;</span>
                        {item.sentence
                          ? item.sentence
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.sentence_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deletePayment(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>

            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Consent</p>
                <p className="m-0 font-weight-bold">
                  {consent?.length ? "Yes" : "No"}
                </p>
                {!showAddConsent ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      props.onEdit("consent", 0, false, [], -1, "", consent);
                      setShowAddConsent(true);
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Change of Control Consent"
                    savedInsight={!isNullOrUndefined(consent) ? consent : []}
                    savedParentClauseDataPoint={consent}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddConsent(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="change_of_control"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {consent?.map((item: any, index: number) => (
                    <div
                      className="clause-div cursor-pointer"
                      key={index}
                      onClick={() =>
                        props.onClickHighlight(
                          index,
                          item,
                          "Change of Control Consent"
                        )
                      }
                    >
                      <p className="clause-data">
                        <span>{index + 1}.&nbsp;</span>
                        {item.sentence
                          ? item.sentence
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.sentence_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteConsent(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>

            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Notice</p>
                {notice?.length ? "Yes" : "No"}

                {!showAddNotice ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      props.onEdit("notice", 0, false, [], -1, "", notice);
                      setShowAddNotice(true);
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Change of Control Notice"
                    savedInsight={!isNullOrUndefined(notice) ? notice : []}
                    savedParentClauseDataPoint={notice}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddNotice(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="change_of_control"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {notice?.map((item: any, index: number) => (
                    <div
                      className="clause-div cursor-pointer"
                      key={index}
                      onClick={() =>
                        props.onClickHighlight(
                          index,
                          item,
                          "Change of Control Notice"
                        )
                      }
                    >
                      <p className="clause-data">
                        <span>{index + 1}.&nbsp;</span>
                        {item.sentence
                          ? item.sentence
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.sentence_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )}
                      </p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteNotice(item)}
                      />
                    </div>
                  ))}
                </Scrollable>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
