import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";
import { getEditedPayload, getPara } from "./utils/ClauseTypeUtils";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { deepmerge } from "deepmerge-ts";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function SublettingClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const data = React.useMemo(
    () => clauseData?.subletting && clauseData.subletting,
    [clauseData?.subletting]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "subletting");
  }, [fileId, hasData]);

  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddNotice, setShowAddNotice] = useState(false);
  const [showAddConsent, setShowAddConsent] = useState(false);

  const updates = useMemo(
    () => updatedClauseData?.subletting,
    [updatedClauseData]
  );

  const subletting_clause = useMemo(() => {
    if (updates?.subletting) {
      let sortData = updates.subletting.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const noticeInfo = useMemo(() => {
    if (updates?.notice) {
      let sortData = updates.notice.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const consentInfo = useMemo(() => {
    if (updates?.consent) {
      let sortData = updates.consent.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let subletting = subletting_clause?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let savedRequired = noticeInfo?.required;
      let notice = noticeInfo?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      if (savedRequired) {
        notice.required = savedRequired;
      }
      if (notice?.length === 0) {
        notice.required = false;
      }
      savedRequired = consentInfo?.required;
      let consent = consentInfo?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      if (savedRequired) {
        consent.required = savedRequired;
      }
      if (consent?.length === 0) {
        consent.required = false;
      }

      let updatedData = {
        ...updates,
        subletting,
        notice,
        consent,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "subletting", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, subletting_clause, data, fileId]
  );

  const deleteNotice = React.useCallback(
    (item: any) => {
      let savedRequired = noticeInfo?.required;
      let notice = noticeInfo?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      notice.required = savedRequired;

      if (notice?.length === 0) {
        notice.required = false;
      }

      let updatedData = {
        ...updates,
        notice,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "subletting", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, noticeInfo, data, fileId]
  );

  const deleteConsent = React.useCallback(
    (item: any) => {
      let savedRequired = consentInfo?.required;
      let consent = consentInfo?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      consent.required = savedRequired;

      if (consent?.length === 0) {
        consent.required = false;
      }

      let updatedData = {
        ...updates,
        consent,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });

      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "subletting", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, consentInfo, data, fileId]
  );

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(updates?.subletting, "Subletting/Assignment");
    setCounter(0);
  };
  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && "toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Subletting/Assignment</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", updates?.subletting);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${updates?.subletting?.length}`
                  : updates?.subletting?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (updates?.subletting?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", updates?.subletting);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {subletting_clause.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "Subletting/Assignment",
                          subletting_clause,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="Subletting/Assignment"
                savedInsight={
                  !isNullOrUndefined(subletting_clause) ? subletting_clause : []
                }
                savedParentClauseDataPoint={subletting_clause}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="subletting"
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {subletting_clause?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    key={index}
                    onClick={() =>
                      props.onClickHighlight(index, item, "subletting")
                    }
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Notice Info</p>
              {noticeInfo?.required ? "Yes" : "No"}
              {!showAddNotice ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Subletting/Assignment Notice Info",
                      noticeInfo,
                      setShowAddNotice
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Subletting/Assignment Notice Info"
                  savedInsight={
                    !isNullOrUndefined(noticeInfo) ? noticeInfo : []
                  }
                  savedParentClauseDataPoint={noticeInfo}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddNotice(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="subletting"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {noticeInfo?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    key={index}
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        "Subletting/Assignment Notice Info"
                      )
                    }
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteNotice(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Consent Info</p>
              {consentInfo.length ? "Yes" : "No"}
              {!showAddConsent ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Subletting/Assignment Consent Info",
                      consentInfo,
                      setShowAddConsent
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Subletting/Assignment Consent Info"
                  savedInsight={
                    !isNullOrUndefined(consentInfo) ? consentInfo : []
                  }
                  savedParentClauseDataPoint={consentInfo}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddConsent(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="subletting"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {consentInfo?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    key={index}
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        "Subletting/Assignment Consent Info"
                      )
                    }
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteConsent(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
