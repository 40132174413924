import { Box, Stack } from "@mui/material";

import ApprovalCard from "./ApprovalCard";
import ListSkeleton from "../../../RiverusUI/Components/Skeleton/ListSkeleton";
import React from "react";
import { fetchExtractedData } from "../../../Services/Insights";
import { getDraftApprovals } from "../../../Services/Draft";
import { useQuery } from "@tanstack/react-query";

interface IProps {
  draftData: any;
  version: string;
  approvalData: any;
  approvalLoading: boolean;
}

const ApprovalTab: React.FC<IProps> = ({
  draftData,
  version,
  approvalData,
  approvalLoading,
}) => {
  const { data: extractedData, isLoading } = useQuery(
    ["clause_term", draftData?.id],
    async () => {
      const response = await fetchExtractedData(draftData?.id);
      return response;
    },
    {
      enabled: !!draftData?.id,
    }
  );

  const { data: templateApprovalData, isFetching: templateLoading } = useQuery(
    ["template_approvals_data", draftData?.draftID, version],
    async () => {
      let type =
        version === "0" ? "requisition_approvers" : "deviation_approvers";

      const response = await getDraftApprovals(draftData?.draftID, type);
      return response?.results;
    },
    {
      enabled: !!(
        draftData?.createFrom === "template" && draftData?.version === 1
      ),
    }
  );

  if (approvalLoading || isLoading || templateLoading) {
    return (
      <Box px={2}>
        <ListSkeleton />
      </Box>
    );
  }

  return (
    <Stack spacing={1}>
      {approvalData?.length > 0 && (
        <>
          {approvalData?.map((approvals: any) => (
            <ApprovalCard approvals={approvals} extractedData={extractedData} />
          ))}
        </>
      )}
      {templateApprovalData?.length > 0 && (
        <>
          {templateApprovalData?.map((approvals: any) => (
            <ApprovalCard approvals={approvals} extractedData={extractedData} />
          ))}
        </>
      )}
    </Stack>
  );
};

export default ApprovalTab;
