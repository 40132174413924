import * as React from "react";
import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function GoverningClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    durationList,
    onClickDataPoint,
    hasData,
  } = props;

  const [counter, setCounter] = React.useState(1);
  const [showAdd, setShowAdd] = React.useState(false);
  const [isExpand, setExpand] = React.useState(false);
  const [showAddJurisdiction, setShowAddJurisdiction] = React.useState(false);

  React.useEffect(() => {
    hasData && getClauseDataByType(fileId, "governing_law");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.governingLaw && clauseData.governingLaw,
    [clauseData?.governingLaw]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.governingLaw || {},
    [updatedClauseData]
  );

  const governingLaw = React.useMemo(() => {
    if (updates?.governing_law) {
      let sortData = updates.governing_law.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev: boolean) => !prev);
    onClickDataPoint(governingLaw, "Governing Law clause");
    setCounter(0);
  };

  React.useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const jurisdiction = React.useMemo(() => {
    if (updates?.jurisdiction) {
      let sortData = updates.jurisdiction.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let governing_law = governingLaw?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let jurisdictionData = jurisdiction?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        governing_law,
        jurisdiction: jurisdictionData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "governing_law", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, governingLaw, data, fileId]
  );

  const deleteJurisdiction = React.useCallback(
    (item: any) => {
      let newJurisdiction = jurisdiction?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        jurisdiction: newJurisdiction,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "governing_law", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, jurisdiction, data, fileId]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Governing Law clause</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", governingLaw);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${governingLaw?.length}`
                  : governingLaw?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (governingLaw?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", governingLaw);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {governingLaw.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        props.onEdit(
                          "governing_law",
                          0,
                          false,
                          [],
                          -1,
                          "",
                          governingLaw
                        );
                        setShowAdd(true);
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="governing_law"
                savedInsight={
                  !isNullOrUndefined(governingLaw) ? governingLaw : []
                }
                savedParentClauseDataPoint={governingLaw}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                governingLaw
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {governingLaw?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "governing_law")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-governing_law"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Jurisdiction</p>
              {!showAddJurisdiction ? (
                <DarkTooltip
                  title="Add"
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Jurisdiction",
                      jurisdiction,
                      setShowAddJurisdiction
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited="Jurisdiction"
                  savedInsight={
                    !isNullOrUndefined(jurisdiction) ? jurisdiction : []
                  }
                  savedParentClauseDataPoint={jurisdiction}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddJurisdiction(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="governing_law"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {jurisdiction?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    key={index}
                    onClick={() =>
                      props.onClickHighlight(index, item, "Jurisdiction")
                    }
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.clean_jurisdiction}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteJurisdiction(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
