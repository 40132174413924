import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormGroup,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import React, { useCallback, useEffect, useState } from "react";
import { riNeutral, riNeutralVariant } from "../../../RiverusUI/Theme/colors";

import Accordion from "@mui/material/Accordion";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListSkeleton from "../../../RiverusUI/Components/Skeleton/ListSkeleton";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import PopoverPopupState from "../../../RiverusUI/Components/Popups/PopoverPopupState";
import SearchInputComponent from "../../../RiverusUI/Components/SearchInput";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { fetchClauseLibraryData } from "../../../Services/Approval";
import { styled } from "@mui/material/styles";
import { useQuery } from "@tanstack/react-query";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",

  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    margin: "6px 0 0 !important",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

interface IProps {
  selectedClause?: any;
  handleSelectedValue?: (value: any) => void;
  isClauseLibraryTab?: boolean;
  openDocxEditor?: VoidFunction;
  isDocxOpen?: boolean;
}

const CLauseLibrary: React.FC<IProps> = ({
  selectedClause,
  handleSelectedValue,
  isClauseLibraryTab = false,
  openDocxEditor,
  isDocxOpen,
}) => {
  const [searchResults, setSearchResults] = useState<any>([]);
  const [isCopied, setIsCopied] = useState<string>("");

  const { data: clauseLibraryData, isLoading } = useQuery(
    ["clause_library"],
    async () => {
      const response = await fetchClauseLibraryData();
      return response.results;
    }
  );

  useEffect(() => {
    setSearchResults(clauseLibraryData);
  }, [clauseLibraryData]);

  const [value, setValue] = useState<any>([]);
  const [expanded, setExpanded] = React.useState<number | false>(false);
  const [isValueChange, setIsValueChange] = React.useState<boolean>(false);

  const copyToClipboard = (textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(textToCopy);
    setTimeout(() => setIsCopied(""), 3000);
    if (!isDocxOpen) {
      openDocxEditor?.();
    }
  };

  const setSelectedClause = React.useCallback(
    (child: any, checked: boolean) => {
      let tempSelectedClause = selectedClause || [];
      child.map((data: any) => {
        let index = tempSelectedClause.findIndex(
          (clause: any) => clause?.id === data?.id
        );
        if (index === -1) {
          tempSelectedClause.push({
            id: data.id,
            clause_name: data.clause_name,
            clause_text: data.clause_text,
            type: "clause_library",
          });
        } else {
          if (!checked) {
            tempSelectedClause.splice(index, 1);
          }
        }
      });
      handleSelectedValue?.(tempSelectedClause);
    },
    [handleSelectedValue, selectedClause]
  );

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: any) => {
      let childData: any = [];
      setSelectedClause(item, event.target.checked);
      if (event.target.checked) {
        item.map((data: any) =>
          childData.push({
            id: data.id,
            clause_name: data.clause_name,
            clause_text: data.clause_text,
          })
        );
        let newItem = {
          parent: event.target.name,
          child: childData,
        };

        if (selectedClause) {
        }

        setValue([...value, newItem]);
      } else {
        let newValue = value;
        let index = newValue.findIndex(
          (data: any) => data.parent === event.target.name
        );
        if (index > -1) {
          newValue.splice(index, 1);
        }
        let childData = selectedClause || [];
        item.map((data: any) => {
          let index = childData.findIndex(
            (clause: any) => clause.id === data.id
          );
          if (index > -1) {
            childData.splice(index, 1);
          }
        });
        setValue([...newValue]);
      }
      setIsValueChange(true);
    },
    [selectedClause, setSelectedClause, value]
  );

  const handleChildChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, item: any, parent: string) => {
      let newValue = value;
      setSelectedClause([item], event.target.checked);
      let index = newValue.findIndex((data: any) => data.parent === parent);
      if (event.target.checked) {
        let childData = {
          id: item.id,
          clause_name: item.clause_name,
          clause_text: item.clause_text,
        };
        if (index > -1) {
          newValue[index].child.push(childData);
        } else {
          newValue.push({
            parent: parent,
            child: [childData],
          });
        }
        setValue([...newValue]);
      } else {
        if (index > -1) {
          let newChild = newValue[index].child;
          let childIndex = newChild.findIndex(
            (childData: any) => childData.id === item.id
          );
          newChild.splice(childIndex, 1);
          if (newChild.length === 0) {
            newValue.splice(index, 1);
          } else {
            newValue[index].child = newChild;
          }
        }
        setValue([...newValue]);
      }
      setIsValueChange(true);
    },
    [setSelectedClause, value]
  );

  useEffect(() => {
    let newValue: any = [];
    if (!isValueChange && selectedClause?.length) {
      clauseLibraryData.map((clauses: any) => {
        let tempValue: any = {};

        selectedClause.map((selectedValue: any) => {
          let key = Object.keys(clauses)[0];
          let childData = clauses[Object.keys(clauses)[0]];
          childData.map((child: any) => {
            if (child.id === selectedValue.id) {
              let newChild = {
                id: child.id,
                clause_name: child.child_name,
                clause_text: child.child_text,
                type: "clause_library",
              };
              if (tempValue.child) {
                if (tempValue.parent === key) {
                  tempValue.child.push(newChild);
                }
              } else {
                tempValue = {
                  parent: key,
                  child: [newChild],
                };
              }
            }
          });
        });
        if (tempValue.child) {
          newValue.push(tempValue);
        }
      });
      setValue(newValue);
    }
  }, [selectedClause, clauseLibraryData, isValueChange]);

  const getChildIndex = React.useCallback(
    (parent: string, id: string) => {
      let parentIndex = value.findIndex(
        (parentData: any) => parentData.parent === parent
      );
      if (parentIndex > -1) {
        let found =
          value[parentIndex].child.findIndex(
            (childData: any) => childData.id === id
          ) > -1;
        return found;
      }
      return false;
    },
    [value]
  );

  const handleAccordionChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const isIntermediate = useCallback(
    (items: any, parent: string) => {
      if (value.length) {
        let index = value.findIndex((data: any) => data.parent === parent);
        if (index > -1) {
          return value[index].child.length !== items.length;
        }
      } else {
        return false;
      }
    },
    [value]
  );

  const getSelectedCountText = useCallback(
    (items: any, parent: string) => {
      if (value.length) {
        let index = value.findIndex((data: any) => data.parent === parent);
        if (index > -1) {
          if (value[index].child.length !== items.length) {
            return `${value[index].child.length}/${items.length} selected`;
          }
        }
      }
      return items.length;
    },
    [value]
  );

  const handleSearch = useCallback(
    (searchQuery: string) => {
      let newClauseData = [...clauseLibraryData];
      clauseLibraryData?.map((data: any) => {
        let key = Object.keys(data)[0];
        let childData = data[Object.keys(data)[0]];
        const filterValue = childData.filter((child: any) =>
          child.clause_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filterValue.length) {
          filterValue.map((filterData: any) => {
            if (key === filterData.section_name) {
              data[key] = filterValue;
            }
            return data;
          });
        } else {
          let index = newClauseData.findIndex(
            (data) => Object.keys(data)[0] === key
          );
          if (index > -1) {
            newClauseData.splice(index, 1);
          }
        }
        return newClauseData;
      });
      if (searchQuery.trim() !== "") {
        setSearchResults(newClauseData);
      } else {
        setSearchResults(clauseLibraryData);
      }
    },
    [clauseLibraryData, setSearchResults]
  );

  if (isLoading) {
    return (
      <Box px={2}>
        <ListSkeleton />
      </Box>
    );
  }
  return (
    <Box>
      <Box sx={{ px: 2, py: 1 }}>
        <SearchInputComponent
          placeholder="Search by clause name"
          handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
            handleSearch(e.target.value)
          }
        />
      </Box>
      <FormGroup>
        {searchResults?.map((item: any, index: number) => {
          for (let key in item) {
            return (
              <Accordion
                key={key}
                expanded={expanded === index}
                onChange={handleAccordionChange(index)}
                sx={{
                  background: expanded === index ? "#88305F24" : "transparent",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={key}
                  id={`${key}_header`}
                >
                  <FormControl sx={{ width: "90%" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack gap={1} flexDirection="row" alignItems="center">
                        {!isClauseLibraryTab && (
                          <Checkbox
                            key={key}
                            name={key}
                            checked={
                              value.findIndex(
                                (data: any) => data.parent === key
                              ) > -1
                            }
                            onChange={(event) => handleChange(event, item[key])}
                            sx={{ padding: "2px" }}
                            indeterminate={isIntermediate(item[key], key)}
                          />
                        )}
                        <Typography>{key}</Typography>
                      </Stack>

                      <Typography>
                        {getSelectedCountText(item[key], key)}
                      </Typography>
                    </Stack>
                  </FormControl>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2} sx={{ ml: 2 }}>
                    {item[key].map((child: any, index: number) => (
                      <FormControl key={index}>
                        <Stack
                          justifyContent="space-between"
                          direction="row"
                          alignItems="center"
                        >
                          <Stack
                            flexDirection="row"
                            gap={1}
                            alignItems="center"
                          >
                            {!isClauseLibraryTab && (
                              <Checkbox
                                key={child.id}
                                name={child.id}
                                checked={getChildIndex(key, child.id)}
                                onChange={(event) =>
                                  handleChildChange(event, child, key)
                                }
                                sx={{ padding: "2px" }}
                              />
                            )}
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: riNeutralVariant[700],
                                  lineHeight: 1,
                                }}
                              >
                                {child.clause_no}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "16px",
                                  color: riNeutral[900],
                                  lineHeight: 1,
                                }}
                              >
                                {child.clause_name}
                              </Typography>
                            </Box>
                          </Stack>
                          {!isClauseLibraryTab ? (
                            <PopoverPopupState
                              id={child.id}
                              renderIcon={() => <VisibilityOutlinedIcon />}
                            >
                              <Typography>{child.clause_text}</Typography>
                            </PopoverPopupState>
                          ) : (
                            <>
                              {isCopied === child?.clause_text && (
                                <Chip
                                  style={{ background: "#D3FFC4" }}
                                  label="Clause Copied"
                                />
                              )}
                              <IconButton
                                onClick={() =>
                                  copyToClipboard(child?.clause_text)
                                }
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </>
                          )}
                        </Stack>
                      </FormControl>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          }
        })}
      </FormGroup>
    </Box>
  );
};

export default CLauseLibrary;
