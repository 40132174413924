import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";

import { AmountEdit } from "./AmountEdit";
import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LinkPhraseRequest } from "../../State/documentState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import { truncateString } from "../../../Utils/DataModifierUtil/dataModUtil";

export default function LimitationClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    currencyList,
    hasData,
  } = props;

  const [isExpand, setExpand] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [counter, setCounter] = useState(1);
  const [showAddAmount, setShowAddAmount] = useState(false);
  const [editAmount, setEditAmount] = useState(false);
  const [amountId, setAmountId] = useState<number>(-1);
  const [isChildDeleted, setChildDeleted] = React.useState(true);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "limited_liability");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.limitedLiability && clauseData.limitedLiability,
    [clauseData?.limitedLiability]
  );

  const updates = useMemo(
    () => updatedClauseData?.limitedLiability || {},
    [updatedClauseData]
  );

  const limitedLiability = useMemo(() => {
    if (updates?.limited_liability) {
      let sortData = updates.limited_liability.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(limitedLiability, "limited_liability");
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const amountString = useMemo(() => {
    if (updates?.amount_string) {
      let sortData = updates.amount_string.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const amounts = useMemo(() => {
    if (updates?.amounts) {
      let sortData = updates.amounts.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      sortData.map((data: any) => {
        const index = currencyList.findIndex(
          (list) => list.currencyTypeId === data.currency_type_id
        );
        const typeIndex = currencyList.findIndex(
          (list) => list.currencyName === data.currency
        );
        if (index > -1) {
          const currency = currencyList[index].currencyName;
          data.currency = currency;
        }
        if (!data.currency_type_id && typeIndex > -1) {
          const id = currencyList[typeIndex].currencyTypeId;
          data.currency_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const isAmountStringDataSame = (addedData: any, amountString: any) => {
    if (
      addedData.para_id === amountString.para_id &&
      addedData.sentence_id === amountString.sentence_id
    ) {
      if (addedData.table) {
        if (
          addedData.table[0].row === amountString.table[0].row &&
          addedData.table[0].column === amountString.table[0].column
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const combineAmount = React.useMemo(() => {
    let newData: any[] = [];
    amountString?.map((stringData: any) => {
      amounts?.map((amount: any) => {
        if (isAmountStringDataSame(amount, stringData)) {
          stringData.currency = amount.currency;
          stringData.value = amount.value;
          stringData.total = amount.total;
          newData.push(stringData);
        }
      });
      let index: number = newData.findIndex(
        (data) =>
          data.para_id === stringData.para_id &&
          data.sentence_id === stringData.sentence_id &&
          data.table[0].row === stringData.table[0].row &&
          data.table[0].column === stringData.table[0].column
      );
      if (index === -1) {
        newData.push(stringData);
      }
    });
    return newData;
  }, [amounts, amountString]);

  React.useEffect(() => {
    if (data && data.edited_content === null && isChildDeleted) {
      let updatedData = {
        ...updates,
        amount_string: [],
        amounts: [],
      };
      if (limitedLiability && limitedLiability.length) {
        amountString?.map((amount: any) => {
          let index = limitedLiability.findIndex(
            (item: any) =>
              item.para_id !== amount.para_id ||
              item.sentence_id !== amount.sentence_id
          );
          if (index > -1) {
            amountString?.splice(index, 1);
          }
        });
        amounts?.map((amount: any) => {
          let index = limitedLiability.findIndex(
            (item: any) =>
              item.para_id !== amount.para_id ||
              item.sentence_id !== amount.sentence_id
          );
          if (index > -1) {
            amounts?.splice(index, 1);
          }
        });
        setChildDeleted(false);
        updatedData = {
          ...updates,
          amount_string: amountString,
          amounts,
        };
      }
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
        getClauseDataByType(fileId, "limited_liability");
      }
    }
  }, [data, amountString, amounts, hasData, limitedLiability, isChildDeleted]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let limited_liability = limitedLiability?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let amount_string = amountString?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let amountData = amounts.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        limited_liability,
        amount_string,
        amounts: amountData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, limitedLiability, data, fileId]
  );

  const deleteAmount = React.useCallback(
    (item: any) => {
      let amount_string = amountString?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let amountData = amounts.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        amount_string,
        amounts: amountData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, amountString, data, fileId]
  );

  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let amount_string = amountString.slice();
      let amountData = amounts.slice();
      let amountIndex = amountData.findIndex(
        (amount: any) =>
          amount.para_id === amount_string[index].para_id &&
          amount.sentence_id === amount_string[index].sentence_id &&
          amount.table[0].row === amount_string[index].table[0].row &&
          amount.table[0].row === amount_string[index].table[0].row
      );
      if (amountIndex !== -1) {
        amountData[amountIndex] = data;
      } else {
        amountData.push(data);
      }
      let updatedData = {
        ...updates,
        amounts: amountData,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "limited_liability", diff, updatedData);
      }
    },
    [amountString]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Limitation Of Liability</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", limitedLiability);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${limitedLiability?.length}`
                  : limitedLiability?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (limitedLiability?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", limitedLiability);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {limitedLiability.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "limited_liability",
                          limitedLiability,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="limited_liability"
                savedInsight={
                  !isNullOrUndefined(limitedLiability) ? limitedLiability : []
                }
                savedParentClauseDataPoint={limitedLiability}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {limitedLiability?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "non_compete")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-non-compete"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Amount</p>
                {!showAddAmount ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      handleAddEdit(
                        props,
                        "Limitation Of Liability Amount",
                        amountString,
                        setShowAddAmount
                      );
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Limitation Of Liability Amount"
                    savedInsight={
                      !isNullOrUndefined(amountString) ? amountString : []
                    }
                    savedParentClauseDataPoint={amountString}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddAmount(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="limited_liability"
                  />
                )}
              </div>
              {combineAmount?.map((amountString: any, index: number) => (
                <div key={index}>
                  <div>
                    {editAmount && amountId === index ? (
                      <AmountEdit
                        dataPointName="Limitation Of Liability Amount"
                        currencyList={currencyList}
                        savedInsight={
                          !isNullOrUndefined(amountString) ? amountString : []
                        }
                        editPhrasesRequest={(
                          newPhraseRequest: LinkPhraseRequest
                        ) => {
                          editPhrase(newPhraseRequest, index);
                        }}
                        clearNormalizedEdit={() => setEditAmount(false)}
                        selectedAmount={{
                          para_id: amountString.para_id,
                          table: amountString.table,
                          sentence_id: amountString.sentence_id,
                          currency: amountString.currency,
                          value: amountString.value,
                          total: amountString.total,
                          start_word_id: amountString.start_word_id,
                          end_word_id: amountString.end_word_id,
                        }}
                      />
                    ) : (
                      <div
                        className={
                          editAmount && amountId === index
                            ? " mr-1 toc-content toc-content-active animate__animated animate__fadeInDown"
                            : " mr-1 toc-content toc-content-default animate__animated animate__fadeInDown"
                        }
                        style={{
                          border:
                            editAmount && amountId === index
                              ? "2px solid #882f5e"
                              : "",
                          marginBottom: "1px",
                        }}
                      >
                        <div
                          className="bi-clause-clickable"
                          onClick={() =>
                            props.onClickHighlight(
                              index,
                              amountString,
                              "Limitation Of Liability Amount"
                            )
                          }
                        >
                          <div className="d-flex flex-row px-3">
                            <span style={{ color: "#4D4D4D" }}>
                              {index + 1}. &nbsp;&nbsp;
                            </span>
                            <span>
                              {amountString.total < 0 ? (
                                <img
                                  src="/static_images/empty-dash-grey-icn.svg"
                                  alt="empty"
                                />
                              ) : amountString.total > -1 &&
                                (
                                  amountString.total +
                                  " " +
                                  amountString.currency
                                ).length > 30 ? (
                                <DarkTooltip
                                  title={
                                    amountString.total +
                                    " " +
                                    amountString.currency
                                  }
                                  placement={"right-end"}
                                >
                                  <>
                                    {truncateString(
                                      amountString.total +
                                        " " +
                                        amountString.currency,
                                      30
                                    )}
                                  </>
                                </DarkTooltip>
                              ) : (
                                `${amountString.total || "_"}  ${
                                  amountString.currency || "_"
                                }`
                              )}
                              <DarkTooltip title="Edit" placement="right-end">
                                <img
                                  src="/static_images/new-edit-icon.svg"
                                  alt="edit"
                                  onClick={() => {
                                    setEditAmount(true);
                                    setAmountId(index);
                                  }}
                                />
                              </DarkTooltip>
                            </span>
                            <img
                              className="cursor-pointer ml-auto "
                              style={{ zIndex: 2 }}
                              src="/static_images/delete-insight-icn.svg"
                              alt="delete-currency-insight"
                              data-toggle="modal"
                              data-target="#deleteInsightModal"
                              onClick={() => deleteAmount(amountString)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      editAmount && amountId === index
                        ? "mr-1 toc-content toc-content-active animate__animated animate__fadeInDown p-2 mb-1"
                        : "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1 cursor-pointer"
                    }
                    style={{
                      border:
                        editAmount && amountId === index
                          ? "2px solid #882f5e"
                          : "",
                    }}
                    key={index}
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        amountString,
                        "Limitation Of Liability Amount"
                      )
                    }
                  >
                    {amountString.amountString}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
