import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import CloseIcon from "@mui/icons-material/Close";
import DeviationCard from "./DeviationCard";
import { riPrimary } from "../../../RiverusUI/Theme/colors";

const allTypes = [
  { value: "show_all", label: "Show all" },
  { value: "deviant", label: "Deviant" },
  { value: "complaint", label: "Complaint" },
  { value: "unrecognized", label: "Unrecognized" },
];

const status = [
  { value: "default", label: "Default" },
  { value: "approval_pending", label: "Approval pending" },
  { value: "approved", label: "Approved" },
];

interface Props {
  deviationsList: any[];
  instance: any;
  setActiveTab: Dispatch<SetStateAction<string>>;
  deviation_type: string;
  handleOpenClauseLibraryDrawer: VoidFunction;
}

const Policies: React.FC<Props> = ({
  deviationsList,
  instance,
  setActiveTab,
  deviation_type,
  handleOpenClauseLibraryDrawer,
}) => {
  const [selectedType, setSelectedType] = useState<string>("deviant");
  const [selectedStatus, setSelectedStatus] = useState("default");
  const [filteredDeviation, setFilteredDeviation] = useState<any[]>([]);

  const handleTypes = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedType(event.target.value as string);
  };

  const handleStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedStatus(event.target.value as string);
  };

  useEffect(() => {
    if (deviationsList?.length > 0) {
      let filteredData = [];
      if (
        selectedType === "show_all" &&
        (selectedStatus === "default" || selectedStatus === "")
      ) {
        filteredData = deviationsList;
      } else if (selectedType === "show_all") {
        filteredData = deviationsList?.filter(
          (item: any) => item?.approval_status === selectedStatus
        );
      } else if (selectedStatus === "default" || selectedStatus === "") {
        filteredData = deviationsList?.filter(
          (item: any) => item?.status === selectedType
        );
      } else {
        filteredData = deviationsList?.filter((item: any) => {
          if (
            item?.status === selectedType &&
            item?.approval_status === selectedStatus
          ) {
            return item;
          }
        });
      }
      setFilteredDeviation(filteredData);
    }
  }, [selectedStatus, selectedType, deviationsList]);

  const toShowNoIssueFound = useMemo(() => {
    return deviationsList?.some((list: any) => {
      return (
        list?.draft_datapoint ||
        (list?.manual_datapoint && list?.manual_datapoint.length > 0) ||
        (list?.kdp_datapoint && list?.kdp_datapoint.length > 0)
      );
    });
  }, [deviationsList]);

  return (
    <Box>
      <Stack spacing={2} direction="row" width="100%">
        <TextField
          sx={{ minWidth: "150px" }}
          select
          value={selectedType}
          onChange={handleTypes}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Type:</InputAdornment>
            ),
          }}
        >
          {allTypes?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{
            minWidth: "200px",
            background: selectedStatus ? riPrimary[500] : "",
            borderRadius: "5px",
            "& .MuiSelect-select:focus": {
              color: "#fff",
            },
            "& .MuiSelect-select": {
              color: selectedStatus ? "#fff" : "black",
            },
          }}
          select
          value={selectedStatus}
          onChange={handleStatus}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ color: selectedStatus ? "#fff" : "black" }}
              >
                {selectedStatus && (
                  <IconButton
                    onClick={() => {
                      setSelectedStatus("");
                    }}
                    style={{ color: selectedStatus ? "#fff" : "black" }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                Status:
              </InputAdornment>
            ),
          }}
        >
          {status?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {filteredDeviation?.map((list: any) => (
        <>
          {(list?.draft_datapoint ||
            list?.manual_datapoint?.length > 0 ||
            list?.kdp_datapoint?.length > 0) && (
            <DeviationCard
              cardData={list}
              instance={instance}
              setActiveTab={setActiveTab}
              deviation_type={deviation_type}
              handleOpenClauseLibraryDrawer={handleOpenClauseLibraryDrawer}
            />
          )}
        </>
      ))}
      {!toShowNoIssueFound && (
        <Typography marginTop="15px">No issue found in this draft</Typography>
      )}
    </Box>
  );
};

export default Policies;
