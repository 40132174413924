import {
  Autocomplete,
  AutocompleteProps,
  Checkbox,
  TextField,
  TextFieldProps,
} from "@mui/material";
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
  Path,
} from "react-hook-form";

import CircularProgress from "@mui/material/CircularProgress";
import { FieldValues } from "react-hook-form/dist/types/fields";
import React from "react";
import { ReactNode } from "react";
import { useFormError } from "./FormErrorProvider";

export type AutocompleteElementProps<
  F extends FieldValues,
  T,
  M extends boolean | undefined,
  D extends boolean | undefined
> = {
  name: Path<F>;
  control?: Control<F>;
  options: T[];
  loading?: boolean;
  multiple?: M;
  matchId?: boolean;
  rules?: ControllerProps<F>["rules"];
  parseError?: (error: FieldError) => ReactNode;
  required?: boolean;
  label?: TextFieldProps["label"];
  showCheckbox?: boolean;
  autocompleteProps?: Omit<
    AutocompleteProps<T, M, D, any>,
    "name" | "options" | "loading" | "renderInput"
  >;
  textFieldProps?: Omit<TextFieldProps, "name" | "required" | "label">;
  value?: any;
  labelKey?: string;
  valueKey?: string;
  fixedValues?: any;
  readOnly?: boolean;
};

type AutoDefault = {
  id: string | number; // must keep id in case of keepObject
  label: string;
};

export default function AutocompleteElement<TFieldValues extends FieldValues>({
  textFieldProps,
  autocompleteProps,
  name,
  control,
  options,
  loading,
  showCheckbox,
  rules,
  required,
  multiple,
  matchId,
  label,
  value,
  parseError,
  fixedValues,
  readOnly = false,
  labelKey = "name",
  valueKey = "id",
}: AutocompleteElementProps<
  TFieldValues,
  AutoDefault | string | any,
  boolean | undefined,
  boolean | undefined
>) {
  const errorMsgFn = useFormError();
  const customErrorFn = parseError || errorMsgFn;
  const validationRules: ControllerProps<TFieldValues>["rules"] = {
    ...rules,
    ...(required && {
      required: rules?.required || "This field is required",
    }),
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={validationRules}
      defaultValue={value}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        let currentValue = multiple ? value || [] : value ?? null;
        if (matchId) {
          currentValue = multiple
            ? (value || []).map((i: any) =>
                options.find((j) => (j[valueKey] ?? j) === (i[valueKey] ?? i))
              )
            : options.find((i) => (i[valueKey] ?? i) === value) ?? null;
        }
        return (
          <Autocomplete
            readOnly={readOnly}
            {...autocompleteProps}
            value={currentValue}
            loading={loading}
            multiple={multiple}
            options={options}
            disableCloseOnSelect={
              typeof autocompleteProps?.disableCloseOnSelect === "boolean"
                ? autocompleteProps.disableCloseOnSelect
                : !!multiple
            }
            isOptionEqualToValue={
              autocompleteProps?.isOptionEqualToValue
                ? autocompleteProps.isOptionEqualToValue
                : (option, value) => {
                    return value
                      ? option[valueKey] === (value?.[valueKey] ?? value)
                      : false;
                  }
            }
            getOptionLabel={
              autocompleteProps?.getOptionLabel
                ? autocompleteProps.getOptionLabel
                : (option) => {
                    return `${option?.[labelKey] ?? option}`;
                  }
            }
            onChange={(event, value, reason, details) => {
              let changedVal = value;
              if (matchId) {
                changedVal = Array.isArray(value)
                  ? value.map((i: any) => i?.[valueKey] ?? i)
                  : value?.[valueKey] ?? value;
              }

              if (fixedValues) {
                changedVal = [
                  ...fixedValues,
                  ...changedVal.filter(
                    (option: any) => fixedValues.indexOf(option) === -1
                  ),
                ];
              }
              onChange(changedVal);
              if (autocompleteProps?.onChange) {
                autocompleteProps.onChange(event, value, reason, details);
              }
            }}
            renderOption={
              autocompleteProps?.renderOption ??
              (showCheckbox
                ? (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox sx={{ marginRight: 1 }} checked={selected} />
                      {autocompleteProps?.getOptionLabel?.(option) ||
                        option[labelKey] ||
                        option}
                    </li>
                  )
                : undefined)
            }
            onBlur={(event) => {
              onBlur();
              if (typeof autocompleteProps?.onBlur === "function") {
                autocompleteProps.onBlur(event);
              }
            }}
            renderInput={(params) => (
              <TextField
                name={name}
                required={rules?.required ? true : required}
                label={label}
                {...textFieldProps}
                {...params}
                error={!!error}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  ...textFieldProps?.InputLabelProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  ...textFieldProps?.InputProps,
                }}
                inputProps={{
                  ...params.inputProps,
                  ...textFieldProps?.inputProps,
                }}
                helperText={
                  error
                    ? typeof customErrorFn === "function"
                      ? customErrorFn(error)
                      : error.message
                    : textFieldProps?.helperText
                }
              />
            )}
          />
        );
      }}
    />
  );
}
