import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  DataDictionary,
  approvalTypeOptions,
  approverSequence,
  conditionMap,
  entireContractCondition,
  policyScope,
  rangeCondition,
  similarityArray,
} from "./CreateApproval";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  createApproval,
  fetchApprovalData,
  fetchApprovers,
  fetchContractType,
  fetchGroups,
  fetchMergeTag,
  fetchRequestApprovals,
  updateApproval,
} from "../../../Services/Approval";
import { useMutation, useQuery } from "@tanstack/react-query";

import ApprovalChecklist from "./ApprovalChecklist";
import ChecklistDrawer from "./ChecklistDrawer";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationModal from "../../../RiverusUI/Components/ConfirmationModalComponent";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CustomChip from "./CustomChip";
import CustomModal from "../../../RiverusUI/Components/CustomModal";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import LinkCLauseTextField from "./LinkClauseTextField";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import NotepadComponent from "../../../RiverusUI/Components/NotepadComponent";
import PercentIcon from "@mui/icons-material/Percent";
import { QueryKeyGenerator } from "../../../Utils/QueryKeyGenerator";
import RISelectComponent from "../../../RiverusUI/Components/SelectComponent";
import RadioButtonGroup from "../../../RiverusUI/Components/RadioButtonGroup";
import SelectElement from "../../../RiverusUI/Components/SelectElement";
import ShareChip from "../../../DocumentLibrary/Component/ShareModal/ShareChip";
import { useSnackbar } from "notistack";

// import ContractDrawer from "./ContractDrawer";

interface Props {
  open: boolean;
  onClose: () => void;
  editDetails: boolean;
  editCopyDetailId: string;
  isViewPolicy?: boolean;
  setApprovalTableKey?: any;
  setIsViewPolicy?: Dispatch<SetStateAction<boolean>>;
}

const textDataTypeArray = ["text-box", "text"];
const triggerApprovalArray = ["contains", "does not contain"];

const CreateApprovalComponent: React.FC<Props> = ({
  open,
  onClose,
  editDetails,
  editCopyDetailId,
  isViewPolicy = false,
  setApprovalTableKey,
  setIsViewPolicy,
}) => {
  // const [openContractDrawer, setOpenContractDrawer] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedChecklists, setSelectedChecklists] = useState<any>([]);
  const [clauseValue, setClauseValue] = useState<any>([]);
  const [formDefaults, setFormDefaults] = useState<any>({});
  const [selectedClauseArray, setSelectedClauseArray] = useState<any>([]);
  const [selectedFieldOperator, setSelectedFieldOperator] = useState<any>([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [dataType, setDataType] = useState<string>("");
  const [isClose, setIsClose] = useState<boolean>(false);
  const [clauseNameLists, setClauseNameLists] = useState<any[]>([]);
  // Checklist Drawer
  const [openChecklistDrawer, setOpenChecklistDrawer] =
    useState<boolean>(false);
  const [approvalTypes, setApprovalTypes] = useState(approvalTypeOptions);

  const { data } = useQuery(
    ["get_approval", editCopyDetailId],
    async () => {
      const response = await fetchApprovalData(editCopyDetailId);

      return response;
    },
    {
      enabled: !!editCopyDetailId,
    }
  );

  const { data: contractData } = useQuery(
    QueryKeyGenerator.getChoiceFetchingQuery(),
    async () => {
      const response = await fetchContractType();
      return response.results;
    }
  );

  const { data: approversData, isLoading: approversLoading } = useQuery(
    ["Approvers"],
    async () => {
      const response = await fetchApprovers();
      let groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + " " + data.last_name,
      }));
      return groups;
    }
  );

  const methods = useForm({
    defaultValues: formDefaults,
  });

  const { handleSubmit, reset, control, watch, resetField } = methods;

  // Prescribing Department

  const { data: groupListData, isLoading: groupLoading } = useQuery(
    ["GroupList"],
    async () => {
      const response = await fetchGroups();
      return response;
    }
  );

  useEffect(() => {
    if (data) {
      const approvers = data.approvers.map((data: any) => ({
        ...data,
        name: data.first_name + " " + data.last_name,
      }));
      setSelectedChecklists(data.checklist);
      setDataType(data.data_type);
      if (data.value && data.data_type === "text") {
        setClauseValue(JSON.parse(data.value));
      }
      setFormDefaults((prev: any) => ({
        ...prev,
        contract_type: data.contract[0]?.id || data.contract[0],
        approval_type: data.approval_type,
        approvers: approvers?.map((approver: any) => approver?.id),
        approval_sequence: data.approval_sequence,
        groups: data.groups?.map((group: any) => group?.id),
        policy_name: data.policy_name,
        case_sensitive: data.case_sensitive,
        include_all: data.include_all,
        scope: data.scope,
        condition: data.condition,
        similarity: data.similarity,
        clause_name:
          data?.clause_type === "kdp"
            ? data.clause_name + " (Machine Extracted)"
            : data.clause_name,
        field_name: data.field_name,
        duration: data.field_name === "duration" ? data.value : "",
        optional_data: data.optional_data,
        note_for_approvers: data.note_for_approvers,
        value: data.value,
        data_type: data.data_type,
        selected_clause_text: data.data_type === "text" ? data.value : null,
      }));
    }
  }, [contractData, data]);

  React.useEffect(() => {
    reset(formDefaults);
  }, [formDefaults, reset]);

  const handleCloseChecklistDrawer = () => {
    setOpenChecklistDrawer(false);
  };

  const handleScope = React.useCallback(() => {
    resetField("clause_name");
    resetField("condition");
    resetField("field_type");
    setSelectedChecklists([]);
  }, [resetField]);

  const handleOpen = () => {
    setOpenConfirmationModal(true);
  };

  const handleClose = () => {
    setOpenConfirmationModal(false);
  };

  const handleConfirm = () => {
    setOpenConfirmationModal(false);
    onClose();
  };

  const handleSelectClause = (value: any) => {
    setClauseValue(value);
  };

  const approvalType = watch("approval_type") || "";
  const selectClauseName = watch("clause_name") || "";
  const selectedCondition = watch("condition") || "";
  const selectFieldName = watch("field_name") || "";
  const scope = watch("scope") || "";
  const contractType = watch("contract_type") || "";
  const value = watch("value") || "";
  const contract_type = watch("contract_type") || "";

  const { data: contractRequestData } = useQuery({
    queryKey: [QueryKeyGenerator.getRequestApproval(), contract_type],
    queryFn: () => {
      let params = `?approval_type=contract_request&contract=${contract_type}`;
      return fetchRequestApprovals(params);
    },
    enabled: !!contract_type,
    keepPreviousData: true,
  });

  const { data: getMergeTagList } = useQuery(
    ["merge_tag_lists", contractType],
    async () => {
      const response = await fetchMergeTag(contractType);
      return response?.data;
    },
    { enabled: !!contractType }
  );

  useEffect(() => {
    if (getMergeTagList !== undefined) {
      setClauseNameLists([...DataDictionary, ...getMergeTagList]);
    }
  }, [getMergeTagList]);

  useEffect(() => {
    if (contract_type && contractRequestData) {
      const updateDraftOptions = [...approvalTypeOptions];
      if (contractRequestData.results?.length > 0) {
        let option = {
          value: "contract_request",
          title: "",
          description: `Contract Request Already found under selected contract type.`,
          disabled: true,
        };
        updateDraftOptions[0] = option;
      } else {
        updateDraftOptions[0].disabled = false;
      }
      setApprovalTypes(updateDraftOptions);
    }
  }, [contractRequestData, contract_type]);

  React.useEffect(() => {
    if (!editCopyDetailId) {
      resetField("approval_type");
      setSelectedChecklists([]);
    }
  }, [contractType, resetField, editCopyDetailId]);

  React.useEffect(() => {
    resetField("condition");
    resetField("value");
  }, [resetField, selectFieldName]);

  React.useEffect(() => {
    resetField("field_name");
    resetField("condition");
    resetField("value");
  }, [resetField, selectClauseName]);

  useEffect(() => {
    if (selectClauseName) {
      const filterSelectedObject = clauseNameLists?.find(
        (item) => item?.section_name === selectClauseName
      );
      setSelectedClauseArray(filterSelectedObject);
    }
  }, [selectClauseName, clauseNameLists]);

  useEffect(() => {
    if (selectFieldName) {
      const selectedFieldObject = selectedClauseArray?.field_data?.find(
        (item: any) => item?.display_name === selectFieldName
      );
      setDataType(selectedFieldObject?.data_type?.toLowerCase());
      const getOperators = conditionMap.find(
        (item: any) =>
          item?.mergeTagDatatype ===
          selectedFieldObject?.data_type?.toLowerCase()
      );
      setSelectedFieldOperator(getOperators);
    }
  }, [selectFieldName, selectedClauseArray]);

  const { mutate: addApproval } = useMutation(
    ["create_approval", isClose],
    async (approvalAddData) => createApproval(approvalAddData),
    {
      onSuccess: () => {
        isClose ? onClose() : reset();
        enqueueSnackbar("Approval added successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setApprovalTableKey(Math.random());
      },
      onError: () => {
        enqueueSnackbar("Failed to create Approval!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const { mutate: editApproval } = useMutation(
    ["update_approval", editCopyDetailId, isClose],
    async (approvalAddData) =>
      updateApproval(editCopyDetailId, approvalAddData),
    {
      onSuccess: () => {
        isClose ? onClose() : reset();
        enqueueSnackbar("Approval updated successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        setApprovalTableKey(Math.random());
      },
      onError: () => {
        enqueueSnackbar("Failed to update Approval!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const onSubmit = (data: any, isClose: boolean = true) => {
    data.contract = [contractType];
    data.value =
      clauseValue?.length !== 0 ? JSON.stringify(clauseValue) : value ?? "";
    data.checklist =
      approvalType === "contract_request" ? selectedChecklists : [];
    data.priority = data.approvers;
    data.clause_name = selectClauseName.replace(/\(Machine Extracted\)/g, "");
    if (dataType !== "") {
      data.data_type = dataType;
    }

    setIsClose(isClose);

    const payload = {
      ...data,
      clause_type: selectClauseName?.includes("Machine Extracted")
        ? "kdp"
        : "merge_tag",
    };

    if (editDetails) {
      editApproval(payload);
    } else {
      addApproval(payload);
    }
  };

  const renderTextInput = React.useCallback(
    () => (
      <LinkCLauseTextField
        readOnly={isViewPolicy}
        name="selected_clause_text"
        control={control}
        key="entire-contract-text"
        clauseValue={clauseValue}
        handleClauseValue={(value: any) => handleSelectClause(value)}
      />
    ),
    [clauseValue, control, isViewPolicy]
  );

  const showValueField = useMemo(
    () =>
      selectedCondition &&
      selectedCondition !== "is present" &&
      selectedCondition !== "is absent",
    [selectedCondition]
  );

  return (
    <>
      <CustomModal
        title="Create Policy"
        fullScreen
        open={open}
        handleClose={onClose}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <Grid container spacing={2} p={5} alignItems={"center"}>
              <Grid item sm={9}>
                <Typography marginBottom={2} fontWeight={"700"}>
                  Select contracts and type of policy
                </Typography>
                <SelectElement
                  name="contract_type"
                  control={control}
                  options={contractData}
                  labelKey="name"
                  InputProps={{
                    readOnly: isViewPolicy,
                  }}
                />
                <Typography variant="caption">
                  You can select multiple contracts here. The approval rule
                  below will be applied to this selection
                </Typography>
              </Grid>
              <Grid item sm={9}>
                <RadioButtonGroup
                  row
                  required
                  name="approval_type"
                  options={approvalTypes}
                  valueKey="value"
                  isDescription
                  control={control}
                  disabled={isViewPolicy}
                />
              </Grid>
              {approvalType === "contract_condition" && (
                <>
                  <Grid item sm={9}>
                    <Typography marginBottom={2} fontWeight={"700"}>
                      Define scope of this policy
                    </Typography>
                    <RadioButtonGroup
                      name="scope"
                      isDescription
                      control={control}
                      row
                      required
                      options={policyScope}
                      valueKey="value"
                      onChange={handleScope}
                      disabled={isViewPolicy}
                    />
                  </Grid>
                  {scope && (
                    <Grid item sm={9}>
                      <Typography fontWeight={"700"}>
                        Define condition to match
                      </Typography>
                    </Grid>
                  )}
                  {scope === "entire_contract" && (
                    <>
                      <Grid item sm={12} mx={1}>
                        {renderTextInput()}
                      </Grid>
                      <Grid container alignItems={"center"} mt={2}>
                        <Grid
                          item
                          sm={1}
                          style={{
                            transform: "scaleX(-1)",
                            paddingRight: "15px",
                          }}
                        >
                          <KeyboardReturnIcon />
                        </Grid>
                        <Grid item sm={8}>
                          <Controller
                            name="condition"
                            control={control}
                            render={({ field }) => (
                              <FormControl fullWidth style={{ margin: "10px" }}>
                                <InputLabel htmlFor="Select-condition">
                                  Select condition
                                </InputLabel>
                                <Select
                                  {...field}
                                  required
                                  label="Select condition"
                                  variant="outlined"
                                  inputProps={{
                                    id: "Select-condition",
                                    readOnly: isViewPolicy,
                                  }}
                                >
                                  {entireContractCondition.map(
                                    (option: any, index: number) => {
                                      return (
                                        <MenuItem key={index} value={option}>
                                          {option}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {scope === "text_of_clause" && (
                    <>
                      <Grid item sm={9}>
                        <SelectElement
                          label="Select Clause Name"
                          name="clause_name"
                          required
                          labelKey="section_name"
                          options={clauseNameLists}
                          valueKey="section_name"
                          InputProps={{
                            readOnly: isViewPolicy,
                          }}
                        />
                      </Grid>
                      {selectClauseName && (
                        <Grid container alignItems={"center"} mt={2}>
                          <Grid
                            item
                            sm={1}
                            style={{
                              transform: "scaleX(-1)",
                              paddingRight: "15px",
                            }}
                          >
                            <KeyboardReturnIcon />
                          </Grid>
                          <Grid item sm={8}>
                            <SelectElement
                              label="Select a field name"
                              name="field_name"
                              required
                              labelKey="display_name"
                              options={selectedClauseArray?.field_data}
                              valueKey="display_name"
                              InputProps={{
                                readOnly: isViewPolicy,
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {selectFieldName && (
                        <Grid container alignItems={"center"} mt={2}>
                          <Grid
                            item
                            sm={2}
                            style={{
                              transform: "scaleX(-1)",
                            }}
                          >
                            <KeyboardReturnIcon />
                          </Grid>
                          <Grid item sm={7}>
                            <Controller
                              name="condition"
                              control={control}
                              render={({ field }) => (
                                <FormControl
                                  fullWidth
                                  style={{ margin: "10px" }}
                                >
                                  <InputLabel htmlFor="Select-condition">
                                    Select condition
                                  </InputLabel>
                                  <Select
                                    {...field}
                                    required
                                    label="Select condition"
                                    variant="outlined"
                                    inputProps={{
                                      id: "Select-condition",
                                      readOnly: isViewPolicy,
                                    }}
                                  >
                                    {selectedFieldOperator?.operators?.map(
                                      (option: any, index: number) => {
                                        return (
                                          <MenuItem key={index} value={option}>
                                            {option}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {showValueField && (
                        <>
                          <Grid container alignItems={"center"} mt={2}>
                            <Grid
                              item
                              sm={2}
                              style={{
                                transform: "scaleX(-1)",
                              }}
                            >
                              <KeyboardReturnIcon />
                            </Grid>
                            <Grid
                              item
                              sm={textDataTypeArray.includes(dataType) ? 10 : 7}
                            >
                              {dataType === "duration" && (
                                <Stack direction="row" alignItems="center">
                                  <Controller
                                    name="optional_data"
                                    control={control}
                                    render={({ field }) => (
                                      <FormControl
                                        style={{ margin: "10px", width: "50%" }}
                                      >
                                        <InputLabel htmlFor="Select-duration">
                                          Duration
                                        </InputLabel>
                                        <Select
                                          {...field}
                                          required
                                          label="Duration"
                                          variant="outlined"
                                          inputProps={{
                                            id: "Select-duration",
                                            readOnly: isViewPolicy,
                                          }}
                                        >
                                          <MenuItem key={"day"} value={"days"}>
                                            Day(s)
                                          </MenuItem>
                                          <MenuItem
                                            key={"Month"}
                                            value={"Months"}
                                          >
                                            Month(s)
                                          </MenuItem>
                                          <MenuItem
                                            key={"Years"}
                                            value={"Years"}
                                          >
                                            Years(s)
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    )}
                                  />
                                  <ControlledTextField
                                    name="value"
                                    control={control}
                                    required
                                    type="number"
                                    label="Enter value"
                                    inputProps={{
                                      readOnly: isViewPolicy,
                                    }}
                                  />
                                </Stack>
                              )}
                              {dataType === "date" && (
                                <ControlledTextField
                                  name="value"
                                  control={control}
                                  fullWidth
                                  required
                                  type="date"
                                  inputProps={{
                                    readOnly: isViewPolicy,
                                  }}
                                />
                              )}
                              {dataType === "numeric" && (
                                <ControlledTextField
                                  name="value"
                                  control={control}
                                  fullWidth
                                  required
                                  type="number"
                                  inputProps={{
                                    readOnly: isViewPolicy,
                                  }}
                                />
                              )}
                              {dataType === "percentage" && (
                                <ControlledTextField
                                  name="value"
                                  control={control}
                                  fullWidth
                                  type="number"
                                  required
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PercentIcon />
                                      </InputAdornment>
                                    ),
                                    readOnly: isViewPolicy,
                                  }}
                                />
                              )}
                              {dataType === "currency" && (
                                <ControlledTextField
                                  name="value"
                                  control={control}
                                  fullWidth
                                  type="number"
                                  required
                                  style={{ margin: "0 10px" }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <CurrencyRupeeIcon />
                                      </InputAdornment>
                                    ),
                                    readOnly: isViewPolicy,
                                  }}
                                />
                              )}
                              {textDataTypeArray.includes(dataType) && (
                                <Box sx={{ paddingX: "12px" }}>
                                  {renderTextInput()}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                  {scope &&
                    (textDataTypeArray.includes(dataType) ||
                      scope === "entire_contract") && (
                      <Grid container>
                        <Grid sm={scope === "entire_contract" ? 2 : 3} />
                        <Grid item sm={6}>
                          {rangeCondition.includes(selectedCondition) && (
                            <Controller
                              disabled={isViewPolicy}
                              name="similarity"
                              control={control}
                              render={({ field }) => (
                                <Slider
                                  step={5}
                                  marks={similarityArray}
                                  {...field}
                                />
                              )}
                            />
                          )}
                          <Controller
                            disabled={isViewPolicy}
                            name="case_sensitive"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <FormControlLabel
                                control={<Switch {...field} />}
                                label="Clauses are case sensitive"
                                labelPlacement="end"
                              />
                            )}
                          />
                          {triggerApprovalArray.includes(selectedCondition) &&
                            clauseValue?.length > 1 && (
                              <Controller
                                disabled={isViewPolicy}
                                name="include_all"
                                control={control}
                                defaultValue={false}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={<Switch {...field} />}
                                    label="Trigger approval if all values pass the condition"
                                    labelPlacement="end"
                                  />
                                )}
                              />
                            )}
                        </Grid>
                        <Grid sm={3} />
                      </Grid>
                    )}
                </>
              )}
              <Grid item sm={9}>
                <Typography marginBottom={2} fontWeight={"700"}>
                  Who will approve this?
                </Typography>
                <RISelectComponent
                  readOnly={isViewPolicy}
                  name="approvers"
                  control={control}
                  label="Select approvers"
                  options={approversData}
                  loading={approversLoading}
                  isMultiselect={true}
                  renderCustomComponent={(value: any, props) => (
                    <ShareChip key={value?.id} {...props} label={value?.name} />
                  )}
                />
                <Typography variant="caption">
                  You can select multiple approvers. All approvers must approve
                  in order for this rule to pass
                </Typography>
              </Grid>
              <Grid item sm={9}>
                <RadioButtonGroup
                  disabled={isViewPolicy}
                  name="approval_sequence"
                  row
                  options={approverSequence}
                  control={control}
                  isDescription
                  valueKey="value"
                  required
                />
              </Grid>
              {approvalType === "contract_request" && (
                <Grid item sm={9}>
                  <NotepadComponent
                    name="note_for_approvers"
                    control={control}
                    disabled={isViewPolicy}
                  />
                </Grid>
              )}
              <Grid item sm={9}>
                <RISelectComponent
                  readOnly={isViewPolicy}
                  name="groups"
                  control={control}
                  label="Prescribing Department"
                  options={groupListData}
                  loading={groupLoading}
                  isMultiselect={true}
                  renderCustomComponent={(value: any) => (
                    <CustomChip key={value?.id} label={value?.name} />
                  )}
                />
              </Grid>
              {approvalType === "contract_request" && (
                <>
                  <Grid item sm={9}>
                    <Box>
                      <Typography fontWeight={"700"}>
                        Define checklist
                      </Typography>
                      <Typography variant="caption">
                        These documents/checks will need to be completed before
                        signing
                      </Typography>
                    </Box>

                    {selectedChecklists?.length ? (
                      <FormControl disabled={isViewPolicy}>
                        <ApprovalChecklist list={selectedChecklists} />
                      </FormControl>
                    ) : (
                      <Typography
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          columnGap: "10px",
                          color: "#B0A7C0",
                          margin: "10px",
                        }}
                      >
                        <ErrorOutlineIcon /> No items defined yet. Click "Modify
                        list" button to add items
                      </Typography>
                    )}
                  </Grid>
                  {!isViewPolicy && (
                    <Grid item sm={3}>
                      <Button
                        onClick={() => {
                          setOpenChecklistDrawer(true);
                        }}
                        style={{ marginLeft: "20px" }}
                      >
                        <EditIcon /> <Typography ml={1}>Modify list</Typography>
                      </Button>
                    </Grid>
                  )}
                </>
              )}
              <Grid item sm={9}>
                <ControlledTextField
                  inputProps={{
                    readOnly: isViewPolicy,
                  }}
                  name="policy_name"
                  control={control}
                  required
                  label="Policy Name"
                  fullWidth
                  helperText="   The name will appear in your policy dashboard and in drafts
                  page."
                />
              </Grid>
              {!isViewPolicy && (
                <Grid item sm={9}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Stack spacing={2} direction="row">
                      <Button variant="contained" type="submit">
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={handleSubmit((data) => onSubmit(data, false))}
                      >
                        Save and create new policy
                      </Button>
                    </Stack>
                    <Button
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      onClick={() => handleOpen()}
                    >
                      Cancel
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
          </form>
          {isViewPolicy && setIsViewPolicy && (
            <Stack
              justifyContent="space-between"
              px={5}
              alignItems="center"
              direction="row"
              sx={{ width: "75%" }}
            >
              <Button
                variant="outlined"
                startIcon={<ModeOutlinedIcon />}
                onClick={() => setIsViewPolicy(false)}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </FormProvider>
      </CustomModal>
      {/* {openContractDrawer && (
        <ContractDrawer
          open={openContractDrawer}
          onClose={handleCloseContractDrawer}
          contractTypes={contractTypes}
        />
      )} */}
      {openChecklistDrawer && (
        <ChecklistDrawer
          open={openChecklistDrawer}
          onClose={handleCloseChecklistDrawer}
          selectedChecklist={selectedChecklists}
          setSelectedChecklist={setSelectedChecklists}
        />
      )}
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={handleClose}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to close this?"
      />
    </>
  );
};

export default CreateApprovalComponent;
