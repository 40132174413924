import { Button, Grid, Stack, Typography } from "@mui/material";

import React from "react";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import RadioButtonGroup from "../../../RiverusUI/Components/RadioButtonGroup";
import RISelectComponent from "../../../RiverusUI/Components/SelectComponent";
import colors from "../../../RiverusUI/Theme/colors";

interface Props {
  signatoriesData: any[];
  userTypes: any[];
  user_type: any;
  onSubmit: (data: any) => void;
  handleUserType: VoidFunction;
  control: any;
  handleSubmit: any;
}

const AddSignatories: React.FC<Props> = ({
  signatoriesData,
  userTypes,
  user_type,
  onSubmit,
  handleUserType,
  control,
  handleSubmit,
}) => {
  const handleClear = () => {
    handleUserType();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        spacing={1}
        sx={{ backgroundColor: colors?.riPrimary[20] }}
        borderRadius="10px"
        padding={2}
        marginBottom={2}
      >
        <Typography fontSize="16px" fontWeight="bold">
          Add signatories
        </Typography>

        <RadioButtonGroup
          name="user_type"
          control={control}
          row
          required
          options={userTypes}
          valueKey="value"
          onChange={handleUserType}
        />

        {user_type === "external" && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item sm={6}>
              <ControlledTextField
                name="name"
                control={control}
                label="Name"
                fullWidth
                required
              />
            </Grid>
            <Grid item sm={6}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email Address"
                fullWidth
                type="email"
                required
              />
            </Grid>
          </Grid>
        )}
        {user_type === "internal" && (
          <RISelectComponent
            name="name"
            control={control}
            label="Select user"
            options={signatoriesData}
            required
          />
        )}

        <Stack justifyContent="end" direction="row" marginTop="15px">
          <Button type="submit">Add signatory</Button>
          <Button onClick={handleClear}>Clear</Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default AddSignatories;
