import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { CLAUSE_DATA, getPara } from "./utils/ClauseTypeUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import { DurationEdit } from "./DurationEdit";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LinkPhraseRequest } from "../../State/documentState";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import { truncateString } from "../../../Utils/DataModifierUtil/dataModUtil";

export default function RenewalClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    durationList,
    hasData,
  } = props;
  const data = React.useMemo(
    () => clauseData?.renewal && clauseData.renewal,
    [clauseData?.renewal]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "renewal");
  }, [fileId, hasData]);

  const [isExpand, setExpand] = useState(false);
  const [counter, setCounter] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddAutoRenewal, setShowAddAutoRenewal] = useState(false);
  const [showAddAutoNoticeRenewal, setShowAddAutoNoticeRenewal] =
    useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);
  const [showAddDate, setShowAddDate] = useState(false);
  const [durationId, setDurationId] = useState<number>(-1);
  const [editDuration, setEditDuration] = useState(false);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(renewal, "Renewal Clause");
    setCounter(0);
  };

  const updates = useMemo(
    () => updatedClauseData?.renewal || {},
    [updatedClauseData]
  );

  const renewal = useMemo(() => {
    if (updates?.renewal) {
      let sortData = updates.renewal.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const autoRenewal = useMemo(() => {
    if (updates?.auto_renewal) {
      let sortData = updates.auto_renewal.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const noticeAutoRenewal = useMemo(() => {
    if (updates?.notice_auto_renewal) {
      let sortData = updates.notice_auto_renewal.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const referenceDate = useMemo(() => {
    if (updates?.reference_date) {
      let sortData = updates.reference_date.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const renewalDuration = useMemo(() => {
    if (updates?.renewal_duration) {
      let sortData = updates.renewal_duration.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationType === data.duration_type
        );
        if (index > -1) {
          const type = durationList[index].durationType;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const deleteClause = useCallback(
    (item: any) => {
      let renewal_parent = renewal?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let renewal_duration = renewalDuration?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let reference_date = referenceDate?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let auto_renewal = autoRenewal?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let notice_auto_renewal = noticeAutoRenewal?.filter(
        (data: any) => data.para_id !== item.para_id
      );

      let updatedData = {
        ...updates,
        renewal: renewal_parent,
        auto_renewal,
        notice_auto_renewal,
        reference_date,
        renewal_duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "renewal", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, renewal, data, fileId]
  );

  const deleteAutoRenewal = useCallback(
    (item: any) => {
      let auto_renewal = autoRenewal?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        auto_renewal,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "renewal", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, autoRenewal, data, fileId]
  );

  const deleteNoticeAutoRenewal = useCallback(
    (item: any) => {
      let notice_auto_renewal = noticeAutoRenewal?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        notice_auto_renewal,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "renewal", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, noticeAutoRenewal, data, fileId]
  );

  const deleteDuration = useCallback(
    (item: any) => {
      let renewal_duration = renewalDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        renewal_duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "renewal", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, renewalDuration, data, fileId]
  );

  const deleteDate = useCallback(
    (item: any) => {
      let reference_date = referenceDate?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        reference_date,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "renewal", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, referenceDate, data, fileId]
  );

  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let duration = renewalDuration.slice();
      duration[index] = data;
      let updatedData = {
        ...updates,
        renewalDuration: duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "renewal", diff, updatedData);
      }
    },
    [renewalDuration, fileId]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">{CLAUSE_DATA.renewal_clause["heading"]}</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", renewal);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${renewal && renewal.length}`
                  : renewal
                  ? renewal.length
                  : 0}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (renewal.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", renewal);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2 w-100 d-flex flex-column">
          <div className="d-flex flex-column w-100">
            <div className="d-flex flex-column w-100">
              {!showAdd ? (
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {renewal.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "Renewal clause",
                          renewal,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              ) : (
                <EditFeature
                  fileId={props.fileId}
                  toBeEdited={"Renewal clause"}
                  savedInsight={!isNullOrUndefined(renewal) ? renewal : []}
                  savedParentClauseDataPoint={renewal}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAdd(false);
                  }}
                  updatedClauseDataByType={updates}
                  clauseDataByType={data}
                  parentClauseType={"renewal"}
                />
              )}
            </div>
            <div className="mt-2">
              {renewal &&
                renewal?.map((item: any, index: number) => (
                  <div
                    className="clause-div"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        CLAUSE_DATA.renewal_clause["heading"]
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      {`${index + 1}. ${
                        item.para
                          ? item.para
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )
                      }`}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
            </div>
            {!showAddAutoRenewal ? (
              <div className="clause-sub-heading mt-2">
                <p className="m-0">
                  {CLAUSE_DATA.renewal_clause["auto_renewal"]}
                </p>
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Auto-Renewal",
                      autoRenewal,
                      setShowAddAutoRenewal
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited={"Auto-Renewal"}
                savedInsight={
                  !isNullOrUndefined(autoRenewal) ? autoRenewal : []
                }
                savedParentClauseDataPoint={autoRenewal}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAddAutoRenewal(false);
                }}
                updatedClauseDataByType={updates}
                clauseDataByType={data}
                parentClauseType={"renewal"}
              />
            )}
            <div className="mt-2">
              {autoRenewal &&
                autoRenewal?.map((item: any, index: number) => (
                  <div
                    className="clause-div"
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        CLAUSE_DATA.renewal_clause["auto_renewal"]
                      )
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      {`${index + 1}. ${
                        item.para
                          ? item.para
                          : getPara(
                              props?.sentenceData,
                              item.para_id,
                              item.table && item.table[0]?.row,
                              item.table && item.table[0]?.column
                            )
                      }`}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteAutoRenewal(item)}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div className="d-flex flex-column mt-2 w-100">
            {!showAddAutoNoticeRenewal ? (
              <div className="clause-sub-heading">
                <p className="m-0">
                  {CLAUSE_DATA.renewal_clause["notice_auto_renewal"]}
                </p>
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Renewal clause",
                      noticeAutoRenewal,
                      setShowAddAutoNoticeRenewal
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited={"Notice for Auto-Renewal"}
                savedInsight={
                  !isNullOrUndefined(noticeAutoRenewal) ? noticeAutoRenewal : []
                }
                savedParentClauseDataPoint={noticeAutoRenewal}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAddAutoNoticeRenewal(false);
                }}
                updatedClauseDataByType={updates}
                clauseDataByType={data}
                parentClauseType={"renewal"}
              />
            )}
            <div className="mt-2">
              {noticeAutoRenewal &&
                noticeAutoRenewal?.map((item: any, index: number) => (
                  <div
                    className="clause-div"
                    key={index}
                    onClick={() =>
                      props.onClickHighlight(
                        index,
                        item,
                        CLAUSE_DATA.renewal_clause["notice_auto_renewal"]
                      )
                    }
                  >
                    <p className="clause-data">{`${index + 1}. ${
                      item.phrase
                    }`}</p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteNoticeAutoRenewal(item)}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div className="d-flex flex-column mt-2 w-100">
            {!showAddDate ? (
              <div className="clause-sub-heading">
                <p className="m-0">
                  {CLAUSE_DATA.renewal_clause["notice_reference_date"]}
                </p>
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Renewal Notice Reference Date",
                      referenceDate,
                      setShowAddDate
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited={"Renewal Notice Reference Date"}
                savedInsight={
                  !isNullOrUndefined(referenceDate) ? referenceDate : []
                }
                savedParentClauseDataPoint={referenceDate}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAddDate(false);
                }}
                updatedClauseDataByType={updates}
                clauseDataByType={data}
                parentClauseType={"renewal"}
              />
            )}
            <div className="mt-2">
              {referenceDate &&
                referenceDate?.map((item: any, index: number) => (
                  <div key={index}>
                    <div
                      className="clause-div"
                      onClick={() =>
                        props.onClickHighlight(
                          index,
                          item,
                          CLAUSE_DATA.renewal_clause["notice_auto_renewal"]
                        )
                      }
                    >
                      <p className="clause-data">{`${index + 1}. ${new Date(
                        item.date
                      ).toDateString()}`}</p>
                      <img
                        className="cursor-pointer"
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteDate(item)}
                      />
                    </div>
                    <div className="clause-div mt-2">
                      <p className="clause-data">{item.phrase}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="d-flex flex-column mt-2 w-100">
            {!showAddDuration ? (
              <div className="clause-sub-heading">
                <p className="m-0">{CLAUSE_DATA.renewal_clause["duration"]}</p>
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Renewal Notice Duration",
                      renewalDuration,
                      setShowAddDuration
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited={"Renewal Notice Duration"}
                savedInsight={
                  !isNullOrUndefined(renewalDuration) ? renewalDuration : []
                }
                savedParentClauseDataPoint={renewalDuration}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAddDuration(false);
                }}
                updatedClauseDataByType={updates}
                clauseDataByType={data}
                parentClauseType={"renewal"}
              />
            )}
            <div className="mt-2">
              {renewalDuration &&
                renewalDuration.map((durationData: any, index: number) => (
                  <div key={index}>
                    {editDuration && durationId === index ? (
                      <DurationEdit
                        key={index}
                        durationList={durationList}
                        savedInsight={
                          !isNullOrUndefined(renewalDuration)
                            ? renewalDuration
                            : []
                        }
                        editPhrasesRequest={(
                          newPhraseRequest: LinkPhraseRequest
                        ) => {
                          editPhrase(newPhraseRequest, index);
                        }}
                        clearNormalizedEdit={() => setEditDuration(false)}
                        selectedDuration={durationData}
                      />
                    ) : (
                      <div
                        className={
                          editDuration && durationId === index
                            ? " mr-1 toc-content toc-content-active animate__animated animate__fadeInDown"
                            : " mr-1 toc-content toc-content-default animate__animated animate__fadeInDown"
                        }
                        style={{
                          border:
                            editDuration && durationId === index
                              ? "2px solid #882f5e"
                              : "",
                          marginBottom: "1px",
                        }}
                      >
                        <div
                          className="bi-clause-clickable"
                          onClick={() =>
                            props.onClickHighlight(
                              index,
                              durationData,
                              CLAUSE_DATA.renewal_clause["notice_auto_renewal"]
                            )
                          }
                        >
                          <div className="d-flex flex-row px-3">
                            <span style={{ color: "#4D4D4D" }}>
                              {index + 1}. &nbsp;&nbsp;
                            </span>
                            <span>
                              {durationData?.duration_value < 0 ? (
                                <img
                                  src="/static_images/empty-dash-grey-icn.svg"
                                  alt="empty"
                                />
                              ) : durationData?.duration_value > -1 &&
                                (
                                  durationData?.duration_value +
                                  " " +
                                  durationData.duration_type
                                ).length > 30 ? (
                                <DarkTooltip
                                  title={
                                    durationData?.duration_value +
                                    " " +
                                    durationData?.duration_type
                                  }
                                  placement={"right-end"}
                                >
                                  <>
                                    {truncateString(
                                      durationData?.duration_value +
                                        " " +
                                        durationData.duration_type,
                                      30
                                    )}
                                  </>
                                </DarkTooltip>
                              ) : (
                                `${durationData.duration_value} ${durationData.duration_type}`
                              )}
                              <DarkTooltip title={"Edit"} placement="right-end">
                                <img
                                  src="/static_images/new-edit-icon.svg"
                                  alt="edit"
                                  onClick={() => {
                                    setEditDuration(true);
                                    setDurationId(index);
                                  }}
                                  className="pl-2"
                                />
                              </DarkTooltip>
                            </span>
                            <img
                              className="cursor-pointer ml-auto "
                              style={{ zIndex: 2 }}
                              src="/static_images/delete-insight-icn.svg"
                              alt="delete-duration-insight"
                              data-toggle="modal"
                              data-target="#deleteInsightModal"
                              onClick={() => deleteDuration(durationData)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        editDuration && durationId === index
                          ? "mr-1 toc-content toc-content-active animate__animated animate__fadeInDown p-2 mb-1 clause-data"
                          : "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1 clause-data"
                      }
                      style={{
                        border:
                          editDuration && durationId === index
                            ? "2px solid #882f5e"
                            : "",
                      }}
                    >
                      {durationData.phrase}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
