import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import ShareChip from "../../../DocumentLibrary/Component/ShareModal/ShareChip";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import CustomModal from "../../../RiverusUI/Components/CustomModal";
import RISelectComponent from "../../../RiverusUI/Components/SelectComponent";
import { requestApproval } from "../../../Services/Draft";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface Props {
  open: boolean;
  onClose: VoidFunction;
  approversList: any[];
  cardData: any;
}

const RequestApproval: React.FC<Props> = ({
  open,
  onClose,
  approversList,
  cardData,
}) => {
  const { control, handleSubmit } = useForm();
  const queryClient = useQueryClient();

  const approversData = approversList?.map((data: any) => ({
    ...data,
    name: data.first_name + " " + data.last_name,
  }));

  const { mutate: request_approval } = useMutation({
    mutationKey: ["request_approval"],
    mutationFn: (payload: any) => {
      return requestApproval(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["fetch_Kdp_deviations"],
      });
      queryClient.invalidateQueries({
        queryKey: ["draft_approvals"],
      });
      onClose();
    },
  });

  const onSubmit = (data: any) => {
    const { condition, draft, id } = cardData;
    let priority: number = 0;
    const approversArray = data?.approvers?.map((approver: string) => {
      priority = priority + 1;
      return {
        id: approver,
        priority: priority,
      };
    });
    const payload = {
      approvers: approversArray,
      reason: data?.reason,
      approval_sequence: condition?.approval_sequence,
      // approval_type: condition?.approval_type,
      deviation: id,
      draft: draft,
    };
    request_approval(payload);
  };

  return (
    <CustomModal
      title="Remove selected text from the Issue"
      open={open}
      handleClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} marginY="15px">
          <Typography>Request approval for this Issue from:</Typography>
          <RISelectComponent
            name="approvers"
            control={control}
            label="Select approvers"
            options={approversData}
            isMultiselect={true}
            renderCustomComponent={(value: any, props) => (
              <ShareChip key={value?.id} {...props} label={value?.name} />
            )}
          />
          <Typography>Reason for approval</Typography>
          <ControlledTextField
            name="reason"
            control={control}
            required
            fullWidth
          />
          <Stack spacing={2} direction="row">
            <Button variant="contained" type="submit">
              Yes, Request Approval
            </Button>
            <Button variant="outlined" onClick={onClose}>
              No, Go Back
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default RequestApproval;
