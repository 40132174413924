import AnalysisState from "../../Analysis/State/analysisState";
import AppState from "../../App/State/appState";
import ClauseComponentReducer from "../../DocumentView/Component/ClauseComponent/Reducer/clauseComponentRed";
import ClauseComponentState from "../../DocumentView/Component/ClauseComponent/State/clauseComponentState";
import ClauseErrorManagementState from "../../UniversalComponents/Modals/DocumentLibraryClauseErrorModal/State/clauseErrorManagementState";
import ClauseLibraryState from "../../ClauseLibrary/State/clauseLibraryState";
import DashboardState from "../../Dashboard/State/dashboardState";
import DocumentLibraryState from "../../DocumentLibrary/State/documentLibraryState";
import DocumentState from "../../DocumentView/State/documentState";
import FilterModule from "../../Aggregates/State/AggregatesState";
import HeimdallReducer from "../../UniversalComponents/HeimdallChild/Reducer/heimdallReducer";
import HeimdallState from "../../UniversalComponents/HeimdallChild/State/heimdallState";
import HistoryState from "../../History/State/historyState";
import NewAnalysisState from "../../NewAnalysis/State/newAnalysisState";
import NewDashboardState from "../../NewDashboard/State/newDashboardState";
import NotificationState from "../../UniversalComponents/Notification/State/notificationState";
import ReportsState from "../../Reports/State/reportsState";
import SearchBarReducer from "../../UniversalComponents/SearchBar/Reducer/red";
import SearchBarState from "../../UniversalComponents/SearchBar/State/searchBarState";
import SearchModuleState from "../../SearchModule/State/searchModuleState";
import SearchState from "../../Search/State/SearchState";
import TaskManagementPageState from "../../TaskManagement/State/taskManagementPageState";
import TaskManagementState from "../../UniversalComponents/Modals/TaskManagementModal/State/taskManagementState";
import { TemplateState } from "../../Templates/State/templateState";
import UploadState from "../../Upload/State/uploadState";
import aggregatesReducer from "../../Aggregates/Reducer/AggregatesRed";
import analysisReducer from "../../Analysis/Reducer/red";
import appReducer from "../../App/Reducer/appReducer";
import clauseLibraryReducer from "../../ClauseLibrary/Reducer/clauseLibraryRed";
import clauserrorManagementReducer from "../../UniversalComponents/Modals/DocumentLibraryClauseErrorModal/Reducer/clauserrorManagementRed";
import { combineReducers } from "redux";
import dashboardReducer from "../../Dashboard/Reducer/dashboardRed";
import documentLibraryReducer from "../../DocumentLibrary/Reducer/documentLibraryRed";
import documentReducer from "../../DocumentView/Reducer/documentRed";
import historyReducer from "../../History/Reducer/historyRed";
import newAnalysisReducer from "../../NewAnalysis/Reducer/newAnalysisReducer";
import newDashboardReducer from "../../NewDashboard/Reducer/newDashboardReducer";
import notificationReducer from "../../UniversalComponents/Notification/Reducer/notificationRed";
import reportsReducer from "../../Reports/Reducer/reportsReducer";
import searchModuleReducer from "../../SearchModule/Reducer/searchModuleRed";
import searchReducer from "../../Search/Reducer/SearchRed";
import taskManagementPageReducer from "../../TaskManagement/Reducer/taskManagementPageRed";
import taskManagementReducer from "../../UniversalComponents/Modals/TaskManagementModal/Reducer/taskManagementRed";
import uploadReducer from "../../Upload/Reducer/uploadRed";

// import reactNotificationReducer from '../../UniversalComponents/ReactNotificationChild/Reducer/ReactNotificationReducer'
// import ReactNotificationState from "../../UniversalComponents/ReactNotificationChild/State/reactNotificationState";

export interface StoreTree {
  [x: string]: any;
  heimdall: HeimdallState;
  aggregates: FilterModule;
  upload: UploadState;
  document: DocumentState;
  app: AppState;
  searchBar: SearchBarState;
  search: SearchState;
  searchModule: SearchModuleState;
  historyModule: HistoryState;
  analysis: AnalysisState;
  dashboard: DashboardState;
  taskManagement: TaskManagementState;
  library: DocumentLibraryState;
  reports: ReportsState;
  taskManagementPage: TaskManagementPageState;
  clauseLibrary: ClauseLibraryState;
  notification: NotificationState;
  newAnalysis: NewAnalysisState;
  newDashboard: NewDashboardState;
  templateLibrary: TemplateState;
  clauserrorManagement: ClauseErrorManagementState;
  clauseComponent: ClauseComponentState;
}

export const mainReducer = combineReducers({
  heimdall: HeimdallReducer,
  aggregates: aggregatesReducer,
  upload: uploadReducer,
  document: documentReducer,
  app: appReducer,
  searchBar: SearchBarReducer,
  search: searchReducer,
  searchModule: searchModuleReducer,
  historyModule: historyReducer,
  analysis: analysisReducer,
  dashboard: dashboardReducer,
  library: documentLibraryReducer,
  reports: reportsReducer,
  taskManagement: taskManagementReducer,
  taskManagementPage: taskManagementPageReducer,
  clauseLibrary: clauseLibraryReducer,
  notification: notificationReducer,
  newAnalysis: newAnalysisReducer,
  newDashboard: newDashboardReducer,
  clauserrorManagement: clauserrorManagementReducer,
  clauseComponent: ClauseComponentReducer,
});
