import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import { DurationEdit } from "./DurationEdit";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LinkPhraseRequest } from "../../State/documentState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import { truncateString } from "../../../Utils/DataModifierUtil/dataModUtil";

export default function ConfidentialityClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    durationList,
    hasData,
  } = props;
  const data = React.useMemo(
    () => clauseData?.confidentiality && clauseData.confidentiality,
    [clauseData?.confidentiality]
  );

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "confidentiality");
  }, [hasData, fileId]);

  const [counter, setCounter] = useState(1);
  const [isExpand, setExpand] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);
  const [editDuration, setEditDuration] = useState(false);
  const [durationId, setDurationId] = useState<number>(-1);
  const [showAddNature, setShowAddNature] = useState(false);
  const [showAddException, setShowAddException] = useState(false);
  const [isChildDeleted, setChildDeleted] = React.useState(true);

  const updates = useMemo(
    () => updatedClauseData?.confidentiality,
    [updatedClauseData]
  );

  const confidentiality = useMemo(() => {
    if (updates?.confidentiality) {
      let sortData = updates.confidentiality.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const confidentialityDuration = useMemo(() => {
    if (updates?.duration) {
      let sortData = updates.duration.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationName === data.duration_type
        );
        if (index > -1) {
          const type = durationList[index].durationName;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const nature = useMemo(() => {
    if (updates?.nature) {
      let sortData = updates.nature.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const exceptionToConfidentiality = React.useMemo(() => {
    if (updates?.c_except) {
      let sortData = updates.c_except.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(confidentiality, "Confidentiality Clause");
    setCounter(0);
  };

  React.useEffect(() => {
    if (data && data?.edited_content === null && isChildDeleted) {
      let updatedData = {
        ...updates,
        duration: [],
        nature: [],
        c_except: [],
      };
      if (confidentiality && confidentiality.length) {
        confidentialityDuration?.map((parentData: any) => {
          let index = confidentiality.findIndex(
            (item: any) =>
              item.para_id !== parentData.para_id ||
              item.sentence_id !== parentData.sentence_id
          );
          if (index > -1) {
            confidentialityDuration?.splice(index, 1);
          }
        });
        nature?.map((parentData: any) => {
          let index = confidentiality.findIndex(
            (item: any) =>
              item.para_id !== parentData.para_id ||
              item.sentence_id !== parentData.sentence_id
          );
          if (index > -1) {
            nature?.splice(index, 1);
          }
        });
        exceptionToConfidentiality?.map((parentData: any) => {
          let index = confidentiality.findIndex(
            (item: any) =>
              item.para_id !== parentData.para_id ||
              item.sentence_id !== parentData.sentence_id
          );
          if (index > -1) {
            nature?.splice(index, 1);
          }
        });
        setChildDeleted(false);
        updatedData = {
          ...updates,
          duration: confidentialityDuration,
          nature,
          c_except: exceptionToConfidentiality,
        };
      }
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "confidentiality", diff, updatedData);
        getClauseDataByType(fileId, "confidentiality");
      }
    }
  }, [
    data,
    updates,
    exceptionToConfidentiality,
    confidentiality,
    confidentialityDuration,
    isChildDeleted,
  ]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let Confidentiality = confidentiality?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let confidentiality_duration = confidentialityDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );
      let confidentiality_nature = nature?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );
      let exceptionToConfidentiality_data = exceptionToConfidentiality?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );
      let updatedData = {
        ...updates,
        confidentiality: Confidentiality,
        duration: confidentiality_duration,
        nature: confidentiality_nature,
        c_except: exceptionToConfidentiality_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "confidentiality", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, confidentiality, data, fileId]
  );
  const deleteDuration = React.useCallback(
    (item: any) => {
      let duration = confidentialityDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "confidentiality", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, confidentialityDuration, data, fileId]
  );

  const deleteNature = React.useCallback(
    (item: any) => {
      let confidentiality_nature = nature?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id
      );
      let updatedData = {
        ...updates,
        nature: confidentiality_nature,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "confidentiality", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, nature, data, fileId]
  );
  const deleteExceptionToConfidentiality = React.useCallback(
    (item: any) => {
      let exceptionToConfidentiality_data = exceptionToConfidentiality?.filter(
        (data: any) => data.para_id !== item.para_id
      );
      let updatedData = {
        ...updates,
        c_except: exceptionToConfidentiality_data,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "confidentiality", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, exceptionToConfidentiality, data, fileId]
  );
  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let duration = confidentialityDuration.slice();
      duration[index] = data;
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "confidentiality", diff, updatedData);
      }
    },
    [confidentiality]
  );

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Confidentiality Clause</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", confidentiality);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${confidentiality?.length}`
                  : confidentiality?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (confidentiality?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", confidentiality);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {confidentiality.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "Confidentiality",
                          confidentiality,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="confidentiality"
                savedInsight={
                  !isNullOrUndefined(confidentiality) ? confidentiality : []
                }
                savedParentClauseDataPoint={confidentiality}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="confidentiality"
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {confidentiality?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "confidentiality")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-confidentiality"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>

            <div className="clause-sub-heading mt-2">
              <p className="m-0">Post Contract Duration</p>
              {!showAddDuration ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Confidentiality Duration",
                      confidentialityDuration,
                      setShowAddDuration
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={fileId}
                  toBeEdited="Confidentiality Duration"
                  savedInsight={
                    !isNullOrUndefined(confidentialityDuration)
                      ? confidentialityDuration
                      : []
                  }
                  savedParentClauseDataPoint={confidentialityDuration}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddDuration(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="confidentiality"
                />
              )}
            </div>
            {confidentialityDuration &&
              confidentialityDuration.map(
                (durationData: any, index: number) => (
                  <div key={index}>
                    {editDuration && durationId === index ? (
                      <DurationEdit
                        key={index}
                        durationList={durationList}
                        savedInsight={
                          !isNullOrUndefined(confidentialityDuration)
                            ? confidentialityDuration
                            : []
                        }
                        editPhrasesRequest={(
                          newPhraseRequest: LinkPhraseRequest
                        ) => {
                          editPhrase(newPhraseRequest, index);
                        }}
                        clearNormalizedEdit={() => setEditDuration(false)}
                        selectedDuration={durationData}
                      />
                    ) : (
                      <div
                        className={
                          editDuration && durationId === index
                            ? " mr-1 toc-content toc-content-active animate__animated animate__fadeInDown"
                            : " mr-1 toc-content toc-content-default animate__animated animate__fadeInDown"
                        }
                        style={{
                          border:
                            editDuration && durationId === index
                              ? "2px solid #882f5e"
                              : "",
                          marginBottom: "1px",
                        }}
                      >
                        <div
                          className="bi-clause-clickable"
                          onClick={() =>
                            props.onClickHighlight(
                              index,
                              durationData,
                              "Confidentiality Duration"
                            )
                          }
                        >
                          <div className="d-flex flex-row px-3">
                            <span style={{ color: "#4D4D4D" }}>
                              {index + 1}. &nbsp;&nbsp;
                            </span>
                            <span>
                              {durationData?.duration_value < 0 ? (
                                <img
                                  src="/static_images/empty-dash-grey-icn.svg"
                                  alt="empty"
                                />
                              ) : durationData?.duration_value > -1 &&
                                (
                                  durationData?.duration_value +
                                  " " +
                                  durationData.duration_type
                                ).length > 30 ? (
                                <DarkTooltip
                                  title={
                                    durationData?.duration_value +
                                    " " +
                                    durationData?.duration_type
                                  }
                                  placement={"right-end"}
                                >
                                  <>
                                    {truncateString(
                                      durationData?.duration_value +
                                        " " +
                                        durationData.duration_type,
                                      30
                                    )}
                                  </>
                                </DarkTooltip>
                              ) : (
                                `${durationData.duration_value} ${durationData.duration_type}`
                              )}
                              <DarkTooltip title={"Edit"} placement="right-end">
                                <img
                                  src="/static_images/new-edit-icon.svg"
                                  alt="edit"
                                  onClick={() => {
                                    setEditDuration(true);
                                    setDurationId(index);
                                  }}
                                  className="pl-2"
                                />
                              </DarkTooltip>
                            </span>
                            <img
                              className="cursor-pointer ml-auto "
                              style={{ zIndex: 2 }}
                              src="/static_images/delete-insight-icn.svg"
                              alt="delete-duration-insight"
                              data-toggle="modal"
                              data-target="#deleteInsightModal"
                              onClick={() => deleteDuration(durationData)}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className={
                        editDuration && durationId === index
                          ? "mr-1 toc-content toc-content-active animate__animated animate__fadeInDown p-2 mb-1"
                          : "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1"
                      }
                      style={{
                        border:
                          editDuration && durationId === index
                            ? "2px solid #882f5e"
                            : "",
                      }}
                    >
                      {durationData.phrase}
                    </div>
                  </div>
                )
              )}

            <div className="clause-sub-heading mt-2">
              <p className="m-0">Nature</p>
              {!showAddNature ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Confidentiality Nature",
                      nature,
                      setShowAddNature
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={fileId}
                  toBeEdited="Confidentiality Nature"
                  savedInsight={!isNullOrUndefined(nature) ? nature : []}
                  savedParentClauseDataPoint={nature}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddNature(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="confidentiality"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {nature?.map((natureItem: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, natureItem, "Nature")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {natureItem.phrase}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-nature-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteNature(natureItem)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>

            <div className="d-flex flex-column mt-2">
              <div className="clause-sub-heading">
                <p className="m-0">Exception to Confidentiality</p>
                {!showAddException ? (
                  <DarkTooltip
                    title={"Add"}
                    placement="right-end"
                    onClick={() => {
                      props.onEdit(
                        "ExceptionToConfidentiality",
                        0,
                        false,
                        [],
                        -1,
                        "",
                        exceptionToConfidentiality
                      );
                      setShowAddException(true);
                    }}
                  >
                    <img src="/static_images/add-icn.svg" alt="add" />
                  </DarkTooltip>
                ) : (
                  <EditFeature
                    fileId={props.fileId}
                    toBeEdited="Exception to Confidentiality"
                    savedInsight={
                      !isNullOrUndefined(exceptionToConfidentiality)
                        ? exceptionToConfidentiality
                        : []
                    }
                    savedParentClauseDataPoint={exceptionToConfidentiality}
                    editOptionSelected={(selected: boolean) => {
                      props.editOptionSelected(selected);
                    }}
                    childInEditId={props.childInEditId}
                    onClose={() => {
                      setShowAddException(false);
                    }}
                    clauseDataByType={data}
                    updatedClauseDataByType={updates}
                    parentClauseType="confidentiality"
                  />
                )}
              </div>
              <div className="mt-2">
                <Scrollable maxHeight={200}>
                  {exceptionToConfidentiality?.map(
                    (item: any, index: number) => (
                      <div
                        className="clause-div cursor-pointer"
                        key={index}
                        onClick={() =>
                          props.onClickHighlight(
                            index,
                            item,
                            "Exception to Confidentiality"
                          )
                        }
                      >
                        <p className="clause-data">
                          <span>{index + 1}.&nbsp;</span>
                          {item.sentence
                            ? item.sentence
                            : getPara(
                                props?.sentenceData,
                                item.para_id,
                                item.sentence_id,
                                item.table && item.table[0]?.row,
                                item.table && item.table[0]?.column
                              )}
                        </p>
                        <img
                          className="cursor-pointer"
                          style={{ zIndex: 2 }}
                          src="/static_images/delete-insight-icn.svg"
                          alt="delete-insight"
                          data-toggle="modal"
                          data-target="#deleteInsightModal"
                          onClick={() => deleteExceptionToConfidentiality(item)}
                        />
                      </div>
                    )
                  )}
                </Scrollable>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
