import colors from "./colors";

const modalTheme = {
  styleOverrides: {
    root: {
      "& .custom-modal": {
        width:"100%",
        height:"100vh",
        backgroundColor: "white", 
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        border: "1px solid #000",
        borderRadius: "12px",
        "overflow-y":"scroll",
        "& .modal-header": {
          backgroundColor: colors?.riPrimary?.main,
          borderRadius: "12px 12px 0 0 ",
          minHeight: "44px",
          width:"100%",
          padding:"0px"
        },
        "& .modal-content": {
          padding: "12px 16px",
          borderRadius: "0px 0px 12px 12px"
        },
      },
    },
  },
};
export default modalTheme;
