import { Chip, SvgIconProps } from "@mui/material";

import React from "react";

interface Props {
  icon?: React.ReactElement<SvgIconProps>;
  label: string;
}

const CustomChip: React.FC<Props> = ({ icon, label }) => {
  return (
    <Chip
      style={{
        background: "#FFECF1",
        padding: "15px 10px",
        borderRadius: "5px",
        color: "#6D264C",
      }}
      icon={icon}
      label={label}
    />
  );
};

export default CustomChip;
