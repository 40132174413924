import { getLocalStorage } from "../Authentication/Actions/authentication";

export const isRiverusAdmin = () => {
  const orgName = getLocalStorage("kcRealm");
  const adminRole = getLocalStorage("user_profile");
  const isAdmin = adminRole?.roles?.includes("admin");
  if (orgName === "Riverus" && isAdmin) {
    return true;
  }
  return false;
};
