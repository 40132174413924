import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";
import AxiosGateWay from "../../UniversalComponents/HeimdallChild/HeimdallUtil/axiosUtils";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());

export const updateClauses = (
  fileID: string,
  clauseType: string,
  payload: any
) => {
  const URL = `${SITEAPI}insights/${fileID}/${clauseType}/`;
  try {
    return AxiosGateWay.patch(URL, payload)
      .then((response) => response.data)
      .catch((error) => console.log(error));
  } catch (error) {}
};

export const updateContractData = (fileID: string, payload: any) => {
  console.log("In post function", fileID, payload);
  const URL = `${SITEAPI}${fileID}/`;
  return AxiosGateWay.patch(URL, payload)
    .then((response) => response)
    .catch((error) => console.log(error));
};
