import { Button, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import {
  uploadNewDocument,
  upload_file_in_s3_bucket,
} from "../../../Services/Draft";
import {
  uploadNewDocumentExternal,
  upload_file_in_s3_bucket_external,
} from "../../../ExternalUserFlow/Services/Draft";

import CustomModal from "../../../RiverusUI/Components/CustomModal";
import { LoadingButton } from "@mui/lab";
import MultipleUploadDoc from "./MultipleUploadDoc";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";

interface Props {
  open: boolean;
  onClose: VoidFunction;
  checklistId: string;
  reCallQuery: VoidFunction;
  documentList: any[];
  isExternal?: boolean;
}

const SparkMD5 = require("spark-md5");

const UploadDialog: React.FC<Props> = ({
  open,
  onClose,
  checklistId,
  reCallQuery,
  documentList,
  isExternal,
}) => {
  const [uploadedFile, setUploadedFile] = useState<any[]>([]);
  const { control } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnclose = () => {
    onClose();
    setUploadedFile([]);
  };
  const onUploadProgress = useCallback(
    (progressEvent: any) => {
      let reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      let file = uploadedFile[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [uploadedFile]
  );

  const { mutate: uploadDocInS3Bucket } = useMutation({
    mutationKey: ["upload_document_in_S3_bucket"],
    mutationFn: isExternal
      ? upload_file_in_s3_bucket_external
      : upload_file_in_s3_bucket,
    onSuccess: () => {
      reCallQuery();
      handleOnclose();
    },
    onError: () => {
      enqueueSnackbar("Failed to upload the document!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: uploadDocument, isLoading } = useMutation({
    mutationKey: ["upload_draft_document", uploadedFile],
    mutationFn: isExternal ? uploadNewDocumentExternal : uploadNewDocument,
    onSuccess: (response: any) => {
      if (response?.data) {
        let file = uploadedFile[0];
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadDocInS3Bucket({
            presignedPostData: response?.data?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: (error: any) => {
      let message = error?.response?.data?.__all__;
      enqueueSnackbar(message, {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const handleUploadFile = () => {
    const fileItem = uploadedFile?.[0];
    let reader = new FileReader();
    if (fileItem) {
      reader.readAsDataURL(fileItem);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        const payload = {
          file_name: fileItem?.name,
          file_hash: hexHash,
          file_size: fileItem?.size,
          draft_checklist: checklistId,
        };
        uploadDocument(payload);
      };
    }
  };

  const handleDeleteDocument = () => {
    reCallQuery();
    handleOnclose();
  };

  return (
    <CustomModal
      title="Upload for “Effective Virtual Teamwork”"
      open={open}
      handleClose={handleOnclose}
    >
      <Stack margin="30px 15px 20px 10px">
        <MultipleUploadDoc
          allowedFileTypes={[".pdf", ".docx"]}
          label="Upload file"
          name="upload_file"
          control={control}
          files={uploadedFile}
          setFiles={setUploadedFile}
          fileList={documentList}
          deleteUploadedFile={handleDeleteDocument}
          isExternal={isExternal}
        />
        <Stack direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            loading={isLoading}
            loadingPosition="start"
            variant="contained"
            onClick={() => handleUploadFile()}
          >
            Save and close
          </LoadingButton>
          <Button variant="outlined" onClick={() => handleOnclose()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default UploadDialog;
