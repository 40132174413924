import { LoadingButton } from "@mui/lab";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";
import CustomModal from "../../../RiverusUI/Components/CustomModal";

interface Props {
  open:boolean;
  onClose:VoidFunction;
  handleWithdrawEscalation:VoidFunction;
}

const WithdrawEscalationDialog: React.FC<Props> = ({open, onClose, handleWithdrawEscalation}) => {

  return (
    <CustomModal title="Withdraw escalation'" open={open} handleClose={onClose}>
      <Stack spacing={2} margin="15px">
        <Typography fontSize="14px" pt="20px">
          Are you sure you want to withdraw this escalation? The escalation will
          also be removed from the approver’s view
        </Typography>
        <Stack alignItems="start" direction="row">
          <LoadingButton loadingPosition="start" variant="contained" onClick={handleWithdrawEscalation}>
            Yes, Withdraw escalation
          </LoadingButton>
          <Button variant="outlined" onClick={() => onClose()} size="small">
            Cancel
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default WithdrawEscalationDialog;
