import {
  ContractDetails,
  CurrencyType,
  DurationType,
  LinkDateRequest,
  LinkDurationRequest,
  LinkEventRequest,
  LinkParaRequest,
  LinkPhraseRequest,
  LinkSentenceRequest,
  ParentClauseDataPoint,
  SentencesData,
  TagData,
  dateInfo,
  phraseInfo,
  sentenceInfo,
  tableInfo,
} from "../../../State/documentState";
import React, { Component } from "react";

import AssignmentInfoAddEdit from "./CustomAddEdit/assignmentInfoAddEdit";
import { BasicdocumentLibraryFileInfo } from "../../../../DocumentLibrary/State/documentLibraryState";
import BlurryLoader from "../../../../UniversalComponents/Loader/blurryLoader";
import { ClauseType } from "../../../Constants/ClauseType";
import Comment from "./BasicInformation/comment";
import ConsentAddEdit from "./CustomAddEdit/consentAddEdit";
import ConsentAuthorityAddEdit from "./CustomAddEdit/Consent/consentAuthorityAddEdit";
import ConsentRegulatoryAddEdit from "./CustomAddEdit/Consent/consentRegulatoryAddEdit";
import DefinitionAddEdit from "./CustomAddEdit/definitionAddEdit";
import Duration from "./TermDates/duration";
import EditEvents from "./Events/Container/editEventsCon";
import EditPhrases from "./Phrases/Container/editPhrasesCon";
import EditPresent from "./Present/editPresent";
import EditPresentSentence from "./Present/newEditPresentSentence";
import EditSinglePara from "./LockInPeriod/editSinglePara";
import EffectiveDate from "./TermDates/effectiveDate";
import EndDate from "./TermDates/endDate";
import LinkAmountPhrases from "./Link/linkAmountPhrases";
import LinkDurationPhrases from "./Link/linkDurationPhrases";
import LinkPhrases from "./Link/linkPhrasesCon";
import LinkPresentSentence from "./Link/linkPresentSentence";
import Parties from "./BasicInformation/parties";
import RenewalReferenceDate from "./TermDates/renewalReferenceDate";
import StartDate from "./TermDates/startDate";
import Tags from "./BasicInformation/Tags/tags";
import Title from "./BasicInformation/title";
import { isNullOrUndefined } from "is-what";
import EditTerminationAtConvenience from "./Present/editTerminationAtConvenience";

interface Props {
  toBeEdited: string;
  editOptionSelected: (editOptionSelected: boolean) => void;
  editDataPoint: (
    fileId: string,
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ) => void;
  listTagNature: () => void;
  listTagType: () => void;
  storeOtherTags: (storedOtherTags: any) => void;
  tagNature: TagData[];
  tagType: TagData[];
  otherTags: TagData[];
  listOtherTags: () => void;
  fileId: string;
  createOtherTags: (name: string) => void;
  savedInsight: any;
  editLoader: boolean;
  newTagData: TagData;
  storedOtherTags: any;
  insightsLoader: boolean;
  dataPointName: string;
  highlightedId: number[] | null;
  saveHighlightedDataPoint: (
    dataPointName: string,
    editOptionSelection: boolean
  ) => void;
  saveHighlightedId: (highlightedId: number[] | null) => void;
  savedHighlightedSentences: sentenceInfo[] | null;
  saveHighlightedSentences: (
    savedHighlightedSentences: sentenceInfo[] | null
  ) => void;
  savedHighlightedTableCells: tableInfo[] | null;
  saveHighlightedTableCells: (
    savedHighlightedTableCells: tableInfo[] | null
  ) => void;
  savedHighlightedDates: dateInfo[] | null;
  saveHighlightedDates: (savedHighlightedDates: dateInfo[] | null) => void;
  dateInAddEditMode: dateInfo | null;
  saveDateInAddEditMode: (dateInAddEditMode: dateInfo | null) => void;
  dateEditingStatus: boolean;
  saveDateEditingStatus: (dateEditingStatus: boolean) => void;
  otherTagsLoader: boolean;
  savedHighlightedPhrases: phraseInfo[] | null;
  saveHighlightedPhrases: (
    savedHighlightedPhrases: phraseInfo[] | null
  ) => void;
  phraseEditOptionSelected: boolean;
  savePhraseEditOption: (phraseEditOptionSelected: boolean) => void;
  phraseInDeleteMode: phraseInfo | null;
  phraseDeleteStatus: boolean;
  saveDeletePhrase: (
    phraseInDeleteMode: phraseInfo | null,
    phraseDeleteStatus: boolean
  ) => void;
  phraseInAddMode: phraseInfo | null;
  phraseAddStatus: boolean;
  saveAddPhrase: (
    phraseInAddMode: phraseInfo | null,
    phraseAddStatus: boolean
  ) => void;
  savedParentClauseDataPoint: ParentClauseDataPoint;
  childInEditId: number;
  durationList: DurationType[];
  currencyList: CurrencyType[];
  documentselectedFile: BasicdocumentLibraryFileInfo | null;
  contractData: ContractDetails;
  getContractData: (fileId: string) => void;
  otherClauses: (type: string) => void;
  getIndividualInsights: (clause: string, id: string) => void;
  onClose: any;
  onSubmit: any;
  clauseData: any;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: string;
  clauseItem: any;
  updateTitle: any;
  parentClauseData: any;
  updatedParentClauseDataByType: any;
}

interface State {
  editOptionSelection: boolean;
}

export default class EditFeature extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editOptionSelection: false,
    };
  }

  render() {
    let {
      highlightedId,
      newTagData,
      toBeEdited,
      insightsLoader,
      storedOtherTags,
      savedInsight,
      storeOtherTags,
      editOptionSelected,
      createOtherTags,
      listTagNature,
      listTagType,
      tagNature,
      tagType,
      listOtherTags,
      otherTags,
      editLoader,
      saveHighlightedId,
      saveHighlightedSentences,
      savedHighlightedSentences,
      savedHighlightedTableCells,
      saveHighlightedTableCells,
      savedHighlightedDates,
      saveHighlightedDates,
      dateInAddEditMode,
      saveDateInAddEditMode,
      dateEditingStatus,
      saveDateEditingStatus,
      otherTagsLoader,
      savedHighlightedPhrases,
      saveHighlightedPhrases,
      phraseEditOptionSelected,
      savePhraseEditOption,
      phraseInDeleteMode,
      phraseDeleteStatus,
      saveDeletePhrase,
      phraseInAddMode,
      phraseAddStatus,
      saveAddPhrase,
      savedParentClauseDataPoint,
      durationList,
      currencyList,
      documentselectedFile,
      getContractData,
      otherClauses,
      getIndividualInsights,
      clauseData,
      updatedClauseData,
      updatedClauseDataByType,
      clauseDataByType,
      parentClauseType,
    } = this.props;
    if (editLoader || insightsLoader) {
      return (
        <div className="row toc-content toc-content-default">
          <BlurryLoader />
        </div>
      );
    } else {
      switch (toBeEdited) {
        case "Title": {
          return (
            <Title
              // savedTitle={
              //   !isNullOrUndefined(savedInsight[0]) ? savedInsight[0].name : ""
              // }
              fileId={this.props.fileId}
              savedTitle={
                !isNullOrUndefined(savedInsight.title) ? savedInsight.title : ""
              }
              editOptionSelected={editOptionSelected}
              editTitle={(title) => this.addOrEditData(toBeEdited, title)}
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              nature={
                !isNullOrUndefined(savedInsight[0])
                  ? savedInsight[0].nature
                  : ""
              }
              updateTitle={this.props.updateTitle}
              onClose={this.props.onClose}
            />
          );
        }
        case "Tags": {
          return (
            <Tags
              storedOtherTags={storedOtherTags}
              storeOtherTags={storeOtherTags}
              newTagData={newTagData}
              otherTags={otherTags}
              listOtherTags={listOtherTags}
              editOptionSelected={editOptionSelected}
              listTagNature={listTagNature}
              createOtherTags={createOtherTags}
              tags={savedInsight.tags}
              listTagType={listTagType}
              tagNature={tagNature}
              tagType={tagType}
              editTags={(natureTagId, typeTagId, othersTagId) =>
                this.addOrEditTags(
                  natureTagId,
                  typeTagId,
                  othersTagId,
                  toBeEdited
                )
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              otherTagsLoader={otherTagsLoader}
              fileId={this.props.fileId}
              documentselectedFile={documentselectedFile}
              contractData={this.props.contractData}
              getcontractData={(fileId: string) => {
                getContractData(btoa(fileId));
                getIndividualInsights("basic_information", fileId);
              }}
              onClose={this.props.onClose}
            />
          );
        }
        case "Contracting Parties": {
          return (
            <>
              <Parties
                savedParties={savedInsight}
                editOptionSelected={editOptionSelected}
                editParties={(parties) =>
                  this.addOrEditData(toBeEdited, parties)
                }
                saveHighlightedId={(highlightedId: number[] | null) =>
                  saveHighlightedId(highlightedId)
                }
                fileId={this.props.fileId}
                clauseType={this.props.toBeEdited}
                wholeData={this.props.clauseData}
                postClauseDataByType={this.props.postClauseDataByType}
                updatedClauseData={this.props.updatedClauseData}
                sentenceData={this.props.sentenceData}
                clauseDataByType={this.props.clauseDataByType}
                updatedClauseDataByType={this.props.updatedClauseDataByType}
                parentClauseType={this.props.parentClauseType}
                onClose={this.props.onClose}
                clauseData={clauseData}
              />
            </>
          );
        }
        case "Term Start Dates": {
          return (
            <StartDate
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDates={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editStartDates={(startDates: LinkDateRequest) =>
                this.addOrEditData(toBeEdited, startDates)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              parentClauseType={this.props.parentClauseType}
              updatedClauseData={updatedClauseData}
            />
          );
        }
        case "Effective Dates": {
          return (
            <EffectiveDate
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDates={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editEndDates={(endDates: LinkDateRequest) =>
                this.addOrEditData(toBeEdited, endDates)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              clauseData={clauseData}
            />
          );
        }
        case "Renewal Notice Reference Date": {
          return (
            <RenewalReferenceDate
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPhrasesRequest={(newPhraseRequest: LinkPhraseRequest) =>
                this.addOrEditData(toBeEdited, newPhraseRequest)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              currencyList={currencyList}
              phraseInAddMode={phraseInAddMode}
              phraseAddStatus={phraseAddStatus}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseInDeleteMode={phraseInDeleteMode}
              saveAddPhrase={saveAddPhrase}
              saveHighlightedPhrases={saveHighlightedPhrases}
              savedHighlightedPhrases={savedHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              onClose={this.props.onClose}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              sentenceData={this.props.sentenceData}
              fileId={this.props.fileId}
              postClauseDataByType={this.props.postClauseDataByType}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              updatedClauseData={updatedClauseData}
            />
          );
        }

        case "Term End Dates": {
          return (
            <EndDate
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDates={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editEndDates={(endDates: LinkDateRequest) =>
                this.addOrEditData(toBeEdited, endDates)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseData={this.props.clauseData}
              updatedClauseData={updatedClauseData}
            />
          );
        }
        case "Term Duration": {
          return (
            <Duration
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedDuration={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editDuration={(duration: LinkDateRequest) =>
                this.addOrEditData(toBeEdited, duration)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedDates={savedHighlightedDates}
              saveHighlightedDates={(
                savedHighlightedDates: dateInfo[] | null
              ) => saveHighlightedDates(savedHighlightedDates)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              dateInAddEditMode={dateInAddEditMode}
              saveDateInAddEditMode={(dateInAddEditMode: dateInfo | null) =>
                saveDateInAddEditMode(dateInAddEditMode)
              }
              dateEditingStatus={dateEditingStatus}
              saveDateEditingStatus={(dateEditingStatus: boolean) =>
                saveDateEditingStatus(dateEditingStatus)
              }
              durationList={durationList}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseData={clauseData}
              updatedClauseData={updatedClauseData}
            />
          );
        }

        case "Termination clause":
        case "confidentiality":
        case "payment":
        case "Exception to Confidentiality":
        case "non_compete":
        case "Non-compete clause":
        case "Non-solicitation clause":
        case "Limitation Of Liability":
        case "notice":
        case "Notice Obligations":
        case "non_solicitation":
        case "exclusivity":
        case "limited_liability":
        case "change_of_control":
        case "Change of Control Termination":
        case "Change of Control Consent":
        case "Change of Control Notice":
        case "Change of Control Payment":
        case "obligation_statements":
        case "Other Obligations": {
          return (
            <EditPresentSentence
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresentSentences={(present: LinkSentenceRequest) =>
                this.addOrEditData(toBeEdited, present)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              otherClauses={(type: string) => otherClauses(type)}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              clauseData={clauseData}
              parentClauseType={parentClauseType}
            />
          );
        }
        case "Effective Dates Notify":
        case "Notice for Auto-Renewal":
        case "Non Compete Territory":
        case "Confidentiality Nature":
        case "Events Covered":
        case "Jurisdiction":
        case "Data Breach Regulation":
        case "Data Breach Notify":
        case "Dispute Resolution Panel":
        case "Dispute Resolution Venue":
        case "Dispute Resolution Act/Statute": {
          return (
            <EditPhrases
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPhrasesRequest={(newPhraseRequest: LinkPhraseRequest) =>
                this.addOrEditData(toBeEdited, newPhraseRequest)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
              clauseData={clauseData}
            />
          );
        }
        case "Auto-Renewal":
        case "Force Majeure":
        case "renewal":
        case "Renewal clause":
        case "Insurance clause":
        case "eventsOfDefault":
        case "term":
        case "Governing Law clause":
        //case "Termination at Convenience":
        case "Dispute Resolution":
        case "Dispute Resolution Conciliation":
        case "Dispute Resolution Mediation":
        case "Dispute Resolution Negotiation":
        case "Dispute Resolution Others":
        case "Dispute Resolution Arbitration":
        case "notice_renewal":
        case "insurance":
        case "governing_law":
        case "force_majeure":
        case "data_breach":
        case "indemnity":
        case "events_of_default":
        case "Change of Control Definition":
        case "dispute_resolution":
        case "Subletting/Assignment": {
          return (
            <EditPresent
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresent={(newParasRequest: LinkParaRequest) =>
                this.addOrEditData(toBeEdited, newParasRequest)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
            />
          );
        }
        case "Termination at Convenience": {
          return (
            <EditTerminationAtConvenience
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresent={(newParasRequest: LinkParaRequest) =>
                this.addOrEditData(toBeEdited, newParasRequest)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
              parentClauseData={this.props.parentClauseData}
              updatedParentClauseDataByType={this.props.updatedParentClauseDataByType}
            />
          );
        }
        case "Lock-in Period": {
          return (
            <EditSinglePara
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresent={(newParasRequest: LinkDurationRequest) =>
                this.addOrEditData(toBeEdited, newParasRequest)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseData={clauseData}
            />
          );
        }
        case "Termination Event":
        case "Events Of Default Event": {
          return (
            <EditEvents
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresent={(newLinkedEventsRequest: LinkEventRequest) =>
                this.addOrEditData(toBeEdited, newLinkedEventsRequest)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
            />
          );
        }

        case "Indemnity/Reimbursements/Costs Payer":
        case "Indemnity/Reimbursements/Costs Payee":
        case "Indemnity/Reimbursements/Costs Triggering Event":
        case "Indemnity/Reimbursements/Costs Extent of Cost": {
          return (
            <LinkPhrases
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPhrasesRequest={(newPhraseRequest: LinkPhraseRequest) =>
                this.addOrEditData(toBeEdited, newPhraseRequest)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
            />
          );
        }
        case "general_definitions": {
          return (
            <DefinitionAddEdit
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPhrasesRequest={(newPhraseRequest: LinkPhraseRequest) =>
                this.addOrEditData(toBeEdited, newPhraseRequest)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              savedHighlightedPhrases={savedHighlightedPhrases}
              saveHighlightedPhrases={saveHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              phraseInDeleteMode={phraseInDeleteMode}
              phraseInAddMode={phraseInAddMode}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseAddStatus={phraseAddStatus}
              saveAddPhrase={saveAddPhrase}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              clauseData={clauseData}
            />
          );
        }
        case "Consent Clause": {
          return (
            <ConsentAddEdit
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresentSentences={(present: LinkSentenceRequest) =>
                this.addOrEditData(toBeEdited, present)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        //case "Exception to Confidentiality":
        case "Subletting/Assignment Notice Info":
        case "Subletting/Assignment Consent Info": {
          return (
            <AssignmentInfoAddEdit
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              highlightedId={highlightedId}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresentSentences={(present: LinkSentenceRequest) =>
                this.addOrEditData(toBeEdited, present)
              }
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              otherClauses={(type: string) => otherClauses(type)}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseItem={this.props.clauseItem}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        case "Consent Regulatory": {
          return (
            <ConsentRegulatoryAddEdit
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsightChild={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresentSentences={(present: LinkSentenceRequest) =>
                this.addOrEditData(toBeEdited, present)
              }
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseItem={this.props.clauseItem}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        case "Consent Authority": {
          return (
            <ConsentAuthorityAddEdit
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsightChild={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresentSentences={(present: LinkSentenceRequest) =>
                this.addOrEditData(toBeEdited, present)
              }
              savedParentClauseDataPoint={savedParentClauseDataPoint}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              wholeData={this.props.clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={this.props.updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={this.props.clauseDataByType}
              updatedClauseDataByType={this.props.updatedClauseDataByType}
              parentClauseType={this.props.parentClauseType}
              clauseItem={this.props.clauseItem}
              onClose={this.props.onClose}
              clauseData={clauseData}
            />
          );
        }
        case "Subletting Duration":
        case "Consent Duration":
        case "Other Obligations - Post Contract Duration":
        case "Renewal Notice Duration":
        case "Non Compete Duration":
        case "Non Solicitation Duration":
        case "Confidentiality Duration":
        case "Payment Duration":
        case "Payment Timeline": {
          return (
            <LinkDurationPhrases
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPhrasesRequest={(newPhraseRequest: LinkPhraseRequest) =>
                this.addOrEditData(toBeEdited, newPhraseRequest)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              durationList={durationList}
              phraseInAddMode={phraseInAddMode}
              phraseAddStatus={phraseAddStatus}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseInDeleteMode={phraseInDeleteMode}
              saveAddPhrase={saveAddPhrase}
              saveHighlightedPhrases={saveHighlightedPhrases}
              savedHighlightedPhrases={savedHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              onClose={this.props.onClose}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              clauseData={clauseData}
              postClauseDataByType={this.props.postClauseDataByType}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
            />
          );
        }
        case "Indemnity/Reimbursements/Costs Amount":
        case "Amount":
        case "Capped":
        case "Uncapped":
        case "Limitation Of Liability Amount":
        case "Payment Amounts": {
          return (
            <LinkAmountPhrases
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedInsight={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPhrasesRequest={(newPhraseRequest: LinkPhraseRequest) =>
                this.addOrEditData(toBeEdited, newPhraseRequest)
              }
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              currencyList={currencyList}
              phraseInAddMode={phraseInAddMode}
              phraseAddStatus={phraseAddStatus}
              phraseDeleteStatus={phraseDeleteStatus}
              phraseInDeleteMode={phraseInDeleteMode}
              saveAddPhrase={saveAddPhrase}
              saveHighlightedPhrases={saveHighlightedPhrases}
              savedHighlightedPhrases={savedHighlightedPhrases}
              phraseEditOptionSelected={phraseEditOptionSelected}
              savePhraseEditOption={savePhraseEditOption}
              saveDeletePhrase={saveDeletePhrase}
              fileId={this.props.fileId}
              clauseType={this.props.toBeEdited}
              postClauseDataByType={this.props.postClauseDataByType}
              onClose={this.props.onClose}
              updatedClauseData={updatedClauseData}
              sentenceData={this.props.sentenceData}
              clauseDataByType={clauseDataByType}
              updatedClauseDataByType={updatedClauseDataByType}
              parentClauseType={parentClauseType}
              clauseData={clauseData}
            />
          );
        }
        case "Comment": {
          return (
            <Comment
              savedTitle={
                !isNullOrUndefined(savedInsight[0]) ? savedInsight[0].name : ""
              }
              editOptionSelected={editOptionSelected}
              editTitle={(title) => this.addOrEditData(toBeEdited, title)}
              saveHighlightedId={(highlightedId: number[] | null) =>
                saveHighlightedId(highlightedId)
              }
            />
          );
        }
        case "Prohibitions": {
          return (
            <LinkPresentSentence
              saveHighlightedDataPoint={(dataPointName: string) =>
                this.saveHighlightedDataPoint(dataPointName)
              }
              dataPointName={toBeEdited}
              savedPresent={savedInsight[0]?.para_id}
              savedPresentData={savedInsight}
              editOptionSelected={(editOptionSelected: boolean) =>
                this.editOptionSelected(editOptionSelected)
              }
              editPresentSentences={(present: LinkSentenceRequest) =>
                this.addOrEditData(toBeEdited, present)
              }
              savedHighlightedSentences={savedHighlightedSentences}
              saveHighlightedSentences={(
                savedHighlightedSentences: sentenceInfo[] | null
              ) => saveHighlightedSentences(savedHighlightedSentences)}
              savedHighlightedTableCells={savedHighlightedTableCells}
              saveHighlightedTableCells={(
                savedHighlightedTableCells: tableInfo[] | null
              ) => saveHighlightedTableCells(savedHighlightedTableCells)}
              onClose={this.props.onClose}
            />
          );
        }
        default: {
          return <div />;
        }
      }
    }
  }

  editOptionSelected(selection: boolean) {
    this.setState({ editOptionSelection: selection });
    this.props.editOptionSelected(selection);
  }

  saveHighlightedDataPoint(dataPointName: string) {
    this.props.saveHighlightedDataPoint(
      dataPointName,
      this.state.editOptionSelection
    );
  }

  addOrEditData(
    dataType: string,
    dataPointName: any,
    highlightedId?: number | null
  ) {
    let { editDataPoint, fileId } = this.props;
    let payload = [];
    if (dataPointName?.editedParas) {
      payload = dataPointName?.editedParas?.upsert?.map((item: any) => {
        return {
          table: [
            {
              row: item.rowId,
              column: item.columnId,
            },
          ],
          para_id: item.paraId,
          pattern: "",
          pattern_type: "",
          sentence_id: item.sentenceId ? item.sentenceId : -1,
        };
      });
    } else if (dataPointName?.editedSentences) {
      payload = dataPointName?.editedSentences?.upsert?.map((item: any) => {
        return {
          table: [
            {
              row: item.rowId,
              column: item.columnId,
            },
          ],
          para_id: item.paraId,
          pattern: "",
          pattern_type: "",
          sentence_id: item.sentenceId ? item.sentenceId : -1,
        };
      });
    }

    //this.props.onSubmit(payload);
  }

  addOrEditTags(
    natureTagId: string,
    typeTagId: string,
    othersTagId: string[],
    toBeEdited: string
  ) {
    this.addOrEditData(toBeEdited + ":Nature", natureTagId);
    this.addOrEditData(toBeEdited + ":Type", typeTagId);
    this.addOrEditData(toBeEdited + ":Others", othersTagId);
  }
}
