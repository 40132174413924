import { Skeleton, Stack } from "@mui/material";

import React from "react";

interface IProps {
  numberOfLines?: number;
}

const ListSkeleton: React.FC<IProps> = ({ numberOfLines = 6 }) => {
  return (
    <Stack>
      {Array(numberOfLines)
        .fill(0)
        .map((_, index: number) => (
          <Skeleton key={index} width="100%" height={50} />
        ))}
    </Stack>
  );
};

export default ListSkeleton;
