import colors from "./colors";
import components from "./components";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: colors,
  components: components,
});

export default theme;
