import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { deleteCustomDeviation } from "../../../Services/Draft";

import CheckIcon from "@mui/icons-material/Check";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { highlightedSentence } from "../Helper";
import WithdrawEscalationDialog from "./WithdrawEscalationDialog";
import { riPrimary } from "../../../RiverusUI/Theme/colors";

interface Props {
  cardData: any;
  instance: any;
}

const CustomDeviationCard: React.FC<Props> = ({ cardData, instance }) => {
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState<boolean>(false);
  const [deviationId, setDeviationId] = useState<string>("");
  const [showFullText, setShowFullText] = useState<any>({
    id: "",
    show: false,
  });

  const queryClient = useQueryClient();

  const handleCloseDialog = () => {
    setOpenWithdrawDialog(false);
  };

  const handleOpenDialog = (value: string) => {
    setOpenWithdrawDialog(true);
    setDeviationId(value);
  };

  const { mutate: removeCustomDeviations } = useMutation({
    mutationKey: ["remove_Custom_deviation"],
    mutationFn: (id: string) => {
      return deleteCustomDeviation(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["fetch_custom_deviations"],
      });
      handleCloseDialog();
    },
  });

  const handleWithdrawEscalation = () => {
    removeCustomDeviations(deviationId);
  };

  const handleSeeMoreAndLess = (text: string) => {
    const words = text.split(/\s+/);
    if (words?.length > 20) {
      const sentence = words.slice(0, 20).join(" ");
      return `${sentence}...`;
    } else {
      return words.join(" ");
    }
  };

  const handleToggle = (id: string) => {
    setShowFullText({
      id: id,
      show: !showFullText?.show,
    });
  };

  return (
    <>
      {cardData?.custom_datapoint?.map((item: any) => (
        <Box
          sx={{
            marginTop: "20px",
            padding: "15px",
            background: "#88305F24",
            borderRadius: "10px",
          }}
        >
          <Stack spacing={2}>
            <Typography fontSize="16px" fontWeight={700}>
              Issue created in {cardData?.condition?.condition}
            </Typography>

            <Typography fontSize="14px" fontWeight={700}>
              Text in the draft
            </Typography>
            <Box
              sx={{
                padding: "10px",
                background: "#FFF7FA",
                borderRadius: "10px",
              }}
            >
              <Typography fontSize="13px">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    highlightedSentence(item?.selectedValue, instance)
                  }
                >
                  {showFullText?.id === cardData?.id && showFullText?.show
                    ? item?.selectedValue
                    : handleSeeMoreAndLess(item?.selectedValue)}
                  {item?.selectedValue?.split(/\s+/)?.length > 20 && (
                    <span
                      onClick={() => handleToggle(cardData?.id)}
                      style={{ cursor: "pointer", color: riPrimary?.[500] }}
                    >
                      {showFullText?.id === cardData?.id && showFullText?.show
                        ? " See less"
                        : "See more"}
                    </span>
                  )}
                </span>
              </Typography>
            </Box>
            <Typography fontSize="14px" fontWeight={700}>
              Reason (context)
            </Typography>
            <Box
              sx={{
                padding: "10px",
                background: "#FFF7FA",
                borderRadius: "10px",
              }}
            >
              <Typography fontSize="13px">{item?.reason}</Typography>
            </Box>
            <Stack direction="row" justifyContent="end">
              {cardData?.approval_status === "approval_pending" && (
                <Button
                  style={{
                    padding: "5px 8px",
                  }}
                  variant="outlined"
                  onClick={() => handleOpenDialog(cardData?.id)}
                >
                  Withdraw escalation
                </Button>
              )}
              {cardData?.approval_status === "approved" && (
                <Chip
                  label="Approved"
                  icon={<CheckIcon />}
                  style={{ padding: "15px 5px" }}
                />
              )}
            </Stack>
          </Stack>
        </Box>
      ))}
      <WithdrawEscalationDialog
        open={openWithdrawDialog}
        onClose={handleCloseDialog}
        handleWithdrawEscalation={handleWithdrawEscalation}
      />
    </>
  );
};

export default CustomDeviationCard;
