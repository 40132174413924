import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";

export default function GeneralClause(props: ClauseComponentInterface) {
  const {
    getClauseDataByType,
    clauseData,
    fileId,
    updatedClauseData,
    postClauseDataByType,
    hasData,
  } = props;

  const [isExpand, setExpand] = useState(false);
  const [counter, setCounter] = useState(0);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "general_definitions");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.generalDefinitions && clauseData.generalDefinitions,
    [clauseData?.generalDefinitions]
  );

  const updates = React.useMemo(
    () => updatedClauseData?.generalDefinitions || {},
    [updatedClauseData]
  );

  const generalDefinitions = React.useMemo(() => {
    if (updates?.general_definitions) {
      let sortData = updates.general_definitions.sort(function (
        a: any,
        b: any
      ) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(
      data?.raw_content?.general_definitions,
      "General Definitions"
    );
    setCounter(0);
  };

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  const deleteClause = React.useCallback(
    (item: any) => {
      let general_definitions = generalDefinitions?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        general_definitions,
      };

      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(
          fileId,
          "general_definitions",
          diff,
          updatedData
        );
      }
    },
    [updates, postClauseDataByType, generalDefinitions, data, fileId]
  );

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && " toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">General Definitions</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", generalDefinitions);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${generalDefinitions?.length}`
                  : generalDefinitions?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (generalDefinitions?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", generalDefinitions);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {generalDefinitions.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "general_definitions",
                          generalDefinitions,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={props.fileId}
                toBeEdited="general_definitions"
                savedInsight={
                  !isNullOrUndefined(generalDefinitions)
                    ? generalDefinitions
                    : []
                }
                savedParentClauseDataPoint={generalDefinitions}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
              />
            )}
            <div className="mt-2">
              {generalDefinitions?.map((item: any, index: number) => (
                <div
                  className="cursor-pointer"
                  key={index}
                  onClick={() =>
                    props.onClickHighlight(index, item, "General Definitions")
                  }
                >
                  <div className="mr-1 toc-content toc-content-default animate__animated animate__fadeInDown bi-clause-clickable my-1">
                    <div className="d-flex flex-row px-3 py-2">
                      <span style={{ color: "#4D4D4D" }}>
                        {index + 1}. &nbsp;&nbsp;
                      </span>
                      <span>{item.defined_word}</span>
                      <img
                        className="cursor-pointer ml-auto "
                        style={{ zIndex: 2 }}
                        src="/static_images/delete-insight-icn.svg"
                        alt="delete-duration-insight"
                        data-toggle="modal"
                        data-target="#deleteInsightModal"
                        onClick={() => deleteClause(item)}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1"
                    }
                  >
                    {item.definition}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
