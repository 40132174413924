import React, { useEffect } from "react";

import { Box } from "@mui/system";
import ViewDraft from "./ViewExternalDraft";
import { setLocalStorage } from "../Authentication/Actions/authentication";
import { useLocation } from "react-router-dom";

const VerifyExternalUser = () => {
  const search = useLocation().search;
  const auth_token = new URLSearchParams(search).get("auth_token");
  const external_token = new URLSearchParams(search).get("external_token");
  const tab = new URLSearchParams(search).get("tab");
  const email = new URLSearchParams(search).get("email");

  useEffect(() => {
    if (auth_token && external_token) {
      setLocalStorage("external_auth_token", auth_token);
      setLocalStorage("external_token", external_token);
      setLocalStorage("external_email", email);
    }
  }, [auth_token, external_token, email]);

  return <Box>{tab && <ViewDraft activeTab={tab} />}</Box>;
};

export default VerifyExternalUser;
