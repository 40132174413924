import { ClauseType } from "../../../Constants/ClauseType";
import { clauseTypeData } from "../State/clauseComponentState";
import {
  GETCLAUSEDATABYTYPE,
  GetClauseDataByType,
  GetClauseDataByTypeSuccess,
  GETCLAUSEDATABYTYPE_SUCCESS,
  GetClauseDataByTypeFailure,
  GETCLAUSEDATABYTYPE_FAILURE,
  POSTCLAUSEDATABYTYPE,
  PostClauseDataByType,
  PostClauseDataByTypeSuccess,
  POSTCLAUSEDATABYTYPE_SUCCESS,
  POSTCLAUSEDATABYTYPE_FAILURE,
  PostClauseDataByTypeFailure,
  UPDATECLAUSEDATA,
  UpdateClauseData,
} from "./def";

export default class ClauseComponentActionGenerator {
  public static getClausDataByType(
    fileID: string,
    type: ClauseType
  ): GetClauseDataByType {
    return {
      type: GETCLAUSEDATABYTYPE,
      payload: {
        fileID: fileID,
        type: type,
      },
    };
  }
  public static getClausDataByTypeSuccess(
    clauseData: clauseTypeData,
    type: ClauseType
  ): GetClauseDataByTypeSuccess {
    return {
      type: GETCLAUSEDATABYTYPE_SUCCESS,
      payload: {
        clauseData: clauseData,
        type: type,
      },
    };
  }
  public static getClausDataByTypeFailure(): GetClauseDataByTypeFailure {
    return {
      type: GETCLAUSEDATABYTYPE_FAILURE,
    };
  }
  public static postClauseDataByType(
    fileID: string,
    type: ClauseType,
    diff: any,
    updatedClauseData: any
  ): PostClauseDataByType {
    return {
      type: POSTCLAUSEDATABYTYPE,
      payload: {
        fileID,
        type,
        diff,
        updatedClauseData,
      },
    };
  }

  public static postClauseDataByTypeSuccess(
    type: ClauseType,
    updatedClauseData: any
  ): PostClauseDataByTypeSuccess {
    return {
      type: POSTCLAUSEDATABYTYPE_SUCCESS,
      payload: {
        type,
        updatedClauseData,
      },
    };
  }

  public static postClauseDataByTypeFailure(): PostClauseDataByTypeFailure {
    return {
      type: POSTCLAUSEDATABYTYPE_FAILURE,
    };
  }

  public static UpdateClauseData(
    clauseData: any,
    type: ClauseType
  ): UpdateClauseData {
    return {
      type: UPDATECLAUSEDATA,
      payload: {
        clauseData,
        type,
      },
    };
  }
}
