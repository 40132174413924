import * as React from "react";

import { CurrencyType, LinkPhraseRequest } from "../../State/documentState";

import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";

interface Props {
  savedInsight: any;
  currencyList: CurrencyType[];
  dataPointName: string;
  editPhrasesRequest: (newPhraseRequest: LinkPhraseRequest) => void;
  clearNormalizedEdit: () => void;
  selectedAmount: any;
}

export const AmountEdit = (props: Props) => {
  const {
    currencyList,
    editPhrasesRequest,
    selectedAmount,
    clearNormalizedEdit,
  } = props;
  const [selectedOption, setSelectedOption] = React.useState<CurrencyType>({
    currencyTypeId: -1,
    currencyName: "",
    currencyType: "",
  });
  const [hideOptions, setHideOptions] = React.useState<boolean>(false);
  const [amount, setAmount] = React.useState<string>("");

  React.useEffect(() => {
    let newAmount = selectedAmount.total ? " " + selectedAmount.total : "";
    setAmount(newAmount);
    let newSelectedOption =
      (selectedAmount?.currency && selectedAmount?.currency) !== -1
        ? currencyList.filter(
            (item) => item.currencyName === selectedAmount.currency
          )[0]
        : selectedOption;
    setSelectedOption(newSelectedOption);
  }, []);

  const setAmountFun = React.useCallback(
    (currency: CurrencyType) => {
      setSelectedOption(currency);
      setHideOptions(false);
    },
    [setSelectedOption, setHideOptions]
  );

  const setAmountValue = React.useCallback(
    (event: any) => {
      let value = event.target.value;
      let amountString: string = "";
      if (value.length === 0) {
        amountString = "";
      } else {
        if (!isNaN(value) && value.length > 0) {
          let amountValue: number = Number(value);
          amountString = "" + amountValue;
        }
      }
      setAmount(amountString);
    },
    [setAmount]
  );

  const onCancel = React.useCallback(() => {
    clearNormalizedEdit();
    setAmount("");
    setHideOptions(false);
    setSelectedOption({
      currencyTypeId: -1,
      currencyName: "",
      currencyType: "",
    });
  }, [clearNormalizedEdit]);

  const onSave = React.useCallback(() => {
    const newData = selectedAmount;
    newData.value =
      selectedOption.currencyTypeId !== -1
        ? selectedOption.currencyType
        : selectedAmount?.value;
    newData.total = amount.length > 0 ? Number(amount) : selectedAmount.total;
    newData.currency =
      selectedOption.currencyTypeId !== -1
        ? selectedOption.currencyName
        : selectedAmount.currency;
    editPhrasesRequest(newData);
    clearNormalizedEdit();
  }, [amount, selectedOption]);

  return (
    <div className="row">
      <div className="col-md-12" id="currency-container">
        <div className="row">
          <div className="col-md-3 py-2" style={{ marginLeft: "10px" }}>
            Amount
          </div>
          <div className="col-md-3 py-2">
            <input
              type="text"
              className="currency-input"
              value={amount === "-1" ? "" : amount}
              placeholder="Enter no."
              onChange={(e) => setAmountValue(e)}
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-3 py-2">
            <span
              className="filter-modal-input"
              style={{ background: "white", border: "1px solid #DDDDD" }}
              onClick={() => setHideOptions(!hideOptions)}
            >
              <input
                type="text"
                className="modal-input"
                placeholder="Select currency"
                style={{ width: "100%", border: "none", outline: "none" }}
                value={selectedOption?.currencyName}
                readOnly
              />
              <img
                src="/static_images/tag-dropdown-active.svg"
                alt="dropdown"
                style={{
                  transform: hideOptions ? "rotate(180deg)" : "none",
                  zIndex: 1,
                }}
              />
            </span>
            {hideOptions && (
              <div
                className="col-md-12 filter-modal-autocomplete-container"
                style={{ margin: 0, paddingLeft: "2px" }}
              >
                <Scrollable maxHeight={100}>
                  {currencyList.map((currencyItem, i) => (
                    <div
                      className="filter-modal-input-suggestion cursor-pointer"
                      key={i}
                      onClick={() => setAmountFun(currencyItem)}
                    >
                      {currencyItem.currencyName}
                    </div>
                  ))}
                </Scrollable>
              </div>
            )}
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-10 align-right">
            <span
              className="add-datapoint"
              style={{ textDecoration: "none" }}
              onClick={onCancel}
            >
              Cancel
            </span>
            {amount.length > 0 && selectedOption?.currencyTypeId > -1 ? (
              <span
                className="upload-yellow-btn ml-4"
                id="save-btn"
                onClick={onSave}
              >
                Save
              </span>
            ) : (
              <span className="upload-disable-btn ml-4" id="save-btn">
                Save
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
