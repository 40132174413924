import { Box, Button, Divider, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface Props {
  options?: any[];
  buttonName?: any;
  changeButtonText?: Boolean;
  startIcon?: any;
  menuHeader?: string;
  setValue?: Dispatch<SetStateAction<string | undefined>>;
  handleDropdownChange?: (value: any) => void;
  disabled?: boolean;
}

const DropdownButton: React.FC<Props> = ({
  options,
  buttonName,
  changeButtonText = false,
  startIcon,
  menuHeader,
  disabled = false,
  setValue,
  handleDropdownChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonText, setButtonText] = useState<string>(
    buttonName || options?.[0].label
  );

  const iconToDisplay = options
    ? changeButtonText
      ? buttonText === options[0]?.menuOption
        ? options[0]?.startIcon
        : options[1]?.startIcon
      : startIcon
    : null; // Handle options being undefined

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (text: string, value: string) => {
    setValue?.(text);
    handleDropdownChange?.(value);
    if (changeButtonText) {
      setButtonText(text);
    }
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={iconToDisplay}
        endIcon={<ArrowDropDownIcon />}
        disabled={disabled}
      >
        {buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box width="200px">
          <Typography margin={1}>{menuHeader}</Typography>
          {options?.map((option, index) => (
            <>
              <MenuItem
                key={option.value}
                onClick={() => handleMenuItemClick(option.label, option.value)}
              >
                {option.startIcon}
                <Typography marginLeft={2}> {option.label}</Typography>
              </MenuItem>
              {index < options.length - 1 && <Divider />}
            </>
          ))}
        </Box>
      </Menu>
    </>
  );
};
export default DropdownButton;
