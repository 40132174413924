import * as changesets from "json-diff-ts";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import React, { useEffect, useMemo, useState } from "react";

import { ClauseComponentInterface } from "./interfaces/ClauseComponentIterface";
import { DarkTooltip } from "../documentInsights";
import { DurationEdit } from "./DurationEdit";
import EditFeature from "../EditFeature/Container/cont";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LinkPhraseRequest } from "../../State/documentState";
import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";
import { getPara } from "./utils/ClauseTypeUtils";
import { handleAddEdit } from "./Components/ClauseComponent";
import { isNullOrUndefined } from "is-what";
import { truncateString } from "../../../Utils/DataModifierUtil/dataModUtil";

export default function NonCompeteClause({
  getClauseDataByType,
  clauseData,
  fileId,
  updatedClauseData,
  postClauseDataByType,
  durationList,
  hasData,
  ...props
}: ClauseComponentInterface) {
  const [counter, setCounter] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const [showAddDuration, setShowAddDuration] = useState(false);
  const [showAddTerritory, setShowAddTerritory] = useState(false);
  const [durationId, setDurationId] = useState<number>(-1);
  const [editDuration, setEditDuration] = useState(false);

  useEffect(() => {
    hasData && getClauseDataByType(fileId, "non_compete");
  }, [fileId, hasData]);

  const data = React.useMemo(
    () => clauseData?.nonCompete && clauseData.nonCompete,
    [clauseData?.nonCompete]
  );

  const updates = useMemo(
    () => updatedClauseData?.nonCompete || {},
    [updatedClauseData]
  );

  const nonCompete = useMemo(() => {
    if (updates?.non_compete) {
      let sortData = updates.non_compete.sort(function (a: any, b: any) {
        return a.para_id - b.para_id || a.sentence_id - b.sentence_id;
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const nonCompeteDuration = useMemo(() => {
    if (updates?.duration) {
      let sortData = updates.duration.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      sortData.map((data: any) => {
        const index = durationList.findIndex(
          (list) => list.durationTypeId === data.duration_type_id
        );
        const typeIndex = durationList.findIndex(
          (list) => list.durationName === data.duration_type
        );
        if (index > -1) {
          const type = durationList[index].durationName;
          data.duration_type = type;
        }
        if (!data.duration_type_id && typeIndex > -1) {
          const id = durationList[typeIndex].durationTypeId;
          data.duration_type_id = id;
        }
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const territory = useMemo(() => {
    if (updates?.territory) {
      let sortData = updates.territory.sort(function (a: any, b: any) {
        return (
          a.para_id - b.para_id || a.start_sentence_id - b.start_sentence_id
        );
      });
      return sortData;
    }
    return [];
  }, [updates]);

  const toggleAccordion = () => {
    setExpand((prev) => !prev);
    props.onClickDataPoint(nonCompete, "non_compete");
    setCounter(0);
  };

  const deleteClause = React.useCallback(
    (item: any) => {
      let non_compete = nonCompete?.filter(
        (data: any) =>
          data.para_id !== item.para_id || data.sentence_id !== item.sentence_id
      );

      let duration = nonCompeteDuration?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );

      let non_compete_territory = territory?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.sentence_id
      );

      let updatedData = {
        ...updates,
        non_compete,
        duration,
        territory: non_compete_territory,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, nonCompete, data, fileId]
  );

  const deleteDuration = React.useCallback(
    (item: any) => {
      let duration = nonCompeteDuration?.filter(
        (data: any) =>
          data.para_id === item.para_id ||
          data.start_sentence_id === item.start_sentence_id ||
          data.start_word_id === item.start_word_id ||
          data.end_word_id === item.end_word_id
      );
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, nonCompeteDuration, data, fileId]
  );

  const deleteTerritory = React.useCallback(
    (item: any) => {
      let non_compete_territory = territory?.filter(
        (data: any) =>
          data.para_id !== item.para_id ||
          data.start_sentence_id !== item.start_sentence_id ||
          data.start_word_id! === item.start_word_id ||
          data.end_word_id! === item.end_word_id
      );
      let updatedData = {
        ...updates,
        territory: non_compete_territory,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [updates, postClauseDataByType, territory, data, fileId]
  );

  const editPhrase = React.useCallback(
    (data: any, index: number) => {
      let duration = nonCompeteDuration.slice();
      duration[index] = data;
      let updatedData = {
        ...updates,
        duration,
      };
      const diff = changesets.diff(data?.raw_content, updatedData, {
        children: "$index",
      });
      if (diff.length > 0) {
        postClauseDataByType?.(fileId, "non_compete", diff, updatedData);
      }
    },
    [nonCompeteDuration]
  );

  useEffect(() => {
    setShowAdd(false);
  }, [isExpand]);

  return (
    <div>
      <Accordion className="according-class" expanded={isExpand}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={`${
            isExpand && "toc-row toc-row-active active-color"
          } clause-heading`}
          onClick={toggleAccordion}
        >
          <div
            className="d-flex justify-content-between"
            style={{
              width: "80%",
            }}
          >
            <p className="m-0">Non-compete clause</p>
            <div className="d-flex align-items-center">
              {isExpand && (
                <AiOutlineArrowUp
                  size={16}
                  className="mr-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (counter > 1) {
                      setCounter((prev) => prev - 1);
                      props.onScroll("up", nonCompete);
                    }
                  }}
                />
              )}
              <p className="m-0">
                {isExpand
                  ? `${counter} / ${nonCompete?.length}`
                  : nonCompete?.length}
              </p>
              {isExpand && (
                <AiOutlineArrowDown
                  size={16}
                  className="ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (nonCompete?.length > counter) {
                      setCounter((prev) => prev + 1);
                      props.onScroll("down", nonCompete);
                    }
                  }}
                />
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="p-0 mt-2">
          <div className="d-flex flex-column w-100">
            {!showAdd ? (
              <div className="d-flex flex-column w-100">
                <div className="clause-present-heading">
                  <p className="m-0">Present</p>
                  <p className="m-0 font-weight-bold">
                    {nonCompete.length ? "Yes" : "No"}
                  </p>
                  <div>
                    {!hasData && (
                      <Tooltip
                        title={"Auto extraction failed. Manually review"}
                        style={{ marginRight: "8px" }}
                      >
                        <img src="/static_images/clause-info.svg"></img>
                      </Tooltip>
                    )}
                    <DarkTooltip
                      title={"Add"}
                      placement="right-end"
                      onClick={() => {
                        handleAddEdit(
                          props,
                          "non_compete",
                          nonCompete,
                          setShowAdd
                        );
                      }}
                    >
                      <img src="/static_images/add-icn.svg" alt="add" />
                    </DarkTooltip>
                  </div>
                </div>
              </div>
            ) : (
              <EditFeature
                fileId={fileId}
                toBeEdited="non_compete"
                savedInsight={!isNullOrUndefined(nonCompete) ? nonCompete : []}
                savedParentClauseDataPoint={nonCompete}
                editOptionSelected={(selected: boolean) => {
                  props.editOptionSelected(selected);
                }}
                childInEditId={props.childInEditId}
                onClose={() => {
                  setShowAdd(false);
                }}
                clauseDataByType={data}
                updatedClauseDataByType={updates}
                parentClauseType="non_compete"
              />
            )}
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {nonCompete?.map((item: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, item, "non_compete")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {item.sentence
                        ? item.sentence
                        : getPara(
                            props?.sentenceData,
                            item.para_id,
                            item.sentence_id,
                            item.table && item.table[0]?.row,
                            item.table && item.table[0]?.column
                          )}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-non-compete"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteClause(item)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Post Contract Duration</p>
              {!showAddDuration ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Non Compete Duration",
                      nonCompeteDuration,
                      setShowAddDuration
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={fileId}
                  toBeEdited="Non Compete Duration"
                  savedInsight={
                    !isNullOrUndefined(nonCompeteDuration)
                      ? nonCompeteDuration
                      : []
                  }
                  savedParentClauseDataPoint={nonCompeteDuration}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  onClose={() => {
                    setShowAddDuration(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="non_compete"
                />
              )}
            </div>
            {nonCompeteDuration &&
              nonCompeteDuration.map((durationData: any, index: number) => (
                <div key={index}>
                  {editDuration && durationId === index ? (
                    <DurationEdit
                      key={index}
                      durationList={durationList}
                      savedInsight={
                        !isNullOrUndefined(nonCompeteDuration)
                          ? nonCompeteDuration
                          : []
                      }
                      editPhrasesRequest={(
                        newPhraseRequest: LinkPhraseRequest
                      ) => {
                        editPhrase(newPhraseRequest, index);
                      }}
                      clearNormalizedEdit={() => setEditDuration(false)}
                      selectedDuration={durationData}
                    />
                  ) : (
                    <div
                      className={
                        editDuration && durationId === index
                          ? " mr-1 toc-content toc-content-active animate__animated animate__fadeInDown"
                          : " mr-1 toc-content toc-content-default animate__animated animate__fadeInDown"
                      }
                      style={{
                        border:
                          editDuration && durationId === index
                            ? "2px solid #882f5e"
                            : "",
                        marginBottom: "1px",
                      }}
                    >
                      <div
                        className="bi-clause-clickable"
                        onClick={() =>
                          props.onClickHighlight(
                            index,
                            durationData,
                            "Non Compete Duration"
                          )
                        }
                      >
                        <div className="d-flex flex-row px-3">
                          <span style={{ color: "#4D4D4D" }}>
                            {index + 1}. &nbsp;&nbsp;
                          </span>
                          <span>
                            {durationData?.duration_value < 0 ? (
                              <img
                                src="/static_images/empty-dash-grey-icn.svg"
                                alt="empty"
                              />
                            ) : durationData?.duration_value > -1 &&
                              (
                                durationData?.duration_value +
                                " " +
                                durationData.duration_type
                              ).length > 30 ? (
                              <DarkTooltip
                                title={
                                  durationData?.duration_value +
                                  " " +
                                  durationData?.duration_type
                                }
                                placement={"right-end"}
                              >
                                <>
                                  {truncateString(
                                    durationData?.duration_value +
                                      " " +
                                      durationData.duration_type,
                                    30
                                  )}
                                </>
                              </DarkTooltip>
                            ) : (
                              `${durationData.duration_value} ${durationData.duration_type}`
                            )}
                            <DarkTooltip title={"Edit"} placement="right-end">
                              <img
                                src="/static_images/new-edit-icon.svg"
                                alt="edit"
                                onClick={() => {
                                  setEditDuration(true);
                                  setDurationId(index);
                                }}
                                className="pl-2"
                              />
                            </DarkTooltip>
                          </span>
                          <img
                            className="cursor-pointer ml-auto "
                            style={{ zIndex: 2 }}
                            src="/static_images/delete-insight-icn.svg"
                            alt="delete-duration-insight"
                            data-toggle="modal"
                            data-target="#deleteInsightModal"
                            onClick={() => deleteDuration(durationData)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      editDuration && durationId === index
                        ? "mr-1 toc-content toc-content-active animate__animated animate__fadeInDown p-2 mb-1"
                        : "mr-1 toc-content toc-content-default animate__animated animate__fadeInDown p-2 mb-1"
                    }
                    style={{
                      border:
                        editDuration && durationId === index
                          ? "2px solid #882f5e"
                          : "",
                    }}
                  >
                    {durationData.phrase}
                  </div>
                </div>
              ))}
            <div className="clause-sub-heading mt-2">
              <p className="m-0">Territory</p>
              {!showAddTerritory ? (
                <DarkTooltip
                  title={"Add"}
                  placement="right-end"
                  onClick={() => {
                    handleAddEdit(
                      props,
                      "Non Compete Territory",
                      territory,
                      setShowAddTerritory
                    );
                  }}
                >
                  <img src="/static_images/add-icn.svg" alt="add" />
                </DarkTooltip>
              ) : (
                <EditFeature
                  fileId={fileId}
                  toBeEdited="Non Compete Territory"
                  savedInsight={!isNullOrUndefined(territory) ? territory : []}
                  savedParentClauseDataPoint={territory}
                  editOptionSelected={(selected: boolean) => {
                    props.editOptionSelected(selected);
                  }}
                  childInEditId={props.childInEditId}
                  durationList={durationList}
                  currencyList={props.currencyList}
                  contractData={props.contractData}
                  onClose={() => {
                    setShowAddTerritory(false);
                  }}
                  clauseDataByType={data}
                  updatedClauseDataByType={updates}
                  parentClauseType="non_compete"
                />
              )}
            </div>
            <div className="mt-2">
              <Scrollable maxHeight={200}>
                {territory?.map((territoryItem: any, index: number) => (
                  <div
                    className="clause-div cursor-pointer"
                    onClick={() =>
                      props.onClickHighlight(index, territoryItem, "Territory")
                    }
                    key={index}
                  >
                    <p className="clause-data">
                      <span>{index + 1}.&nbsp;</span>
                      {territoryItem.phrase}
                    </p>
                    <img
                      className="cursor-pointer"
                      style={{ zIndex: 2 }}
                      src="/static_images/delete-insight-icn.svg"
                      alt="delete-territory-insight"
                      data-toggle="modal"
                      data-target="#deleteInsightModal"
                      onClick={() => deleteTerritory(territoryItem)}
                    />
                  </div>
                ))}
              </Scrollable>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
