import { Chip, ChipProps } from "@mui/material";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import React from "react";

const chipStyle = {
  background: "#FFECF1",
  padding: "15px 10px",
  borderRadius: "5px",
  color: "#6D264C",
};

interface Props extends ChipProps {
  label?: string;
}

const ShareChip = (props: Props) => {
  let { label } = props;
  return (
    <Chip
      icon={<PersonOutlineOutlinedIcon />}
      label={label}
      sx={chipStyle}
      {...props}
    />
  );
};

export default ShareChip;
