import { Box, InputBase } from "@mui/material";

import React from "react";
import { Search as SearchIcon } from "@mui/icons-material";

interface IProps {
  handleChange?: any;
  placeholder?: string;
}

const SearchInputComponent = (props: IProps) => {
  const { handleChange, placeholder } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        background: "#88305F24",
        borderRadius: "25px",
        padding: "4px 8px 4px 10px",
      }}
    >
      <SearchIcon sx={{ color: "#49454F" }} />
      <InputBase
        sx={{ width: "90%", ml: "7px" }}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
        onChange={handleChange}
      />
    </Box>
  );
};

export default SearchInputComponent;
