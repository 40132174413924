import { inherits } from "util";
import {
  DocumentHierarchyData,
  LibraryTagFilterStructure,
  LibraryTagData,
  TagInfo,
  ParentFileInfo,
  ChildrenFileInfo,
  BasicFileInfo,
  LibraryStatistics,
  LibraryTagDataA,
  documentLibraryInfo,
  BasicdocumentLibraryFileInfo,
} from "../State/documentLibraryState";
import {
  AddParent,
  ADDPARENT,
  AddParent_Success,
  ADDPARENT_SUCCESS,
  ApplyGeneralFilterDL,
  ApplyGeneralFilterDLFailure,
  ApplyGeneralFilterDLSuccess,
  APPLYGENERALFILTERSDL,
  APPLYGENERALFILTERSDL_FAILURE,
  APPLYGENERALFILTERSDL_SUCCESS,
  ApplyLibraryTagFilters,
  APPLYLIBRARYTAGFILTERS,
  ApplyLibraryTagFiltersFailure,
  ApplyLibraryTagFiltersSuccess,
  APPLYLIBRARYTAGFILTERS_FAILURE,
  APPLYLIBRARYTAGFILTERS_SUCCESS,
  EditTags,
  EDITTAGS,
  EditTagsSuccess,
  EDITTAGS_SUCCESS,
  GetChildrenFileList,
  GETCHILDRENFILELIST,
  GetChildrenFileList_Failure,
  GETCHILDRENFILELIST_FAILURE,
  GETCHILDRENFILELIST_SUCCESS,
  GetChildrenFileList_Success,
  GetDocumentHierarchy,
  GETDOCUMENTHIERARCHY,
  GetDocumentHierarchyFailure,
  GetDocumentHierarchySuccess,
  GETDOCUMENTHIERARCHY_FAILURE,
  GETDOCUMENTHIERARCHY_SUCCESS,
  GetGeneralFilterDL,
  GetGeneralFilterDLFailure,
  GetGeneralFilterDLSuccess,
  GETGENERALFILTERSDL,
  GETGENERALFILTERSDL_FAILURE,
  GETGENERALFILTERSDL_SUCCESS,
  GetLibraryFileId,
  GETLIBRARYFILEID,
  GetLibraryFileIdFailure,
  GetLibraryFileIdSuccess,
  GETLIBRARYFILEID_FAILURE,
  GETLIBRARYFILEID_SUCCESS,
  GETLIBRARYTAGS,
  GetLibraryTags,
  GetLibraryTagsFailure,
  GetLibraryTagsSuccess,
  GETLIBRARYTAGS_FAILURE,
  GETLIBRARYTAGS_SUCCESS,
  GetParentFileList,
  GETPARENTFILELIST,
  GetParentFileList_Failure,
  GETPARENTFILELIST_FAILURE,
  GetParentFileList_Success,
  GETPARENTFILELIST_SUCCESS,
  REMOVEPARENT,
  RemoveParent,
  RemoveParentSuccess,
  REMOVEPARENT_SUCCESS,
  SaveCollapsedFileIds,
  SAVECOLLAPSEDFILEIDS,
  SAVEFILETAGDATA,
  SaveFileTagData,
  SAVELIBRARYTAGFILTERS,
  SaveLibraryTagFilters,
  SAVEMULTIPLESELECTEDFILES,
  SaveMultipleSelectedFiles,
  SaveSelectedFile,
  SAVESELECTEDFILE,
  SAVESELECTEDLIBRARYACTION,
  SaveSelectedLibraryAction,
  GetLibraryStatistics,
  GETLIBRARYSTATISTICS,
  GETLIBRARYSTATISTICS_SUCCESS,
  GetLibraryStatisticsSuccess,
  GetLibraryTagsA,
  GETLIBRARYTAGSA,
  GetLibraryTagsASuccess,
  GETLIBRARYTAGSA_SUCCESS,
  GETDOCUMENTLIBRARY_SUCCESS,
  GetDocumentLibraryDataSuccess,
  DOCUMENTSAVESELECTEDFILE,
  DocumentSaveSelectedFile,
  DOCUMENTSAVEMULTIPLESELECTEDFILES,
  DocumentSaveMultipleSelectedFiles,
  DOCUMENTAPPLYLIBRARYTAGFILTERS_SUCCESS,
  DocumentApplyLibraryTagFiltersSuccess,
} from "./def";

export default class DocumentLibraryGenerator {
  public static getLibraryFileId(
    sort: string,
    order: string
  ): GetLibraryFileId {
    return {
      type: GETLIBRARYFILEID,
      payload: {
        sort: sort,
        order: order,
      },
    };
  }
  public static getLibraryFileIdSuccess(
    initialFileIds: string[]
  ): GetLibraryFileIdSuccess {
    console.log("🚀 ~ file: gen.ts", initialFileIds);
    initialFileIds = initialFileIds;
    return {
      type: GETLIBRARYFILEID_SUCCESS,
      payload: {
        initialFileIds: initialFileIds,
      },
    };
  }
  public static getLibraryFileIdFailure(): GetLibraryFileIdFailure {
    return {
      type: GETLIBRARYFILEID_FAILURE,
    };
  }
  public static getLibraryTags(): GetLibraryTags {
    return {
      type: GETLIBRARYTAGS,
    };
  }
  public static getLibraryTagsA(): GetLibraryTagsA {
    return {
      type: GETLIBRARYTAGSA,
    };
  }
  public static getLibraryTagsSuccess(
    libraryTags: LibraryTagData[]
  ): GetLibraryTagsSuccess {
    return {
      type: GETLIBRARYTAGS_SUCCESS,
      payload: {
        libraryTags: libraryTags,
      },
    };
  }
  public static getLibraryTagsASuccess(
    libraryTagsA: LibraryTagDataA[]
  ): GetLibraryTagsASuccess {
    return {
      type: GETLIBRARYTAGSA_SUCCESS,
      payload: {
        libraryTagsA: libraryTagsA,
      },
    };
  }

  public static getLibraryTagsFailure(): GetLibraryTagsFailure {
    return {
      type: GETLIBRARYTAGS_FAILURE,
    };
  }
  public static getDocumentHierarchy(
    sort: string,
    order: string,
    fileIds: string[]
  ): GetDocumentHierarchy {
    return {
      type: GETDOCUMENTHIERARCHY,
      payload: {
        sort: sort,
        order: order,
        fileIds: fileIds,
      },
    };
  }
  public static getDocumentHierarchySuccess(
    documentHierarchy: DocumentHierarchyData[]
  ): GetDocumentHierarchySuccess {
    return {
      type: GETDOCUMENTHIERARCHY_SUCCESS,
      payload: {
        documentHierarchy: documentHierarchy,
      },
    };
  }

  public static getDocumentLibraryDataSuccess(
    documentLibraryData: documentLibraryInfo[]
  ): GetDocumentLibraryDataSuccess {
    return {
      type: GETDOCUMENTLIBRARY_SUCCESS,
      payload: {
        documentLibraryData: documentLibraryData,
      },
    };
  }

  public static getDocumentHierarchyFailure(): GetDocumentHierarchyFailure {
    return {
      type: GETDOCUMENTHIERARCHY_FAILURE,
    };
  }
  public static saveMultipleSelectedFiles(
    savedMultipleSelectedFiles: BasicFileInfo[]
  ): SaveMultipleSelectedFiles {
    return {
      type: SAVEMULTIPLESELECTEDFILES,
      payload: {
        savedMultipleSelectedFiles: savedMultipleSelectedFiles,
      },
    };
  }

  public static documentsaveMultipleSelectedFiles(
    documentsavedMultipleSelectedFiles: BasicdocumentLibraryFileInfo[]
  ): DocumentSaveMultipleSelectedFiles {
    return {
      type: DOCUMENTSAVEMULTIPLESELECTEDFILES,
      payload: {
        documentsavedMultipleSelectedFiles: documentsavedMultipleSelectedFiles,
      },
    };
  }
  public static saveCollapsedFileIds(
    savedCollapsedFileIds: string[]
  ): SaveCollapsedFileIds {
    return {
      type: SAVECOLLAPSEDFILEIDS,
      payload: {
        savedCollapsedFileIds: savedCollapsedFileIds,
      },
    };
  }
  public static saveLibraryTagFilters(
    savedLibraryTagFilters: TagInfo[]
  ): SaveLibraryTagFilters {
    return {
      type: SAVELIBRARYTAGFILTERS,
      payload: {
        savedLibraryTagFilters: savedLibraryTagFilters,
      },
    };
  }
  public static applyLibraryTagFilters(
    appliedLibraryTagFilters: LibraryTagFilterStructure[],
    initialFileIds: string[]
  ): ApplyLibraryTagFilters {
    return {
      type: APPLYLIBRARYTAGFILTERS,
      payload: {
        appliedLibraryTagFilters: appliedLibraryTagFilters,
        initialFileIds: initialFileIds,
      },
    };
  }
  public static applyLibraryTagFiltersSuccess(
    filteredFileIds: string[]
  ): ApplyLibraryTagFiltersSuccess {
    return {
      type: APPLYLIBRARYTAGFILTERS_SUCCESS,
      payload: {
        filteredFileIds: filteredFileIds,
      },
    };
  }

  public static documentapplyLibraryTagFiltersSuccess(
    documentfilteredFileIds: string[]
  ): DocumentApplyLibraryTagFiltersSuccess {
    return {
      type: DOCUMENTAPPLYLIBRARYTAGFILTERS_SUCCESS,
      payload: {
        documentfilteredFileIds: documentfilteredFileIds,
      },
    };
  }

  public static applyLibraryTagFiltersFailure(): ApplyLibraryTagFiltersFailure {
    return {
      type: APPLYLIBRARYTAGFILTERS_FAILURE,
    };
  }
  public static saveSelectedFile(
    selectedFile: BasicFileInfo | null
  ): SaveSelectedFile {
    return {
      type: SAVESELECTEDFILE,
      payload: {
        selectedFile: selectedFile,
      },
    };
  }
  public static documentsaveSelectedFile(
    documentselectedFile: BasicdocumentLibraryFileInfo | null
  ): DocumentSaveSelectedFile {
    return {
      type: DOCUMENTSAVESELECTEDFILE,
      payload: {
        documentselectedFile: documentselectedFile,
      },
    };
  }
  public static saveSelectedLibraryAction(
    selectedLibraryAction: string,
    isBulkAction: boolean
  ): SaveSelectedLibraryAction {
    return {
      type: SAVESELECTEDLIBRARYACTION,
      payload: {
        selectedLibraryAction: selectedLibraryAction,
        isBulkAction: isBulkAction,
      },
    };
  }
  public static editTags(
    editFileIds: string[],
    dataType: string,
    tagEditData: any,
    isBulkAction: boolean
  ): EditTags {
    return {
      type: EDITTAGS,
      payload: {
        editFileIds: editFileIds,
        dataType: dataType,
        tagEditData: tagEditData,
        isBulkAction: isBulkAction,
      },
    };
  }
  public static editTagsSuccess(): EditTagsSuccess {
    return {
      type: EDITTAGS_SUCCESS,
    };
  }
  public static saveFileTagData(savedFileTagData: any[]): SaveFileTagData {
    return {
      type: SAVEFILETAGDATA,
      payload: {
        savedFileTagData: savedFileTagData,
      },
    };
  }
  public static getParentFileList(
    selectedFileId: string,
    fileIds: string[],
    bulkFileIds: string[]
  ): GetParentFileList {
    return {
      type: GETPARENTFILELIST,
      payload: {
        selectedFileId: selectedFileId,
        fileIds: fileIds,
        bulkFileIds: bulkFileIds,
      },
    };
  }
  public static getParentFileListSuccess(
    savedParentFileList: ParentFileInfo[]
  ): GetParentFileList_Success {
    return {
      type: GETPARENTFILELIST_SUCCESS,
      payload: {
        savedParentFileList: savedParentFileList,
      },
    };
  }
  public static getParentFileListFailure(): GetParentFileList_Failure {
    return {
      type: GETPARENTFILELIST_FAILURE,
    };
  }
  public static addParent(
    childFileIds: string[],
    editedParentFileId: string
  ): AddParent {
    return {
      type: ADDPARENT,
      payload: {
        childFileIds: childFileIds,
        editedParentFileId: editedParentFileId,
      },
    };
  }
  public static addParentSuccess(): AddParent_Success {
    return {
      type: ADDPARENT_SUCCESS,
    };
  }
  public static getChildrenFileList(
    selectedFileId: string,
    fileIds: string[]
  ): GetChildrenFileList {
    return {
      type: GETCHILDRENFILELIST,
      payload: {
        selectedFileId: selectedFileId,
        fileIds: fileIds,
      },
    };
  }
  public static getChildrenFileListSuccess(
    savedChildrenFileList: ChildrenFileInfo[]
  ): GetChildrenFileList_Success {
    return {
      type: GETCHILDRENFILELIST_SUCCESS,
      payload: {
        savedChildrenFileList: savedChildrenFileList,
      },
    };
  }
  public static getChildrenFileListFailure(): GetChildrenFileList_Failure {
    return {
      type: GETCHILDRENFILELIST_FAILURE,
    };
  }
  public static removeParent(
    childFileIds: string[],
    editedParentFileId: string
  ): RemoveParent {
    return {
      type: REMOVEPARENT,
      payload: {
        childFileIds: childFileIds,
        editedParentFileId: editedParentFileId,
      },
    };
  }
  public static removeParentSuccess(): RemoveParentSuccess {
    return {
      type: REMOVEPARENT_SUCCESS,
    };
  }

  public static getGeneralFilterDL(fileIds: string[]): GetGeneralFilterDL {
    return {
      type: GETGENERALFILTERSDL,
      payload: {
        fileIds: fileIds,
      },
    };
  }

  public static getGeneralFilterDLSuccess(
    generalFilter: any
  ): GetGeneralFilterDLSuccess {
    return {
      type: GETGENERALFILTERSDL_SUCCESS,
      payload: {
        generalFilter: generalFilter,
      },
    };
  }

  public static getGeneralFilterDLFailure(): GetGeneralFilterDLFailure {
    return {
      type: GETGENERALFILTERSDL_FAILURE,
    };
  }

  public static applyGeneralFilterDL(
    fileIds: string[],
    filterType: string,
    sort: string,
    order: string
  ): ApplyGeneralFilterDL {
    return {
      type: APPLYGENERALFILTERSDL,
      payload: {
        filterIds: fileIds,
        filterType: filterType,
        sort: sort,
        order: order,
      },
    };
  }
  public static applyGeneralFilterDLSuccess(
    documentLibraryFileIds: string[]
  ): ApplyGeneralFilterDLSuccess {
    return {
      type: APPLYGENERALFILTERSDL_SUCCESS,
      payload: {
        documentLibraryFileIds: documentLibraryFileIds,
      },
    };
  }
  public static applyGeneralFilterDLFailure(): ApplyGeneralFilterDLFailure {
    return {
      type: APPLYGENERALFILTERSDL_FAILURE,
    };
  }
  public static getLibraryStatistics(): GetLibraryStatistics {
    return {
      type: GETLIBRARYSTATISTICS,
    };
  }

  public static getLibraryStatisticsSuccess(
    documentLibrarystatistics: LibraryStatistics[]
  ): GetLibraryStatisticsSuccess {
    console.log("🚀 ~ file: gen.ts", documentLibrarystatistics);
    documentLibrarystatistics = [
      {
        contracts_count: 6,
        contracts_with_task_count: 1,
        contracts_without_task_count: 5,
        total_contracts_size: "504 KB",
      },
    ];
    return {
      type: GETLIBRARYSTATISTICS_SUCCESS,
      payload: {
        documentLibrarystatistics: documentLibrarystatistics,
      },
    };
  }
}
