import { Box, Grid, Stack, Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  createDraft,
  editDraftDataExternal,
  getDraftById,
} from "./Services/Draft";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import ChecklistTab from "../Draft/Component/ChecklistTab/ChecklistTab";
import CompleteDraft from "../Draft/Component/CompleteDraft/CompleteDraft";
import DraftHeader from "../RiverusUI/Header/DraftHeader";
import { LoadingButton } from "@mui/lab";
import PdfView from "../Draft/Component/PdfView";
import SignatureTab from "../Draft/Component/SignatureTab/SignatureTab";
import { a11yProps } from "../RiverusUI/Components/CustomTabPanel";
import { removeLocalStorage } from "../Authentication/Actions/authentication";
import { riPrimary } from "../RiverusUI/Theme/colors";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: "20px",
  paddingX: "15px",
};

export const ActiveTabMap: { [key in any]: string } = {
  checklist: "Checklist",
  signature: "Signatures",
  complete_draft: "Complete Draft",
};

interface IProps {
  activeTab: string;
}

const ViewDraft: React.FC<IProps> = ({ activeTab }) => {
  const { id, version } = useParams<{ id: string; version: string }>();

  const [hideSignatureButton, setHideSignatureButton] =
    useState<boolean>(false);
  const [addedSignatureFields, setAddedSignatureFields] =
    useState<boolean>(false);
  const [dropPoint, setDropPoint] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(true);
  const [instance, setInstance] = useState<any>();
  const [canEdit, setCanEdit] = useState<boolean>(true);
  const [saveDraftLoading, setSaveDraftLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: draftData } = useQuery(
    ["get_draft_by_id_for_external", id],
    async () => {
      const response = await getDraftById(id);
      return response;
    },
    { enabled: !!id }
  );

  const fileLink = useMemo(() => draftData?.access_url, [draftData]);

  const { mutate: updateDraftData, isLoading: updatingDraft } = useMutation({
    mutationFn: editDraftDataExternal,
    onSuccess: (response: any) => {
      queryClient.invalidateQueries({
        queryKey: ["get_draft_by_id_for_external"],
      });
      enqueueSnackbar("Successfully updated draft data!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      instance?.UI.loadDocument(response.data.access_url);
      setLoaded(true);
      setTimeout(() => {
        removeLocalStorage("external_email");
        removeLocalStorage("external_token");
        removeLocalStorage("external_auth_token");
        window.close();
      }, 3000);
    },
    onError: () => {
      enqueueSnackbar("Failed to update draft data!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
  });

  const { mutate: createDraftMutation } = useMutation(
    ["create_draft"],
    async (payload: any) => createDraft(payload),
    {
      onSuccess: (response: any) => {
        queryClient.invalidateQueries({
          queryKey: ["get_draft_by_id_for_external"],
        });
        enqueueSnackbar("Draft edited successfully!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        instance?.UI.loadDocument(response?.data?.access_url);
        setLoaded(true);
        setTimeout(() => {
          removeLocalStorage("external_email");
          removeLocalStorage("external_token");
          removeLocalStorage("external_auth_token");
          window.close();
        }, 3000);
      },
      onError: () => {
        enqueueSnackbar("Failed to edit Draft!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  return (
    <React.Fragment>
      <Box>
        <DraftHeader
          version={parseInt(version)}
          name={draftData?.contractName}
          renderAction={() =>
            activeTab === "complete_draft" ? (
              <Stack direction="row">
                <LoadingButton
                  type="submit"
                  id="save_draft_button"
                  form="complete_draft_form"
                  loading={saveDraftLoading || updatingDraft}
                >
                  Save and Commit
                </LoadingButton>
              </Stack>
            ) : null
          }
          setCanEdit={setCanEdit}
        />
        <Grid container sx={{ mt: 2 }}>
          <Grid item sm={canEdit ? 12 : 6}>
            {loaded && (
              <PdfView
                key={fileLink}
                draftData={draftData}
                fileLink={fileLink}
                instance={instance}
                setInstance={setInstance}
                dropPoint={dropPoint}
                setDropPoint={setDropPoint}
                addedSignatureFields={addedSignatureFields}
                setAddedSignatureFields={setAddedSignatureFields}
                hideSignatureButton={hideSignatureButton}
                setHideSignatureButton={setHideSignatureButton}
                canEdit={canEdit}
              />
            )}
          </Grid>
          <Grid item sm={6} sx={{ paddingLeft: 3 }}>
            <Tabs
              value={activeTab}
              aria-label="Draft tabs"
              variant="scrollable"
              scrollButtons={false}
              sx={innerTabStyle}
            >
              <Tab
                label={ActiveTabMap[activeTab]}
                value={activeTab}
                {...a11yProps(activeTab)}
              />
            </Tabs>
            <Stack
              spacing={1}
              sx={{
                height: "90%",
                overflowY: "auto",
                backgroundColor: "riTertiary.50",
                borderRadius: "24px",
                padding: "8px",
                mt: "8px",
              }}
            >
              {activeTab === "complete_draft" && (
                <CompleteDraft
                  draftData={draftData}
                  instance={instance}
                  updateDraftData={updateDraftData}
                  isExternal
                  setSaveDraftLoading={setSaveDraftLoading}
                />
              )}
              {activeTab === "checklist" && (
                <ChecklistTab draftData={draftData} isExternal />
              )}
              {activeTab === "signature" && (
                <SignatureTab
                  createDraft={createDraftMutation}
                  draftData={draftData}
                  instance={instance}
                  dropPoint={dropPoint}
                  addedSignatureFields={addedSignatureFields}
                  hideSignatureButton={hideSignatureButton}
                  setAddedSignatureFields={setAddedSignatureFields}
                  updateDraftData={updateDraftData}
                  isExternal
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default ViewDraft;
