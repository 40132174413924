import React, { useState, useEffect, useRef } from "react";
import { AUTHURL } from "../../../Configuration/global";
import { useKeycloak } from "@react-keycloak/web";
import {
  removeLocalStorage,
  setLocalStorage,
  getLocalStorage,
} from "../../../Authentication/Actions/authentication";
import { History } from "history";
import { createBrowserHistory } from "history";
import { isNullOrUndefined } from "is-what";
const history = createBrowserHistory();
interface Props {
  logout: () => void;
  userName: string;
}

export default function UserInfo(props: Props) {
  const [profile, setProfile] = useState<any>({});
  const [groupName, setGroupName] = useState<string[]>([]);
  const [userRole, setUserRole] = useState<string[]>([]);
  let { logout, userName } = props;

  const { keycloak, initialized } = useKeycloak();
  const [isActive, setActive] = useState(false);
  const handleLogOut = async () => {
    await removeLocalStorage("accessToken");
    await removeLocalStorage("user_profile");
    await removeLocalStorage("user_id");
    await removeLocalStorage("user_name");
    await localStorage.clear();
  };
  useEffect(() => {
    const profile = getLocalStorage("user_profile");

    if (profile && profile.name) {
      setProfile(profile);
      setGroupName(profile.groupMembers);
      setUserRole(profile.roles);
    }
  }, []);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setActive(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className={"generic-web-menu-item mr-3"}
      onClick={() => setActive(isActive ? false : true)}
      onBlur={() => {
        setActive(false);
      }}
      id="user_info"
      ref={wrapperRef}
    >
      {!!keycloak.authenticated && (
        <>
          <img alt="org-icon" src="/static_images/user-me-icon-active.svg" style={{filter:"invert(42%) sepia(93%) saturate(1522%) hue-rotate(267deg) brightness(49%) contrast(119%)"}} />
          <br />
          {isActive && (
            <div className="detail-container">
              {localStorage.getItem("user_name") != null ? (
                <p style={{ backgroundColor: "#efefef" }}>
                  <b>{localStorage.getItem("user_name")}</b>{" "}
                </p>
              ) : (
                ""
              )}
              <RenderGroups groups={groupName} />
              <RenderRoles roles={userRole} />
              <p
                className="dropdown-signout"
                onClick={() => {
                  handleLogOut();
                  history.push("/");
                  keycloak.logout();
                  logout();
                  setLocalStorage("loginStatus", "finished");
                }}
              >
                Sign Out
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
interface stringProps {
  groups?: string[];
  roles?: string[];
}
const RenderGroups = (props: stringProps) => {
  const { groups } = props;
  if (groups && groups.length > 0) {
    return (
      <p className="debug" style={{ backgroundColor: "#efefef" }}>
        {groups.map((a) => a.substr(1)).join(", ")}
      </p>
    );
  } else return <></>;
};
const RenderRoles = (props: stringProps) => {
  const { roles } = props;
  if (roles && roles.length > 0) {
    return (
      <p className="debug" style={{ backgroundColor: "#efefef" }}>
        {roles.join(", ")}
      </p>
    );
  } else return <></>;
};
