export interface ContractData {
  projectNames: string[];
  contractName: string;
  contractType: any;
  counterParties: CounterParty[];
  owner: string[] | null;
  created_on: string;
  status: string;
  deadline: string;
  link: string;
  signatories: any[];
  approvalTypes: any[];
  collaborators: any[];
  createFrom: string;
  source: string;
  draftID: string | null;
  version: number;
  groups: any[];
  notes: string;
  optional_field: any;
  file_name: string;
  filehash: string;
}

export interface DraftsStateType {
  id: string;
  createFrom: string;
  contractName: string;
  projectNames: string[];
  contractType: string;
  counterParties: CounterParty[];
  collaborators: string[];
  approvers: any[];
  approverSequence: string;
  signatories: any[];
  notifiers: any[];
  created_on: string;
  status: string;
  deadline: string;
  link: string;
  source: string;
  created_by: any;
  version: number;
  lastUpdated: string;
  draftID: string;
  docID: string;
  notes: string;
  approvalTypes: any[];
}

export interface DraftsModule {
  draftsData: DraftsStateType[];
  isLoading: boolean;
  draftsFetched: boolean;
}

export function defaultDraftsState(): DraftsModule {
  return {
    draftsData: [],
    isLoading: false,
    draftsFetched: false,
  };
}

export interface ContractRes {
  id: string;
  approval_remaining_count: number;
  projectNames: string[];
  contractName: string;
  contractType: any;
  counterParties: CounterParty[];
  created_on: string;
  status: string;
  deadline: string;
  link: string;
  access_url: string;
  signatories: any[];
  approvalTypes: any[];
  collaborators: any[];
  createFrom: string;
  created_by: CreatedBy;
  source: string;
  version: number;
  draftID: string | null;
  docID: string | null;
  notes: string | null;
  optional_field: any;
  requisition_id: any;
  requisition_approver_link: any;
  support_document: any;
  approval_status: string | null;
  pre_signatories: any[] | null;
  groups: string[];
}

interface CreatedBy {
  first_name: string;
  groups: string[];
  last_name: string;
  username: string;
  id: string;
}

export interface CounterParty {
  orgName: string;
  orgContactName: string;
  email: string;
  isExternalSignatory: boolean;
  // manually:any,
  disable: boolean;
}

export interface Signatory {
  contactName: string;
  email: string;
}

export const initialContractData: ContractData = {
  projectNames: [],
  contractName: "",
  contractType: "",
  counterParties: [
    {
      orgContactName: "",
      orgName: "",
      disable: false,
      email: "",
      isExternalSignatory: false,
    },
  ],
  owner: null,
  created_on: new Date().toLocaleDateString(),
  status: "",
  deadline: "",
  link: "",
  signatories: [],
  approvalTypes: [],
  collaborators: [],
  createFrom: "",
  source: "",
  draftID: null,
  version: 0,
  groups: [],
  notes: "",
  optional_field: null,
  file_name: "",
  filehash: "",
};

export interface File {
  name: string;
  size: number;
  type: string;
  hash?: string;
  status?: string;
  template_type: string;
  file_type: string;
  ref_draft_id?: string;
  // progress: number;
}

export interface FileForGettingURL {
  file_name: String;
  file_size: Number;
  file_hash: String;
  template_type: string;
  file_type: string;
  creation_type: string;
}
export interface PresignedDataField {
  key: string;
  AWSAccessKeyId: string;
  policy: string;
  signature: string;
}
export interface PresignedData {
  url: string;
  fields: PresignedDataField;
}

export interface User {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  groups: any[];
  email: string;
}

export const defaultUser: User = {
  id: "",
  username: "",
  first_name: "",
  last_name: "",
  groups: [""],
  email: "",
};

export interface ErrorValidation {
  draftName: Validation;
  contractType: Validation;
  projectName: Validation;
  counterParty: Validation;
  collaborators: Validation;
  approvers: Validation;
  signatories: Validation;
  deadline: Validation;
  // notifiers: Validation;
  templateData: Validation;
  approverSequence: Validation;
  earlierdraft: Validation;
}

export interface Validation {
  showError: boolean;
  errorMsg?: string;
}

export const defaultErrorValidation: ErrorValidation = {
  draftName: { showError: false, errorMsg: "Draft name is required." },
  contractType: { showError: false, errorMsg: "Contract type is required." },
  projectName: { showError: false, errorMsg: "Project name is required." },
  counterParty: {
    showError: false,
    errorMsg: "Atleast 2 Counter party is required.",
  },
  collaborators: { showError: false, errorMsg: "Collaborator is required." },
  approvers: { showError: false, errorMsg: "Approver is required." },
  signatories: { showError: false, errorMsg: "Signatory is required." },
  deadline: { showError: false, errorMsg: "Deadline is required." },
  // notifiers: { showError: false, errorMsg: 'Notifier is required.' },
  templateData: { showError: false, errorMsg: "Template is required" },
  approverSequence: { showError: false, errorMsg: "Sequence is required" },
  earlierdraft: { showError: false, errorMsg: "Earlier draft is required" },
};

export interface Sort {
  sortKey: string;
  sortType: string;
  valueType: string;
}

export type FilterData = {
  [name: string]: string[];
};

export interface Approvers {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
}

export interface DeviationLogs {
  id: string;
  action_by: CreatedBy;
  type: string;
  type_id: string;
  time: string;
  action: string;
  annotation_text: string;
}

export enum draftStatus {
  REQUISITION_APPROVED = "Requisition Approved",
  INTERNAL_DRAFT_CREATED = "Internal Draft Created",
  DRAFT_CREATION_PENDING = "Draft Creation Pending",
  REQUISITION_APPROVAL_PENDING = "Requisition Approval Pending",
  COUNTER_PARTY_DRAFT_CREATED = "Counter Party Draft Created",
  PRE_SIGNATORIES_APPROVAL_PENDING = "Pre Signatories Approval Pending",
  PRE_SIGNATORIES_APPROVED = "Pre Signatories Approved",
  PRE_SIGNATORIES_REJECTED = "Pre Signatories Rejected",
  CONTRACT_EXECUTED_SUCCESSFULLY = "Contract Executed Successfully",
  COMMITTED_FOR_SIGNATURE = "Committed for signature",
  SIGNATURE_PENDING = "Signature pending",
  SIGNATURE_ABORTED = "Signature aborted",
}

export interface AddComment {
  ref_line: string;
  html_structure: string;
  ref_draft: string;
  user_details: string[];
  support_docs: string[];
  annotation?: string;
  for_annotation?: boolean;
  internal_only: boolean;
  text_comment: string;
}

export interface AddReply {
  ref_line: string;
  html_structure: string;
  parent: string;
  ref_draft: string;
  user_details: string[];
  support_docs: string[];
}

export interface AddAnnotation {
  html_structure: string;
  ref_draft: string;
  proposed_to: string;
  status: string;
}
