import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { draftDeviation, requestApproval } from "../../../Services/Draft";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RISelectComponent from "../../../RiverusUI/Components/SelectComponent";
import RadioButtonGroup from "../../../RiverusUI/Components/RadioButtonGroup";
import ShareChip from "../../../DocumentLibrary/Component/ShareModal/ShareChip";
import { approverSequence } from "../../../Approvals/Component/CreateApprovalForm/CreateApproval";
import { enqueueSnackbar } from "notistack";
import { fetchApprovers } from "../../../Services/Approval";
import { useForm } from "react-hook-form";

interface Props {
  draftId: string;
  instance: any;
}

const AddCustomIssue: React.FC<Props> = ({ draftId, instance }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const { control, handleSubmit, resetField } = useForm();

  const handleClose = () => {
    setExpanded(false);
    resetField("reason");
    resetField("approvers");
    resetField("approval_sequence");
  };

  const { data: approversData, isLoading: approversLoading } = useQuery(
    ["Approvers"],
    async () => {
      const response = await fetchApprovers();
      let groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + " " + data.last_name,
      }));
      return groups;
    }
  );

  const { mutate: request_approval } = useMutation({
    mutationKey: ["request_approval"],
    mutationFn: (payload: any) => {
      return requestApproval(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["fetch_custom_deviations"],
      });
      handleClose();
    },
  });

  const { mutate: custom_deviation } = useMutation({
    mutationKey: ["create_custom_deviation"],
    mutationFn: (payload: any) => {
      return draftDeviation(payload);
    },
    onSuccess: (response: any) => {
      const result = response?.data;
      let priority: number = 0;
      result?.custom_datapoint?.map((data: any) => {
        const approversArray = data?.approvers?.map((approver: string) => {
          priority = priority + 1;
          return {
            id: approver,
            priority: priority,
          };
        });
        const payload = {
          approvers: approversArray,
          reason: data?.reason,
          approval_sequence: data?.approval_sequence,
          deviation: result?.id,
          draft: draftId,
        };
        request_approval(payload);
      });
    },
  });

  const addAnnotation = () => {
    const { Core } = instance;
    const { documentViewer } = Core;
    const selectedText = documentViewer.getSelectedText();
    return selectedText;
  };

  const onSubmit = useCallback((data: any) => {
    const payload = {
      deviation_type: "custom",
      error: false,
      details: null,
      condition: {
        condition: "test",
      },
      kdp_datapoint: null,
      draft_datapoint: null,
      manual_datapoint: null,
      replaced_datapoint: null,
      reason: null,
      status: null,
      draft: draftId,
      custom_datapoint: [
        {
          selectedValue: addAnnotation(),
          ...data,
        },
      ],
    };
    if (addAnnotation()) {
      custom_deviation(payload);
    }
    if (!addAnnotation()) {
      enqueueSnackbar(
        "Please select some text from the document on left to mark as Issue ",
        {
          variant: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        }
      );
    }
  }, []);

  return (
    <Accordion
      sx={{
        background: "#88305F24",
        boxShadow: "none",
        borderRadius: "5px",
      }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" spacing={1}>
          <Typography>Add a custom issue</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Typography fontSize="15px" fontWeight={700}>
              1. Select some text from the document on left to mark as Issue
            </Typography>
            <Typography fontSize="15px" fontWeight={700}>
              2. Add reason (context)
            </Typography>
            <ControlledTextField
              name="reason"
              control={control}
              required
              label="Reason"
              fullWidth
            />
            <Typography fontSize="15px" fontWeight={700}>
              3. Select approvers
            </Typography>
            <RISelectComponent
              name="approvers"
              control={control}
              label="Select approvers"
              options={approversData}
              loading={approversLoading}
              isMultiselect={true}
              renderCustomComponent={(value: any, props) => (
                <ShareChip key={value?.id} {...props} label={value?.name} />
              )}
            />
            <Typography variant="caption">
              You can select multiple approvers. All approvers must approve in
              order for this rule to pass
            </Typography>
            <RadioButtonGroup
              name="approval_sequence"
              row
              options={approverSequence}
              control={control}
              isDescription
              valueKey="value"
              required
            />
            <Stack spacing={2} direction="row">
              <Button startIcon={<AddIcon />} variant="contained" type="submit">
                Create custom issue
              </Button>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddCustomIssue;
