import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  fetchExternalCollaborators,
  fetchOptionalFieldsExternal,
  saveExternalDraft,
} from "../../../ExternalUserFlow/Services/Draft";
import {
  fetchGetFields,
  fetchOptionalFields,
  saveDraft,
} from "../../../Services/Draft";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { AddCircleOutline } from "@mui/icons-material";
import AssignDialog from "../DraftChecklist/AssignDialog";
import ControlledCheckbox from "../../../RiverusUI/Components/ControlledCheckbox";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteIcon from "../../../RiverusUI/Components/Icons/DeleteIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Field_INFO } from "../../../Constants/DataDictionary";
import NameAvatar from "../../../RiverusUI/DataGrid/NameAvatar";
import PercentIcon from "@mui/icons-material/Percent";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import dayjs from "dayjs";
import { fetchCollaborators } from "../../../Services/Approval";
import { generateTags } from "../../../Templates/Component/DocGenerate";
import { useSnackbar } from "notistack";

interface Props {
  draftData: any;
  setCompleteDraftFormData?: Dispatch<SetStateAction<any>>;
  instance: any;
  setFieldUpdated?: Dispatch<SetStateAction<boolean>>;
  updateDraftData: any;
  isExternal?: boolean;
  setSaveDraftLoading: Dispatch<SetStateAction<boolean>>;
}

const CompleteDraft: React.FC<Props> = ({
  draftData,
  setCompleteDraftFormData,
  instance,
  setFieldUpdated,
  updateDraftData,
  isExternal,
  setSaveDraftLoading,
}) => {
  const [openAssigneeDialog, setOpenAssigneeDialog] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<string[]>([]);
  const [assigneeDialogPropData, setAssigneeDialogPropData] = useState<any>({});
  const [tags, setTags] = useState({});
  const [expanded, setExpanded] = useState<string | false>(false);
  const [dynamicFields, setDynamicFields] = useState<any>([]);
  const [collaborators, setCollaborators] = useState<any>([]);

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const draft_id = useMemo(() => draftData?.id, [draftData?.id]);
  const contractTypeId = useMemo(
    () => draftData?.contractType?.id,
    [draftData?.contractType?.id]
  );
  const access_url = useMemo(() => draftData?.access_url, [draftData]);

  const methods = useForm();

  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    let collaborator = draftData?.collaborators?.map((data: any) => data.id);
    setCollaborators(collaborator);
  }, [draftData?.collaborators]);

  const handleCloseAssigneeDialog = () => {
    setOpenAssigneeDialog(false);
  };

  const handleOpenAssigneeDialog = useCallback(
    (data: any) => {
      if (isExternal) return;
      setOpenAssigneeDialog(true);
      setAssigneeDialogPropData(data);
    },
    [isExternal]
  );

  const handleAssignee = useCallback(
    (payload: any) => {
      const fields = [...dynamicFields];
      fields?.forEach((fieldInfo: any) => {
        fieldInfo?.field_data?.forEach((element: any) => {
          if (element?.field_name === assigneeDialogPropData?.field_name) {
            element.user_name = payload?.user_name;
            element.email = payload?.email;
            element.user_type = payload?.assignee_type;
          }
          return;
        });
      });
      handleCloseAssigneeDialog();
      setDynamicFields(fields);
      if (payload?.assignee_type === "internal") {
        let index = collaborators.findIndex(
          (collaborator: any) => collaborator === payload.id
        );
        if (index === -1) {
          setCollaborators((prev: any) => [...prev, payload.id]);
        }
      }
    },
    [assigneeDialogPropData, dynamicFields, collaborators]
  );

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { data: getFields } = useQuery(
    ["get_fields", contractTypeId],
    async () => {
      const response = await fetchGetFields(contractTypeId);
      return response?.data;
    },
    {
      enabled: !!contractTypeId && !isExternal,
    }
  );

  const { data: optionalFields } = useQuery(
    ["optional_fields", draft_id, isExternal],
    async () => {
      const response = isExternal
        ? await fetchOptionalFieldsExternal(draft_id)
        : await fetchOptionalFields(draft_id);
      return response?.results;
    },
    {
      enabled: !!draft_id,
    }
  );

  const { data: approversData } = useQuery(
    ["Approvers_list", isExternal],
    async () => {
      const response = isExternal
        ? await fetchExternalCollaborators()
        : await fetchCollaborators();
      let groups = response.results.map((data: any) => ({
        ...data,
        name: data?.first_name + " " + data?.last_name,
      }));
      const filteredApproversList = groups.filter((data: any) => {
        return data?.groups?.some((element: string) => {
          if (draftData?.groups?.includes(element)) return data;
        });
      });
      return filteredApproversList;
    },
    { enabled: !!draftData?.groups }
  );

  const { mutate: saveDraftMutation } = useMutation({
    mutationFn: isExternal ? saveExternalDraft : saveDraft,
    onMutate: () => {
      setSaveDraftLoading(true);
    },
    onSuccess: () => {
      setFieldUpdated?.(false);
      enqueueSnackbar("Save successfully!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      queryClient.invalidateQueries({
        queryKey: ["optional_fields"],
      });
      const payload = {
        id: draftData?.id,
        body: {
          collaborators: collaborators,
        },
      };
      if (collaborators.length) {
        updateDraftData(payload);
      }
    },
    onError: () => {
      enqueueSnackbar("Failed to save!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    },
    onSettled: () => {
      setSaveDraftLoading(false);
    },
  });

  useEffect(() => {
    if (optionalFields?.length) {
      setFormFields(optionalFields);
    } else if (getFields?.length) {
      setFormFields(getFields);
    }
  }, [getFields, optionalFields]);

  const updateDynamicFieldValue = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    type: string
  ) => {
    const fields = [...dynamicFields];
    setFieldUpdated?.(true);
    if (
      type === "text" ||
      type === "select" ||
      type.toLowerCase() === "currency"
    ) {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.value;
      const { required, field_value, field_validation, validation } =
        fields[sectionIndex].field_data[fieldIndex];
      if (validation) {
        if (required && field_value.length > 0) {
          validation["errorMsg"] = "";
        }
        if (field_validation && event.target.value.length > field_validation) {
          validation[
            "errorMsg"
          ] = `Max character limit allowed is ${validation.maxCharLength} chars`;
        } else validation["errorMsg"] = "";
      }
    }
    if (type === "duration") {
      fields[sectionIndex].field_data[fieldIndex].fieldLength =
        event.target.value;
    }
    if (type === "percentage") {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.value;
    }
    if (type === "date") {
      fields[sectionIndex].field_data[fieldIndex].field_value = event.$d
        .toISOString()
        .split("T")[0];
    }
    if (type === "boolean") {
      fields[sectionIndex].field_data[fieldIndex].field_value =
        event.target.checked;
    }
    setDynamicFields(fields);
  };

  const parseTagList = (tags: any) => {
    let parsedTags = Object.keys(tags).reduce((acc, curr) => {
      let innerObject = {};
      if (Object.keys(tags[curr]).length > 0) {
        innerObject = { ...innerObject, ...tags[curr] };
      }
      return { ...acc, [curr.split(" ")[0]]: {}, ...innerObject };
    }, {});
    return parsedTags;
  };

  useEffect(() => {
    generateTags(access_url, (tags: any) => {
      let parseTags: any = parseTagList(tags);
      setTags(parseTags);
    });
  }, [access_url, getFields]);

  const getFieldName = (mergeTag: string, fieldName: string): string => {
    let name = fieldName;
    try {
      if (
        mergeTag.charCodeAt(mergeTag.length - 1) >= 49 &&
        mergeTag.charCodeAt(mergeTag.length - 1) <= 57
      ) {
        name = name + " " + mergeTag[mergeTag.length - 1];
      }
      return name;
    } catch (error) {
    } finally {
      return name;
    }
  };

  const getFieldValue = (type: string, value: any) => {
    if (type === "boolean") {
      if (value === "true") {
        return true;
      }
      return false;
    } else if (type === "bulleted list" || type === "numbered list") {
      if (value && value !== "") {
        return JSON.parse(value);
      }
      return [{ list: "" }];
    } else return value || "";
  };

  const constructDocFields = useCallback(() => {
    if (Object.keys(tags).length > 0) {
      let tagList = Object.keys(tags);
      let fields: any[] = [];
      tagList.forEach((tag) => {
        let sectionObj: any = {};
        for (let index = 0; index < formFields?.length; index++) {
          const section = JSON.parse(JSON.stringify(formFields[index]));
          let filteredTags: Field_INFO[] = [];
          filteredTags = section.field_data.filter((field: any) => {
            return tag.includes(field.field_name.replaceAll(/{|}/g, ""));
          });

          if (filteredTags.length > 1) {
            filteredTags = filteredTags.filter((field: any) =>
              field.field_name.replaceAll(/{|}/g, "").includes(tag)
            );
          }
          if (filteredTags.length > 0) {
            sectionObj = { ...section };
            if (fields.length > 0) {
              let fieldIndex = fields.findIndex(
                (value) => value.section_name === sectionObj.section_name
              );
              if (fieldIndex > -1) {
                const field = JSON.parse(JSON.stringify(filteredTags[0]));
                field.display_name = getFieldName(tag, field.display_name);
                field.field_value = getFieldValue(
                  field.data_type.toLowerCase(),
                  field.field_value
                );
                field.fieldLength = "";
                field.field_name = tag;
                field.field = tag.replace(/[0-9]/g, "");
                field.validation = {};
                fields[fieldIndex].field_data = [
                  ...fields[fieldIndex].field_data,
                  field,
                ];
                return;
              } else {
                sectionObj.field_data = [];
                const field = JSON.parse(JSON.stringify(filteredTags[0]));
                field.display_name = getFieldName(tag, field.display_name);
                field.field_value = getFieldValue(
                  field.data_type.toLowerCase(),
                  field.field_value
                );
                field.fieldLength = "";
                field.validation = {};
                field.field = tag.replace(/[0-9]/g, "");
                field.field_name = tag;
                sectionObj.field_data = [field];
                fields.push(sectionObj);
                return;
              }
            } else {
              sectionObj.field_data = [];
              const field = JSON.parse(JSON.stringify(filteredTags[0]));
              field.display_name = getFieldName(tag, field.display_name);
              field.field_value = getFieldValue(
                field.data_type.toLowerCase(),
                field.field_value
              );
              field.fieldLength = "";
              field.validation = {};
              field.field = tag.replace(/[0-9]/g, "");
              field.field_name = tag;
              sectionObj.field_data = [field];
              fields.push(sectionObj);
              return;
            }
          }
        }
      });
      setDynamicFields(fields);
    }
  }, [formFields, tags]);

  useEffect(() => {
    if (tags) {
      constructDocFields();
    }
  }, [constructDocFields, tags]);

  useEffect(() => {
    if (dynamicFields?.length) {
      let defaultFields = {};
      dynamicFields.map((fields: any) => {
        fields.field_data?.map((data: any) => {
          let form_fields = {
            [data.field_name]: data.field_value,
          };
          defaultFields = {
            ...defaultFields,
            ...form_fields,
          };
        });
      });
      reset(defaultFields);
    }
  }, [dynamicFields, reset]);

  useEffect(() => {
    if (dynamicFields?.length) {
      let dynamicFields_ = JSON.parse(JSON.stringify(dynamicFields));
      dynamicFields_?.forEach((fieldInfo: any) => {
        fieldInfo?.field_data?.forEach((fields: any) => {
          if (!fields.field_value) {
            fields.field_value = `{${fields.field}}`;
          }
          if (
            fields.data_type.toLowerCase() === "boolean" ||
            fields.data_type.toLowerCase() === "bulleted list" ||
            fields.data_type.toLowerCase() === "numbered list"
          ) {
            fields.field_value = fields.field_value
              ? JSON.stringify(fields.field_value)
              : `{${fields.field}}`;
          }
        });
      });
      setCompleteDraftFormData?.(dynamicFields_);
    }
  }, [dynamicFields, setCompleteDraftFormData]);

  const highlightTag = useCallback(
    (tag: string) => {
      if (instance) {
        const { Core } = instance;
        const { documentViewer, Annotations, annotationManager } = Core;
        const annotations = annotationManager.getAnnotationsList();
        annotationManager.deleteAnnotations(annotations);
        let searchData = "{" + tag + "}";
        const searchMode =
          instance.Core.Search.Mode.REGEX |
          instance.Core.Search.Mode.HIGHLIGHT |
          instance.Core.Search.Mode.PAGE_STOP;
        let isMoveToPage = true;
        const searchOptions = {
          fullSearch: true,
          onResult: (result: any) => {
            if (result.resultCode === instance.Core.Search.ResultCode.FOUND) {
              const highlight = new Annotations.TextHighlightAnnotation();
              result.quads.map((quad: { getPoints: () => any }) => {
                highlight.Quads = [quad.getPoints()] as any[];
              });
              highlight.PageNumber = result.pageNum;
              highlight.X = 405;
              highlight.Y = 165;
              highlight.Width = 275;
              highlight.Height = 25;
              annotationManager.addAnnotation(highlight);
              annotationManager.redrawAnnotation(highlight);
              annotationManager.drawAnnotations({ pageNumber: result.pageNum });
              if (documentViewer.getCurrentPage() === result.pageNum) {
                isMoveToPage = false;
              }
              if (isMoveToPage) {
                documentViewer.setCurrentPage(result.pageNum, true);
              }
            }
          },
        };
        documentViewer.textSearchInit(searchData, searchMode, searchOptions);
      }
    },
    [instance]
  );

  const updateListData = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    itemIndex: number
  ) => {
    const fields = [...dynamicFields];
    fields[sectionIndex].field_data[fieldIndex].field_value[itemIndex].list =
      event.target.value;
    setDynamicFields(fields);
  };

  const addItemList = (
    event: any,
    sectionIndex: number,
    fieldIndex: number,
    ItemIndex: number
  ) => {
    event.preventDefault();
    const fields = [...dynamicFields];
    if (
      fields[sectionIndex].field_data[fieldIndex].field_value[ItemIndex][
        "list"
      ] === ""
    )
      return;
    fields[sectionIndex].field_data[fieldIndex].field_value.push({ list: "" });
    setDynamicFields(fields);
  };

  const removeItemFromField = (
    sectionIndex: number,
    fieldIndex: number,
    index: number
  ) => {
    const fields = [...dynamicFields];
    fields[sectionIndex].field_data[fieldIndex].field_value.splice(index, 1);
    setDynamicFields(fields);
  };

  const onSubmit = React.useCallback(() => {
    if (dynamicFields?.length) {
      let dynamicFields_ = JSON.parse(JSON.stringify(dynamicFields));
      dynamicFields_?.forEach((fieldInfo: any) => {
        fieldInfo?.field_data?.forEach((fields: any) => {
          if (
            fields.data_type.toLowerCase() === "boolean" ||
            fields.data_type.toLowerCase() === "bulleted list" ||
            fields.data_type.toLowerCase() === "numbered list"
          ) {
            fields.field_value = JSON.stringify(fields.field_value);
          }
        });
      });
      const payload = {
        draft: draftData?.id,
        optional_data: dynamicFields_,
      };
      saveDraftMutation(payload);
    }
  }, [draftData?.id, dynamicFields, saveDraftMutation]);

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form id="complete_draft_form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1} margin="15px">
            {dynamicFields?.map((section: any, sectionIndex: number) => (
              <Accordion
                sx={{
                  background: "#88305F24",
                  boxShadow: "none",
                }}
                expanded={expanded === section.section_id}
                onChange={handleAccordionChange(section.section_id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{section?.section_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={2}>
                    {section?.field_data?.map(
                      (fieldData: any, fieldIndex: number) => {
                        const fullName: string[] =
                          fieldData?.user_name?.split(" ");
                        return (
                          <Stack direction="row" spacing={2}>
                            {(fieldData?.data_type.toLowerCase() === "text" ||
                              fieldData?.data_type.toLowerCase() ===
                                "numeric" ||
                              fieldData?.data_type.toLowerCase() ===
                                "text box") && (
                              <ControlledTextField
                                key={fieldData?.id}
                                defaultValue={fieldData?.field_value}
                                control={control}
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                variant="outlined"
                                fullWidth
                                type={fieldData?.data_type.toLowerCase()}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    "text"
                                  )
                                }
                                required={fieldData?.required}
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                              />
                            )}
                            {fieldData?.data_type.toLowerCase() === "date" && (
                              <DatePicker
                                label={fieldData.display_name}
                                value={dayjs(fieldData.field_value)}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    "date"
                                  )
                                }
                                slotProps={{
                                  textField: {
                                    required: fieldData.required,
                                    onFocus: () =>
                                      highlightTag(fieldData.field_name),
                                  },
                                }}
                                format="MM-DD-YYYY"
                              />
                            )}
                            {(fieldData?.data_type.toLowerCase() ===
                              "percentage" ||
                              fieldData?.data_type.toLowerCase() ===
                                "currency") && (
                              <ControlledTextField
                                key={fieldData?.id}
                                control={control}
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                variant="outlined"
                                fullWidth
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {fieldData?.data_type.toLowerCase() ===
                                        "percentage" && <PercentIcon />}
                                      {fieldData?.data_type.toLowerCase() ===
                                        "currency" && <CurrencyRupeeIcon />}
                                    </InputAdornment>
                                  ),
                                }}
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    fieldData?.data_type.toLowerCase()
                                  )
                                }
                                required={fieldData?.required}
                              />
                            )}

                            {fieldData?.data_type.toLowerCase() ===
                              "duration" && (
                              <Stack direction="row" spacing={1}>
                                <ControlledTextField
                                  key={fieldData?.id}
                                  control={control}
                                  name={fieldData?.field_name}
                                  label={fieldData?.display_name}
                                  type="number"
                                  sx={{ flex: 1 }}
                                  onChange={(event: any) =>
                                    updateDynamicFieldValue(
                                      event,
                                      sectionIndex,
                                      fieldIndex,
                                      "text"
                                    )
                                  }
                                  required={fieldData?.required}
                                  onFocus={() => {
                                    highlightTag(fieldData.field_name);
                                  }}
                                />
                                <Select
                                  value={fieldData.fieldLength || "days"}
                                  label="Duration"
                                  variant="outlined"
                                  required={fieldData?.required}
                                  inputProps={{
                                    id: "Select-duration",
                                  }}
                                  onChange={(event: any) =>
                                    updateDynamicFieldValue(
                                      event,
                                      sectionIndex,
                                      fieldIndex,
                                      "duration"
                                    )
                                  }
                                  onFocus={() => {
                                    highlightTag(fieldData.field_name);
                                  }}
                                >
                                  <MenuItem key={"day"} value={"days"}>
                                    Day(s)
                                  </MenuItem>
                                  <MenuItem key={"Month"} value={"Months"}>
                                    Month(s)
                                  </MenuItem>
                                  <MenuItem key={"Years"} value={"Years"}>
                                    Years(s)
                                  </MenuItem>
                                </Select>
                              </Stack>
                            )}
                            {fieldData.data_type.toLowerCase() === "select" && (
                              <Select
                                value={fieldData.field_value}
                                label={fieldData?.display_name}
                                variant="outlined"
                                required={fieldData?.required}
                                fullWidth
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    "select"
                                  )
                                }
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                              >
                                {fieldData?.set_recommended_value?.default_field_value.map(
                                  (item: string, key: number) => (
                                    <MenuItem key={key} value={item}>
                                      {item}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            )}
                            {fieldData.data_type.toLowerCase() ===
                              "boolean" && (
                              <ControlledCheckbox
                                name={fieldData?.field_name}
                                label={fieldData?.display_name}
                                required={fieldData?.required}
                                control={control}
                                onChange={(event: any) =>
                                  updateDynamicFieldValue(
                                    event,
                                    sectionIndex,
                                    fieldIndex,
                                    "boolean"
                                  )
                                }
                                onFocus={() => {
                                  highlightTag(fieldData.field_name);
                                }}
                              />
                            )}
                            {(fieldData.data_type.toLowerCase() ===
                              "bulleted list" ||
                              fieldData.data_type.toLowerCase() ===
                                "numbered list") && (
                              <Stack spacing={1} mb={3}>
                                {fieldData?.field_value?.length > 0 &&
                                  fieldData?.field_value.map(
                                    (
                                      item: any,
                                      index: number,
                                      items: any[]
                                    ) => (
                                      <Stack direction="row">
                                        <TextField
                                          key={fieldData?.id}
                                          value={item.list}
                                          label={fieldData?.display_name}
                                          variant="outlined"
                                          fullWidth
                                          type={
                                            fieldData.data_type.toLowerCase() ===
                                            "numbered list"
                                              ? "number"
                                              : "text"
                                          }
                                          onChange={(event: any) =>
                                            updateListData(
                                              event,
                                              sectionIndex,
                                              fieldIndex,
                                              index
                                            )
                                          }
                                          required={fieldData?.required}
                                          onFocus={() => {
                                            highlightTag(fieldData.field_name);
                                          }}
                                        />
                                        {items.length - 1 === index && (
                                          <IconButton
                                            onClick={(e) =>
                                              addItemList(
                                                e,
                                                sectionIndex,
                                                fieldIndex,
                                                index
                                              )
                                            }
                                          >
                                            <AddCircleOutline />
                                          </IconButton>
                                        )}
                                        {items.length - 1 !== index && (
                                          <IconButton
                                            onClick={() =>
                                              removeItemFromField(
                                                sectionIndex,
                                                fieldIndex,
                                                index
                                              )
                                            }
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        )}
                                      </Stack>
                                    )
                                  )}
                              </Stack>
                            )}
                            {fieldData?.user_name && (
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  handleOpenAssigneeDialog(fieldData)
                                }
                              >
                                <NameAvatar
                                  firstName={fullName?.[0]}
                                  lastName={fullName?.[1]}
                                />
                              </IconButton>
                            )}
                            {!fieldData?.user_name && (
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  handleOpenAssigneeDialog(fieldData)
                                }
                              >
                                <PersonAddAltOutlinedIcon />
                              </IconButton>
                            )}
                          </Stack>
                        );
                      }
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
        </form>
      </FormProvider>
      {openAssigneeDialog && (
        <AssignDialog
          open={openAssigneeDialog}
          onClose={handleCloseAssigneeDialog}
          handleAssignee={handleAssignee}
          loadingUpdateItem={false}
          propsData={assigneeDialogPropData}
          approversData={approversData}
        />
      )}
    </React.Fragment>
  );
};

export default CompleteDraft;
