export default interface ClauseComponentState {
  clauseData: clauseTypeInterface;
  updatedClauseData?: any;
}

export interface clauseTypeInterface {
  nonCompete?: clauseTypeData;
  contractingParties?: clauseTypeData;
  changeOfControl?: clauseTypeData;
  confidentiality?: clauseTypeData;
  consent?: clauseTypeData;
  term?: clauseTypeData;
  renewal?: clauseTypeData;
  payment?: clauseTypeData;
  indemnity?: clauseTypeData;
  termination?: clauseTypeData;
  insurance?: clauseTypeData;
  notice?: clauseTypeData;
  eventsOfDefault?: clauseTypeData;
  limitedLiability?: clauseTypeData;
  forceMajeure?: clauseTypeData;
  governingLaw?: clauseTypeData;
  disputeResolution?: clauseTypeData;
  nonSolicitation?: clauseTypeData;
  subletting?: clauseTypeData;
  generalDefinitions?: clauseTypeData;
  dataBreach?: clauseTypeData;
  effectiveDate?: clauseTypeData;
  exclusivity?: clauseTypeData;
  obligationStatements?: clauseTypeData;
}

export interface clauseTypeData {
  edited_content: any;
  raw_content: any;
}

export function defaultClauseComponentState(): ClauseComponentState {
  return {
    clauseData: {},
  };
}
