import React, { useEffect, useRef } from "react";

import WebViewer from "@pdftron/webviewer";

const DocxViewerComponent = (props: any) => {
  const documentContainer = useRef<HTMLDivElement>(null);
  const {
    fileLink,
    setInstance,
    hoverTextSource,
    dragOver,
    setAddedSignatureFields,
    setHideSignatureButton,
    drop,
    draftDetails,
  } = props;

  useEffect(() => {
    if (fileLink && documentContainer.current) {
      WebViewer.WebComponent(
        {
          path: "/webviewer",
          fullAPI: true,
          initialDoc: fileLink,
          css: "/styles/webviewer.css",
          disabledElements: [
            "header",
            "toolsHeader",
            "linkButton",
            "annotationCommentButton",
            "contextMenuPopup",
          ],
          licenseKey:
            "Riverus Technology Solutions Pvt. Ltd (riverus.in):OEM:Riverus::B+:AMS(20240530):A65548BC0477C80A0360B13AB9C2527160610FEBB9361A37E36C05DA5CF50ED654BA31F5C7",
        },
        documentContainer.current
      ).then((instance) => {
        const { UI, Core } = instance;

        setInstance(instance);
        const { documentViewer, Annotations, annotationManager } = Core;

        instance.UI.annotationPopup.update([{ type: "ac" }]);

        documentViewer.addEventListener("documentLoaded", async () => {
          var FitMode = instance.UI.FitMode;
          instance.UI.setFitMode(FitMode.FitWidth);
        });

        documentViewer.addEventListener("mouseMove", async (e) => {
          const annotation = annotationManager.getAnnotationByMouseEvent(e);
          if (annotation) {
            let stampData: any = {};
            let isKeyPresentStampAnnotation = hoverTextSource.some(
              (el: any) => {
                if (el.hasOwnProperty(annotation.Id)) {
                  stampData = el;
                  return true;
                }
              }
            );

            if (isKeyPresentStampAnnotation) {
              instance.UI.setAnnotationContentOverlayHandler((annotation) => {
                const div = document.createElement("div");
                div.appendChild(
                  document.createTextNode(stampData[annotation.Id])
                );
                return div;
              });
            }
          }
        });

        const doc = documentContainer.current;
        if (doc) {
          doc.addEventListener("dragover", dragOver);
          doc.addEventListener("drop", (e: any) => {
            drop(e, documentViewer);
          });
        }

        Annotations.setCustomCreateSignHereElementHandler(function (
          tool,
          { annotation, originalCreateSignHereElement }
        ) {
          const signHereElement = originalCreateSignHereElement(tool);
          signHereElement.innerHTML = "Click Here To Sign";
          return signHereElement;
        });
        annotationManager.addEventListener(
          "annotationChanged",
          (annot, action, info) => {
            annot.forEach((annot: any) => {
              if (annot.Subject?.toLowerCase() === "signature") {
                annot.NoMove = true;
                annot.NoResize = true;
                annot.NoRotate = true;
                setAddedSignatureFields?.(true);
              }
              if (annot instanceof Annotations.SignatureWidgetAnnotation) {
                setHideSignatureButton?.(true);
              }
            });
          }
        );
        if (!draftDetails?.open_collab) {
          UI.disableElements(["textPopup"]);
        }
      });
    }
  }, [
    fileLink,
    documentContainer,
    setInstance,
    dragOver,
    draftDetails,
    hoverTextSource,
    drop,
    setAddedSignatureFields,
    setHideSignatureButton,
  ]);

  return (
    <div
      ref={documentContainer}
      id="document-viewer"
      style={{ height: "82vh" }}
    />
  );
};

export default DocxViewerComponent;
