import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveTextDialog from "./RemoveTextDialog";
import { highlightedSentence } from "../Helper";

interface Props {
  fieldValueId: string;
  sentence: string;
  instance: any;
  handleRemoveText: (value: string) => void;
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>;
  openDeleteDialog: boolean;
  handleCloseDeleteDialog: VoidFunction;
  setActiveTab: Dispatch<SetStateAction<string>>;
  handleOpenClauseLibraryDrawer: VoidFunction;
}

const ActionButton: React.FC<Props> = ({
  sentence,
  instance,
  handleRemoveText,
  setOpenDeleteDialog,
  openDeleteDialog,
  handleCloseDeleteDialog,
  fieldValueId,
  setActiveTab,
  handleOpenClauseLibraryDrawer,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [textId, setTextId] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSentenceClick = (sentence: string) => {
    highlightedSentence(sentence, instance);
  };

  useEffect(() => {
    if (!openDeleteDialog) {
      setTextId("");
    }
  }, [openDeleteDialog]);

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ fontSize: "15px" }}
      >
        <MenuItem>
          <Button
            variant="text"
            style={{
              padding: 0,
            }}
            onClick={() => handleSentenceClick(sentence)}
          >
            Highlight in the document
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            variant="text"
            onClick={() => {
              setTextId(fieldValueId);
              setOpenDeleteDialog(true);
            }}
            style={{
              padding: 0,
            }}
          >
            Remove this text
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            variant="text"
            style={{
              padding: 0,
            }}
            onClick={handleOpenClauseLibraryDrawer}
          >
            Swap this text with clause library
          </Button>
        </MenuItem>
      </Menu>
      {textId && (
        <RemoveTextDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          handleRemoveText={handleRemoveText}
          fieldValueId={textId}
        />
      )}
    </>
  );
};

export default ActionButton;
