import { Box, CircularProgress, Stack, Tab, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import CustomDeviation from "./CustomDeviation";
import ListSkeleton from "../../../RiverusUI/Components/Skeleton/ListSkeleton";
import Policies from "./Policies";
import { fetchKDPTagDeviations } from "../../../Services/Draft";
import { loadingOfInsightList } from "../InsightsTab/InsightsTab";
import { riPrimary } from "../../../RiverusUI/Theme/colors";
import { useQuery } from "@tanstack/react-query";

interface Props {
  draftData: any;
  instance: any;
  setActiveTab: Dispatch<SetStateAction<string>>;
  handleOpenClauseLibraryDrawer: VoidFunction;
}

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: "20px",
  paddingX: "10px",
  marginTop: "15px",
};

const DeviationTab: React.FC<Props> = ({
  draftData,
  instance,
  setActiveTab,
  handleOpenClauseLibraryDrawer,
}) => {
  const draftId =
    draftData?.createFrom === "template" && draftData?.version === 1
      ? draftData?.draftID
      : draftData?.id;

  const [tabValue, setTabValue] = useState<string>("policies");
  const [deviationType, setDeviationType] = useState<string>("");

  const { data: deviationsList, isLoading: isLoadingDeviations } = useQuery(
    ["fetch_Kdp_deviations", draftId, deviationType],
    async () => {
      const id = deviationType === "kdp" ? draftData?.id : draftId;
      const response = await fetchKDPTagDeviations(id, deviationType);
      return response?.results;
    },
    { enabled: !!draftId && !!deviationType }
  );

  useEffect(() => {
    if (draftData?.createFrom === "template" && draftData?.version === 1) {
      setDeviationType("merge_tag");
    } else {
      setDeviationType("kdp");
    }
  }, [draftData]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        onChange={handleChange}
        aria-label="lab API tabs example"
        style={innerTabStyle}
      >
        <Tab label="Policies" value="policies" />
        <Tab label="Custom" value="custom" />
      </TabList>
      <Stack
        spacing={1}
        sx={{
          height: "70vh",
          overflowY: "auto",
          backgroundColor: "riTertiary.50",
          borderRadius: "24px",
          mt: "8px",
        }}
      >
        <TabPanel value="policies" sx={{ padding: "15px" }}>
          {isLoadingDeviations ? (
            <Box px={2}>
              <ListSkeleton />
            </Box>
          ) : (
            <>
              {deviationsList?.length === 0 ? (
                <Typography>No issue for this draft</Typography>
              ) : (
                <>
                  {loadingOfInsightList && deviationType === "kdp" ? (
                    <CircularProgress />
                  ) : (
                    <Policies
                      deviationsList={deviationsList}
                      instance={instance}
                      setActiveTab={setActiveTab}
                      deviation_type={deviationType}
                      handleOpenClauseLibraryDrawer={
                        handleOpenClauseLibraryDrawer
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel value="custom" sx={{ padding: "15px" }}>
          <CustomDeviation instance={instance} draftId={draftId} />
        </TabPanel>
      </Stack>
    </TabContext>
  );
};

export default DeviationTab;
