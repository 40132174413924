import * as changesets from "json-diff-ts";

import { ClauseType, ClauseTypeMap } from "../../../../../Constants/ClauseType";
import {
  LinkSentenceRequest,
  ParentClauseDataPoint,
  SentencesData,
  editedSentences,
  sentenceInfo,
  tableInfo,
} from "../../../../../State/documentState";
import React, { Component } from "react";
import {
  dataForMap,
  getAuthoritiesFromChild,
} from "../../../../Utils/docUtils";
import {
  deleteDataFromObligation,
  getClauseDataFormat,
  getClauseObjects,
  getEditedPayload,
} from "../../../../ClauseComponent/utils/ClauseTypeUtils";

import { duration } from "@mui/material";
import { regulatoryMap } from "../consentAddEdit";
import { table } from "console";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  saveHighlightedDataPoint: (dataPointName: string) => void;
  editPresentSentences: (presentValue: LinkSentenceRequest) => void;
  dataPointName: string;
  savedInsightChild: any;
  savedParentClauseDataPoint: ParentClauseDataPoint;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileId: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseItem: any;
  onClose: any;
  clauseData: any;
}

interface State {
  savedAuthorities: any;
  authorityInAddMode: sentenceInfo;
  present: string;
  origData: any;
  deletedAuthority: boolean;
  deletedIndex: number[];
  newAdded: boolean;
  authorityListLength: number;
  addedListLength: number;
}

export default class ConsentAuthorityAddEdit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      savedAuthorities: [],
      authorityInAddMode: {
        paraId: props.savedParentClauseDataPoint.paraId,
        sentenceId: props.savedParentClauseDataPoint.sentenceId,
        rowId: props.savedParentClauseDataPoint.rowId,
        columnId: props.savedParentClauseDataPoint.columnId,
        startWordId: -1,
        endWordId: -1,
        phrase: "",
        typestring: "",
      },
      present: "Yes",
      origData: props.clauseDataByType,
      deletedAuthority: false,
      deletedIndex: [],
      newAdded: false,
      authorityListLength: -1,
      addedListLength: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let authoritiesArray: sentenceInfo[] = getAuthoritiesFromChild(
      this.props.savedInsightChild
    );
    let currentIndex = -1;
    for (
      let i = 0;
      i < this.props.updatedClauseData.consent.consent_regulatory_bi.length;
      i++
    ) {
      if (
        this.props.updatedClauseData.consent.consent_regulatory_bi[i]
          ?.para_id === this.props.clauseItem?.para_id &&
        this.props.updatedClauseData.consent.consent_regulatory_bi[i]
          ?.sentence_id === this.props.clauseItem?.sentence_id
      ) {
        currentIndex = i;
      }
    }

    let existingAuthoritiesArray =
      this.props.updatedClauseData.consent.consent_regulatory_bi[currentIndex]
        ?.extraction;
    this.setState({ savedAuthorities: existingAuthoritiesArray });
  }

  render() {
    return (
      <div className="row toc-content toc-content-default">
        <div className="col-md-12">
          <div className="row mt-1">
            <div
              className="col-md-12 m-0 bi-label-clickable"
              style={{ fontWeight: 600 }}
            >
              Add Consent Authority
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3 edit-title-header">
              Add/edit the authorities to your contract here...
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 bi-label-clickable edit-date-title">
              Authority
            </div>
            <div className="col-md-12" style={{ margin: "auto" }}>
              {this.getAuthorities()}
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-md-12">
                            <span className="mr-2">
                                <img alt='active' src='/static_images/checkbox_active.svg' className="filter-select-asset cursor-pointer " />
                            </span>&nbsp;&nbsp;
                            Share feedback with Riverus.
                        </div>
                    </div> */}
          {this.saveOrCancelAuthority()}
        </div>
      </div>
    );
  }

  getAuthorities() {
    let { dataPointName } = this.props;
    let { savedAuthorities, authorityInAddMode } = this.state;
    return (
      <>
        {savedAuthorities?.map((authorityItem: any, index: number) => (
          <div className="row" key={index}>
            <div
              className="col-md-8 pr-0 my-1 tag-selection-header"
              style={{ color: "#4D4D4D" }}
            >
              <span
                className="simple-input"
                style={{ background: "white", border: "1px solid #DDDDDD" }}
              >
                <input
                  type="text"
                  placeholder={"Enter text"}
                  style={{ width: "100%", border: "none", outline: "none" }}
                  value={
                    authorityItem.entity
                      ? authorityItem.entity
                      : authorityItem.phrase
                  }
                  readOnly
                />
              </span>
            </div>
            <div className="col-md-4 align-right">
              <img
                className="cursor-pointer"
                src="/static_images/less-parties.svg"
                alt="remove-icon"
                onClick={() =>
                  this.addOrRemoveAuthority("remove", authorityItem, index)
                }
              />
            </div>
          </div>
        ))}
        <div className="row">
          <div
            className="col-md-8 my-1 pr-0 tag-selection-header"
            style={{ color: "#4D4D4D" }}
          >
            <span
              className="simple-input"
              style={{ background: "white", border: "1px solid #DDDDDD" }}
            >
              <input
                type="text"
                placeholder={"Enter text"}
                style={{ width: "100%", border: "none", outline: "none" }}
                value={authorityInAddMode.phrase}
                onChange={(e) => this.handleChange(e)}
              />
            </span>
          </div>
          <div className="col-md-4 align-right">
            {authorityInAddMode.phrase !== undefined &&
            authorityInAddMode.phrase.length > 0 ? (
              <img
                className="cursor-pointer"
                src="/static_images/more-parties.svg"
                alt="add-more"
                onClick={() =>
                  this.addOrRemoveAuthority("add", authorityInAddMode, -1)
                }
              />
            ) : (
              <img
                src="/static_images/more-parties-disabled.svg"
                alt="add-more"
              />
            )}
          </div>
        </div>
      </>
    );
  }

  saveOrCancelAuthority() {
    return (
      <div className="row my-2">
        <div className="col-md-5" />
        <div className="col-md-7">
          <span
            className="add-datapoint"
            style={{ textDecoration: "none" }}
            onClick={() => this.onCancel()}
          >
            Cancel
          </span>
          <span
            className="upload-yellow-btn ml-4"
            id="save-btn"
            onClick={() => this.onSave()}
          >
            Save
          </span>
        </div>
      </div>
    );
  }

  handleChange(e: any) {
    let { authorityInAddMode } = this.state;
    e.preventDefault();
    this.setState({
      authorityInAddMode: {
        paraId: authorityInAddMode.paraId,
        sentenceId: authorityInAddMode.sentenceId,
        rowId: authorityInAddMode.rowId,
        columnId: authorityInAddMode.columnId,
        startWordId: authorityInAddMode.startWordId,
        endWordId: authorityInAddMode.endWordId,
        phrase: e.target.value,
        typestring: authorityInAddMode.typestring,
      },
    });
  }

  containsObject(obj: any, list: any) {
    var x;
    for (x in list) {
      if (
        list[x].para_id === obj.paraId &&
        list[x].sentence_id === obj.sentenceId
      ) {
        return true;
      }
    }
    return false;
  }

  editPresent(action: string) {
    let {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      updatedClauseDataByType,
      postClauseDataByType,
      parentClauseType,
      updatedClauseData,
      clauseData,
    } = this.props;

    let addSentenceRequest;
    if (this.state.newAdded) {
      addSentenceRequest = this.addOrRemovePresent(action);
      this.props.editPresentSentences(addSentenceRequest);
    }
    let updatedData = updatedClauseDataByType;
    let newData = updatedData;
    let obligationData = updatedClauseData.obligationStatements;
    let clauseItem = this.props.clauseItem;

    if (this.state.present === "Yes") {
      if (this.state.savedAuthorities.length > 0) {
        let addedData = this.state.savedAuthorities;
        for (let i = 0; i < addedData.length; i++) {
          newData = getClauseDataFormat(
            "add",
            clauseType as ClauseType,
            addedData[i],
            newData,
            sentenceData,
            clauseItem,
            i
          );
          obligationData = deleteDataFromObligation(
            "sentence",
            addedData[i],
            obligationData
          );
        }
        this.setState({ addedListLength: addedData.length });
      } else {
        newData = getClauseDataFormat(
          "add",
          clauseType as ClauseType,
          null,
          newData,
          sentenceData,
          clauseItem,
          0
        );
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType);
    }

    this.setState({
      deletedAuthority: false,
      deletedIndex: [],
      newAdded: false,
    });

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });

    const obligationDiff = changesets.diff(
      clauseData?.obligationStatements?.raw_content,
      obligationData,
      { children: "$index" }
    );

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    if (obligationDiff.length > 0) {
      postClauseDataByType(
        fileId,
        "obligation_statements",
        obligationDiff,
        obligationData
      );
    }
  }

  onSave() {
    this.props.editOptionSelected(false);
    this.editPresent("add");
    this.props.onClose();
  }

  onCancel() {
    this.props.editOptionSelected(false);
    this.props.onClose();
  }

  addOrRemoveAuthority(action: string, authority: any, index: number) {
    let { savedParentClauseDataPoint } = this.props;
    let { savedAuthorities } = this.state;
    if (action === "add") {
      let tempSavedAuthorities: sentenceInfo[];
      if (!savedAuthorities) {
        tempSavedAuthorities = [];
      } else {
        tempSavedAuthorities = savedAuthorities;
      }
      tempSavedAuthorities.push(authority);

      this.setState({ newAdded: true });
      this.setState({ savedAuthorities: tempSavedAuthorities });

      this.setState({
        authorityInAddMode: {
          paraId: savedParentClauseDataPoint.paraId,
          sentenceId: savedParentClauseDataPoint.sentenceId,
          rowId: savedParentClauseDataPoint.rowId,
          columnId: savedParentClauseDataPoint.columnId,
          startWordId: -1,
          endWordId: -1,
          phrase: "",
          typestring: "",
        },
      });
    } else if (action === "remove") {
      let tempFilteredAuthorities: sentenceInfo[] = [];
      for (let i = 0; i < savedAuthorities.length; i++) {
        if (authority.entity) {
          if (
            savedAuthorities[i].startWordId === authority.startWordId &&
            savedAuthorities[i].endWordId === authority.endWordId &&
            savedAuthorities[i].entity === authority.entity
          ) {
            continue;
          } else {
            tempFilteredAuthorities.push(savedAuthorities[i]);
          }
        } else if (authority.phrase) {
          if (
            savedAuthorities[i].startWordId === authority.startWordId &&
            savedAuthorities[i].endWordId === authority.endWordId &&
            savedAuthorities[i].phrase === authority.phrase
          ) {
            continue;
          } else {
            tempFilteredAuthorities.push(savedAuthorities[i]);
          }
        }
      }
      this.setState({ savedAuthorities: tempFilteredAuthorities });

      this.state.deletedIndex.push(index);
      this.setState({ deletedAuthority: true });
      if (this.state.addedListLength - 1 >= 0) {
        this.setState({ addedListLength: this.state.addedListLength - 1 });
      } else {
        this.setState({ addedListLength: 0 });
      }
    }
  }

  addOrRemovePresent(action: string): LinkSentenceRequest {
    let { savedInsightChild } = this.props;
    let { savedAuthorities, authorityInAddMode } = this.state;
    let tempPresentList: LinkSentenceRequest = { data: "", mode: "" };

    let oldAuthorities: sentenceInfo[] =
      getAuthoritiesFromChild(savedInsightChild);
    let changedAuthorities: sentenceInfo[] = savedAuthorities;
    if (
      authorityInAddMode.phrase !== undefined &&
      authorityInAddMode.phrase.length > 0
    ) {
      changedAuthorities.push(authorityInAddMode);
    }

    let mergeEditedAuthorities: editedSentences =
      this.getAddedAndDeletedAuthorities(changedAuthorities, oldAuthorities);

    if (action === "add") {
      tempPresentList = {
        mode: "manual",
        editedSentences: mergeEditedAuthorities,
      };
    }
    return tempPresentList;
  }

  getAddedAndDeletedAuthorities(
    changedAuthorities: sentenceInfo[] | null,
    previousAuthorities: sentenceInfo[]
  ) {
    let { dataPointName } = this.props;
    let addedAuthorities: sentenceInfo[] = [];
    let deletedAuthorities: sentenceInfo[] = [];

    if (previousAuthorities.length !== 0) {
      if (changedAuthorities !== null) {
        if (changedAuthorities.length !== 0) {
          //get newly added elements
          for (let i = 0; i < changedAuthorities.length; i++) {
            let addedExists = false;
            for (let j = 0; j < previousAuthorities.length; j++) {
              if (
                changedAuthorities[i].startWordId ===
                  previousAuthorities[j].startWordId &&
                changedAuthorities[i].endWordId ===
                  previousAuthorities[j].endWordId &&
                changedAuthorities[i].phrase === previousAuthorities[j].phrase
              ) {
                addedExists = true;
                break;
              }
            }
            if (addedExists === false) {
              addedAuthorities.push(changedAuthorities[i]);
            }
          }

          //get deleted elements
          for (let i = 0; i < previousAuthorities.length; i++) {
            let deletedExists = false;
            for (let j = 0; j < changedAuthorities.length; j++) {
              if (
                previousAuthorities[i].paraId ===
                  changedAuthorities[j].paraId &&
                previousAuthorities[i].sentenceId ===
                  changedAuthorities[j].sentenceId &&
                previousAuthorities[i].phrase === changedAuthorities[j].phrase
              ) {
                deletedExists = true;
                break;
              }
            }
            if (deletedExists === false) {
              deletedAuthorities.push(previousAuthorities[i]);
            }
          }
        } else if (changedAuthorities.length === 0) {
          for (let i = 0; i < previousAuthorities.length; i++) {
            deletedAuthorities.push(previousAuthorities[i]);
          }
        }
      }
    } else {
      if (changedAuthorities !== null) {
        if (changedAuthorities.length !== 0) {
          //adding for first time, newly added elements
          for (let i = 0; i < changedAuthorities.length; i++) {
            addedAuthorities.push(changedAuthorities[i]);
          }
        }
      }
    }

    let addedDeletedAuthorties: editedSentences = {
      upsert: addedAuthorities,
      deleted: deletedAuthorities,
      dataFor: dataForMap[dataPointName],
    };

    return addedDeletedAuthorties;
  }
}
