import colors from "./colors";

const rangeTheme = {
  styleOverrides: {
    thumb: {
      color: colors?.riPrimary?.[600],
    },
    track: {
      color: colors?.riPrimary?.[600],
    },
    rail: {
      color: colors?.riPrimary?.[10],
    },
  },
};
export default rangeTheme;