import React, { Dispatch, SetStateAction } from "react";
import UploadDocComponent from "../../../RiverusUI/Components/UploadDocComponent";
import CustomModal from "../../../RiverusUI/Components/CustomModal";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";

interface Props {
  files: any;
  setFiles: Dispatch<SetStateAction<any>>;
  open: boolean;
  onClose: VoidFunction;
  loadingUploadDoc: boolean;
  uploadDocument: (payload: any) => void;
  draftID: string;
}

const SparkMD5 = require("spark-md5");

const UploadDraftDialog: React.FC<Props> = ({
  files,
  setFiles,
  open,
  onClose,
  loadingUploadDoc,
  uploadDocument,
  draftID,
}) => {
  const { control } = useForm();

  const handleUploadFile = () => {
    let file = files?.[0];
    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        const payload = {
          file_name: file?.name,
          file_hash: hexHash,
          file_size: file?.size,
          file_type: "Contract_Draft",
          creation_type: "custom",
          ref_draft_id: draftID,
        };
        uploadDocument(payload);
      };
    }
  };

  return (
    <CustomModal
      title="Upload Counterparty draft"
      open={open}
      handleClose={onClose}
    >
      <Stack margin="20px 15px" spacing={2}>
        <UploadDocComponent
          label="Upload Counterparty draft"
          control={control}
          name="counter_party_draft"
          allowedFileTypes={[".pdf", ".docx"]}
          files={files}
          setFiles={setFiles}
        />
        <Stack direction="row" spacing={2}>
          <LoadingButton
            type="submit"
            loading={loadingUploadDoc}
            loadingPosition="start"
            variant="contained"
            onClick={() => handleUploadFile()}
          >
            Upload draft
          </LoadingButton>
          <Button variant="outlined" onClick={() => onClose()}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default UploadDraftDialog;
