import * as React from "react";

import { DurationType, LinkPhraseRequest } from "../../State/documentState";

import Scrollable from "../../../UniversalComponents/Scrollable/scrollable";

interface Props {
  savedInsight: any;
  durationList: DurationType[];
  editPhrasesRequest: (newPhraseRequest: LinkPhraseRequest) => void;
  clearNormalizedEdit: () => void;
  selectedDuration: any;
}

export const DurationEdit = (props: Props) => {
  const {
    durationList,
    editPhrasesRequest,
    selectedDuration,
    clearNormalizedEdit,
  } = props;
  const [selectedOption, setSelectedOption] = React.useState<DurationType>({
    durationTypeId: -1,
    durationName: "",
    durationType: "",
  });
  const [hideOptions, setHideOptions] = React.useState<boolean>(false);
  const [duration, setDuration] = React.useState<string>("");

  React.useEffect(() => {
    let newDuration =
      selectedDuration.duration_value !== undefined &&
      selectedDuration.duration_value !== -1
        ? " " + selectedDuration.duration_value
        : "";
    setDuration(newDuration);
    let newSelectedOption =
      (selectedDuration.duration_type_id !== undefined &&
        selectedDuration.duration_type_id) !== -1
        ? durationList.filter(
            (item) => item.durationTypeId === selectedDuration.duration_type_id
          )[0]
        : selectedOption;
    setSelectedOption(newSelectedOption);
  }, [selectedDuration]);

  const setDurationFun = React.useCallback(
    (duration: DurationType) => {
      setSelectedOption(duration);
      setHideOptions(false);
    },
    [setSelectedOption, setHideOptions]
  );

  const setDurationValue = React.useCallback((event: any) => {
    let value = event.target.value;
    let durationString: string = duration;
    if (value.length === 0) {
      durationString = "";
    } else {
      if (!isNaN(value) && value.length > 0) {
        let durationValue: number = Number(value);
        durationString = "" + durationValue;
      }
    }
    setDuration(durationString);
  }, []);

  const onCancel = React.useCallback(() => {
    clearNormalizedEdit();
    setDuration("");
    setHideOptions(false);
    setSelectedOption({
      durationTypeId: -1,
      durationName: "",
      durationType: "",
    });
  }, [clearNormalizedEdit]);

  const onSave = React.useCallback(() => {
    const newData = selectedDuration;
    newData.duration_type_id =
      selectedOption.durationTypeId !== -1
        ? selectedOption.durationTypeId
        : selectedDuration.duration_type_id;
    newData.duration_value =
      duration.length > 0 ? Number(duration) : selectedDuration.duration_value;
    newData.duration_type =
      selectedOption.durationTypeId !== -1
        ? selectedOption.durationName
        : selectedDuration.duration_type;
    editPhrasesRequest(newData);
    clearNormalizedEdit();
  }, [duration, selectedOption]);

  return (
    <div className="row">
      <div className="col-md-12" id="duration-container">
        <div className="row">
          <div className="col-md-3 py-2" style={{ marginLeft: "10px" }}>
            Duration
          </div>
          <div className="col-md-3 py-2">
            <input
              type="text"
              className="duration-input"
              value={duration === "-1" ? "" : duration}
              placeholder="Enter no."
              onChange={(e) => setDurationValue(e)}
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-3 py-2">
            <span
              className="filter-modal-input"
              style={{ background: "white", border: "1px solid #DDDDD" }}
              onClick={() => setHideOptions(!hideOptions)}
            >
              <input
                type="text"
                className="modal-input"
                placeholder="Select duration"
                style={{ width: "100%", border: "none", outline: "none" }}
                value={selectedOption?.durationName}
                readOnly
              />
              <img
                src="/static_images/tag-dropdown-active.svg"
                alt="dropdown"
                style={{
                  transform: hideOptions ? "rotate(180deg)" : "none",
                  zIndex: 1,
                }}
              />
            </span>
            {hideOptions && (
              <div
                className="col-md-12 filter-modal-autocomplete-container"
                style={{ margin: 0, paddingLeft: "2px" }}
              >
                <Scrollable maxHeight={100}>
                  {durationList.map((durationItem, i) => (
                    <div
                      className="filter-modal-input-suggestion cursor-pointer"
                      key={i}
                      onClick={() => setDurationFun(durationItem)}
                    >
                      {durationItem.durationName}
                    </div>
                  ))}
                </Scrollable>
              </div>
            )}
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-10 align-right">
            <span
              className="add-datapoint"
              style={{ textDecoration: "none" }}
              onClick={onCancel}
            >
              Cancel
            </span>
            {duration.length > 0 && selectedOption?.durationTypeId > -1 ? (
              <span
                className="upload-yellow-btn ml-4"
                id="save-btn"
                onClick={onSave}
              >
                Save
              </span>
            ) : (
              <span className="upload-disable-btn ml-4" id="save-btn">
                Save
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
