import { Button, Col, Container, Row } from "react-bootstrap";
import React, { useState } from "react";

import { TextField } from "@mui/material";

// import { useKeycloak } from "@react-keycloak/web";

const Nav = (props: any) => {
	// const { keycloak, initialized } = useKeycloak();
	const { handleKeycloakRealmNameChange } = props;
	const [realmName, setRealmName] = useState("");
	return (
		<div className="cover-background">
			<div className="row" style={{ height: '100%', width: '100%' }}>
				<div className="col-md-8 image-background responsive-background">
				</div>
				<div className="col-md-4 align-self-center position-right responsive-login" style={{ maxWidth: '480px' }}>
					<div className="mb-5 align-center">
						<img src="static_images/web-menubar-logo.svg" className="riverus-logo-login"></img>
					</div>
					<div className="realm-input-view mb-4">
						<p className="org-name-heading">Name of Organization</p>
						<TextField className="org-input mb-5" size="small" value={realmName} onChange={(e) => {
							setRealmName(e.target.value);
						}} fullWidth={true} id="outlined-basic" variant="outlined" />
						<div className="org-continue-btn"
							onClick={() => {
								console.log("click");
								handleKeycloakRealmNameChange(realmName);
							}}
						>
							Continue
						</div>
					</div>
					<p className="f-14 fw c-grey align-center">
						<a href="https://www.riverus.co" target="_blank" className="link c-grey">Home</a> | <a href="https://www.riverus.co/privacy-policy" target="_blank" className="c-grey link">Terms of Service</a> | <a href="https://www.riverus.co/privacy-policy" target="_blank" className="c-grey link">Privacy Policy</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Nav;
