import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
const imageAddress =
  "https://image.shutterstock.com/image-illustration/black-white-sign-that-says-260nw-1808760496.jpg";
const UnAuthorized = (props) => {
  return (
    <Container className="unauth-view">
      <Row>
        <Col>YOU ARE NOT AUTHORIZED TO VIEW THIS PAGE.</Col>
      </Row>
      <Row>
        <Col>
          <Image src={imageAddress} />
        </Col>
      </Row>
    </Container>
  );
};
export default UnAuthorized;
