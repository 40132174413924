interface buttonProps {
  // styleOverrides?: {},
  // variant: []
}

const buttonTheme: buttonProps = {
  styleOverrides: {
    root: {
      margin: "4px",
      padding: "10px 24px",
      fontSize: "14px",
      textTransform: "capitalize",
      fontWeight: "500",
      borderRadius: "100px",
    },
  },
  variants: [
    {
      props: { variant: "contained" },
      style: {
        outline: "none",
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        border: "2px solid",
        ":hover": {
          borderWidth: "2px",
        },
      },
    },
    {
      props: {
        variant: "text",
        ":hover": {
          backgroundColor: "transparent",
        },
      },
      style: {
        padding: "0",
      },
    },
    {
      props: {
        variant: "draft",
      },
      style: {
        color: "white",
        background: "#88305F",
        padding: "8px 16px",
        borderRadius: "6px",
        fontSize: "11px",
      },
    },
  ],
};

export default buttonTheme;
