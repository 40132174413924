import { Box, Button, IconButton, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";

import DocxEditingComponent from "./DocxEditingComponent";
import DocxViewerComponent from "./DocxViewerComponent";
import SearchIcon from "@mui/icons-material/Search";
import ValidationPopup from "./ValidationPopup";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

interface IProps {
  draftData: any;
  fileLink: string;
  handleOpenClauseLibraryDrawer?: VoidFunction;
  canEdit?: boolean;
  instance: any;
  setInstance: any;
  dropPoint?: any;
  setDropPoint?: any;
  addedSignatureFields?: any;
  setAddedSignatureFields?: any;
  hideSignatureButton?: any;
  setHideSignatureButton?: any;
}
const PdfView: React.FC<IProps> = ({
  draftData,
  fileLink,
  canEdit,
  instance,
  setInstance,
  setDropPoint,
  setAddedSignatureFields,
  setHideSignatureButton,
  handleOpenClauseLibraryDrawer,
}) => {
  const [hoverTextSource, setHoverTextSource] = React.useState<any>([]);

  const [validationPopup, setValidationPopup] = useState<any>({
    modal: false,
    text: "",
  });

  const zoomOut = () => {
    const zoom = instance.UI.getZoomLevel() - 0.25;
    if (zoom > 0.25) {
      instance.UI.setZoomLevel(zoom);
    }
  };

  const zoomIn = () => {
    const zoom = instance.UI.getZoomLevel() + 0.25;
    instance.UI.setZoomLevel(zoom);
  };

  const search = useCallback(() => {
    const { UI } = instance;
    UI.searchText("", {
      caseSensitive: true,
      wholeWord: true,
    });
  }, [instance]);

  const drop = useCallback(
    (e: any, docViewer: any) => {
      const scrollElement = docViewer.getScrollViewElement();
      const scrollLeft = scrollElement.scrollLeft || 0;
      const scrollTop = scrollElement.scrollTop || 0;
      setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
      e.preventDefault();
      return false;
    },
    [setDropPoint]
  );

  const dragOver = useCallback((e: any) => {
    e.preventDefault();
    return false;
  }, []);

  return (
    <React.Fragment>
      <Stack direction="row">
        <Stack
          sx={{
            background: "#88305F14",
            borderRadius: "25px",
            padding: "2px 10px",
            flex: 1,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <IconButton
            color="primary"
            onClick={() => {
              search();
            }}
          >
            <SearchIcon /> Search
          </IconButton>
          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={() => {
                zoomIn();
              }}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                zoomOut();
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </Stack>
        </Stack>
        {canEdit && (
          <Button variant="contained" onClick={handleOpenClauseLibraryDrawer}>
            Open Clause Library
          </Button>
        )}
      </Stack>
      <Box marginTop={1}>
        {canEdit ? (
          <DocxEditingComponent
            key={`${fileLink}-edit`}
            fileLink={fileLink}
            setInstance={setInstance}
            hoverTextSource={hoverTextSource}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            setHideSignatureButton={setHideSignatureButton}
            drop={drop}
            draftDetails={draftData}
          />
        ) : (
          <DocxViewerComponent
            key={fileLink}
            fileLink={fileLink}
            setInstance={setInstance}
            hoverTextSource={hoverTextSource}
            dragOver={dragOver}
            setAddedSignatureFields={setAddedSignatureFields}
            setHideSignatureButton={setHideSignatureButton}
            drop={drop}
            draftDetails={draftData}
          />
        )}
      </Box>
      {validationPopup && (
        <ValidationPopup
          validationPopup={validationPopup}
          onClose={() => {
            setValidationPopup({ modal: false, text: "" });
          }}
        />
      )}
    </React.Fragment>
  );
};

export default PdfView;
