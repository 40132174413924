import AddIcon from "@mui/icons-material/Add";
import { Stack } from "@mui/material";
import React from "react";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import { LoadingButton } from "@mui/lab";

interface Props {
  contractTypeId: string;
  handleAddCheckList: (payload: any) => void;
  handleSubmit: any;
  control: any;
  loadingAddCheckList: boolean;
  loadingCreateCheckList: boolean;
}

const AddNewChecklist: React.FC<Props> = ({
  handleSubmit,
  control,
  contractTypeId,
  handleAddCheckList,
  loadingAddCheckList,
  loadingCreateCheckList,
}) => {
  const onSubmit = async (data: any) => {
    const payload = {
      name: data?.name,
      checklist_type: "draft",
      contract_type: contractTypeId,
    };
    handleAddCheckList(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <ControlledTextField
          name="name"
          control={control}
          required
          label="Item label"
          fullWidth
        />
        <LoadingButton
          loading={loadingAddCheckList || loadingCreateCheckList}
          loadingPosition="start"
          variant="contained"
          startIcon={<AddIcon />}
          type="submit"
        >
          Add item to the checklist
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default AddNewChecklist;
