import {
  Box,
  Button,
  Drawer,
  FormControl,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";

import ApprovalChecklist from "./ApprovalChecklist";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";

interface Props {
  open: boolean;
  onClose: () => void;
  selectedChecklist: any;
  setSelectedChecklist: Dispatch<SetStateAction<any>>;
}

const ChecklistDrawer: React.FC<Props> = ({
  open,
  onClose,
  selectedChecklist,
  setSelectedChecklist,
}) => {
  const [item, setItem] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();

  const addItemToChecklist = React.useCallback(() => {
    const index = selectedChecklist.indexOf(item);
    if (index === -1) {
      selectedChecklist.push(item);
      setSelectedChecklist(selectedChecklist);
    } else {
      enqueueSnackbar("Already exist!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
    setItem("");
  }, [selectedChecklist, item, setSelectedChecklist, enqueueSnackbar]);

  const deleteChecklist = React.useCallback(
    (item: string) => {
      let selected = selectedChecklist.filter((list: string) => list !== item);
      setSelectedChecklist(selected);
    },
    [selectedChecklist, setSelectedChecklist]
  );

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box>
        <FormControl
          sx={{ m: 2, width: "84%" }}
          component="fieldset"
          variant="standard"
        >
          <FormLabel
            component="legend"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Typography>Modify policy checklist</Typography>
          </FormLabel>
          <ApprovalChecklist
            list={selectedChecklist}
            renderAction={(id: string) => (
              <IconButton
                aria-label="delete"
                onClick={() => deleteChecklist(id)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          />
          <TextField
            label="Add another item"
            value={item}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setItem(event.target.value);
            }}
          />
          <Button
            variant="contained"
            onClick={addItemToChecklist}
            sx={{
              width: "100%",
              marginTop: "10px",
            }}
          >
            + Add item to the checklist
          </Button>
        </FormControl>
      </Box>
    </Drawer>
  );
};

export default ChecklistDrawer;
