import React, { Component } from "react";
import { DarkTooltip } from "../../../../DocumentView/Component/documentInsights";
import {
  getUploadedTime,
  truncateFileName,
  truncateString,
} from "../../../../Utils/DataModifierUtil/dataModUtil";
import { NotificationData } from "../../State/notificationState";

interface Props {
  notification: NotificationData;
  removeNotification: (notificationID: string) => void;
}

interface State {
  isActive: boolean;
}

export default class CommentNotification extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  componentDidMount() {}

  removeNotification = () => {};

  render() {
    let { notification, removeNotification } = this.props;
    let { isActive } = this.state;
    return (
      <>
        {/* {JSON.stringify(notification)} */}

        <div
          className="col-md-12"
          style={
            { borderLeft: "6px solid #FBCE2F" }

            // { borderLeft: notification.isRead === false ? '6px solid #FBCE2F' : '' }
          }
          id="notification-type-container"
        >
          <div className="col-md-12 p-0" style={{ display: "inline-flex" }}>
            <div className="col-md-1">
              <img alt="comment-icon" src="/static_images/comment-icn.svg" />
            </div>
            <div className="col-md-10 mt-1 pb-2 task-title">
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "/document/tasks/" +
                      btoa(notification.id.toString()) +
                      "/" +
                      btoa(notification.id.toString()),
                    "_blank"
                  )
                }
              >
                {notification.details.created_by} commented
              </span>
            </div>
            <div className="col-md-1">
              <span
                style={{ float: "right", cursor: "pointer" }}
                id="close-btn"
                onClick={() => removeNotification(notification.id)}
              >
                <img src="/static_images/close-modal-icn.svg" alt="close" />
              </span>
            </div>
          </div>
          <div className="col-md-12 mt-1 file-name">
            {notification.details.task.contract_file_name !== null &&
            notification.details.task.contract_file_name.length > 15 ? (
              <DarkTooltip
                title={notification.details.task.contract_file_name}
                placement="right-end"
              >
                <span
                  style={{ display: "initial", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/document/analysis/" + btoa(notification.id),
                      "_blank"
                    )
                  }
                >
                  {truncateFileName(
                    notification.details.task.contract_file_name
                  )}{" "}
                  <img src="/static_images/open-file-icn.svg" alt="close" />
                </span>
              </DarkTooltip>
            ) : (
              <span
                style={{ display: "initial", cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "/document/analysis/" + btoa(notification.id),
                    "_blank"
                  )
                }
              >
                {notification.details.task.contract_file_name}{" "}
                <img src="/static_images/open-file-icn.svg" alt="close" />
              </span>
            )}
          </div>
          <div className="col-md-12 mt-2 clause-bi-name">
            <span>
              {notification.details.task.contract_clause_type}

              {/* - {notification.biType} */}
            </span>
          </div>
          <div className="col-md-12 mt-2 clause-bi-name">
            <span>"{notification.details.message}"</span>
          </div>
          <div
            className="col-md-12 mt-3 mb-2"
            style={{ display: "inline-flex" }}
          >
            <div className={"col-md-4 mr-2 status "}>
              {/* <span>{notification.progress}</span> */}
              <span>Comment</span>
            </div>
            <div className="col-md-4">
              {/* <span>{notification.state}</span> */}
            </div>
            <div className="col-md-4 mt-1 pr-0 alert-date">
              <span>{getUploadedTime(notification.created_at)}</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
