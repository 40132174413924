import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects";
import { SITE_API_BY_REALM_NAME } from "../../Configuration/global";
import TaskManagementPageAPI from "../Actions/api";
import {
  GETALLTASKSDATA,
  GetAllTasksData,
  GETLASTUPDATEDON,
  GetLastUpdatedOn,
  GETPROGRESSNAMELIST,
  GetProgressNameList,
  GETTASKCOUNT,
  GetTaskCount,
  UPDATEPROGRESS,
  UpdateProgress,
} from "../Actions/def";
import TaskManagementPageActionGenerator from "../Actions/gen";
import {
  AllTasksData,
  ProgressNameList,
  TaskCount,
} from "../State/taskManagementPageState";
import { getKeyCloakRealmFromLS } from "../../Authentication/Actions/authentication";
import { getTaskNormalizedDateToDateString } from "../../Utils/DataModifierUtil/dataModUtil";
import NotificationGenerator from "../../UniversalComponents/Notification/Actions/gen";
import { ResponseGenerator } from "../../Types";

const SITEAPI = SITE_API_BY_REALM_NAME(getKeyCloakRealmFromLS());
function* getAllTasksData(action: GetAllTasksData) {
  let fileID = action.payload.fileID;
  let requestID = action.payload.requestID;
  let sort = action.payload.sort;
  let order = action.payload.order;
  let selfAssigned = action.payload.selfAssigned;
  let clauseType = action.payload.clauseType;
  let url = process.env.REACT_APP_RIVERUS_CLM_API + "tasks/";

  if (requestID != "0") {
    url = url + "" + requestID + "/";
  }

  if (order == "desc") {
    url = url + "?ordering=-" + sort;
  } else {
    url = url + "?ordering=" + sort;
  }

  if (fileID != "0") {
    url = url + "&contract_id=" + fileID;
  }

  // let url = "http://localhost:6064/contracts/task/all";
  //SITEAPI + "task/all";

  try {
    let response:ResponseGenerator = yield call(
      TaskManagementPageAPI.getAllTasksData,
      url,
      fileID,
      requestID,
      sort,
      order,
      selfAssigned,
      clauseType
    );

    switch (response.status) {
      case 200: {
        // let allTasksData = response.data.queryResult as AllTasksData[];

        let allTasksData = [] as AllTasksData[];
        if (requestID != "0") {
          let task_details = [];
          task_details.push(response.data);
          allTasksData = task_details as AllTasksData[];
        } else {
          allTasksData = response.data.results as AllTasksData[];
        }
        if (allTasksData === undefined) {
          allTasksData = [];
        }
        if (selfAssigned == true) {
          if (
            localStorage.getItem("user_id") == undefined ||
            localStorage.getItem("user_id") == null
          ) {
            allTasksData = [];
          } else {
            var user_id = localStorage.getItem("user_id");

            var data_filter = allTasksData.filter(function (element) {
              return element.assignees.some(function (assignee) {
                return assignee.id === user_id;
              });
            });
            var assigneeList = [];
            for (var i = 0; i < data_filter.length; i++) {
              assigneeList.push(data_filter[i]);
              var loop_assignees = data_filter[i]["assignees"];
              data_filter[i]["assignees"] = loop_assignees.filter(
                (assignee: any) => assignee.id === user_id
              );
            }
            allTasksData = assigneeList;
          }
        }

        yield put(
          TaskManagementPageActionGenerator.getAllTasksDataSuccess(allTasksData)
        );
        break;
      }
      default: {
        yield put(TaskManagementPageActionGenerator.getAllTasksDataFailure());
        break;
      }
    }
  } catch (error) {
    yield put(TaskManagementPageActionGenerator.getAllTasksDataFailure());
  }
}

function* getLastUpdatedOn(action: GetLastUpdatedOn) {
  let selfAssigned = action.payload.selfAssigned;

  // let url = SITEAPI + "task/all/lastupdate";
  let url = process.env.REACT_APP_RIVERUS_CLM_API + "tasks/";
  try {
    let response:ResponseGenerator = yield call(
      TaskManagementPageAPI.getLastUpdatedOn,
      url,
      selfAssigned
    );

    switch (response.status) {
      case 200: {
        // let lastUpdate = response.data.queryResult.lastUpdate;
        var res = response.data.results;
        var lastUpdate = "";
        if (res.length != 0) {
          lastUpdate = getTaskNormalizedDateToDateString(
            response.data.results[0]["created_at"].split("T")[0]
          );
          // response.data.results[0]["created_at"];
        }

        yield put(
          TaskManagementPageActionGenerator.getLastUpdatedOnSuccess(lastUpdate)
        );
        break;
      }
      default: {
        yield put(TaskManagementPageActionGenerator.getLastUpdatedOnFailure());
      }
    }
  } catch (e) {
    yield put(TaskManagementPageActionGenerator.getLastUpdatedOnFailure());
  }
}

function* getProgressNameList(action: GetProgressNameList) {
  let url = SITEAPI + "task/progress";
  // yield put(TaskManagementPageActionGenerator.getProgressNameListSuccess([]));
  try {
    // let response:ResponseGenerator = yield call(TaskManagementPageAPI.getProgressNameList, url);
    let response = {
      queryResult: [
        { id: "1", name: "Not Started" },
        { id: "2", name: "In Progress" },
        { id: "3", name: "Completed" },
      ],
    };
    let progressNameList = response.queryResult as ProgressNameList[];

    yield put(
      TaskManagementPageActionGenerator.getProgressNameListSuccess(
        progressNameList
      )
    );

    /*switch (response.status) {
      case 200: {
        let progressNameList = response.data.queryResult as ProgressNameList[];
        yield put(
          TaskManagementPageActionGenerator.getProgressNameListSuccess(
            progressNameList
          )
        );
        break;
      }
      default: {
        // yield put(
        //   TaskManagementPageActionGenerator.getProgressNameListFailure()
        // );
        break;
      }
    }*/
  } catch (error) {
    // yield put(TaskManagementPageActionGenerator.getProgressNameListFailure());
  }
}

function* updateProgress(action: UpdateProgress) {
  let requestID = action.payload.requestID;
  let progressID = action.payload.progressID;
  let id = action.payload.id;

  // let url = SITEAPI + "task/progress";
  let url = process.env.REACT_APP_RIVERUS_CLM_API + "tasks/" + id + "/";

  var progressList = [
    { id: "1", name: "Not Started" },
    { id: "2", name: "In Progress" },
    { id: "3", name: "Completed" },
  ];
  var index = progressList.findIndex((obj) => obj.id === progressID.toString());
  var progressNameList = {
    "Not Started": "NOT_STARTED",
    "In Progress": "IN_PROGRESS",
    Completed: "COMPLETED",
  };
  var progressName = "";
  if (index == 0) {
    progressName = progressNameList["Not Started"];
  }
  if (index == 1) {
    progressName = progressNameList["In Progress"];
  }
  if (index == 2) {
    progressName = progressNameList["Completed"];
  }

  try {
    let response:ResponseGenerator = yield call(
      TaskManagementPageAPI.updateProgress,
      url,
      requestID,
      progressID,
      progressName
    );

    switch (response.status) {
      case 200: {
        yield put(
          TaskManagementPageActionGenerator.updateProgressSuccess(
            1,
            requestID,
            progressID,
            id
          )
        );
        yield put(NotificationGenerator.getNotificationsData());
        break;
      }
      default: {
        yield put(TaskManagementPageActionGenerator.updateProgressFailure(0));
        break;
      }
    }
  } catch (error) {
    yield put(TaskManagementPageActionGenerator.updateProgressFailure(0));
  }
}

function* getTaskCount(action: GetTaskCount) {
  // let url = "http://localhost:6064/contracts/task/count"; //SITEAPI + "task/count";
  let url = process.env.REACT_APP_RIVERUS_CLM_API + "tasks/statistics/";
  try {
    let response:ResponseGenerator = yield call(TaskManagementPageAPI.getTaskCount, url);
    switch (response.status) {
      case 200: {
        let taskCount = response.data.task_statistic as TaskCount; //response.data.queryResult as TaskCount;
        yield put(
          TaskManagementPageActionGenerator.getTaskCountSuccess(taskCount)
        );
        break;
      }
      default: {
        yield put(TaskManagementPageActionGenerator.getTaskCountFailure());
        break;
      }
    }
  } catch (error) {
    yield put(TaskManagementPageActionGenerator.getTaskCountFailure());
  }
}
export default function* taskManagementPageWatcher() {
  yield all([
    takeLatest(GETALLTASKSDATA, getAllTasksData),
    takeLatest(GETLASTUPDATEDON, getLastUpdatedOn),
    takeLatest(GETPROGRESSNAMELIST, getProgressNameList),
    takeEvery(UPDATEPROGRESS, updateProgress),
    takeLatest(GETTASKCOUNT, getTaskCount),
  ]);
}
