import * as changesets from "json-diff-ts";

import { ClauseType, ClauseTypeMap } from "../../../../Constants/ClauseType";
import { PartyData, SentencesData } from "../../../../State/documentState";
import React, { Component } from "react";
import {
  deleteDataFromObligation,
  getClauseDataFormat,
  getClauseObjects,
  getEditedPayload,
} from "../../../ClauseComponent/utils/ClauseTypeUtils";

import SaveOrCancel from "../saveOrCancel";
import { isNullOrUndefined } from "is-what";

interface Props {
  editOptionSelected: (editOptionSelected: boolean) => void;
  editParties: (parties: PartyData[]) => void;
  savedParties: PartyData[];
  saveHighlightedId: (highlightedId: number[] | null) => void;
  onClose: any;
  fileId: string;
  clauseType: string;
  wholeData: any;
  postClauseDataByType: (
    fileID: string,
    type: ClauseType,
    payload: any,
    updatedObject: any
  ) => void;
  updatedClauseData: any;
  sentenceData: SentencesData;
  clauseDataByType: any;
  updatedClauseDataByType: any;
  parentClauseType: any;
  clauseData: any;
}

interface State {
  partyDataString: PartyData[];
  partyName: string;
  partyType: string;
  origData: any;
  present: string;
  origDataLength: number;
  deletedDataString: PartyData[];
}

export default class Parties extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      partyDataString: props.savedParties,
      partyName: "",
      partyType: "",
      origData: props.clauseDataByType,
      present: "Yes",
      origDataLength: props.savedParties.length,
      deletedDataString: [],
    };
  }
  render() {
    let { editOptionSelected, saveHighlightedId } = this.props;
    return (
      <div className="row toc-content toc-content-default">
        <div className="col-md-12">
          <div className="row mt-1">
            <div
              className="col-md-12 m-0 bi-label-clickable mt-2"
              style={{ fontWeight: 600 }}
            >
              Contracting parties
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3 edit-title-header">
              Add parties to your contract here...
            </div>
          </div>
          {this.getParties()}
          {/* <div className="row">
                        <div className="col-md-12">
                            <span className="mr-2">
                                <img alt='active' src='/static_images/checkbox_active.svg' className="filter-select-asset cursor-pointer " />
                            </span>&nbsp;&nbsp;
                            Share feedback with Riverus.
                        </div>
                    </div> */}
          <SaveOrCancel
            enableHighlightOption={true}
            dataPointName={"Parties"}
            editOptionSelected={editOptionSelected}
            editDataPoint={() => {
              this.editParties();
            }}
            highlightedId={null}
            enableSaveBtn={true}
            saveHighlightedId={(highlightedId: number[] | null) =>
              saveHighlightedId(highlightedId)
            }
            handleSubmitSelectedUserGroups={() => {}}
            onClose={this.props.onClose}
          />
        </div>
      </div>
    );
  }

  editPresent() {
    let {
      clauseType,
      sentenceData,
      clauseDataByType,
      fileId,
      postClauseDataByType,
      updatedClauseData,
      updatedClauseDataByType,
      parentClauseType,
      clauseData,
    } = this.props;
    let partyDataString = this.state.partyDataString;
    let updatedData = updatedClauseDataByType;
    let newData = updatedData;
    let obligationData = updatedClauseData.obligationStatements;

    if (this.state.present === "Yes") {
      if (this.state.deletedDataString.length > 0) {
        let deletedData = this.state.deletedDataString;
        for (let i = 0; i < deletedData.length; i++) {
          newData = getClauseDataFormat(
            "remove",
            clauseType as ClauseType,
            deletedData[i],
            newData,
            sentenceData
          );
        }
      }

      let addedData = partyDataString;
      for (var i = 0; i < addedData.length; i++) {
        newData = getClauseDataFormat(
          "add",
          clauseType as ClauseType,
          addedData[i],
          newData,
          sentenceData
        );
        obligationData = deleteDataFromObligation(
          "sentence",
          addedData[i],
          obligationData
        );
      }
    } else {
      newData = getClauseObjects(clauseType as ClauseType);
    }
    this.setState({ deletedDataString: [] });

    const diff = changesets.diff(clauseDataByType?.raw_content, newData, {
      children: "$index",
    });
    const obligationDiff = changesets.diff(
      clauseData?.obligationStatements?.raw_content,
      obligationData,
      { children: "$index" }
    );

    if (diff.length > 0) {
      postClauseDataByType(
        fileId,
        parentClauseType || clauseType,
        diff,
        newData
      );
    }
    if (obligationDiff.length > 0) {
      postClauseDataByType(
        fileId,
        "obligation_statements",
        obligationDiff,
        obligationData
      );
    }
  }

  editParties() {
    let { partyDataString } = this.state;
    if (this.state.partyName && this.state.partyName.length > 0)
      partyDataString.push({
        partyName: this.state.partyName,
        partyType: this.state.partyType,
        mode: "manual",
        id: "",
      });
    this.props.editParties(partyDataString);
    this.addOrRemoveParties("save");
    this.editPresent();
    this.props.onClose();
  }
  handlePartyDataChange = (index: number, key: string, value: string) => {
    let partyDataString_: PartyData[] = this.state.partyDataString;
    partyDataString_[index][key as keyof PartyData] = value;
    this.setState({ partyDataString: partyDataString_ });
  };

  getParties() {
    let { partyDataString, partyName, partyType } = this.state;
    if (partyDataString.length > 0) {
      return (
        <>
          {partyDataString.map((party, i) => (
            <div className="row my-1" key={i}>
              <div className="col-md-12">
                <input
                  type="text"
                  value={party.partyName ? party.partyName : party.party}
                  // readOnly
                  className="title-input"
                  onChange={(e) => {
                    this.handlePartyDataChange(i, "partyName", e.target.value);
                  }}
                  style={{ width: "80%" }}
                />
              </div>
              <div className="col-md-12 mt-2">
                <input
                  type="text"
                  value={party.partyType ? party.partyType : ""}
                  // readOnly
                  className="title-input"
                  onChange={(e) => {
                    this.handlePartyDataChange(i, "partyType", e.target.value);
                  }}
                  style={{ width: "50%" }}
                />
              </div>
              <div className="col-md-2 mt-2">
                <img
                  className="cursor-pointer"
                  src="/static_images/less-parties.svg"
                  alt="remove-icon"
                  onClick={() => this.addOrRemoveParties("remove", party)}
                />
              </div>
            </div>
          ))}
          <div className="row my-1">
            <div className="col-md-12">
              <input
                type="text"
                value={partyName !== "" ? partyName : ""}
                className="title-input"
                placeholder="Enter party name"
                onChange={(e) =>
                  this.setState({ partyName: e.currentTarget.value })
                }
                style={{ width: "80%" }}
              />
            </div>
            <div className="col-md-12 mt-2">
              <input
                type="text"
                value={partyType !== "" ? partyType : ""}
                className="title-input"
                placeholder="Enter party type"
                onChange={(e) =>
                  this.setState({ partyType: e.currentTarget.value })
                }
                style={{ width: "50%" }}
              />
            </div>
            <div className="col-md-2 mt-2">
              {partyName !== "" ? (
                <img
                  className="cursor-pointer"
                  src="/static_images/more-parties.svg"
                  alt="add-more"
                  onClick={() => this.addOrRemoveParties("add")}
                />
              ) : (
                <img
                  src="/static_images/more-parties-disabled.svg"
                  alt="add-more"
                />
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="row my-1">
          <div className="col-md-12">
            <input
              type="text"
              value={partyName !== "" ? partyName : ""}
              className="title-input"
              placeholder="Enter party name"
              onChange={(e) =>
                this.setState({ partyName: e.currentTarget.value })
              }
              style={{ width: "80%" }}
            />
          </div>
          <div className="col-md-12 mt-2">
            <input
              type="text"
              value={partyType !== "" ? partyType : ""}
              className="title-input"
              placeholder="Enter party type"
              onChange={(e) =>
                this.setState({ partyType: e.currentTarget.value })
              }
              style={{ width: "50%" }}
            />
          </div>
          <div className="col-md-2 mt-2">
            {partyName !== "" ? (
              <img
                className="cursor-pointer"
                src="/static_images/more-parties.svg"
                alt="add-more"
                onClick={() => this.addOrRemoveParties("add")}
              />
            ) : (
              <img
                src="/static_images/more-parties-disabled.svg"
                alt="add-more"
              />
            )}
          </div>
        </div>
      );
    }
  }

  addOrRemoveParties(action: string, name?: PartyData) {
    let { partyDataString, partyName, partyType } = this.state;
    let tempPartyList: PartyData[] = partyDataString;
    if (action === "add") {
      tempPartyList.push({
        partyName: partyName,
        partyType: partyType,
        mode: "manual",
        id: "",
      });
    } else if (action === "remove" && !isNullOrUndefined(name)) {
      tempPartyList = tempPartyList.filter((el) => {
        if (el === name) {
          this.state.deletedDataString.push(el);
        }
        return el !== name;
      });
    } else {
      if (partyName === "") {
        tempPartyList = tempPartyList.filter((el) => {
          return el !== name;
        });
      } else {
        /*tempPartyList.push({
          partyName: partyName,
          partyType: partyType,
          mode: "manual",
          id: "",
        });*/
      }
    }
    this.setState({ partyDataString: tempPartyList });
    this.setState({ partyName: "", partyType: "" }); //Set back to default
  }
}
