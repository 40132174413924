import { Button, Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import ConfirmationModal from "../../../RiverusUI/Components/ConfirmationModalComponent";
import ControlledTextField from "../../../RiverusUI/Components/ControlledTextField";
import CustomModal from "../../../RiverusUI/Components/CustomModal";
import { LoadingButton } from "@mui/lab";
import RadioButtonGroup from "../../../RiverusUI/Components/RadioButtonGroup";
import SelectElement from "../../../RiverusUI/Components/SelectElement";
import { assignOptions } from "./StaticData";
import { useForm } from "react-hook-form";

interface Props {
  open: boolean;
  onClose: VoidFunction;
  propsData: any;
  handleAssignee: (payload: any) => void;
  loadingUpdateItem?: boolean;
  approversData: any;
}

const AssignDialog: React.FC<Props> = ({
  open,
  onClose,
  propsData,
  handleAssignee,
  loadingUpdateItem,
  approversData,
}) => {
  const methods = useForm();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const { handleSubmit, reset, control, watch, resetField } = methods;

  const assignee_type = watch("assignee_type") || "";
  const selectUser = watch("select_user") || "";

  useEffect(() => {
    if (propsData.user_type === "internal") {
      reset({
        select_user: propsData?.email,
        assignee_type: propsData?.user_type,
      });
    } else {
      reset({
        email: propsData?.email,
        name: propsData?.user_name,
        assignee_type: propsData?.user_type,
      });
    }
  }, [propsData, reset]);

  useEffect(() => {
    resetField("name");
    resetField("email");
    resetField("select_user");
  }, [assignee_type, resetField]);

  const getUserDetail = useMemo(() => {
    const userData = approversData?.find(
      (item: any) => item?.email === selectUser
    );
    return { email: userData?.email, name: userData?.name, id: userData?.id };
  }, [approversData, selectUser]);

  const handleCloseDialog = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleConfirm = useCallback(() => {
    setOpenConfirmationModal(false);
    handleCloseDialog();
  }, [handleCloseDialog]);

  const onSubmit = useCallback(
    (data: any) => {
      const payload = {
        user_name: getUserDetail?.name || data?.name,
        email: data?.select_user || data?.email,
        assignee_type: data?.assignee_type,
        id: getUserDetail?.id,
      };
      handleAssignee(payload);
      reset();
    },
    [getUserDetail, handleAssignee, reset]
  );

  return (
    <React.Fragment>
      <CustomModal
        title="Assign this item to someone"
        open={open}
        handleClose={handleCloseDialog}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack margin="10px">
            <Typography fontSize="14px" pt="20px">
              Assign “Develop Recommendations” item to:
            </Typography>
            <RadioButtonGroup
              name="assignee_type"
              control={control}
              row
              required
              options={assignOptions}
              valueKey="value"
            />
            {assignee_type === "internal" && (
              <SelectElement
                label="Select User"
                name="select_user"
                control={control}
                options={approversData}
                labelKey="name"
                valueKey="email"
              />
            )}
            {assignee_type === "external" && (
              <Grid container alignItems="center" spacing={2}>
                <Grid item sm={6}>
                  <ControlledTextField
                    name="name"
                    control={control}
                    label="Name"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6}>
                  <ControlledTextField
                    name="email"
                    control={control}
                    label="Email"
                    fullWidth
                    type="email"
                  />
                </Grid>
              </Grid>
            )}
            <Stack alignItems="start" direction="row" marginTop="15px">
              <LoadingButton
                type="submit"
                loading={loadingUpdateItem}
                loadingPosition="start"
                variant="contained"
              >
                Assign and close
              </LoadingButton>
              <Button
                variant="outlined"
                onClick={() => setOpenConfirmationModal(true)}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </CustomModal>
      <ConfirmationModal
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        onConfirm={handleConfirm}
        title="Confirmation"
        message="Are you sure you want to close this?"
      />
    </React.Fragment>
  );
};

export default AssignDialog;
