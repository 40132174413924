import { IObject } from "../interfaces/IObject";

const CHOICES_QUERY_KEY = "choices";
const APPROVAL_QUERY_KEY = "approvals";

export type QueryType = (string | IObject)[];

export class QueryKeyGenerator {
  static getChoiceFetchingQuery(): QueryType {
    return [CHOICES_QUERY_KEY];
  }
  static getRequestApproval(): QueryType {
    return [APPROVAL_QUERY_KEY];
  }
}
