export const convertPDF = async (Core: any, fileURL: any) => {
  // perform the conversion with no optional parameters
  let file;
  try {
    const buf = await Core.officeToPDFBuffer(fileURL);

    //optionally save the blob to a file or upload to a server
    const blob = new Blob([buf], { type: "application/pdf" });
    file = blob;
  } catch (error) {
    console.log("Error", error);
    file = undefined;
  }
  return file;
};

export const downloadFile = (blob: any, fileName: string, fileExt: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${fileName}.${fileExt}`;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const highlightedSentence = (
  sentence: string,
  instance: any
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (!instance) {
      reject("PDFTron instance not available");
      return;
    }

    const { Core } = instance;
    const { documentViewer, Annotations, annotationManager } = Core;
    const annotations = annotationManager.getAnnotationsList();
    const searchMode =
      instance.Core.Search.Mode.PAGE_STOP | instance.Core.Search.Mode.HIGHLIGHT;

    const searchOptions = {
      fullSearch: true,
      onResult: (result: any) => {
        if (result.resultCode === instance.Core.Search.ResultCode.FOUND) {
          const highlight = new Annotations.TextHighlightAnnotation();
          result.quads.forEach((quad: any) => {
            highlight.Quads.push(quad.getPoints());
          });

          highlight.PageNumber = result.pageNum;
          annotationManager.addAnnotation(highlight);
          annotationManager.redrawAnnotation(highlight);
          annotationManager.drawAnnotations({ pageNumber: result.pageNum });

          if (documentViewer.getCurrentPage() !== result.pageNum) {
            documentViewer.setCurrentPage(result.pageNum, true);
          }
        }
      },
      onDocumentEnd: () => {
        resolve(); // Resolve the promise once highlighting is completed
      },
    };

    documentViewer.textSearchInit(sentence, searchMode, searchOptions);
    annotationManager.deleteAnnotations(annotations);
  });
};
